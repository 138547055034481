<template>
  <v-container fluid class="pa-0">
    <v-carousel cycle
                interval="8000"
                hide-delimiter-background>
      <v-carousel-item>
        <v-sheet class="fill-height"
                 :dark="false"
                 color="sliderBackground">
          <v-container class="fill-height">
            <v-row class="fill-height" align="center" align-content="center">
              <v-col cols="12" sm="6">
                <v-row justify="center">
                  <v-img :src="`${publicPath}images/dummy/macBook@1024.png`"
                         width="100%"
                         max-width="540px" />
                </v-row>
              </v-col>
              <v-col cols="12" sm="6" class="text-center">
                <div class="text-h2 mb-3 text--primary">
                  Az új MacBook Pro
                </div>
                <div class="text-h5 mb-3 text--primary">
                  A legjobbat a legjobbaknak.
                </div>
                <div class="text-subtitle-1 mx-auto mb-3 text--secondary" style="max-width: 80%;">
                  16 hüvelykes Retina kijelző, szupergyors processzorok, új generációs GPU-k, minden idők legnagyobb
                  kapacitású MacBook Pro-akkumulátora
                </div>
                <v-btn color="primary"
                       text>
                  Ismerje meg közelebbről
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
export default {
  name: 'SliderStatic',
  components: {
    //
  },
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
