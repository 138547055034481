/**
 * Az összes általunk használt komponenst itt regisztráljuk globálisan.
 * A komponens használata:
 * pl. <article-title-1 :element-props="<Object>"></article-title-1>
 * vagy <component :is="article-title-1" :element-props="<Object>"></component>
 * Az összes komponens kötelező bemenő props-a az :element-props="<Object>|<Array>|<Boolean>".
 */
import Vue from 'vue';

import ActualJobList1 from '@/components/basicElements/career/ActualJobList1.vue';
import ContactForm1 from '@/components/basicElements/forms/ContactForm1.vue';
import JobApplicationForm from '@/components/basicElements/forms/JobApplicationForm.vue';
import Split1 from '@/components/basicElements/split/Split1.vue';
import ImageGallery1 from '@/components/basicElements/imageGallery/ImageGallery1.vue';
import ImageGallery2 from '@/components/basicElements/imageGallery/ImageGallery2.vue';
import About1 from '@/components/basicElements/about/About1.vue';
import SimpleImage1 from '@/components/basicElements/imageGallery/SimpleImage1.vue';
import SimpleImage2 from './basicElements/imageGallery/SimpleImage2.vue';
import SimpleList1 from '@/components/basicElements/lists/SimpleList1.vue';
// Articles
import ArticleLayout5 from '@/components/basicElements/articles/ArticleLayout5.vue';
import ArticleLayout6 from '@/components/basicElements/articles/ArticleLayout6.vue';
import ArticleLayout7 from '@/components/basicElements/articles/ArticleLayout7.vue';
import ImageGallery3 from '@/components/basicElements/imageGallery/ImageGallery3.vue';
// Boxes
import PromoBox from '@/components/basicElements/boxes/PromoBox.vue';
import ArticleTitle1 from './basicElements/articles/ArticleTitle1.vue';
import ArticleLayout1 from './basicElements/articles/ArticleLayout1.vue';
import ArticleLayout2 from './basicElements/articles/ArticleLayout2.vue';
import ArticleLayout3 from './basicElements/articles/ArticleLayout3.vue';
import ArticleLayout4 from './basicElements/articles/ArticleLayout4.vue';
import Card1 from './basicElements/cards/Card1.vue';
import Card2 from './basicElements/cards/Card2.vue';
import Card3 from './basicElements/cards/Card3.vue';
import CurvedBox from './basicElements/boxes/CurvedBox.vue';
import HeaderType1 from './basicElements/headers/HeaderType1.vue';
import ListStyle1 from './basicElements/lists/ListStyle1.vue';
import Faq from './basicElements/lists/Faq.vue';
import Faq2 from '@/components/basicElements/lists/Faq2.vue';
import Stripe1 from './basicElements/stripes/Stripe1.vue';
import BasicSlider from './basicElements/sliders/BasicSlider.vue';
import BasicSpacer from '@/components/basicElements/spacers/BasicSpacer.vue';
import SliderStatic from '@/components/basicElements/sliders/SliderStatic.vue';
import AppointmentBooking from '@/components/basicElements/forms/AppointmentBooking.vue';
import PriceList1 from '@/components/basicElements/lists/PriceList1.vue';
// Forms
import AppleForm from '@/components/formTemplates/AppleForm.vue';
import TrackingLoginForm from '@/components/basicElements/forms/TrackingLoginForm.vue';

// google map
import MyGoogleMap from '@/components/shared/MyGoogleMap.vue';

Vue.component('article-title-1', ArticleTitle1);
Vue.component('article-layout-1', ArticleLayout1);
Vue.component('article-layout-2', ArticleLayout2);
Vue.component('article-layout-3', ArticleLayout3);
Vue.component('article-layout-4', ArticleLayout4);
Vue.component('article-layout-5', ArticleLayout5);
Vue.component('article-layout-6', ArticleLayout6);
Vue.component('article-layout-7', ArticleLayout7);
Vue.component('header-1', HeaderType1);
Vue.component('card-1', Card1);
Vue.component('card-2', Card2);
Vue.component('card-3', Card3);
Vue.component('list-1', ListStyle1);
Vue.component('faq', Faq);
Vue.component('stripe-1', Stripe1);
Vue.component('basic-slider', BasicSlider);
Vue.component('contact-form-1', ContactForm1);
Vue.component('actual-job-list-1', ActualJobList1);
Vue.component('job-application-form', JobApplicationForm);
Vue.component('split-1', Split1);
Vue.component('image-gallery-1', ImageGallery1);
Vue.component('image-gallery-2', ImageGallery2);
Vue.component('image-gallery-3', ImageGallery3);
Vue.component('about-1', About1);
Vue.component('simple-image-1', SimpleImage1);
Vue.component('simple-image-2', SimpleImage2);
Vue.component('simple-list-1', SimpleList1);
Vue.component('promobox', PromoBox);
Vue.component('apple-form', AppleForm);
Vue.component('basic-spacer', BasicSpacer);
Vue.component('tracking-login-form', TrackingLoginForm);
Vue.component('slider-apple', SliderStatic);
Vue.component('faq2', Faq2);
Vue.component('my-googlemap', MyGoogleMap);
Vue.component('appointment-booking', AppointmentBooking);
Vue.component('price-list-1', PriceList1);
Vue.component('curved-box', CurvedBox);
