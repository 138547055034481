/* eslint-disable no-shadow */

// initial state
const state = {
  requiredField: (value) => !!value || 'Kötelező kitölteni',
  emailValidator: (email) => /.+@.+\..+/.test(email) || 'A helyes formátum: minta@domain.hu',
  phoneValidator: (value) => /^[0-9-]*$/.test(value) || 'Csak számokat írjon!',
  numberValidator: (value) => /^[0-9]*$/.test(value) || 'Csak számokat írjon!',
  codeValidatorLowerCase: (value) => /^[a-z0-9-]*$/.test(value) || 'Csak ékezet nélküli kisbetűket, számokat és kötőjelet tartalmazhat!',
  codeValidatorUpperCase: (value) => /^[A-Z0-9-]*$/.test(value) || 'Csak ékezet nélküli nagybetűket, számokat és kötőjelet tartalmazhat!',
  selectRule: (value) => (value && value.id > 0) || 'Kötelező kitölteni',
  vatRules: [
    (value) => !!value || 'Kötelező kitölteni',
    (value) => (value && value.length === 13) || 'Kötelező kitölteni',
  ],
};

// getters
const getters = {
  //
};

// actions
const actions = {
  //
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
