<template>
  <div v-frag>
    <!-- CONTENT START -->
    <v-container :class="editorMode ? 'b-wrapper': ''">
      <!-- PAGE BUILDER ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            Card1
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- PAGE BUILDER ACTIONBOX END -->
      <v-row>
        <v-col cols="12" sm="5" md="4">
          <v-card elevation="0" class="mb-8">
            <v-card-title>
              <span class="text-h6">
                {{ $t('themeGroups') }}
              </span>
              <v-spacer></v-spacer>
              <span :class="actualCat === -1 ? 'caption grey--text' : 'caption secondaryAlt--text showAllCatLink'"
                    style="cursor: pointer;"
                    @click="showAllCats">
                {{ $t('showAll') }}
              </span>
            </v-card-title>
            <v-divider></v-divider>
          </v-card>
          <template v-for="(cat, index) in moddedboxes.categories">
            <v-card :key="index"
                    class="mb-5"
                    :color="actualCat === cat.id ? 'copyRightColor' : ''"
                    @click="changeCategory(cat.id)">
              <v-card-text class="pa-2">
                <v-row class="mx-0">
                  <!--v-col cols="auto" align-self="center" class="pa-2">
                    <v-icon :color="actualCat === cat.id ? 'white' : 'copyRightColor'" size="28">
                      mdi-{{ cat.icon }}
                    </v-icon>
                  </v-col-->
                  <v-col :class="actualCat === cat.id ? 'pa-2 white--text font-weight-bold' : 'pa-2'" style="word-break: keep-all;">
                    {{ cat.translatedTitle }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-col>
        <v-col cols="12" sm="7" md="8">
          <template v-for="(item, index) in moddedboxes.boxes">
            <v-row class="mx-0 mb-5 pt-4" :key="`${index}r`"
                   v-show="actualCat === item.cat || actualCat === -1">
              <v-col class="pa-2" style="word-break: keep-all;">
                <div class="text-h5">
                  {{ item.translatedQuestion }}
                </div>
                <div class="body-1" v-html="item.translatedAnswer"></div>
              </v-col>
              <v-col cols="auto" class="pa-2">
                <v-icon small color="secondaryAlt">
                  mdi-{{ moddedboxes.categories[moddedboxes.categories.findIndex((category) => category.id === item.cat)].icon }}
                </v-icon>
              </v-col>
            </v-row>
            <v-divider :key="`${index}d`" />
          </template>
        </v-col>
      </v-row>
    </v-container>
    <!-- CONTENT END -->
    <!-- EDITOR PANEL START -->
    <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
      <v-card min-height="690">
        <v-toolbar color="primary" height="64" max-height="64" dark flat tile>
          <v-btn icon>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            GYIK 2 - Szerkesztés
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorPanel = !editorPanel">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="py-4">
          <v-row>
            <!-- TÉMAKÖRÖK START -->
            <v-col cols="12" sm="4">
              <v-row justify="space-between" align="center" class="pb-3">
                <div class="subtitle-1">
                  {{ $t('themeGroups') }}
                </div>
                <v-btn small color="primary" @click="addNewCategory">
                  {{ $t('button.newThemeGroup') }}
                </v-btn>
              </v-row>
              <v-divider></v-divider>
              <v-list>
                <template v-for="(category, index) in elementProps.categories">
                  <v-list-item :key="`${index}l`">
                    <!--v-list-item-avatar class="mr-3">
                      <v-icon color="copyRightColor" size="24">
                        mdi-{{ category.icon }}
                      </v-icon>
                    </v-list-item-avatar-->
                    <v-list-item-content>
                      {{ category.translatedTitle }}
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon color="primary"
                              class="mx-3"
                              @click="editCategory(index, category)">
                        mdi-pencil
                      </v-icon>
                      <v-icon color="copyRightColor"
                              @click="deleteCategory(index)">
                        mdi-delete
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-divider :key="`${index}di`"></v-divider>
                </template>
              </v-list>
            </v-col>
            <!-- TÉMAKÖRÖK END -->
            <v-divider vertical />
            <!-- KÉRDÉSEK START -->
            <v-col cols="12" sm="8">
              <v-row justify="space-between" align="center" class="pb-3">
                <div class="subtitle-1">
                  {{ $t('questions') }}
                </div>
                <v-btn small color="primary" @click="addNewQuestion">
                  {{ $t('button.newQuestion') }}
                </v-btn>
              </v-row>
              <v-divider class="mb-3"></v-divider>
              <v-row justify="center">
                <v-expansion-panels focusable inset v-model="expansionPanel" class="mb-4">
                  <template v-for="(box, boxindex) in elementProps.boxes">
                    <v-expansion-panel :key="`${boxindex}qc`">
                      <v-expansion-panel-header>
                        {{ box.translatedQuestion }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row class="my-3 mx-0">
                          <v-col cols="12">
                            <v-select v-model="box.cat"
                                      :label="$t('themeGroup')"
                                      :items="elementProps.categories"
                                      item-value="id"
                                      item-text="translatedTitle"
                                      filled>
                            </v-select>
                          </v-col>
                          <v-col cols="6" class="text-subtitle-1 text--primary">
                            Szövegek szerkesztése
                          </v-col>
                          <v-col cols="6">
                            <v-row class="mx-0" justify="end">
                              <v-btn color="primary" @click="addNewTranslation(boxindex)">
                                {{ $t('button.addNewTranslation') }}
                              </v-btn>
                            </v-row>
                          </v-col>
                          <!-- FORDÍTÁS START -->
                          <v-col cols="12">
                            <v-tabs v-model="translationTab"
                                    background-color="teal darken-1"
                                    dark>
                              <v-tab v-for="(item, index) in box.translations"
                                     :key="`lt-${index}`">
                                {{ item.name }}
                              </v-tab>
                              <v-tabs-items v-model="translationTab">
                                <v-tab-item v-for="(item, index) in box.translations"
                                            :key="`lc-${index}`">
                                  <v-card outlined tile>
                                    <v-card-text>
                                      <v-col cols="12">
                                        <div class="text-h5">
                                          {{ item.title }}
                                        </div>
                                        <div v-html="item.text"></div>
                                      </v-col>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions class="pa-4">
                                      <v-spacer></v-spacer>
                                      <v-btn v-if="box.translations.length > 1"
                                             color="error"
                                             text
                                             class="mr-4"
                                             @click="deleteTranslation(index, boxindex)">
                                        {{ $t('button.delete') }}
                                      </v-btn>
                                      <v-btn color="primary"
                                             @click="editTranslation(item, index, boxindex)">
                                        {{ $t('button.edit') }}
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-tab-item>
                              </v-tabs-items>
                            </v-tabs>
                          </v-col>
                          <v-col cols="12">
                            <v-btn text
                                   color="error"
                                   small
                                   class="mr-3"
                                   @click="deleteQuestion(boxindex)">
                              {{ $t('button.deleteQuestion') }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </template>
                </v-expansion-panels>
              </v-row>
            </v-col>
            <!-- KÉRDÉSEK END -->
          </v-row>
          <v-divider></v-divider>
          <!--v-row class="mb-4 mx-0">
            <v-col class="text-subtitle-1">
              Ikon színe
            </v-col>
            <v-col cols="auto">
              <v-row justify="end" class="mx-0">
                <v-avatar height="52" width="52" tile
                          :color="`${elementProps.color}`"
                          class="pageBuilder-color-picker-activator"
                          style="border: 1px solid rgba(0,0,0,0.2);"
                          @click="openColorPicker"></v-avatar>
              </v-row>
            </v-col>
          </v-row-->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="saveElementProps">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDITOR PANEL END -->
    <!-- TÉMAKÖR SZERKESZTÉS START -->
    <v-dialog v-model="categoryEditDialog.isVisible" persistent max-width="980">
      <v-card min-height="400">
        <v-card-title class="indigo white--text">
          {{ categoryEditDialog.addOrEdit === 'ADD' ? $t('addNewThemeGroup') : $t('editThemeGroup') }}
          <v-spacer></v-spacer>
          <v-icon color="white" @click="closeCategoryEditDialog">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pa-2">
          <v-row>
            <v-col cols="12" sm="4">
              <v-list>
                <v-list-item-group color="primary">
                  <template v-for="(item, index) in $store.state.languages.availableLanguages">
                    <v-list-item :key="index"
                                 @click="addNewCategoryTranslation(item)">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t(`languages.${item.text}`) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon v-if="categoryEditDialog.category.translations.findIndex((tr) => tr.code === item.value) > -1"
                                color="primary">
                          mdi-check-circle-outline
                        </v-icon>
                        <v-icon v-else
                                color="primary">
                          mdi-plus
                        </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if="index < $store.state.languages.availableLanguages.length - 1" :key="`d-${index}`"></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col cols="12" sm="8">
              <v-tabs v-model="categoryTranslationTab"
                      background-color="teal darken-1"
                      dark>
                <v-tab v-for="(item, index) in categoryEditDialog.category.translations"
                       :key="`lt-${index}`">
                  {{ item.name }}
                </v-tab>
                <v-tabs-items v-model="categoryTranslationTab">
                  <v-tab-item v-for="(item, index) in categoryEditDialog.category.translations"
                              :key="`lc-${index}`">
                    <v-card outlined tile>
                      <v-card-text class="pa-0">
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="item.title"
                                          :label="$t('labels.title')"
                                          filled
                                          dense>
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="pa-4">
                        <v-spacer></v-spacer>
                        <v-btn v-if="categoryEditDialog.category.translations.length > 1"
                               color="error"
                               text
                               class="mr-4"
                               @click="deleteCategoryTranslation(index)">
                          {{ $t('button.delete') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black"
                 text
                 class="mr-4"
                 @click="closeCategoryEditDialog">
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn color="primary"
                 @click="saveCategory">
            {{ $t('button.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- TÉMAKÖR SZERKESZTÉS END -->
    <!-- FORDÍTÁS DIALOG START -->
    <translation-editor-dialog :content="translationDialog"
                               :visible-elements="visibleElements"
                               v-on:@cancel="cancelNewTranslation"
                               v-on:@save="saveNewTranslation" />
    <!-- FORDÍTÁS DIALOG START -->
  </div>
</template>

<script>
import TranslationEditorDialog from '@/components/shared/TranslationEditorDialog.vue';

export default {
  name: 'Faq2',
  components: {
    TranslationEditorDialog,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          color: '#000000',
          boxes: [
            {
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  title: 'Csak regisztráció után vehetem igénybe a szolgáltatásokat?',
                  text: 'Nem, regisztráció nélkül is tudsz szolgáltatást igényelni és az igény leadás során megadott adatokkal'
                    + ' lehetőséged nyílik regisztrálni is.',
                },
              ],
              cat: 1,
            },
            {
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  title: 'Csak regisztráció után vehetem igénybe a szolgáltatásokat?',
                  text: 'Nem, regisztráció nélkül is tudsz szolgáltatást igényelni és az igény leadás során megadott adatokkal'
                    + ' lehetőséged nyílik regisztrálni is.',
                },
              ],
              cat: 1,
            },
          ],
          categories: [
            {
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  title: 'Témakör címe',
                },
              ],
              id: 1,
              icon: 'account-plus',
            },
          ],
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    this.moddedboxes = await this.cloneElementProps();
    await this.changeTranslations();
  },
  data() {
    return {
      actualCat: -1,
      editorPanel: false,
      categoryTranslationTab: 0,
      categoryEditDialog: {
        isVisible: false,
        addOrEdit: 'ADD',
        categoryIndex: null,
        category: {
          translations: [
            {
              code: 'hu',
              name: 'Magyar',
              title: 'Témakör címe',
            },
          ],
          id: null,
          icon: 'account-plus',
        },
      },
      categoryTranslation: {
        code: '',
        name: '',
        title: '',
      },
      questionTranslation: {
        code: '',
        name: '',
        question: '',
        answer: '',
      },
      categoryPrototype: {
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Témakör címe',
          },
        ],
        id: null,
        icon: 'account-plus',
      },
      moddedboxes: [],
      expansionPanel: null,
      translationTab: 0,
      visibleElements: ['title', 'text1'],
    };
  },
  methods: {
    async editElement() {
      await this.changeTranslationsElement();
      this.expansionPanel = null;
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    saveElement() {
      this.$emit('save-element');
    },
    async cloneElementProps() {
      return JSON.parse(JSON.stringify(this.elementProps));
    },
    cancelElementProps() {
      this.editorPanel = false;
    },
    async saveElementProps() {
      await this.changeTranslationsElement();
      this.moddedboxes = await this.cloneElementProps();
      await this.changeTranslations();
      this.translationTab = 0;
      this.expansionPanel = null;
      this.editorPanel = false;
    },
    changeCategory(id) {
      this.actualCat = id;
    },
    showAllCats() {
      this.actualCat = -1;
    },
    addNewCategory() {
      this.categoryEditDialog.addOrEdit = 'ADD';
      this.categoryEditDialog.category = JSON.parse(JSON.stringify(this.categoryPrototype));
      this.categoryEditDialog.category.id = Date.now();
      this.categoryEditDialog.isVisible = true;
    },
    editCategory(categoryIndex, category) {
      this.categoryEditDialog.addOrEdit = 'EDIT';
      this.categoryEditDialog.categoryIndex = categoryIndex;
      this.categoryEditDialog.category = JSON.parse(JSON.stringify(category));
      this.categoryEditDialog.isVisible = true;
    },
    async saveCategory() {
      if (this.categoryEditDialog.addOrEdit === 'ADD') {
        this.elementProps.categories.push(JSON.parse(JSON.stringify(this.categoryEditDialog.category)));
      } else if (this.categoryEditDialog.addOrEdit === 'EDIT') {
        this.elementProps.categories[this.categoryEditDialog.categoryIndex] = JSON.parse(JSON.stringify(this.categoryEditDialog.category));
      }
      await this.changeTranslations();
      await this.changeTranslationsElement();
      this.setDefaultCategory();
    },
    deleteCategory(index) {
      this.elementProps.categories.splice(index, 1);
    },
    addNewQuestion() {
      this.elementProps.boxes.push(JSON.parse(JSON.stringify(this.elementProps.boxes[0])));
      this.expansionPanel = this.elementProps.boxes.length - 1;
    },
    editQuestion() {
      //
    },
    deleteQuestion(index) {
      this.elementProps.boxes.splice(index, 1);
    },
    setDefaultCategory() {
      this.categoryEditDialog = {
        isVisible: false,
        addOrEdit: 'ADD',
        categoryIndex: null,
        category: {
          translations: [
            {
              code: 'hu',
              name: 'Magyar',
              title: 'Témakör címe',
            },
          ],
          id: null,
          icon: 'account-plus',
        },
      }
    },
    async changeTranslations() {
      for (let i = 0; i < this.moddedboxes.boxes.length; i++) {
        const index = this.moddedboxes.boxes[i].translations.findIndex((item) => item.code === this.$i18n.locale.substr(0, 2));
        const currentLanguageIndex = index === -1 ? 0 : index;
        this.moddedboxes.boxes[i].translatedQuestion = this.moddedboxes.boxes[i].translations[currentLanguageIndex].title;
        this.moddedboxes.boxes[i].translatedAnswer = this.moddedboxes.boxes[i].translations[currentLanguageIndex].text;
      }
      for (let j = 0; j < this.moddedboxes.categories.length; j++) {
        const index = this.moddedboxes.categories[j].translations.findIndex((item) => item.code === this.$i18n.locale.substr(0, 2));
        const currentLanguageIndex = index === -1 ? 0 : index;
        this.moddedboxes.categories[j].translatedTitle = this.moddedboxes.categories[j].translations[currentLanguageIndex].title;
      }
    },
    async changeTranslationsElement() {
      for (let i = 0; i < this.elementProps.boxes.length; i++) {
        const index = this.elementProps.boxes[i].translations.findIndex((item) => item.code === this.$i18n.locale.substr(0, 2));
        const currentLanguageIndex = index === -1 ? 0 : index;
        this.elementProps.boxes[i].translatedQuestion = this.elementProps.boxes[i].translations[currentLanguageIndex].title;
        this.elementProps.boxes[i].translatedAnswer = this.elementProps.boxes[i].translations[currentLanguageIndex].text;
      }
      for (let j = 0; j < this.elementProps.categories.length; j++) {
        const index = this.elementProps.categories[j].translations.findIndex((item) => item.code === this.$i18n.locale.substr(0, 2));
        const currentLanguageIndex = index === -1 ? 0 : index;
        this.elementProps.categories[j].translatedTitle = this.elementProps.categories[j].translations[currentLanguageIndex].title;
      }
    },
    closeCategoryEditDialog() {
      this.setDefaultCategory();
    },
    addNewCategoryTranslation(language) {
      if (this.categoryEditDialog.category.translations.findIndex((tr) => tr.code === language.value) === -1) {
        let translation = JSON.parse(JSON.stringify(this.categoryTranslation));
        translation.code = language.value;
        translation.name = language.hun;
        this.categoryEditDialog.category.translations.push(JSON.parse(JSON.stringify(translation)));
      }
    },
    deleteCategoryTranslation(translationindex) {
      this.categoryEditDialog.category.translations.splice(translationindex, 1);
    },
  },
};
</script>

<style scoped>

</style>
