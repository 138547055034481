<template>
  <div v-frag>
    <!-- MÉDIA GALÉRIA START -->
    <v-container fluid>
      <v-row>
        <v-col cols="undefined">
          <v-card>
            <v-card-title>
              Médiatár
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="openUploaderDialog">
                {{ $t('button.addNew') }}
              </v-btn>
              <template v-if="inDialog">
                <v-btn color="primary" class="mx-4" @click="pasteMedia">
                  {{ $t('button.pasteImage') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-icon @click="closeMediaDialog">
                  mdi-close
                </v-icon>
              </template>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-data-iterator :items="mediaGallery.media"
                               :server-items-length="mediaGallery.summaMedia"
                               :items-per-page="18"
                               :options.sync="options"
                               :loading="loading"
                               id="mediaIterator">
                <template v-slot:default="props">
                  <v-row class="mx-0">
                    <template v-for="(item, index) in props.items">
                      <v-col cols="6" sm="3" md="2" :key="index" style="position: relative;">
                        <template v-if="$store.state.media.selectedImageInMediaGallery && $store.state.media.selectedImageInMediaGallery.fileStorageId === item.fileStorageId">
                          <v-btn class="white"
                                 icon
                                 style="position: absolute; top: 18px; right: 18px; z-index: 99;"
                                 @click="clearMedia">
                            <v-icon>
                              mdi-minus
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-card :class="$store.state.media.selectedImageInMediaGallery
                        && $store.state.media.selectedImageInMediaGallery.fileStorageId === item.fileStorageId ? 'mg-img elevation-6 secondary' : 'mg-img fill-height'"
                                @click="chooseMedia(item)">
                          <v-card-text>
                            <template v-if="item.fileType.substr(0, 5) === 'image'">
                              <v-img :src="`${publicPath}public_storage${item.fileFullPath}`" aspect-ratio="1" width="100%"></v-img>
                            </template>
                            <template v-else>
                              <v-img :src="`${publicPath}images/shape-req.jpg`" aspect-ratio="1" width="100%">
                                <v-row class="mx-0 fill-height primary" justify="center" align-content="center">
                                  <v-icon size="48" color="white">
                                    mdi-file-document-outline
                                  </v-icon>
                                </v-row>
                              </v-img>
                            </template>
                          </v-card-text>
                          <v-card-text class="text-center">
                            {{ item.fileName }}
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </template>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- KIVÁLASZTOTT MÉDIA START -->
        <v-col cols="auto" style="width: 220px;">
          <v-card class="fill-height">
            <v-card-title class="text-body-1">
              Kiválasztott média
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="$store.state.media.selectedImageInMediaGallery">
              <template v-if="$store.state.media.selectedImageInMediaGallery.fileType.substr(0, 5) === 'image'">
                <v-img :src="`${publicPath}public_storage${$store.state.media.selectedImageInMediaGallery.fileFullPath}`"
                       width="100%"></v-img>
              </template>
              <template v-else>
                <v-img :src="`${publicPath}images/shape-req.jpg`" aspect-ratio="1" width="100%">
                  <v-row class="mx-0 fill-height primary" justify="center" align-content="center">
                    <v-icon size="48" color="white">
                      mdi-file-document-outline
                    </v-icon>
                  </v-row>
                </v-img>
              </template>
              <div class="text-caption font-weight-bold">
                Média címe:
              </div>
              <div class="text-caption mb-3">
                {{ $store.state.media.selectedImageInMediaGallery.fileName }}
              </div>
              <div class="text-caption font-weight-bold">
                Közvetlen hivatkozás:
              </div>
              <div class="text-caption">
                {{ `${publicPath}public_storage${$store.state.media.selectedImageInMediaGallery.fileFullPath}` }}
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn small
                           v-on="on"
                           @click="copyToClipboard"
                           icon>
                      <v-icon>
                        mdi-content-copy
                      </v-icon>
                    </v-btn>
                  </template>
                  Vágólapra másol
                </v-tooltip>
              </div>
              <input type="hidden" id="clone" :value="selectedMedia.fileFullPath">
            </v-card-text>
          </v-card>
        </v-col>
        <!-- KIVÁLASZTOTT MÉDIA END -->
      </v-row>
    </v-container>
    <!-- MÉDIA GALÉRIA START -->
    <!-- FELTÖLTÉS START -->
    <v-dialog v-model="uploaderDialog"
              max-width="1200"
              scrollable
              persistent>
      <v-card>
        <v-card-title class="indigo">
          <span class="white--text">
            {{ $t('uploadNewMedia') }}
          </span>
          <v-spacer></v-spacer>
          <v-icon @click="closeUploaderDialog" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-row class="mx-0">
            <v-col cols="12">
              <v-file-input v-model="files"
                            accept="image/png, image/jpeg, image/jpg, .doc, .docx, .pdf"
                            multiple
                            filled
                            :label="$t('fileUpload')">
                <!--template v-slot:selection="{ file }">
                  <v-chip label color="primary" close @click:close="removeMediaFromFiles(file)">
                    {{ file.name }}
                  </v-chip>
                </template-->
              </v-file-input>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-col cols="12">
              <v-list two-line>
                <template v-for="(media, index) in imagePreviews">
                  <v-list-item :key="index" class="mb-3 elevation-2 rounded">
                    <v-list-item-avatar tile width="60" height="60">
                      <v-img :src="media.url" aspect-ratio="1" cover height="60" max-width="60"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ media.name }} - {{ media.size }} KB
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="removeMediaFromFiles(media.file)">
                        <v-icon>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 py-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="imagePreviews.length === 0" @click="callMediaUpload">
            {{ $t('button.upload') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- FELTÖLTÉS END -->
    <!-- LAR DIALOG START -->
    <loading-and-response-dialog :element-props.sync="loadingAndResponseDialog"
                                 v-on:on-error-click="onErrorClickInLARDialog"
                                 v-on:on-success-click="onSuccussCickInLARDialog">
    </loading-and-response-dialog>
    <!-- LAR DIALOG END -->
    <!-- SNACKBAR START -->
    <v-snackbar v-model="snackbar"
                :color="snackbarColor"
                multi-line
                outlined
                top
                timeout="2000">
      <div class="text-center">
        {{ snackbarText }}
      </div>
      <div class="text-center text-body-2">
        {{ selectedMedia.fileFullPath }}
      </div>
    </v-snackbar>
    <!-- SNACKBAR END -->
  </div>
</template>

<script>
import LoadingAndResponseDialog from '@/components/shared/LoadingAndResponseDialog.vue';

export default {
  name: 'MediaPage',
  components: {
    LoadingAndResponseDialog,
  },
  props: {
    inDialog: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    // this.mediaGallery = await this.loadMediaFiles(1);
  },
  data() {
    return {
      mediaGallery: [],
      page: 1,
      options: {},
      loading: true,
      files: [],
      imagePreviews: [],
      uploaderDialog: false,
      loadingAndResponseDialog: {
        isVisible: false,
        stepper: 1,
        loadingText: 'LOADING_MESSAGES.PROCESS_IN_PROGRESS',
        successText: 'SUCCESS_MESSAGES.FILE_UPLOAD',
        errorText: 'ERROR_MESSAGES.FILE_UPLOAD',
        successBtnText: 'button.ok',
        errorBtnText: 'button.ok',
      },
      selectedMedia: {
        createdAt: '2020',
        fileFullPath: 'fileFullPath',
        fileName: 'fileFullPath.jpg',
        fileStorageId: '3',
        fileType: 'jpg',
      },
      snackbar: false,
      snackbarColor: 'success',
      snackbarText: 'Vágólapra másolva',
    };
  },
  methods: {
    copyToClipboard() {
      let textInput = document.getElementById('clone');
      textInput.setAttribute('type', 'text');
      textInput.select();
      try {
        const resp = document.execCommand('copy');
        this.snackbarText = 'Vágólapra másolva';
        this.snackbarColor = 'success';
      } catch (e) {
        this.snackbarText = e;
        this.snackbarColor = 'error';
      }
      this.snackbar = true;
      textInput.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
    },
    removeMediaFromFiles(file) {
      const index = this.files.findIndex((item) => item === file);
      this.files.splice(index, 1);
    },
    async loadMediaFiles(page) {
      this.loading = true;
      const response = await this.$store.dispatch('showMediaFiles', page);
      this.loading = false;
      if (response.status === 200) {
        return response.data;
      }
      return [];
    },
    async callMediaUpload() {
      this.openLARDialog();
      for (let i = 0; i < this.files.length; i++) {
        const field = this.$acceptedFileTypesAndFieldNames.find((item) => {
          const regexp = new RegExp(item.regexp, 'g');
          const result = regexp.test(this.files[i].type);
          if (result) {
            return item;
          }
          return false;
        });
        if (field) {
          this.files[i].field_name = field.fieldName;
        } else {
          this.files[i].field_name = 'OTHER';
        }
      }
      const response = await this.$store.dispatch('UploadMedia', this.files);
      this.closeUploaderDialog();
      if (response.status === 201) {
        setTimeout(this.changeLARDialogContentToSuccess, 1100);
      } else {
        setTimeout(this.changeLARDialogContentToError, 1100);
      }
    },
    openLARDialog() {
      this.loadingAndResponseDialog.stepper = 1;
      this.loadingAndResponseDialog.isVisible = true;
    },
    changeLARDialogContentToSuccess() {
      this.loadingAndResponseDialog.stepper = 2;
    },
    changeLARDialogContentToError() {
      this.loadingAndResponseDialog.stepper = 3;
    },
    async onSuccussCickInLARDialog() {
      this.mediaGallery = await this.loadMediaFiles(1);
      this.loadingAndResponseDialog.isVisible = false;
      this.loadingAndResponseDialog.stepper = 1;
      // window.location.reload();
    },
    onErrorClickInLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
      this.loadingAndResponseDialog.stepper = 1;
    },
    openUploaderDialog() {
      this.uploaderDialog = true;
    },
    closeUploaderDialog() {
      this.uploaderDialog = false;
      this.files = [];
    },
    finishLoading() {
      this.loading = false;
    },
    closeMediaDialog() {
      this.$emit('close-media-dialog');
    },
    chooseMedia(media) {
      this.$store.commit('setSelectedImageInMediaGallery', media);
      this.selectedMedia.fileFullPath = `${this.publicPath}public_storage${media.fileFullPath}`;
    },
    clearMedia() {
      this.$store.commit('clearSelectedImageInMediaGallery');
      this.selectedMedia.fileFullPath = '';
    },
    pasteMedia() {
      this.$emit('paste-media');
    },
  },
  watch: {
    async files(value) {
      this.imagePreviews = [];
      if (value.length > 0) {
        value.forEach((file, index) => {
          let temp = {};
          temp.file = file;
          temp.name = file.name;
          temp.size = Math.floor(file.size / 1000);
          /* this.$store.dispatch('MediaTypeChecker', file.type).then(
            (result) => {
              console.log('result: ', result);
              file.field_name = result;
              console.log('temp file: ', temp);
              console.log('file after: ', file);
            },
          ); */
          temp.url = URL.createObjectURL(file);
          this.imagePreviews.push(temp);
        });
      } else {
        this.imagePreviews = [];
      }
    },
    options: {
      async handler() {
        // console.log(this.options);
        this.mediaGallery = await this.loadMediaFiles(this.options.page);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>
