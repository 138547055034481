/* eslint-disable no-shadow */

// initial state
import { APIGETCRM } from '@/utils/apiHelper.js';

const state = {
  locationId: 85200,
};

// getters
const getters = {
  GetLocationId(state) {
    return state.locationId;
  },
};

// actions
const actions = {
  async GetRepairLocationDevicelist(context, credentials) {
    const response = await APIGETCRM(`repair/location/${credentials.locationId}/devicelists`, {}, false);
    if (response.status === 200) {
      response.data.data.sort((a, b) => ((a.fonix_tipus_gyartoi_nev > b.fonix_tipus_gyartoi_nev) ? 1 : -1));
    }
    return response.data;
  },
  async GetRepairDevicelistRepairGroups(context, credentials) {
    const response = await APIGETCRM(`repair/location/${credentials.locationId}/devicelists/${credentials.deviceId}/repair-groups`,
      {bookingEnabled: 1}, false);
    return response.data;
  },
  async GetRepairDevicelistRepairGroupPrice(context, credentials) {
    const response = await APIGETCRM(`repair/location/${credentials.locationId}/devicelists/${credentials.deviceId}/repair-groups/${credentials.repairGroupId}/price`,
      {}, false);
    return response.data;
  },
  async GetRepairDevicelistPrice(context, credentials) {
    const response = await APIGETCRM(`repair/location/${credentials.locationId}/devicelists/${credentials.deviceId}/price`,
      {}, false);
    return response.data;
  },
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
