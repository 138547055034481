import Vue from 'vue';

export default Vue.mixin({
  data() {
    return {
      inputRules: {
        acceptInformation: [v => !!v || this.$t('hints.acceptInformation')],
        generalRules: [v => !!v || this.$t('hints.requiredField')],
        emailRules: [
          v => /.+@.+\..+/.test(v) || this.$t('hints.emailMatch'),
          v => !!v || this.$t('hints.requiredField'),
        ],
        phoneRules: [
          v => /^[0-9-]*$/.test(v) || this.$t('hints.onlyNumbers'),
          v => !!v || this.$t('hints.requiredField'),
        ],
        gdprRules: [
          v => !!v || this.$t('hints.requiredPrivacy'),
        ],
        termAndCondRules: [
          v => !!v || this.$t('hints.requiredTermsAndCond'),
        ],
        maxFileSize: [
          v => !v || v.size < 5000000 || this.$t('hints.maxFileSizeRule'),
        ],
        maxFileSizeOptional: [
          v => !v || v.size < 5000000 || this.$t('hints.maxFileSizeRule'),
        ],
        requiredFile: [
          v => !v || v.size < 5000000 || this.$t('hints.maxFileSizeRule'),
          v => !!v || this.$t('hints.requiredField'),
        ],
        min8: [
          v => v.length >= 8 || this.$t('hints.passwordRuleMin8'),
          v => !!v || this.$t('hints.requiredField')
        ],
        imeiRule: [
          v => !!v || this.$t('hints.requiredField'),
          v => /^[0-9]*$/.test(v) || this.$t('hints.onlyNumbers'),
          v => (v && v.length === 15) || this.$t('hints.minLenght15'),
        ],
        onlyNumbers: [
          v => !!v || this.$t('hints.optionalField'),
          v => /^[0-9]*$/.test(v) || this.$t('hints.onlyNumbers'),
        ],
        snRule: [
          v => !!v || this.$t('hints.requiredField'),
          v => /^[a-zA-Z0-9-/\\]*$/.test(v) || this.$t('hints.snRules'),
          v => (v && v.length < 23) || this.$t('hints.minLenght15'),
        ],
        selectRules: [
          v => !!v || this.$t('hints.requiredField'),
          v => ( v && v.id > 0) || this.$t('hints.requiredField'),
        ],
        selectRule: (value) => this.requiredRule(value) || this.$t('hints.requiredField'),
        purchaseDateRule: [
          v => !!v || this.$t('hints.purchaseDateReq'),
          v => this.purchaseDateIsOK(v) || `${this.$t('hints.purchaseDateMax')} ${this.maxDate}!`,
        ],
        vatRules: [
          (v) => !!v || this.$t('hints.requiredField'),
          (v) => (v && v.length === 13) || this.$t('hints.requiredField'),
        ],
      },
      mask: {
        phoneMask: '##-###-####',
      },
    };
  },
  methods: {
    requiredRule(value) {
      if (value) {
        if (Number.isInteger(value)) {
          return true;
        } else if (typeof value === 'object' && value.constructor === Object) {
          return Object.keys(value).length > 0;
        }
        return !!value;
      }
      return false;
    },
  },
});
