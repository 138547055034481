<template>
  <v-app>
    <template v-if="loadingFinished">
      <component :is="layout" :layout-props="menu" :footer-props="footer" key="normalLayout">
        <router-view></router-view>
      </component>
    </template>
    <template v-else>
      <loading-layout key="loadingComp"></loading-layout>
    </template>
  </v-app>
</template>

<script>
import * as langHelper from '@/utils/langHelper';
import LoadingLayout from '@/layouts/LoadingLayout.vue';

const defaultLayout = 'outer';

export default {
  name: 'App',
  components: {
    LoadingLayout,
  },
  async beforeMount() {
    this.detectLanguage();
    if (!this.runLocal) {
      const whash = await this.loadWebproject();
      if (this.$route.meta.layout !== 'inner' && this.$route.meta.layout !== 'nofooter') {
        this.$store.dispatch('GetSystemInfo', { code: this.$CODE });
        const menu = await this.loadDefaultMenu();
        const footer = await this.loadDefaultFooter();
      }
    }
    this.$store.dispatch('detectiossafari');
    this.loadingFinished = true;
  },
  data() {
    return {
      footer: null,
      loadingFinished: false,
      menu: null,
      runLocal: false,
    };
  },
  methods: {
    async loadWebproject() {
      const response = await this.$store.dispatch('GetWebProjectId');
      // console.log('loadWebproject: ', response);
      return response;
    },
    async loadDefaultMenu() {
      const response = await this.$store.dispatch('showDefaultMenu');
      if (response.status === 200) {
        this.menu = response.data.contentJson;
      } else {
        this.menu = 'FAILED';
      }
      return true;
    },
    async loadDefaultFooter() {
      const response = await this.$store.dispatch('showDefaultFooter');
      if (response.status === 200) {
        this.footer = JSON.parse(response.data.contentJson);
      } else {
        this.footer = {
          noDefaultFooter: 'NODEF',
        };
      }
      return true;
    }
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout || defaultLayout}-layout`;
    },
  },
};
</script>
