/* eslint-disable no-shadow */

import { APIFILEDOWNLOADCRM, APIGETCRM, APIPATCH, APIPATCHCRM, APIPOSTCRM } from '@/utils/apiHelper';
import { getCrmToken, saveCrmToken, destroyCrmToken } from '@/utils/jwtHelper';

// initial state
const state = {
  payedRequestStatusList: [
    {
      status: 'Új igény',
      date: null,
      type: null,
      code: 1,
    },
    {
      status: 'Beérkezett',
      date: null,
      type: null,
      code: 20,
    },
    {
      status: 'Árajánlat elkészült',
      date: null,
      type: null,
      code: 30,
    },
    {
      status: 'Elfogadott árajánlat',
      date: null,
      type: null,
      code: 32,
    },
    /* {
      status: 'Elutasított árajánlat',
      date: null,
      type: null,
      code: 34,
    },
    {
      status: 'Nincs válasz az árajánlatra',
      date: null,
      type: null,
      code: 36,
    }, */
    {
      status: 'Megjavítva',
      date: null,
      type: null,
      code: 60,
    },
  ],
  warrantyRequestStatusList: [
    {
      status: 'Új igény - elbíráslás alatt',
      date: null,
      type: null,
      code: 1,
    },
    {
      status: 'Beérkezett',
      date: null,
      type: null,
      code: 20,
    },
    {
      status: 'Megjavítva',
      date: null,
      type: null,
      code: 60,
    },
  ],
};

// getters
const getters = {
  //
};

// actions
const actions = {
  /**
   *
   * CRM igény leadás
   *
   * @param context
   * @param credentials
   * @returns {Promise<{data: {data: *[], message: string, status: number}}>}
   */
  async sendCrmPickupReturn(context, credentials) {
    const formData = new FormData();
    const tempForm = credentials.form;
    const formJSON = JSON.stringify(tempForm);
    formData.append('data', formJSON);
    formData.append('invoice', credentials.attachment.invoice);
    formData.append('warranty_card', credentials.attachment.warrantyCard);
    const response = await APIPOSTCRM('customer-requests/pickup-return', formData, false);
    return response;
  },
  /***
   *
   * showForm
   *
   * @param context
   * @param credentials: String, form azonosítója
   * @returns {Promise<{data: [], message: string, status: number}|{data: [], message: string, status: number}>}
   */
  async showForm(context, credentials) {
    const response = await APIGETCRM(`forms/${credentials}`);
    return response.data;
  },
  /***
   *
   * showPaymentMethodOfCustomerType
   *
   * @param context
   * @param credentials: customerType
   * @returns {Promise<{data: [], message: string, status: number}|{data: [], message: string, status: number}>}
   */
  async showPaymentMethodOfCustomerType(context, credentials) {
    const response = await APIGETCRM(`customer-types/${credentials}/payments`);
    return response.data;
  },
  /***
   *
   * showProductGroupOfBrand
   *
   * @param context
   * @param credentials: int - brand.id
   * @returns {Promise<{data: [], message: string, status: number}>}
   */
  async showProductGroupOfBrand(context, credentials) {
    const response = await APIGETCRM(`brands/${credentials}/product-groups`);
    return response.data;
  },
  /***
   *
   * showProductTypeOfBrand
   *
   * @param context
   * @param credentials: { brandId: int, productGroupId: int }
   * @returns {Promise<{data: [], message: string, status: number}|{data: [], message: string, status: number}|undefined>}
   */
  async showProductTypeOfBrand(context, credentials) {
    // return await APIPOST(`brands/product-types`, { brandId: credentials.brandId, productGroupId: credentials.productGroupId});
    const response = await APIGETCRM(`brands/${credentials.brandId}/product-types/${credentials.productGroupId}`);
    return response.data;
  },
  /**
   *
   * Nyomonkövetéshez bejelentkezés
   *
   * @param context
   * @param credentials
   * @returns {Promise<{token: null}|{token: (string|acorn.Token|*)}>}
   */
  async trackingLogin(context, credentials) {
    const formData = new FormData();
    const formJSON = JSON.stringify(credentials);
    formData.append('data', formJSON);
    const response = await APIPOSTCRM('login-request', formData);
    if (response.status === 200 && response.data.data.Token) {
      await saveCrmToken(response.data.data.Token);
      return { token: response.data.data.Token };
    }
    return { token: null };
  },
  /**
   *
   * Nyomonkövetés kijelentkezés
   *
   * @param context
   * @param credentials
   * @returns {Promise<number>}
   */
  async trackingLoguot(context, credentials) {
    await destroyCrmToken();
    return 1;
  },
  /**
   *
   * Nyomonkövetés igény adatok kapott token visszaellenőrzése
   *
   * @param context
   * @param credentials
   * @returns {Promise<{data: *[], message: string, status: number}>}
   */
  async tokenValidator(context, credentials) {
    const response = await APIGETCRM(`login-process/${credentials}`);
    if (response.status === 200) {
      await saveCrmToken(credentials);
    }
    return response.data;
  },
  /***
   *
   * Igény adatok lekérdezése id alpaján
   *
   * @param context
   * @param requestId
   * @returns {Promise<{data: [], message: string, status: number}>}
   */
  async getRequestById(context, requestId) {
    const response = await APIGETCRM(`customer-requests/${requestId}/show`, {}, true);
    return response.data;
  },
  /***
   *
   * Igény státusz történet lekérdezése
   *
   * @param context
   * @param requestId
   * @returns {Promise<{data: [], message: string, status: number}>}
   */
  async getRequestHistoryById(context, requestId) {
    const response = await APIGETCRM(`customer-requests/${requestId}/history`, {}, true);
    return response.data;
  },
  /***
   *
   * Igény dokumentumok lekérdezése
   *
   * @param context
   * @param requestId
   * @returns {Promise<{data: [], message: string, status: number}>}
   */
  async getRequestDocumentsById(context, requestId) {
    const response = await APIGETCRM(`customer-requests/${requestId}/documents`,{}, true);
    return response.data;
  },
  /**
   *
   * Fájl letöltése
   *
   * @param context
   * @param credentials
   * @returns {Promise<void>}
   */
  async downloadCrmRequestDocument(context, credentials) {
    const formData = new FormData();
    const tempForm = credentials.doc;
    const formJSON = JSON.stringify(tempForm);
    formData.append('data', formJSON);
    const response = await APIFILEDOWNLOADCRM('documents', formData);
    if (response.status === 200) {
      const responseHeader = new Headers(response.headers);
      const contentType = responseHeader.get('Content-Type');
      const contentDisposition = responseHeader.get('Content-Disposition');
      const i = contentDisposition.lastIndexOf('.');
      let fileType = contentDisposition.substring(i + 1);
      const now = new Date().toISOString();
      const epoch = Date.parse(now);
      const newBlob = new Blob([response.data], { type: `${contentType}` });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const url = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      if (credentials.type === 'OPEN') {
        link.target = '_blank';
        link.click();
      } else {
        if (fileType === 'pdf') {
          link.target = '_blank';
          link.click();
          // setTimeout(() => window.URL.revokeObjectURL(url), 1500);
        } else {
          link.setAttribute('download', `file_${epoch}.${fileType}`);
          link.click();
        }
      }
      return {
        status: response.status,
      };
    } else {
      return response.data;
    }
  },
  /***
   *
   * Árajánlat kezelés
   *
   * @param context
   * @param credentials
   * @returns {Promise<number>}
   * @constructor
   */
  async AcceptQuote(context, credentials) {
    const response = await APIPATCHCRM(`customer-requests/${credentials.requestId}/quote-user-accept`, { "quoteAcceptedStatus": credentials.accept}, true);
    return response.status;
  },
  /***
   *
   * Szakvélemény kezelés
   *
   * @param context
   * @param credentials
   * @returns {Promise<number>}
   * @constructor
   */
  async AcceptExpertReport(context, credentials) {
    const response = await APIPATCHCRM(`customer-requests/${credentials.requestId}/expert-report-user-accept`, { "expertReportAcceptedStatus": credentials.accept}, true);
    return response.status;
  },
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
