import Vue from 'vue';
import {
  checkVersion,
  getPages, getRouting, getVersion, setDefaultPages, setDefaultRouting, setVersion,
} from '@/plugins/localdatabase';
import { Plugin } from 'vue-fragment';
import VueTheMask from 'vue-the-mask';
import x5GMaps from 'x5-gmaps';
import LeftSidebarLayout from '@/layouts/LeftSidebarLayout.vue';
import i18n from './i18n';
import OuterLayout from './layouts/OuterLayout.vue';
import InnerLayout from './layouts/InnerLayout.vue';
import NoFooterLayout from './layouts/NoFooterLayout.vue';
import * as regComps from './components/registerComponents';
import Style from './styles/style.css';
import AppleStyles from './styles/apple.css';
import App from './App.vue';
import router from './router/router';
import store from './store';
import vuetify from './plugins/vuetify';
import frag from 'vue-frag';
import systemParameters from './utils/systemParameters';
import translationMixin from '@/mixins/translationMixin.js';
import availablePagesMixin from '@/mixins/availablePagesMixin.js';
import converterMixin from '@/mixins/converterMixin.js';
import inputRulesMixin from '@/mixins/inputRulesMixin.js';

Vue.config.productionTip = false;

Vue.directive('frag', frag);

Vue.component('outer-layout', OuterLayout);
Vue.component('inner-layout', InnerLayout);
Vue.component('nofooter-layout', NoFooterLayout);
Vue.component('leftSidebar-layout', LeftSidebarLayout);

Vue.use(Plugin);
Vue.use(VueTheMask);
Vue.use(x5GMaps, 'AIzaSyBff7dAry_ZHmSthtBP0svVFiGtVSyjz10');

const LOGINPAGE = '/admin/login';
const NEED_LOGIN = true;

if (NEED_LOGIN) {
  router.beforeEach(async (to, from, next) => {
    const done = await store.dispatch('CheckAuthentication');
    console.log(1);
    if (store.state.authModule.isAuthenticated) {
      if (to.meta.layout === 'outer') {
        console.log(2);
        next();
      } else {
        console.log(3);
        next();
      }
    } else if (to.meta.layout === 'outer') {
      console.log(4);
      next();
    } else if (to.meta.layout === 'nofooter') {
      console.log(5);
      next();
    } else {
      console.log(6);
      next(LOGINPAGE);
    }
  });
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  async beforeCreate() {
    // const phash = await this.$store.dispatch('GetWebProjectId');
    // const dynamicRoutes = await this.$store.dispatch('GetAvailableRoutes');
    // console.log('dynamicRoutes: ', dynamicRoutes);
    // console.log('dynamicRoutes után: ', router.getRoutes());
    // const dynamicRoutes = await this.$store.dispatch('GetRoutes');
    // console.log('dynamicRoutes: ', dynamicRoutes);
    // router.addRoute(dynamicRoutes[0]);
    // console.log('push után: ', router.getRoutes());
    /* if (getVersion()) {
      if (checkVersion()) {
        if (!getRouting()) {
          const res = await setDefaultRouting();
        }
        const p = await getPages();
        if (!p) {
          await setDefaultPages();
        }
      } else {
        setVersion();
        await setDefaultRouting();
        await setDefaultPages();
      }
    } else {
      setVersion();
      await setDefaultRouting();
      await setDefaultPages();
    }
    await this.$store.dispatch('getPages');
    const dynamicRoutes = await this.$store.dispatch('GetRoutes');
    router.addRoutes(dynamicRoutes);
    router.addRoutes([
      {
        path: '*',
        name: 'NoPage',
        meta: { layout: 'outer' },
        component: () => import(/!* webpackChunkName: "about" *!/ './views/NoPage.vue'),
      },
    ]); */
  },
  render: (h) => h(App),
}).$mount('#app');
