/* eslint-disable no-shadow */
import {
  APIGET, APIPOST,
} from '@/utils/apiHelper';
import { saveWebProject } from '@/utils/jwtHelper';

// initial state
const state = {
  projetcs: [],
  isIOS: false,
  isSafari: false,
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async GetWebProjectId(context, credentials) {
    const response = await APIGET('projects-host');
    // console.log('GetWebProjectId: ', response);
    await saveWebProject(response.data.data.webProjectHash);
    return response.data.data.webProjectHash;
  },
  /**
   *
   * @param context
   * @param credentials
   * @returns {Promise<T>}
   * @constructor
   */
  async GetAllProjects(context, credentials) {
    const response = await APIGET('projects');
    if (response.status === 200) {
      if (context.state.projetcs.length === 0) {
        context.commit('setProjects', response.data.data);
      } else {
        context.commit('updateProjects', response.data.data);
      }
    }
    return response.data;
  },
  /**
   *
   * @param context
   * @param credentials
   * @returns {Promise<T>}
   */
  async showProject(context, credentials) {
    const response = await APIGET(`projects/${credentials}`);
    if (response.status === 200) {
      return response.data;
    }
    return {};
  },
  async createProject(context, credentials) {
    const response = await APIPOST('projects', credentials, true);
    return response.data;
  },
  async detectiossafari(context, credentials) {
    const is_ios = /iP(ad|od|hone)/i.test(window.navigator.userAgent);
    const is_safari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
    console.log(`is_ios: ${is_ios} | is_safari: ${is_safari}`);
    context.commit('setEnv', { isIOS: is_ios, isSafari: is_safari });
  },
};

// mutations
const mutations = {
  setProjects(stat, credentials) {
    state.projetcs = credentials;
  },
  updateProjects(stat, credentials) {
    state.projetcs = credentials;
  },
  setEnv(state, credentials) {
    state.isIOS = credentials.isIOS;
    state.isSafari = credentials.isSafari;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
