<template>
  <div>
    <v-container :class="editorMode ? 'b-wrapper': ''" :fluid="elementProps.isContainerFluid">
      <!-- EDITOR ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            Apple űrlap
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- EDITOR ACTIONBOX END -->
      <v-container>
        <v-form v-model="appleForm">
          <!-- KÉSZÜLÉKADATOK START -->
          <v-row>
            <v-col cols="12" :class="$vuetify.breakpoint.mdAndDown ? 'text-h4' : 'text-h2'">
              Készülékadatok
            </v-col>
            <v-col cols="12" sm="4">
              <v-select v-model="worksheet.serviceRequest.productGroup"
                        :items="selectFieldOptions.productGroups"
                        item-text="displayName"
                        item-value="id"
                        :no-data-text="$t('noDataText.productGroups')"
                        :label="$t('labels.productGroup')"
                        :rules="inputRules.generalRules"
                        :hint="$t('hints.requiredField')"
                        persistent-hint
                        outlined
                        return-object
                        required>
              </v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-autocomplete v-model="worksheet.serviceRequest.productTypeFull"
                              :items="selectFieldOptions.productTypes"
                              item-text="displayName"
                              item-value="gsmTipusId"
                              :search-input.sync="typeSearch"
                              :label="$t('labels.productType')"
                              :rules="inputRules.generalRules"
                              :hint="$t('hints.requiredField')"
                              :no-data-text="$t('noDataText.general')"
                              persistent-hint
                              outlined
                              return-object
                              required>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-row class="mx-n3">
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="worksheet.serviceRequest.uniqueIdentifierIn1"
                                :label="worksheet.serviceRequest.productGroup.nameUniqueIdentifier1  || $t('labels.imei1')"
                                :rules="worksheet.serviceRequest.productGroup.reqUniqueIdentifier1 ? inputRules.imeiRule : []"
                                :messages="worksheet.serviceRequest.productGroup.reqUniqueIdentifier1 ? $t('hints.imeiHint') : $t('hints.imeiHintOptional')"
                                :hint="worksheet.serviceRequest.productGroup.reqUniqueIdentifier1 ? $t('hints.imeiHint') : $t('hints.imeiHintOptional') "
                                v-mask="imeiMask"
                                counter="15"
                                maxlength="15"
                                persistent-hint
                                outlined
                                :disabled="!worksheet.serviceRequest.productGroup.isUniqueIdentifier1">
                  </v-text-field>
                  <v-slide-y-transition>
                    <v-row class="pa-0 ma-0" v-show="worksheet.serviceRequest.productGroup.reqUniqueIdentifier1">
                      <v-col class="px-3 py-0 caption">
                        {{ $t('generateRandomImei') }}
                      </v-col>
                      <v-col cols="auto" class="pa-0">
                        <v-btn small
                               color="primary"
                               @click="randomImei(1)">
                          {{ $t('button.generate') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-slide-y-transition>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="worksheet.serviceRequest.uniqueIdentifierIn2"
                                :label="worksheet.serviceRequest.productGroup.nameUniqueIdentifier2 || $t('labels.imei2')"
                                :rules="worksheet.serviceRequest.productGroup.reqUniqueIdentifier2 ? inputRules.imeiRule : []"
                                :hint="worksheet.serviceRequest.productGroup.reqUniqueIdentifier2 ? $t('hints.imeiHint') : $t('hints.optionalField')"
                                :messages="worksheet.serviceRequest.productGroup.reqUniqueIdentifier2 ? $t(`${imeiHint}`) : $t('hints.imeiHintOptional')"
                                v-mask="imeiMask"
                                counter="15"
                                maxlength="15"
                                persistent-hint
                                outlined
                                :disabled="!worksheet.serviceRequest.productGroup.isUniqueIdentifier2">
                  </v-text-field>
                  <v-slide-y-transition>
                    <v-row class="pa-0 ma-0" v-show="worksheet.serviceRequest.productGroup.reqUniqueIdentifier2">
                      <v-col class="px-3 py-0 caption">
                        {{ $t('generateRandomImei') }}
                      </v-col>
                      <v-col cols="auto" class="pa-0">
                        <v-btn small
                               color="primary"
                               @click="randomImei(2)">
                          {{ $t('button.generate') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-slide-y-transition>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="worksheet.serviceRequest.uniqueIdentifierIn3"
                                :label="worksheet.serviceRequest.productGroup.nameUniqueIdentifier3  || $t('labels.serialNr')"
                                :rules="worksheet.serviceRequest.productGroup.reqUniqueIdentifier3 ? inputRules.snRule : []"
                                :hint="worksheet.serviceRequest.productGroup.reqUniqueIdentifier3 ? $t('hints.requiredField') : $t('hints.optionalField')"
                                counter="22"
                                maxlength="22"
                                persistent-hint
                                outlined
                                :disabled="!worksheet.serviceRequest.productGroup.isUniqueIdentifier3">
                  </v-text-field>
                  <v-slide-y-transition>
                    <v-row class="pa-0 ma-0" v-show="worksheet.serviceRequest.productGroup.reqUniqueIdentifier3">
                      <v-col class="px-3 py-0 caption">
                        {{ $t('generateRandomImei') }}
                      </v-col>
                      <v-col cols="auto" class="pa-0">
                        <v-btn small
                               color="primary"
                               @click="randomImei(3)">
                          {{ $t('button.generate') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-slide-y-transition>
                </v-col>
              </v-row>
            </v-col>
            <!--v-col cols="12" sm="4">
              <v-text-field v-model="worksheet.serviceRequest.uniqueIdentifierIn3"
                            :label="$t('labels.serialNumber')"
                            :rules="inputRules.generalRules"
                            :hint="$t('hints.requiredField')"
                            persistent-hint
                            outlined
                            required>
              </v-text-field>
            </v-col-->
            <v-col cols="12" sm="4">
              <v-select v-model="worksheet.serviceRequest.warrantyType"
                        :items="selectFieldOptions.warrantyType"
                        item-text="name"
                        item-value="id"
                        :label="$t('labels.warrantyType2')"
                        :rules="inputRules.selectRules"
                        :hint="$t('hints.requiredField')"
                        persistent-hint
                        outlined
                        return-object
                        required>
              </v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-model="worksheet.serviceRequest.dateOfPurchase"
                            :label="$t('labels.dateOfPurchase')"
                            :rules="inputRules.purchaseDateRule"
                            :hint="$t('hints.purchaseDateReq')"
                            persistent-hint
                            v-mask="dateTextMask"
                            outlined>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-model="worksheet.serviceRequest.purchasePrice"
                            :label="$t('labels.purchasePrice')"
                            :hint="$t('hints.optionalField')"
                            suffix="Ft"
                            persistent-hint
                            outlined
                            v-mask="priceMask"
                            required>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-file-input v-model="attachment.invoice"
                            :label="$t('labels.invoice')"
                            :rules="inputRules.maxFileSizeOptional"
                            :hint="$t('hints.maxFileSizeRuleOptional')"
                            persistent-hint
                            accept=".pdf,.PDF,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                            counter
                            show-size
                            outlined
                            ref="valami">
                <template v-slot:selection="{text}">
                  <v-chip small label color="primary">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
            <v-col cols="12" sm="6">
              <v-file-input v-model="attachment.warrantyCard"
                            :label="$t('labels.warrantyTicket')"
                            :rules="inputRules.maxFileSizeOptional"
                            :hint="$t('hints.maxFileSizeRuleOptional')"
                            persistent-hint
                            accept=".pdf,.PDF,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                            counter
                            show-size
                            outlined>
                <template v-slot:selection="{text}">
                  <v-chip small label color="primary">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
          <!-- KÉSZÜLÉKADATOK END -->
          <!-- SZOLGÁLTATÁSOK START -->
          <v-row>
            <v-col cols="12" :class="$vuetify.breakpoint.mdAndDown ? 'text-h4' : 'text-h2'">
              Hibaleírás
            </v-col>
            <!--v-col cols="12">
              <v-select v-model="worksheet.serviceRequest.services"
                        :label="$t('labels.claimedServices')"
                        :hint="$t('hints.requiredField')"
                        persistent-hint
                        outlined></v-select>
            </v-col-->
            <!--v-col cols="12" md="6">
              <v-textarea v-model="worksheet.physicalDamages"
                          :label="$t('physicalDamages')"
                          :hint="$t('inputMessages.optionalField')"
                          rows="3"
                          persistent-hint
                          clearable
                          counter="255"
                          maxlength="255"
                          outlined/>
            </v-col-->
            <!--v-col cols="12" md="6">
              <v-textarea v-model="worksheet.aestheticFaults"
                          :label="$t('cosmeticDamages')"
                          :hint="$t('inputMessages.optionalField')"
                          rows="3"
                          persistent-hint
                          clearable
                          counter="255"
                          maxlength="255"
                          outlined/>
            </v-col-->
            <v-col cols="12">
              <v-textarea v-model="worksheet.serviceRequest.errorDescription"
                          :label="$t('labels.errorDescription')"
                          :rules="inputRules.generalRules"
                          :hint="$t('hints.requiredField')"
                          rows="3"
                          persistent-hint
                          outlined/>
            </v-col>
          </v-row>
          <!-- SZOLGÁLTATÁSOK END -->
          <!-- ÜGYFÉL START -->
          <v-row>
            <v-col cols="12" :class="$vuetify.breakpoint.mdAndDown ? 'text-h4' : 'text-h2'">
              Ügyfél adatok
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field v-model="worksheet.customer.lastName"
                            :label="$t('labels.lastName')"
                            :rules="inputRules.generalRules"
                            :hint="$t('hints.requiredField')"
                            persistent-hint
                            counter="30"
                            outlined
                            required>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field v-model="worksheet.customer.firstName"
                            :label="$t('labels.firstName')"
                            :rules="inputRules.generalRules"
                            :hint="$t('hints.requiredField')"
                            persistent-hint
                            counter="30"
                            outlined
                            required>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field v-model="worksheet.customer.email"
                            :label="$t('labels.email')"
                            :rules="inputRules.emailRules"
                            :hint="$t('hints.requiredField')"
                            persistent-hint
                            counter="30"
                            outlined
                            required>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field v-model="worksheet.customer.phoneNumber"
                            :label="$t('labels.phone')"
                            :rules="inputRules.phoneRules"
                            :hint="$t('hints.requiredField')"
                            persistent-hint
                            prefix="+36-"
                            v-mask="mask"
                            outlined
                            required>
              </v-text-field>
            </v-col>
          </v-row>
          <!-- ÜGYFÉL END -->
          <!-- CÍMEK START -->
          <v-row>
            <v-col cols="12" sm="6">
              <div :class="$vuetify.breakpoint.mdAndDown ? 'text-h4 mb-6' : 'text-h2 mb-6'">
                Átadás/Átvételi cím
              </div>
              <v-text-field v-model="worksheet.deliveryInAddress.label"
                            :label="$t('labels.shippingAddress')"
                            :rules="inputRules.generalRules"
                            :hint="$t('hints.requiredField')"
                            persistent-hint
                            append-icon="mdi-pencil"
                            @click:append="openAddressDialog('SHIPPING', worksheet.deliveryInAddress)"
                            readonly
                            outlined/>
            </v-col>
            <v-col cols="12" sm="6">
              <div :class="$vuetify.breakpoint.mdAndDown ? 'text-h4 mb-6' : 'text-h2 mb-6'">
                Számlázási cím
              </div>
              <v-text-field v-model="worksheet.billingAddress.label"
                            :label="$t('labels.billingAddress')"
                            :rules="inputRules.generalRules"
                            :hint="$t('hints.requiredField')"
                            persistent-hint
                            append-icon="mdi-pencil"
                            @click:append="openAddressDialog('BILLING', worksheet.billingAddress)"
                            readonly
                            :disabled="billingIsEqualToShipping"
                            outlined/>
              <v-checkbox v-model="billingIsEqualToShipping"
                          :label="$t('labels.billingIsEqualToShipping')"/>
            </v-col>
          </v-row>
          <!-- CÍMEK END -->
          <!-- CHB START -->
          <v-row>
            <v-col cols="12">
              <v-checkbox v-model="worksheet.gdpr" :rules="inputRules.gdprRules" class="mb-0">
                <template v-slot:label>
                  Az Adatvédelmi Tájékoztatót elolvastam és elfogadom.
                </template>
              </v-checkbox>
              <v-checkbox v-model="worksheet.termsAndCond" :rules="inputRules.termAndCondRules" class="mt-0">
                <template v-slot:label>
                  Az Általános Szerződési Feltételeket elolvastam és elfogadom.
                </template>
              </v-checkbox>
              <v-checkbox v-model="worksheet.mandatoryServiceFee" :rules="inputRules.generalRules" class="mt-0">
                <template v-slot:label>
                  <span>
                    Megértettem és tudomásul vettem, hogy a futár szolgáltatás megrendelésével 5.000 Ft bruttó fizetési
                    kötelezettség keletkezik. Elutasított árajánlat esetén a futár- és bevizsgálási díj is
                    fizetendő a mindenkori <a href="https://www.istuff.hu/dijszabas" target="_blank" @click.stop title="Díjszabás">díjszabás</a> szerint.
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <!-- CHB END -->
          <!-- KÜLDÉS START -->
          <v-row justify="end">
            <v-btn color="primary" @click="sendAppleForm" :disabled="!appleForm">
              {{ $t('button.send') }}
            </v-btn>
          </v-row>
          <!-- KÜLDÉS END -->
        </v-form>
        <!-- CÍMVÁLASZTÓ DIALOG START -->
        <v-dialog v-model="addressDialog.visible" max-width="800" persistent scrollable>
          <v-card>
            <v-card-title class="secondary">
              <v-icon color="white" class="mr-4">
                {{ addressDialog.type === 'SHIPPING' ? 'mdi-truck-check-outline' : 'mdi-file-account-outline' }}
              </v-icon>
              <span class="white--text">
            {{ addressDialog.type === 'SHIPPING' ? $t('labels.shippingAddress') : $t('labels.billingAddress') }}
          </span>
              <v-spacer></v-spacer>
              <v-icon @click="closeAndClearAddressDialog" color="white">
                mdi-close
              </v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <address-picker-form :address.sync="addressDialog.address"
                                   :address-type="addressDialog.type"
                                   v-on:on-save-click="saveAddress"
                                   v-on:on-cancel-click="closeAndClearAddressDialog"
                                   v-on:on-company-select="showCompanyWarrantyAlertDialog"/>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- CÍMVÁLASZTÓ DIALOG END -->
        <!-- LAR DIALOG START -->
        <loading-and-response-dialog :element-props.sync="loadingAndResponseDialog"
                                     v-on:on-error-click="onErrorClickInLARDialog"
                                     v-on:on-success-click="onSuccussCickInLARDialog"></loading-and-response-dialog>
        <!-- LAR DIALOG END -->
        <!-- CÉGES JÓTÁLLÁS FIGYELMEZTETŐ START -->
        <v-dialog v-model="isCompanyDialog" max-width="320">
          <v-card>
            <v-card-text class="pa-5">
              <div class="text-center my-8">
                <v-icon size="100"
                        color="primary"
                        class="pulse">
                  mdi-bell-ring
                </v-icon>
              </div>
              <div class="text-center text-h6 mb-2">
                {{ $t('alert') }}
              </div>
              <div class="text-center">
                {{ $t('isCompanyWarrantyAlert') }}
              </div>
            </v-card-text>
            <v-card-actions class="pa-5">
              <v-btn color="primary"
                     block
                     text
                     @click="isCompanyDialog = false">
                {{ $t('button.ok') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- CÉGES JÓTÁLLÁS FIGYELMEZTETŐ END -->
      </v-container>
    </v-container>
  </div>
</template>

<script>
import AddressPickerForm from '@/components/shared/AddressPickerForm.vue';
import LoadingAndResponseDialog from '@/components/shared/LoadingAndResponseDialog.vue';
import dayjs from "dayjs";

export default {
  name: 'AppleForm',
  components: {
    AddressPickerForm, LoadingAndResponseDialog,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          isContainerFluid: false,
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    await this.setDates();
    await this.setServiceRequestFormCountry();
    await this.initForm();
    console.log('q:', this.$route.query.q);
    if (this.$route.query.q) {
      switch (this.$route.query.q) {
        case 'oow':
          this.worksheet.serviceRequest.warrantyType = this.selectFieldOptions.warrantyType.find((item) => item.code.toLowerCase() === 'oow');
          break;
        case 'iw':
          this.worksheet.serviceRequest.warrantyType = this.selectFieldOptions.warrantyType.find((item) => item.code.toLowerCase() === 'iw');
          break;
      }
    }
  },
  data() {
    return {
      worksheet: {
        formType: 17,
        processType: 1,
        prevCrmRequestId: null,
        prevGsmWorkSheetId: null,
        sourceSystem: null,
        externalId: null,
        serviceRequest: {
          dateOfDelivery: null,
          repairCostLimit: null,
          prevCrmRequestId: null,
          prevGsmWorkSheetId: null,
          availableServiceId: null,
          brand: {},
          b2bRefNo: null,
          productGroup: {},
          productTypeFull: null,
          productType: {},
          uniqueIdentifierIn1: '',
          uniqueIdentifierIn2: '',
          uniqueIdentifierIn3: '',
          warrantyType: {},
          dateOfPurchase: null,
          errorDescription: '',
          networkLock: {},
          mobilProvider: {},
          insuranceNumber: '',
          rmaNumber: '',
          accessories: null,
          paymentMethod: null,
          deductible: null,
          isLoan: null,
          purchasePrice: null,
          otherComment: '',
        },
        customer: {
          id: null,
          customerType: 'APPLESTORE_COURIER',
          crmNamePrefixId: null,
          firstName: '',
          middleName: null,
          lastName: '',
          email: '',
          phoneNumber: '',
          password: null,
        },
        deliveryInAddress: {
          id: null,
          isCompany: 0,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
        },
        billingAddress: {
          id: null,
          isCompany: 0,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
        },
        deliveryOutAddress: {},
        gdpr: false,
        termsAndCond: false,
        generalInfos: false,
        mandatoryServiceFee: false,
        accountRegistration: null,
        isAutomaticQuotationAccepting: null,
        isVipService: null,
        isAcceptanceOfUsedParts: null,
        calculatedRepairPrice: null,
        calcDataId: null,
        isStoreDeliveryOut: true,
        physicalDamages: 'Normál állapot, apró használati nyomok, karcok, sérülések',
        aestheticFaults: 'Normál állapot, apró használati nyomok, karcok, sérülések',
      },
      attachment: {
        invoice: null,
        warrantyCard: null,
      },
      billingIsEqualToShipping: false,
      addressDialog: {
        visible: false,
        type: 'SHIPPING',
        address: {},
      },
      appleForm: false,
      imeiMask: '###############',
      imeiHint: '',
      mask: '##-###-####',
      priceMask: '##########',
      dateTextMask: '####-##-##',
      taxNumberMaskHU: '########-#-##',
      maxDate: null,
      typeSearch: '',
      isCompanyDialog: false,
      loadingAndResponseDialog: {
        isVisible: false,
        stepper: 1,
        loadingText: 'LOADING_MESSAGES.PROCESS_IN_PROGRESS',
        successText: 'SUCCESS_MESSAGES.APPLEWORKSHEET_SEND',
        errorText: 'ERROR_MESSAGES.APPLEWORKSHEET_SEND',
        successBtnText: 'button.ok',
        errorBtnText: 'button.ok',
      },
      selectFieldOptions: {
        productGroups: [],
        productTypes: [],
        warrantyType: [
          {
            id: 1,
            name: 'Garanciális javítás',
          },
          {
            id: 2,
            name: 'Fizetős javítás',
          },
        ],
        countries: [
          { label: 'Magyarország', countryCode: 'HUN' },
        ],
        countriesEN: [
          { label: 'Hungary', countryCode: 'HUN' },
        ],
        countriesDE: [
          { label: 'Ungarn', countryCode: 'HUN' },
        ],
      },
      inputRules: {
        acceptInformation: [v => !!v || this.$t('hints.acceptInformation')],
        generalRules: [v => !!v || this.$t('hints.requiredField')],
        emailRules: [
          v => /.+@.+\..+/.test(v) || this.$t('hints.emailMatch'),
          v => !!v || this.$t('hints.requiredField'),
        ],
        phoneRules: [
          v => /^[0-9-]*$/.test(v) || this.$t('hints.onlyNumbers'),
          v => !!v || this.$t('hints.requiredField'),
        ],
        gdprRules: [
          v => !!v || this.$t('hints.requiredPrivacy'),
        ],
        termAndCondRules: [
          v => !!v || this.$t('hints.requiredTermsAndCond'),
        ],
        maxFileSize: [
          v => !v || v.size < 5000000 || this.$t('hints.maxFileSizeRule'),
        ],
        maxFileSizeOptional: [
          v => !v || v.size < 5000000 || this.$t('hints.maxFileSizeRule'),
        ],
        requiredFile: [
          v => !v || v.size < 5000000 || this.$t('hints.maxFileSizeRule'),
          v => !!v || this.$t('hints.requiredField'),
        ],
        min8: [
          v => v.length >= 8 || this.$t('hints.passwordRuleMin8'),
          v => !!v || this.$t('hints.requiredField')
        ],
        imeiRule: [
          v => !!v || this.$t('hints.requiredField'),
          v => /^[0-9]*$/.test(v) || this.$t('hints.onlyNumbers'),
          v => (v && v.length === 15) || this.$t('hints.minLenght15'),
        ],
        onlyNumbers: [
          v => !!v || this.$t('hints.optionalField'),
          v => /^[0-9]*$/.test(v) || this.$t('hints.onlyNumbers'),
        ],
        snRule: [
          v => !!v || this.$t('hints.requiredField'),
          v => /^[a-zA-Z0-9-/\\]*$/.test(v) || this.$t('hints.snRules'),
          v => (v && v.length < 23) || this.$t('hints.minLenght15'),
        ],
        selectRules: [
          v => !!v || this.$t('hints.requiredField'),
          v => ( v && v.id > 0) || this.$t('hints.requiredField'),
        ],
        purchaseDateRule: [
          v => !!v || this.$t('hints.purchaseDateReq'),
          v => this.purchaseDateIsOK(v) || `${this.$t('hints.purchaseDateMax')} ${this.maxDate}!`,
        ],
        vatRules: [
          (v) => !!v || this.$t('hints.requiredField'),
          (v) => (v && v.length === 13) || this.$t('hints.requiredField'),
        ],
      },
    };
  },
  methods: {
    editElement() {
      //
    },
    saveElement() {
      //
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    async initForm() {
      const response = await this.$store.dispatch('showForm', 'AppleMunkalapForm');
      if (response.status === 200) {
        this.selectFieldOptions.warrantyType = JSON.parse(JSON.stringify(response.data.warrantyType));
        this.worksheet.serviceRequest.networkLock = JSON.parse(JSON.stringify(response.data.networkLock[0]));
        this.worksheet.serviceRequest.brand = JSON.parse(JSON.stringify(response.data.brand[0]));
        await this.callShowProductGroupOfBrand(this.worksheet.serviceRequest.brand);
        await this.callShowPaymentMethodOfCustomerType();
      }
    },
    /**
     *
     * Űrlap beküldése
     *
     */
    async sendAppleForm() {
      this.openLARDialog();
      this.worksheet.deliveryOutAddress = JSON.parse(JSON.stringify(this.worksheet.deliveryInAddress));
      this.worksheet.serviceRequest.availableServiceId = this.worksheet.serviceRequest.warrantyType.id === 1 ? 53 : this.worksheet.serviceRequest.warrantyType.id === 2 ? 54 : 77;
      this.worksheet.serviceRequest.mobilProvider = JSON.parse(JSON.stringify(this.worksheet.serviceRequest.networkLock));
      if (this.billingIsEqualToShipping) {
        this.worksheet.billingAddress.billingName = `${this.worksheet.customer.lastName} ${this.worksheet.customer.firstName}`;
      }
      // Purchase Price ellenőrzése
      if (this.worksheet.serviceRequest.purchasePrice === '') {
        this.worksheet.serviceRequest.purchasePrice = null;
      }
      if (this.worksheet.serviceRequest.purchasePrice) {
        if (this.worksheet.serviceRequest.purchasePrice.length < 1) {
          this.worksheet.serviceRequest.purchasePrice = null;
        }
      }
      const response = await this.$store.dispatch('sendCrmPickupReturn',
        {form: this.worksheet, attachment: this.attachment});
      if (response.status === 200) {
        this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.APPLEWORKSHEET_SAVE';
        this.changeLARDialogContentToSuccess();
      } else if (response.status === 201) {
        this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.APPLEWORKSHEET_SAVE';
        this.changeLARDialogContentToSuccess();
      } else if (response.status === 422) {
        this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.USER_EXISTS';
        this.changeLARDialogContentToError();
      } else if (response.status === 405) {
        this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.APPLEWORKSHEET_SAVE';
        this.changeLARDialogContentToError();
      } else {
        this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.APPLEWORKSHEET_SAVE';
        this.changeLARDialogContentToError();
      }
    },
    async setDates() {
      const now = new Date();
      // const date = dayjs(now).subtract(3, 'day');
      this.maxDate = now.toISOString().substr(0, 10);
      if (this.$route.params.ws === 'uj-megrendeles') {
        this.worksheet.serviceRequest.dateOfDelivery = now.toISOString().substr(0, 10);
      }
    },
    async setServiceRequestFormCountry() {
      if (this.$i18n.locale === 'hu-HU') {
        this.worksheet.deliveryInAddress.country = this.selectFieldOptions.countries[0];
        this.worksheet.billingAddress.country = this.selectFieldOptions.countries[0];
      } else if (this.$i18n.locale === 'de-DE') {
        this.worksheet.deliveryInAddress.country = this.selectFieldOptions.countriesDE[0];
        this.worksheet.billingAddress.country = this.selectFieldOptions.countriesDE[0];
      } else {
        this.worksheet.deliveryInAddress.country = this.selectFieldOptions.countriesEN[0];
        this.worksheet.billingAddress.country = this.selectFieldOptions.countriesEN[0];
      }
    },
    purchaseDateIsOK(date) {
      const inputDate = dayjs(date);
      const maxD = dayjs(this.maxDate);
      const max = dayjs(maxD).add(1, 'day');
      if (date) {
        if (date.length === 10) {
          if (new Date(date).getDate()) {
            return inputDate.isBefore(dayjs(max));
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    /**
     *
     * Loading and response dialog
     *
     */
    openLARDialog() {
      this.loadingAndResponseDialogloadingText = 'LOADING_MESSAGES.PROCESS_IN_PROGRESS';
      this.loadingAndResponseDialog.stepper = 1;
      this.loadingAndResponseDialog.isVisible = true;
    },
    closeLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
    },
    changeLARDialogContentToSuccess() {
      this.loadingAndResponseDialog.stepper = 2;
    },
    changeLARDialogContentToError() {
      this.loadingAndResponseDialog.stepper = 3;
    },
    onSuccussCickInLARDialog() {
      this.closeLARDialog();
      this.$router.push({ name: 'kezdolap' });
    },
    onErrorClickInLARDialog() {
      this.closeLARDialog();
    },
    /*
     * TERMÉK CSOPORT LEKÉRDEZÉSE GYÁRTÓ ALAPJÁN
     */
    async callShowProductGroupOfBrand(brand) {
      const resp = await this.$store.dispatch('showProductGroupOfBrand', brand.id);
      // console.log('resp: ', resp);
      const conv = await this.ProductGroupConverter(resp.data);
      // this.selectFieldOptions.productGroups = resp.data.sort((a, b) => ((a.name > b.name) ? 1 : -1));
      this.selectFieldOptions.productGroups = conv.sort((a, b) => ((a.displayName > b.displayName) ? 1 : -1));
    },
    /*
     * TERMÉK TÍPUS LEKÉRDEZÉSE GYÁRTÓ ÉS TERMÉK TÍPUS ALAPJÁN
     */
    async callShowProductTypeOfBrand() {
      // console.log('callShowProductTypeOfBrand start...');
      const response = await this.$store.dispatch('showProductTypeOfBrand',
        { brandId: this.worksheet.serviceRequest.brand.id, productGroupId: this.worksheet.serviceRequest.productGroup.id });
      // console.log('callShowProductTypeOfBrand: ', response);
      if (response.status === 200) {
        response.data.sort((a, b) => ((a.productTypeShoppingName > b.productTypeShoppingName) ? 1 : -1));
        // ha APPLE brand.id = 2, akkor nem kell
        // const benne = this.$NoDisplayNameBrands.includes(this.worksheet.serviceRequest.brand.id);
        const benne = true;
        if (benne) {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].displayName = `${response.data[i].productTypeShoppingName}`;
          }
        } else {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].displayName = `${response.data[i].productTypeShoppingName} - ${response.data[i].gsmTipusNev}`;
          }
        }
        this.selectFieldOptions.productTypes = response.data;
      }
    },
    /*
     * USER-TYPE-HOZ TARTOZÓ PAYMENT-METHODOK LEKÉRDEZÉSE
     */
    async callShowPaymentMethodOfCustomerType() {
      const response = await this.$store.dispatch('showPaymentMethodOfCustomerType', this.worksheet.customer.customerType);
      if (response.status === 200) {
        this.worksheet.serviceRequest.paymentMethod = response.data[0];
      }
    },
    /**
     *
     * @param type SHIPPING | BILLING
     * @param address Object
     */
    openAddressDialog(type, address) {
      this.addressDialog.type = type;
      this.addressDialog.address = JSON.parse(JSON.stringify(address));
      this.addressDialog.visible = true;
    },
    closeAndClearAddressDialog() {
      this.addressDialog.visible = false;
      this.addressDialog.type = 'SHIPPING';
      this.addressDialog.address = {};
    },
    saveAddress() {
      if (this.addressDialog.type === 'SHIPPING') {
        this.worksheet.deliveryInAddress = JSON.parse(JSON.stringify(this.addressDialog.address));
      } else if (this.addressDialog.type === 'BILLING') {
        this.worksheet.billingAddress = JSON.parse(JSON.stringify(this.addressDialog.address));
      }
      this.closeAndClearAddressDialog();
    },
    showCompanyWarrantyAlertDialog(isCompany) {
      const now = dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
      const dateOfPurchase = dayjs(this.worksheet.serviceRequest.dateOfPurchase);
      // console.log('isCompany: ', isCompany);
      // console.log('now: ', now);
      // console.log('dateOfPurchase: ', dateOfPurchase);
      // console.log('warrantyType: ', this.worksheet.serviceRequest.warrantyType);
      const diff = now.diff(dateOfPurchase, 'year', true);
      // console.log('diff: ', diff);
    },
    async randomImei(type) {
      const randomIdentifier = await this.$store.dispatch('randomImeiGenerator');
      // console.log(`%crandomIdentifier: ${randomIdentifier} - hossz: ${randomIdentifier.toString().length}`, 'background-color: #00cec9; color: #ffffff; font-size: 12px;');
      if (type === 1) {
        this.worksheet.serviceRequest.uniqueIdentifierIn1 = randomIdentifier;
      } else if (type === 2) {
        this.worksheet.serviceRequest.uniqueIdentifierIn2 = randomIdentifier;
      } else if (type === 3) {
        this.worksheet.serviceRequest.uniqueIdentifierIn3 = randomIdentifier;
      }
    },
  },
  watch: {
    billingIsEqualToShipping(value) {
      if (value) {
        this.worksheet.billingAddress = JSON.parse(JSON.stringify(this.worksheet.deliveryInAddress));
      } else {
        this.worksheet.billingAddress = {
          id: null,
          isCompany: 0,
          country: { label: 'Magyarország', countryCode: 'HUN' },
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
        };
      }
    },
    'worksheet.serviceRequest.productGroup': function (newVal) {
      // console.log('newVal: ', newVal);
      this.worksheet.serviceRequest.uniqueIdentifierIn1 = null;
      this.worksheet.serviceRequest.uniqueIdentifierIn2 = null;
      this.worksheet.serviceRequest.uniqueIdentifierIn3 = null;
      this.worksheet.serviceRequest.productTypeFull = null;
      this.selectFieldOptions.productTypes = [];
      // this.selectedProductGroup = this.serviceRequestForm.serviceRequest.productGroup.find(item => item.productGroup.id === newVal.id);
      if (newVal.id) {
        this.callShowProductTypeOfBrand();
      }
    },
    'worksheet.serviceRequest.productTypeFull': function (newVal) {
      this.worksheet.serviceRequest.productType = {};
      this.typeSearch = '';
      if (newVal) {
        this.worksheet.serviceRequest.productType.id = newVal.gsmTipusId;
        this.worksheet.serviceRequest.productType.name = newVal.productTypeShoppingName;
      }
    },
  },
};
</script>

<style scoped>

</style>
