const authToken = 'token';
const webUser = 'webuser';
const webProject = 'webproject';
const crmToken = 'ct';

export const getToken = () => window.sessionStorage.getItem(authToken);

export const saveToken = (token) => {
  if (!token) {
    return;
  }
  window.sessionStorage.setItem(authToken, token);
};

export const destroyToken = () => {
  window.sessionStorage.removeItem(authToken);
};

export const getWebUser = () => JSON.parse(window.sessionStorage.getItem(webUser));

export const saveWebUser = (webuser) => {
  if (!webuser) {
    return;
  }
  window.sessionStorage.setItem(webUser, JSON.stringify(webuser));
};

export const destroyWebUser = () => {
  window.sessionStorage.removeItem(webUser);
};

export const getWebProject = () => {
  const whash = window.sessionStorage.getItem(webProject);
  if (whash) {
    return whash;
  }
  return null;
};

export const saveWebProject = (webproject) => {
  if (!webproject) {
    return;
  }
  window.sessionStorage.setItem(webProject, webproject);
};

export const destroyWebProject = () => {
  window.sessionStorage.removeItem(webProject);
};

export const getCrmToken = () => window.sessionStorage.getItem(crmToken);

export const saveCrmToken = (token) => {
  if (!token) {
    return;
  }
  window.sessionStorage.setItem(crmToken, token);
};

export const destroyCrmToken = () => {
  window.sessionStorage.removeItem(crmToken);
};

export default {
  getToken, saveToken, destroyToken, getWebUser, saveWebUser, destroyWebUser, getWebProject, saveWebProject, destroyWebProject, getCrmToken, saveCrmToken, destroyCrmToken,
};
