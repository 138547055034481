<template>
  <v-dialog v-model="elementProps.isVisible" max-width="340" persistent scrollable>
    <v-card>
      <v-card-title class="indigo white--text">
        {{ $t(`${elementProps.title}`) }}
        <v-spacer></v-spacer>
        <v-icon color="white"
                @click="cancelClick">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-list>
          <template v-for="(item, index) in $store.state.languages.availableLanguages">
            <v-list-item :key="index">
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t(`languages.${item.text}`) }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="primary" @click="saveClick(item)">
                  mdi-plus
                </v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index < $store.state.languages.availableLanguages.length - 1" :key="`d-${index}`"></v-divider>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LanguageList',
  components: {
    //
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          title: 'button.addNewTranslation',
          isVisible: false,
          code: 'hu',
          name: 'Magyar',
        };
      },
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    cancelClick() {
      this.$emit('cancel-click');
    },
    saveClick(item) {
      this.elementProps.code = item.value;
      this.elementProps.name = item.hun;
      this.$emit('save-click');
    },
  },
};
</script>

<style scoped>

</style>
