<template>
  <div>
    <!-- CONTENT START -->
    <v-container :class="editorMode ? 'b-wrapper': ''" :fluid="elementProps.isContainerFluid">
      <!-- EDITOR ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            Article Layout 1
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- EDITOR ACTIONBOX END -->
      <!-- CONTENT START -->
      <v-row>
        <v-col cols="12" sm="6" :order="elementProps.imgOnLeft === 1 ? 2 : 1" :align-self="elementProps.textAlign">
          <div class="text-h3" :class="translatedTitle.length > 0 ? 'mb-10' : ''">
            {{ translatedTitle }}
          </div>
          <div v-html="translatedText"></div>
        </v-col>
        <v-col cols="12" sm="6" :order="elementProps.imgOnLeft === 1 ? 1 : 2" :align-self="elementProps.imgAlign">
          <v-row class="mx-0 fill-height" justify="center">
            <template v-for="(image, index) in elementProps.images">
              <v-col cols="12" class="px-1 py-0" :key="index">
                <template v-if="image.length < 1">
                  <v-img :src="`${publicPath}images/dummy/city.jpg`" max-width="800px"></v-img>
                </template>
                <template v-else>
                  <v-img :src="`${publicPath}public_storage${image}`" max-width="800px"></v-img>
                </template>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
      <!-- CONTENT END -->
    </v-container>
    <!-- CONTENT END -->
    <!-- EDITOR PANEL START -->
    <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
      <v-card>
        <v-toolbar color="primary" dark flat tile>
          <v-btn icon>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            Article Layout 1 - Szerkesztés
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorPanel = !editorPanel">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pt-4">
            <!-- SZÖVEGEK SZERKESZTÉSE START -->
            <v-col cols="6" class="text-subtitle-1 text--primary">
              Szövegek szerkesztése
            </v-col>
            <v-col cols="6">
              <v-row class="mx-0" justify="end">
                <v-btn color="primary" @click="addNewTranslation('OFF')">
                  {{ $t('button.addNewTranslation') }}
                </v-btn>
              </v-row>
            </v-col>
            <!-- FORDÍTÁS START -->
            <v-col cols="12">
              <v-tabs v-model="translationTab"
                      background-color="teal darken-1"
                      dark>
                <v-tab v-for="(item, index) in elementProps.translations"
                       :key="`lt-${index}`">
                  {{ item.name }}
                </v-tab>
                <v-tabs-items v-model="translationTab">
                  <v-tab-item v-for="(item, index) in elementProps.translations"
                              :key="`lc-${index}`">
                    <v-card outlined tile>
                      <v-card-text>
                        <v-col cols="12">
                          <div class="text-h5 mb-6">
                            {{ item.title }}
                          </div>
                          <div v-html="item.text"></div>
                        </v-col>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="pa-4">
                        <v-spacer></v-spacer>
                        <v-btn v-if="elementProps.translations.length > 1"
                               color="error"
                               text
                               class="mr-4"
                               @click="deleteTranslation(index, 'OFF')">
                          {{ $t('button.delete') }}
                        </v-btn>
                        <v-btn color="primary"
                               @click="editTranslation(item, index)">
                          {{ $t('button.edit') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row class="mx-n3">
                <v-col cols="12" class="pb-0 text-subtitle-1 text--primary">
                  Szöveg vertikális elhelyezkedése
                </v-col>
                <v-col cols="12" class="pb-0 text-subtitle-1 text--primary">
                  <v-radio-group v-model="elementProps.textAlign"
                                 mandatory>
                    <v-radio :label="$t('labels.toStart')"
                             value="start"></v-radio>
                    <v-radio :label="$t('labels.toCenter')"
                             value="center"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
            <v-col col="12" sm="6">
              <v-row class="mx-n3">
                <v-col cols="12" class="pb-0 text-subtitle-1 text--primary">
                  Kép elhelyezkedése
                </v-col>
                <v-col cols="6" class="pb-0 text-subtitle-1 text--primary">
                  <v-radio-group v-model="elementProps.imgOnLeft"
                                 mandatory>
                    <v-radio :label="$t('labels.toRight')"
                             :value="2"></v-radio>
                    <v-radio :label="$t('labels.toLeft')"
                             :value="1"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6" class="pb-0 text-subtitle-1 text--primary">
                  <v-radio-group v-model="elementProps.imgAlign"
                                 mandatory>
                    <v-radio :label="$t('labels.toStart')"
                             value="start"></v-radio>
                    <v-radio :label="$t('labels.toCenter')"
                             value="center"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
            <!-- FORDÍTÁS END -->
            <!-- SZÖVEGEK SZERKESZTÉSE START -->
            <!--v-col cols="12" class="pb-0">
              <v-divider></v-divider>
            </v-col-->
            <v-col col="12" sm="6">
              <v-row class="mx-n3">
                <v-col cols="12" class="text-subtitle-1 text--primary">
                  Kép szerkesztése <span class="caption">(kattintson a képre a kép cseréjéhez)</span>
                </v-col>
                <v-col cols="12" class="text-subtitle-1 text--primary">
                  <v-row class="mx-n3">
                    <v-col cols="12">
                      <v-img :src="`${publicPath}public_storage${elementProps.images[0]}`"
                             width="100%"
                             height="200px"
                             class="image-changeable"
                             @click="openMediaGallery(0)"
                             max-width="800px"></v-img>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="mx-0">
            <v-col cols="12" class="pb-0">
              <v-row justify="space-between" align="center" class="mx-0">
                <span class="text-subtitle-1 text--primary">
                  A befoglaló container 100% szélességű legyen?
                </span>
                <v-switch v-model="elementProps.isContainerFluid"
                          inset>
                </v-switch>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="saveElementProps">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDITOR PANEL END -->
    <!-- MEDIA GALLERY START -->
    <template v-if="editorMode">
      <v-dialog v-model="mediaGallery.isVisible"
                persistent
                fullscreen
                transition="dialog-bottom-transition"
                scrollable>
        <v-card tile>
          <media-page :in-dialog="true"
                      v-on:paste-media="pasteMedia"
                      v-on:close-media-dialog="mediaGallery.isVisible = false"></media-page>
        </v-card>
      </v-dialog>
    </template>
    <!-- MEDIA GALLERY END -->
    <!-- FORDÍTÁS DIALOG START -->
    <translation-editor-dialog :content="translationDialog"
                               :visible-elements="visibleElements"
                               v-on:@cancel="cancelNewTranslation"
                               v-on:@save="saveNewTranslation" />
    <!-- FORDÍTÁS DIALOG START -->
  </div>
</template>

<script>
import MediaPage from '@/views/admin/MediaPage.vue';
import LanguageList from '@/components/shared/LanguageList.vue';
import TranslationEditorDialog from '@/components/shared/TranslationEditorDialog.vue';

export default {
  name: 'ArticleLayout1',
  components: {
    MediaPage, LanguageList, TranslationEditorDialog,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          isContainerFluid: false,
          imgOnLeft: 2,
          imgAlign: 'start',
          textAlign: 'start',
          images: [
            '',
          ],
          translations: [
            {
              code: 'hu',
              name: 'Magyar',
              title: 'Lorem ipsum dolor sit amet',
              text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
            },
          ],
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.changeTranslations();
  },
  data() {
    return {
      editorPanel: false,
      translationTab: 0,
      mediaGallery: {
        isVisible: false,
      },
      translatedTitle: '',
      translatedText: '',
      visibleElements: ['title', 'text1'],
    };
  },
  methods: {
    editElement() {
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    saveElement() {
      this.$emit('save-element');
    },
    saveElementProps() {
      this.translationTab = 0;
      this.changeTranslations();
      this.editorPanel = false;
    },
    openMediaGallery(index) {
      this.mediaGallery.imageIndex = index;
      this.mediaGallery.isVisible = true;
    },
    pasteMedia() {
      const image = this.$store.getters.getSelectedImageInMediaGallery;
      this.elementProps.images[this.mediaGallery.imageIndex] = image.fileFullPath;
      this.mediaGallery.isVisible = false;
      this.$store.commit('clearSelectedImageInMediaGallery');
    },
    changeTranslations() {
      const index = this.elementProps.translations.findIndex((item) => item.code === this.$i18n.locale.substr(0, 2));
      const currentLanguageIndex = index === -1 ? 0 : index;
      this.translatedTitle = this.elementProps.translations[currentLanguageIndex].title;
      this.translatedText = this.elementProps.translations[currentLanguageIndex].text;
    },
  },
};
</script>

<style scoped>

</style>
