<template>
  <div>
    <v-container :class="editorMode ? 'b-wrapper': ''">
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            Article Title 1
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <v-row>
        <v-col cols="12">
          <p class="secondary--text text-center mb-0">
            {{ translatedSubtitle }}
          </p>
          <h4 class="primary--text text--darken-1 text-center">
            {{ translatedTitle }}
          </h4>
        </v-col>
      </v-row>
    </v-container>
    <!-- EDITOR PANEL START -->
    <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
      <v-card>
        <v-card-title class="primary white--text">
          <v-icon color="white" class="mr-4">
            mdi-pencil
          </v-icon>
          <div>
            Article Title 1 - Szerkesztés
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorPanel = !editorPanel" color="white">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav" color="white">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="pt-4">
            <!-- SZÖVEGEK SZERKESZTÉSE START -->
            <v-col cols="6" class="text-subtitle-1 text--primary">
              Szövegek szerkesztése
            </v-col>
            <v-col cols="6">
              <v-row class="mx-0" justify="end">
                <v-btn color="primary" @click="addNewTranslation('OFF')">
                  {{ $t('button.addNewTranslation') }}
                </v-btn>
              </v-row>
            </v-col>
            <!-- FORDÍTÁS START -->
            <v-col cols="12">
              <v-tabs v-model="translationTab"
                      background-color="teal darken-1"
                      dark>
                <v-tab v-for="(item, index) in elementProps.translations"
                       :key="`lt-${index}`">
                  {{ item.name }}
                </v-tab>
                <v-tabs-items v-model="translationTab">
                  <v-tab-item v-for="(item, index) in elementProps.translations"
                              :key="`lc-${index}`">
                    <v-card outlined tile>
                      <v-card-text>
                        <v-col cols="12">
                          <div class="text-body-1 mb-6">
                            {{ item.subtitle }}
                          </div>
                          <div class="text-h4 mb-6">
                            {{ item.title }}
                          </div>
                        </v-col>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="pa-4">
                        <v-spacer></v-spacer>
                        <v-btn v-if="elementProps.translations.length > 1"
                               color="error"
                               text
                               class="mr-4"
                               @click="deleteTranslation(index, 'OFF')">
                          {{ $t('button.delete') }}
                        </v-btn>
                        <v-btn color="primary"
                               @click="editTranslation(item, index)">
                          {{ $t('button.edit') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="mx-0">
            <v-col cols="12" class="pb-0">
              <v-row justify="space-between" align="center" class="mx-0">
                      <span class="text-subtitle-1 text--primary">
                        A befoglaló container 100% szélességű legyen?
                      </span>
                <v-switch v-model="elementProps.isContainerFluid"
                          inset>
                </v-switch>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="saveElementProps">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDITOR PANEL END -->
    <!-- FORDÍTÁS DIALOG START -->
    <translation-editor-dialog :content="translationDialog"
                               :visible-elements="visibleElements"
                               v-on:@cancel="cancelNewTranslation"
                               v-on:@save="saveNewTranslation" />
    <!-- FORDÍTÁS DIALOG START -->
  </div>
</template>

<script>
import TranslationEditorDialog from '@/components/shared/TranslationEditorDialog.vue';

export default {
  name: 'ArticleTitle1',
  components: {
    TranslationEditorDialog,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          isContainerFluid: false,
          translations: [
            {
              code: 'hu',
              name: 'Magyar',
              title: 'Lorem ipsum dolor sit amet',
              subtitle: 'Lorem ipsum',
            },
          ],
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    this.changeTranslations();
  },
  data() {
    return {
      editorPanel: false,
      translationTab: 0,
      translatedTitle: '',
      translatedSubtitle: '',
      visibleElements: ['title', 'subtitle'],
    };
  },
  methods: {
    editElement() {
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    saveElement() {
      this.$emit('save-element');
    },
    saveElementProps() {
      this.translationTab = 0;
      this.changeTranslations();
      this.editorPanel = false;
    },
    changeTranslations() {
      const index = this.elementProps.translations.findIndex((item) => item.code === this.$i18n.locale.substr(0, 2));
      const currentLanguageIndex = index === -1 ? 0 : index;
      this.translatedTitle = this.elementProps.translations[currentLanguageIndex].title;
      this.translatedSubtitle = this.elementProps.translations[currentLanguageIndex].subtitle;
    },
  },
};
</script>

<style scoped>

</style>
