/* eslint-disable no-shadow */
import { APIGET, APIPOST, APIPUT } from '@/utils/apiHelper';

// initial state
const state = {
  categories: [
    {
      id: 1,
      root: null,
      code: 'allashirdetes',
      isActive: 1,
      translation: {
        language: 'hu',
        name: 'Álláshirdetés',
      },
    },
  ],
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async createCategory(context, credentials) {
    const response = await APIPOST('categories', credentials, true);
    return response.data;
  },
  async showCategories(context, credentials) {
    const response = await APIGET('categories');
    return response.data;
  },
  async showCategoryById(context, credentials) {
    const response = await APIGET(`categories/${credentials}`);
    return response.data;
  },
  async updateCategory(context, credentials) {
    const response = await APIPUT(`categories/${credentials.id}`, credentials, true);
    return response.data;
  },
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
