<template>
  <div>
    <v-container :class="editorMode ? 'b-wrapper': ''" :fluid="elementProps.isContainerFluid"
                 :style="`background: url('${publicPath}images/sampleBackground.png') center center no-repeat;`">
      <!-- PAGE BUILDER ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            ContactForm1
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- PAGE BUILDER ACTIONBOX END -->
      <!-- CONTENT START -->
      <v-container>
        <v-row>
          <!-- BAL OLDALI SZÖVEG START -->
          <v-col cols="12" md="6">
            <v-row class="mx-0">
              <v-col cols="12" class="text-h3 white--text pr-10" v-show="translatedTitle.length > 0">
                {{ translatedTitle }}
              </v-col>
              <v-col cols="12" class="white--text mb-10" v-html="translatedText"></v-col>
              <v-col cols="12">
                <template v-for="(value, name, index) in elementProps.contact">
                  <v-row class="mx-0" :key="index" align-content="center" align="center" v-show="value.show">
                    <v-col cols="auto">
                      <v-avatar color="#FFA726" tile>
                        <v-icon color="white">
                          {{ value.icon }}
                        </v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col class="text-h6 white--text">
                      {{ value.text }}
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-row>
          </v-col>
          <!-- BAL OLDALI SZÖVEG END -->
          <!-- FORM CARD START -->
          <v-col cols="12" md="6">
            <v-card elevation="6">
              <v-card-title class="pa-6">
                Küldjön nekünk üzenetet!
              </v-card-title>
              <v-card-text class="px-6">
                <v-form v-model="formIsValid" ref="iContactForm">
                  <v-row>
                    <v-col cols="12" md="6" class="py-1">
                      <v-text-field v-model="contactForm.visitor.lastName"
                                    :label="$t('labels.lastName')"
                                    filled
                                    :rules="[$store.state.inputRules.requiredField]"
                                    :hint="$t('hints.requiredField')"
                                    persistent-hint>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-1">
                      <v-text-field v-model="contactForm.visitor.firstName"
                                    :label="$t('labels.firstName')"
                                    filled
                                    :rules="[$store.state.inputRules.requiredField]"
                                    :hint="$t('hints.requiredField')"
                                    persistent-hint>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-1">
                      <v-text-field v-model="contactForm.visitor.email"
                                    :label="$t('labels.email')"
                                    filled
                                    :rules="[$store.state.inputRules.requiredField, $store.state.inputRules.emailValidator]"
                                    :hint="$t('hints.requiredField')"
                                    persistent-hint>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-1">
                      <v-text-field v-model="contactForm.visitor.phone"
                                    :label="$t('labels.phone')"
                                    filled
                                    :rules="[$store.state.inputRules.requiredField,$store.state.inputRules.phoneValidator]"
                                    :hint="$t('hints.requiredField')"
                                    prefix="+36-"
                                    persistent-hint
                                    v-mask="phoneMask.mask">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <v-select v-model="contactForm.contactType"
                                :label="$t('labels.subject')"
                                filled
                                :rules="[$store.state.inputRules.requiredField,$store.state.inputRules.selectRule]"
                                :items="selectFieldOptions.contactTypes"
                                item-value="id"
                                item-text="name"
                                return-object
                                :hint="$t('hints.requiredField')"
                                persistent-hint>
                      </v-select>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <v-text-field v-model="contactForm.uniqueIdentifier"
                                    :label="$t('labels.serialNumber2')"
                                    filled
                                    :hint="$t('hints.optionalUniqueIdentifier')"
                                    persistent-hint>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <v-textarea v-model="contactForm.message"
                                  :label="$t('labels.message')"
                                  filled
                                  auto-grow
                                  clearable
                                  hint="Opcionális"
                                  persistent-hint>
                      </v-textarea>
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <v-checkbox v-model="checkboxGDPR"
                                  :rules="[$store.state.inputRules.requiredField]">
                        <template v-slot:label>
                          <div class="text-body-2">
                            Az <a href="#" @click.stop>Adatvédelmi tájékoztatót</a> elolvastam és elfogadom.
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-checkbox v-model="checkboxASZF"
                                  :rules="[$store.state.inputRules.requiredField]"
                                  class="mt-0">
                        <template v-slot:label>
                          <div class="text-body-2">
                            Az <a href="#" @click.stop>Általános Szerződési Feltételeket</a>&nbsp;elolvastam és elfogadom.
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="px-6 pb-6">
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="sendContactForm" :disabled="!formIsValid">
                  Küldés
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <!-- FORM CARD END -->
        </v-row>
      </v-container>
      <!-- CONTENT END -->
    </v-container>
    <!-- LAR DIALOG START -->
    <loading-and-response-dialog :element-props.sync="loadingAndResponseDialog"
                                 v-on:on-error-click="onErrorClickInLARDialog"
                                 v-on:on-success-click="onSuccussCickInLARDialog">
    </loading-and-response-dialog>
    <!-- LAR DIALOG END -->
    <!-- EDITOR PANEL START -->
    <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
      <v-card min-height="690">
        <v-toolbar color="primary" height="64" max-height="64" dark flat tile>
          <v-btn icon>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            ContactForm1 - Szerkesztés
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorPanel = !editorPanel">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="mx-0 mb-3">
            <v-col cols="6" class="text-subtitle-1 text--primary">
              Szövegek szerkesztése
            </v-col>
            <v-col cols="6">
              <v-row class="mx-0" justify="end">
                <v-btn color="primary" @click="addNewTranslation('OFF')">
                  {{ $t('button.addNewTranslation') }}
                </v-btn>
              </v-row>
            </v-col>
            <!-- FORDÍTÁS START -->
            <v-col cols="12">
              <v-tabs v-model="translationTab"
                      background-color="teal darken-1"
                      dark>
                <v-tab v-for="(item, index) in elementProps.translations"
                       :key="`lt-${index}`">
                  {{ item.name }}
                </v-tab>
                <v-tabs-items v-model="translationTab">
                  <v-tab-item v-for="(item, index) in elementProps.translations"
                              :key="`lc-${index}`">
                    <v-card outlined tile>
                      <v-card-text>
                        <v-col cols="12">
                          <div class="text-h5 mb-6" v-if="visibleElements.includes('title')">
                            {{ item.title }}
                          </div>
                          <div v-html="item.text"></div>
                        </v-col>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="pa-4">
                        <v-spacer></v-spacer>
                        <v-btn v-if="elementProps.translations.length > 1"
                               color="error"
                               text
                               class="mr-4"
                               @click="deleteTranslation(index, 'OFF')">
                          {{ $t('button.delete') }}
                        </v-btn>
                        <v-btn color="primary"
                               @click="editTranslation(item, index)">
                          {{ $t('button.edit') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-col>
            <!-- FORDÍTÁS END -->
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="elementProps.contact.home.text"
                            label="Cím"
                            filled
                            clearable
                            hide-details
                            dense>
                <template v-slot:append-outer>
                  <v-switch v-model="elementProps.contact.home.show"
                            class="ml-5 mt-0"
                            inset>
                  </v-switch>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="elementProps.contact.phone.text"
                            label="Telefonszám"
                            filled
                            clearable
                            hide-details
                            dense>
                <template v-slot:append-outer>
                  <v-switch v-model="elementProps.contact.phone.show"
                            class="ml-5 mt-0"
                            inset>
                  </v-switch>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="elementProps.contact.email.text"
                            label="E-mail cím"
                            filled
                            clearable
                            hide-details
                            dense>
                <template v-slot:append-outer>
                  <v-switch v-model="elementProps.contact.email.show"
                            class="ml-5 mt-0"
                            inset>
                  </v-switch>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="mx-0">
            <v-col cols="12" class="pb-0">
              <v-row justify="space-between" align="center" class="mx-0">
                <span class="text-subtitle-1 text--primary">
                  A befoglaló container 100% szélességű legyen?
                </span>
                <v-switch v-model="elementProps.isContainerFluid"
                          inset>
                </v-switch>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="saveElementProps">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDITOR PANEL END -->
    <!-- FORDÍTÁS DIALOG START -->
    <translation-editor-dialog :content="translationDialog"
                               :visible-elements="visibleElements"
                               v-on:@cancel="cancelNewTranslation"
                               v-on:@save="saveNewTranslation" />
    <!-- FORDÍTÁS DIALOG START -->
  </div>
</template>

<script>
import LoadingAndResponseDialog from '@/components/shared/LoadingAndResponseDialog.vue';
import TranslationEditorDialog from 'src/components/shared/TranslationEditorDialog.vue';

export default {
  name: 'ContactForm1',
  components: {
    LoadingAndResponseDialog, TranslationEditorDialog,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          isContainerFluid: true,
          translations: [
            {
              code: 'hu',
              name: 'Magyar',
              title: 'Lorem ipsum dolor sit amet',
              text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
            },
          ],
          contact: {
            home: {
              text: '1234 Kiskunfélegyháza, Majoros utca 89/c',
              icon: 'mdi-domain',
              show: true,
            },
            phone: {
              text: '+36-20/123-4567',
              icon: 'mdi-phone',
              show: true,
            },
            email: {
              text: 'info@valami.io',
              icon: 'mdi-email',
              show: true,
            },
          },
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    this.selectFieldOptions.contactTypes = await this.getContactTypes();
  },
  mounted() {
    this.changeTranslations();
  },
  data() {
    return {
      editorPanel: false,
      contactForm: {
        visitor: {
          firstName: '',
          lastName: '',
          middleName: '',
          companyName: '',
          email: '',
          phone: '',
          isGdpr: 1,
        },
        contactType: null,
        message: '',
        uniqueIdentifier: '',
      },
      formIsValid: false,
      checkboxGDPR: false,
      checkboxASZF: false,
      phoneMask: {
        prefix: '',
        mask: '##-###-####',
      },
      selectFieldOptions: {
        contactTypes: [],
      },
      phoneLocal: '',
      loadingAndResponseDialog: {
        isVisible: false,
        stepper: 1,
        loadingText: 'LOADING_MESSAGES.PROCESS_IN_PROGRESS',
        successText: 'SUCCESS_MESSAGES.CONTACT_FORM_SEND',
        errorText: 'ERROR_MESSAGES.CONTACT_FORM_SEND',
        successBtnText: 'button.ok',
        errorBtnText: 'button.ok',
      },
      textEditorContent: this.elementProps.text,
      translationTab: 0,
      translatedText: '',
      translatedTitle: '',
      visibleElements: ['text1', 'title'],
    };
  },
  methods: {
    editElement() {
      this.textEditorContent = this.elementProps.text;
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    saveElement() {
      this.$emit('save-element');
    },
    saveElementProps() {
      this.translationTab = 0;
      this.changeTranslations();
      this.editorPanel = false;
    },
    async getContactTypes() {
      const response = await this.$store.dispatch('getContactFormContactTypes');
      if (response.status === 200) {
        return response.data;
      }
      return this.$store.state.general.simpleContactFormSubjects;
    },
    async sendContactForm() {
      this.openLARDialog();
      const response = await this.$store.dispatch('sendContactForm', this.contactForm);
      if (response.status === 200) {
        setTimeout(this.changeLARDialogContentToSuccess, 1100);
      } else {
        setTimeout(this.changeLARDialogContentToError, 1100);
      }
    },
    openLARDialog() {
      this.loadingAndResponseDialog.stepper = 1;
      this.loadingAndResponseDialog.isVisible = true;
    },
    changeLARDialogContentToSuccess() {
      this.loadingAndResponseDialog.stepper = 2;
    },
    changeLARDialogContentToError() {
      this.loadingAndResponseDialog.stepper = 3;
    },
    onSuccussCickInLARDialog() {
      this.$refs.iContactForm.reset();
      this.loadingAndResponseDialog.isVisible = false;
      /* this.contactForm = {
        visitor: {
          firstName: '',
          lastName: '',
          middleName: '',
          companyName: '',
          email: '',
          phone: '',
          isGdpr: 1,
        },
        contactType: null,
        message: '',
        uniqueIdentifier: '',
      };
      this.formIsValid = false;
      this.checkboxGDPR = false;
      this.checkboxASZF = false; */
    },
    onErrorClickInLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
    },
    changeTranslations() {
      const index = this.elementProps.translations.findIndex((item) => item.code === this.$i18n.locale.substr(0, 2));
      const currentLanguageIndex = index === -1 ? 0 : index;
      this.translatedTitle = this.elementProps.translations[currentLanguageIndex].title;
      this.translatedText = this.elementProps.translations[currentLanguageIndex].text;
    },
  },
  watch: {
    phoneMask() {
      // console.log('phonemask');
      this.contactForm.phone = null;
      this.phoneLocal = null;
    },
    phoneLocal(newVal) {
      this.contactForm.phone = `+36-${this.phoneMask.label}/${newVal}`;
    },
  },
};
</script>

<style scoped>

</style>
