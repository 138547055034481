import Vue from 'vue';
import VueI18n from 'vue-i18n';
import locales from './locales';

Vue.use(VueI18n);

if (!localStorage.getItem('language')) {
  localStorage.setItem('language', navigator.language);
}

export default new VueI18n({
  locale: localStorage.getItem('language'),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: locales,
});
