<template>
  <v-main>
    <router-view></router-view>
  </v-main>
</template>

<script>
export default {
  name: 'NoFooterLayout',
  components: {
    //
  },
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
