/* eslint-disable no-shadow */
import router from '@/router/router';
import store from '@/store/index';

// initial state
const state = {
  navigation: [
    {
      text: 'navigation.dashboard',
      icon: 'mdi-home',
      children: [],
      click: () => {
        router.push({ name: 'admin-iranyitopult' });
      },
      roles: [],
    },
    {
      text: 'navigation.pages',
      icon: 'mdi-file-multiple',
      children: [
        {
          text: 'navigation.allPages',
          icon: 'mdi-view-list',
          children: [],
          click: () => {
            router.push({ name: 'pageBuilder-home' });
          },
          roles: [],
        },
        {
          text: 'button.addNew',
          icon: 'mdi-card-plus-outline',
          children: [],
          click: () => {
            router.push({ name: 'pageBuilder-editor', params: { pageId: 'uj-oldal' } });
          },
          roles: [],
        },
        {
          text: 'navigation.categories',
          icon: 'mdi-label-multiple',
          children: [],
          click: () => {
            router.push({ name: 'pageBuilder-categories' });
          },
          roles: [],
        },
      ],
      roles: [],
    },
    {
      text: 'navigation.projects',
      icon: 'mdi-apps',
      children: [],
      click: () => {
        router.push({ name: 'admin-projects' });
      },
      roles: [],
    },
    {
      text: 'navigation.formBuilder',
      icon: 'mdi-form-select',
      children: [],
      click: () => {
        router.push({ name: 'formBuilder-home' });
      },
      roles: [],
    },
    {
      text: 'navigation.footerBuilder',
      icon: 'mdi-page-layout-footer',
      children: [],
      click: () => {
        router.push({ name: 'footerBuilder-home' });
      },
      roles: [],
    },
    {
      text: 'navigation.mediaGallery',
      icon: 'mdi-folder-image',
      children: [],
      click: () => {
        router.push({ name: 'admin-media' });
      },
      roles: [],
    },
    {
      text: 'navigation.career',
      icon: 'mdi-briefcase',
      children: [
        {
          text: 'navigation.divisions',
          icon: 'mdi-office-building',
          children: [],
          click: () => {
            router.push({ name: 'admin-career-divisions' });
          },
          roles: [],
        },
        {
          text: 'navigation.jobs',
          icon: 'mdi-account-tie',
          children: [],
          click: () => {
            router.push({ name: 'admin-career-jobs' });
          },
          roles: [],
        },
      ],
      roles: [],
    },
    {
      text: 'navigation.menuBiulder',
      icon: 'mdi-menu',
      children: [
        {
          text: 'navigation.allMenus',
          icon: 'mdi-view-list',
          children: [],
          click: () => {
            router.push({ name: 'menuBuilder-home' });
          },
          roles: [],
        },
        {
          text: 'button.addNew',
          icon: 'mdi-card-plus-outline',
          children: [],
          click: () => {
            router.push({ name: 'menuBuilder-editor', params: { menuId: 'uj-menu' } });
          },
          roles: [],
        },
      ],
      roles: [],
    },
    {
      text: 'navigation.masterData',
      icon: 'mdi-folder-information',
      children: [
        {
          text: 'navigation.pageBasicElements',
          icon: 'mdi-file-code',
          children: [],
          click: () => {
            router.push({ name: 'masterData-pageBasic' });
          },
          roles: [],
        },
        {
          text: 'navigation.formBasicElements',
          icon: 'mdi-form-textbox',
          children: [],
          click: () => {
            router.push({ name: 'masterData-formBasic' });
          },
          roles: [],
        },
        {
          text: 'navigation.apiServices',
          icon: 'mdi-api',
          children: [],
          click: () => {
            router.push({ name: 'masterData-api' });
          },
          roles: [],
        },
      ],
      roles: [],
    },
    {
      text: 'navigation.logout',
      icon: 'mdi-logout',
      children: [],
      click: async () => {
        await store.dispatch('Logout');
      },
      roles: [],
    },
  ],
};

// getters
const getters = {
  //
};

// actions
const actions = {
  //
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
