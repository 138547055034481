<template>
  <div v-frag>
    <app-bar-simple :menu="layoutProps"></app-bar-simple>
    <v-main>
      <router-view></router-view>
    <!--<component :is="`leftSidebar-layout`" :layout-props="pageProps">
        <router-view></router-view>
      </component>-->
    </v-main>
    <GlobalFooter :element-props="footerProps"></GlobalFooter>
  </div>
</template>

<script>
import AppBarSimple from 'src/components/appBars/AppBarSimple.vue';
import AppBarVersion2 from 'src/components/appBars/AppBarVersion2.vue';
import cmsFooter from 'src/components/shared/cmsFooter.vue';
import GlobalFooter from 'src/components/global/GlobalFooter.vue';

export default {
  name: 'OuterLayout',
  components: {
    cmsFooter, AppBarSimple, GlobalFooter, AppBarVersion2,
  },
  props: {
    layoutProps: {
      type: String,
      default: 'NOTHING',
    },
    footerProps: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  async beforeMount() {
    //
  },
  data() {
    return {
      /*footerProps: {
        backgroundColor: '#182C61',
        columns: [
          {
            width: 4,
            content: [
              {
                elementName: 'simple-image-1',
                elementProperties: {
                  // image: '/page/pic/2021/02/26/pic_6038eee244fc7_city.jpg',
                  image: 'images/dummy/city.jpg',
                  title: 'City',
                  aspectRatio: null,
                  contain: true,
                  justify: 'start',
                  align: 'center',
                  width: '100%',
                  height: '100%',
                  maxWidth: '100%',
                  maxHeight: '100%',
                },
              },
            ],
          },
          {
            width: 4,
            content: [
              {
                elementName: 'simple-list-1',
                elementProperties: {
                  title: {
                    text: 'Információ',
                    color: '#FFFFFF',
                    marginBottom: 'mb-3',
                    fontSize: 'text-h6',
                  },
                  color: '#FFFFFF',
                  textStyle: 'body-1',
                  underline: 'not-underlined',
                  underlineOnHover: 'cmsfooterlink',
                  // underline: '',
                  // underlineOnHover: '',
                  paddingLeft: '3',
                  list: [
                    {
                      text: 'Karbantartás',
                      link: '#',
                      title: 'Semmi',
                      target: '_self',
                    },
                    {
                      text: 'Szerviztér',
                      link: 'https://www.google.com',
                      title: 'Valami',
                      target: '_blank',
                    },
                  ],
                },
              },
            ],
          },
          {
            width: 4,
          },
          {
            width: 4,
          },
        ],
      },*/
      pageProps: {
        isFluid: true,
      },
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
