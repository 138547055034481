<template>
  <div v-frag>
    <v-container>
      <v-form v-model="addressForm">
        <v-row>
          <template v-if="addressType === 'BILLING'">
            <v-col cols="12" class="pt-0">
              <v-radio-group v-model="address.isCompany"
                             row mandatory class="mt-0 mx-n3" hide-details>
                <v-row>
                  <v-col cols="12" sm="6">
                    <div :class="address.isCompany === 0 ? 'bra' : 'bri'"
                         class="pa-3">
                      <v-radio :value="0">
                        <template slot="label">
                          <span class="text-uppercase br-label">{{ $t('labels.privatePerson') }}</span>
                        </template>
                      </v-radio>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <div :class="address.isCompany === 1 ? 'bra' : 'bri'"
                         class="pa-3">
                      <v-radio :value="1">
                        <template slot="label">
                          <span class="text-uppercase br-label">{{ $t('labels.company') }}</span>
                        </template>
                      </v-radio>
                    </div>
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="address.billingName"
                            :label="$t('labels.billingName')"
                            :rules="[$store.state.inputRules.requiredField]"
                            :hint="$t('hints.requiredField')"
                            persistent-hint
                            filled>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="address.taxNumber"
                            :label="$t('labels.vatNumber')"
                            :rules="address.isCompany === 1 ? $store.state.inputRules.vatRules : []"
                            :hint="address.isCompany === 1 ? $t('hints.requiredField') : $t('hints.optionalField')"
                            v-mask="taxNumberMaskHU"
                            persistent-hint
                            filled>
              </v-text-field>
            </v-col>
          </template>
          <v-col cols="12" sm="6">
            <v-autocomplete v-model="address.postalCode"
                            :items="selectFieldOptions.postalCodes"
                            item-text="address.postalCode"
                            item-value="address.postalCode"
                            :search-input.sync="postalCodeSearch"
                            :label="$t('labels.postalCode')"
                            :rules="[$store.state.inputRules.numberValidator]"
                            :hint="$t('hints.requiredField')"
                            :no-data-text="$t('hints.noDataText')"
                            persistent-hint
                            filled
                            maxlength="4"
                            required
                            @change="onPostalCodeSelect"
                            @click:clear="clearAllAddressInputs"
                            clearable>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete v-model="address.city"
                            :items="selectFieldOptions.cities"
                            item-value="address.city"
                            item-text="address.city"
                            :search-input.sync="citySearch"
                            :label="$t('labels.city')"
                            :rules="[$store.state.inputRules.requiredField]"
                            :hint="$t('hints.requiredField')"
                            :no-data-text="$t('hints.noDataText')"
                            persistent-hint
                            filled
                            required
                            clearable
                            @change="getPostalCodeByCity"
                            @click:clear="clearAllAddressInputs">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="8">
            <v-combobox v-model="streetTemp"
                        :items="selectFieldOptions.streets"
                        item-value="address.street"
                        item-text="address.street"
                        :search-input.sync="streetSearch"
                        :label="$t('labels.publicPlaceName')"
                        :rules="[$store.state.inputRules.requiredField]"
                        :hint="$t('hints.requiredField')"
                        :no-data-text="$t('hints.noDataText')"
                        persistent-hint
                        filled
                        required
                        @change="onPublicPlaceSelect"
                        clearable>
            </v-combobox>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field v-model="address.streetNumber"
                          :label="$t('labels.streetNumber')"
                          :rules="[$store.state.inputRules.requiredField]"
                          :hint="$t('hints.requiredField')"
                          :no-data-text="$t('hints.noDataText')"
                          counter="30"
                          max-length="30"
                          persistent-hint
                          filled
                          required
                          clearable>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="address.other"
                        :label="addressType === 'SHIPPING' ? $t('labels.otherDescriptionToCourier') : $t('labels.otherDescription')"
                        :hint="$t('hints.optionalField')"
                        persistent-hint
                        clearable
                        filled
                        clear-icon="mdi-close"
                        maxlength="50"
                        counter="50">
            </v-textarea>
          </v-col>
          <v-col cols="12">
            <v-row justify="end">
              <v-btn text
                     class="mr-3"
                     @click="onCancel">
                {{ $t('button.cancel') }}
              </v-btn>
              <v-btn color="primary"
                     @click="onSave">
                {{ $t('button.save') }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-dialog v-model="isCompanyDialog" max-width="320">
      <v-card>
        <v-card-text class="pa-5">
          <div class="text-center my-8">
            <v-icon size="100"
                    color="primary"
                    class="pulse">
              mdi-bell-ring
            </v-icon>
          </div>
          <div class="text-center text-h6 mb-2">
            {{ $t('alert') }}
          </div>
          <div class="text-center">
            {{ $t('isCompanyWarrantyAlert') }}
          </div>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-btn color="primary"
                 block
                 text
                 @click="isCompanyDialog = false">
            {{ $t('button.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  HEREGETPOSTALCODESBYCOUNTRY,
  HEREGETCITYBYPOSTALCODE,
  HEREGETSTREETBYPOSTALCODE,
  HEREGETPOSTALCODESBYCITY,
} from '@/utils/hereAPI';

export default {
  name: 'AddressPickerForm',
  components: {
    //
  },
  props: {
    address: {
      type: Object,
      default() {
        return {
          id: null,
          isCompany: 0,
          country: {
            type: Object,
            default() {
              return {
                country: { label: 'Magyarország', countryCode: 'HUN' },
              }
            }
          },
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
        }
      }
    },
    addressType: {
      type: String,
      default: 'SHIIPING',
    },
  },
  data() {
    return {
      addressForm: false,
      taxNumberMaskHU: '########-#-##',
      postalCodeSearch: '',
      isCompanyDialog: false,
      citySearch: '',
      streetSearch: '',
      streetTemp: '',
      selectFieldOptions: {
        countries: [
          { label: 'Magyarország', countryCode: 'HUN' },
        ],
        postalCodes: [
          { header: this.$t('hints.startTypingPostalCode') },
        ],
        cities: [
          { header: this.$t('hints.startTypingCity') },
        ],
        streets: [
          { header: this.$t('hints.startTypingStreet') },
        ],
      },
    };
  },
  methods: {
    async getPostalCodesByCountry(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.address.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.postalCodes = response.data;
        this.selectFieldOptions.postalCodes.unshift({ header: this.$t('hints.startTypingPostalCode') });
      }
    },
    async getCityByPostalCode() {
      let postalcode = 0;
      if (this.address.postalCode && this.address.postalCode.address) {
        postalcode = this.address.postalCode.address.postalCode;
        this.address.postalCode = postalcode;
      }
      const response = await HEREGETCITYBYPOSTALCODE(this.address.postalCode, this.address.country.countryCode);
      if (!response.error) {
        if (!response.data[0].address.city) {
          this.selectFieldOptions.cities = [];
        } else {
          this.selectFieldOptions.cities = response.data;
          this.selectFieldOptions.cities.unshift({header: this.$t('hints.startTypingCity')});
        }
      }
    },
    async getPostalCodeByCity(value) {
      if (this.address.postalCode === null) {
        this.address.postalCode = ''
      }
      const response = await HEREGETPOSTALCODESBYCITY( value, this.address.postalCode, this.address.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.cities = response.data;
        this.selectFieldOptions.cities.unshift({ header: this.$t('hints.startTypingCity') });
      }
    },
    async getStreetByPostalCode(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value, this.address.postalCode, this.address.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.streets = response.data;
        this.selectFieldOptions.streets.unshift({ header: this.$t('hints.startTypingStreet') });
      }
    },
    async onPostalCodeSelect() {
      await this.getCityByPostalCode();
      if (this.selectFieldOptions.cities.length === 2) {
          this.address.city = this.selectFieldOptions.cities[1].address.city;
          this.citySearch = this.selectFieldOptions.cities[1].address.city;
      }
      this.address.publicPlaceName = '';
      this.address.publicPlaceType = '';
      this.address.streetNumber = '';
      this.streetIdeiglenes = '';
      this.streetSearch = null;
    },
    onPublicPlaceSelect() {
      let street = '';
      let type = '';
      if (this.streetTemp && this.streetTemp.length > 0) {
        street = this.streetTemp.substring(0, this.streetTemp.lastIndexOf(' '));
        type = this.streetTemp.substring(this.streetTemp.lastIndexOf(' '), this.streetTemp.length + 1).trim();
      } else if (this.streetTemp && this.streetTemp.address) {
        street = this.streetTemp.address.street.substring(0, this.streetTemp.address.street.lastIndexOf(' '));
        type = this.streetTemp.address.street.substring(this.streetTemp.address.street.lastIndexOf(' '), this.streetTemp.address.street.length + 1).trim();
      }
      this.address.publicPlaceName = street;
      this.address.publicPlaceType = type;
    },
    clearAllAddressInputs() {
      //
    },
    onCompanySelect(value) {
      this.$emit('on-company-select', value);
    },
    onSave() {
      this.address.label = `${this.address.postalCode} ${this.address.city}, ${this.address.publicPlaceName} ${this.address.publicPlaceType} ${this.address.streetNumber}`;
      this.$emit('on-save-click');
    },
    onCancel() {
      this.$emit('on-cancel-click');
    }
  },
  watch: {
    postalCodeSearch(value) {
      this.getPostalCodesByCountry(value);
    },
    citySearch(value) {
      this.getPostalCodeByCity(value);
    },
    streetSearch(value) {
      this.getStreetByPostalCode(value);
    },
    'address.isCompany': function (value) {
      this.onCompanySelect(value);
      // this.isCompanyDialog = value === 1;
    },
  },
};
</script>

<style scoped>

</style>
