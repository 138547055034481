import axios from 'axios';
import { getCrmToken, getToken, getWebProject } from '@/utils/jwtHelper';
import { BASE_URL, CRM_URL } from '@/config/config';
import i18n from '@/i18n';

const API_URL = BASE_URL;
// console.log('API_URL: ', API_URL);
// console.log('CRM_URL: ', CRM_URL);
// export let PROGRESS = 0;

const INSTANCE = axios.create({
  baseURL: API_URL,
  timeout: 200000,
  // onUploadProgress: progressEvent22,
  /* onUploadProgress: (progressEvent) => {
    PROGRESS = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100), 10);
    // console.log('deal: ', deal);
  }, */
});

const CRM_INSTANCE = axios.create({
  baseURL: CRM_URL,
  timeout: 60000,
});

/* function progressEvent22(progressEvent) {
  console.log('.');
  PROGRESS = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100), 10);
  console.log('progress: ', PROGRESS);
} */

/**
 *
 * @param status
 * @return {Promise<boolean>}
 */
async function htmlStatusRoute(status) {
  return status >= 200;
}

async function setHTMLHeaders(needAuth = false) {
  const token = await getToken();
  const webproject = await getWebProject();
  // console.log('webproject: ', webproject);
  const LANG_CODE = i18n.locale.substring(0, 2);
  if (webproject) {
    if (token && needAuth) {
      return { Token: `${token}`, Language: `${LANG_CODE}`, WebProjectHash: `${webproject}` };
    }
    return { Language: `${LANG_CODE}`, WebProjectHash: `${webproject}` };
  }
  return { Language: `${LANG_CODE}` };
}

async function setCrmHeaders(needAuth = false) {
  const token = await getCrmToken('token');
  const langCode = i18n.locale.substring(0, 2);
  if (token && needAuth) {
    return { 'Token': `${token}`, 'Language': `${langCode}` };
  }
  return { 'Language': `${langCode}` };
}

/**
 *
 * APIGET
 *
 * @param url
 * @param params
 * @param needAuth
 * @returns {Promise<{data: {data: [], message: string, status: number}}|AxiosResponse<any>>}
 * @constructor
 */
export async function APIGET(url, params = {}, needAuth = false) {
  try {
    const htmlResponse = await INSTANCE({
      method: 'get',
      url: `${API_URL}/${url}`,
      headers: await setHTMLHeaders(needAuth),
      params,
      validateStatus: htmlStatusRoute,
    });
    // console.log(`%cAPIGET - ${url} htmlResponse:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    // console.log(htmlResponse);
    return htmlResponse;
  } catch (error) {
    // console.log(`%cAPIGET - ${url} CATCH error:`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    console.log(error);
    return {
      data: {
        status: 999,
        data: [],
        message: `CATCH error: ${error}`,
      },
    };
  }
}

export async function APIPOST(url, body = {}, needAuth = false) {
  try {
    const htmlResponse = await INSTANCE({
      method: 'post',
      url: `${API_URL}/${url}`,
      headers: await setHTMLHeaders(needAuth),
      data: body,
      /* onUploadProgress: function (progressEvent) {
        const deal = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100), 10);
        console.log('deal: ', deal);
      }, */
    });
    // console.log(`%cAPIPOST - ${url} htmlResponse:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    // console.log(htmlResponse);
    return htmlResponse;
  } catch (error) {
    // console.log(`%cAPIPOST - ${url} CATCH error:`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    console.log(error);
    return {
      data: {
        status: 999,
        data: [],
        message: `CATCH error: ${error}`,
      },
    };
  }
}

export async function APIPATCH(url, body = {}, needAuth = false) {
  try {
    const htmlResponse = await INSTANCE({
      method: 'patch',
      url: `${API_URL}/${url}`,
      headers: await setHTMLHeaders(needAuth),
      data: body,
    });
    // console.log(`%cAPIPATCH - ${url} htmlResponse:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    // console.log(htmlResponse);
    return htmlResponse;
  } catch (error) {
    // console.log(`%APIPATCH - ${url} CATCH error:`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    console.log(error);
    return {
      data: {
        status: 999,
        data: [],
        message: `CATCH error: ${error}`,
      },
    };
  }
}

export async function APIDELETE(url, params = {}, needAuth = false) {
  try {
    const htmlResponse = await INSTANCE({
      method: 'delete',
      url: `${API_URL}/${url}`,
      headers: await setHTMLHeaders(needAuth),
      params,
      validateStatus: htmlStatusRoute,
    });
    // console.log(`APIDELETE - ${url} htmlResponse: `, htmlResponse);
    return htmlResponse;
  } catch (error) {
    return {
      data: {
        status: 999,
        data: [],
        message: `CATCH error: ${error}`,
      },
    };
  }
}

export async function APIPUT(url, body = {}, needAuth = false) {
  try {
    const htmlResponse = await INSTANCE({
      method: 'put',
      url: `${API_URL}/${url}`,
      headers: await setHTMLHeaders(needAuth),
      data: body,
    });
    // console.log(`%cAPIPUT - ${url} htmlResponse:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    // console.log(htmlResponse);
    return htmlResponse;
  } catch (error) {
    // console.log(`%cAPIPUT - ${url} CATCH error:`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    console.log(error);
    return {
      data: {
        status: 999,
        data: [],
        message: `CATCH error: ${error}`,
      },
    };
  }
}

export async function APIFILEDOWNLOAD(url, body = {}) {
  // console.log('api start');
  const token = await getToken();
  try {
    const htmlResponse = await INSTANCE({
      method: 'get',
      url: `${API_URL}/${url}`,
      headers: { 'Content-Type': 'multipart/form-data', Token: `${token}` },
      responseType: 'blob',
      data: body,
    });
    // console.log(`APIFILEDOWNLOAD - ${url} htmlResponse: `, htmlResponse);
    return htmlResponse;
  } catch (error) {
    return {
      data: {
        status: 999,
        data: [],
        message: `CATCH error: ${error}`,
      },
    };
  }
}

/**
 *
 * APIGETCRM
 *
 * @param url
 * @param params
 * @param needAuth
 * @returns {Promise<{data: {data: [], message: string, status: number}}>}
 * @constructor
 */
export async function APIGETCRM(url, params = {}, needAuth = false) {
  const LANG_CODE = i18n.locale.substring(0, 2);
  try {
    const htmlResponse = await CRM_INSTANCE({
      method: 'get',
      url: `${CRM_URL}/${url}`,
      headers: await setCrmHeaders(needAuth),
      params,
      validateStatus: htmlStatusRoute,
    });
    // console.log(`%cAPIGETCRM - ${url} htmlResponse:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    // console.log(htmlResponse);
    return htmlResponse;
  } catch (error) {
    // console.log(`%cAPIGETCRM - ${url} CATCH error:`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    console.log(error);
    return {
      data: {
        status: 999,
        data: [],
        message: `CATCH error: ${error}`,
      },
    };
  }
}

/**
 *
 * APIPOST CRM
 *
 * @param url
 * @param body
 * @param needAuth
 * @returns {Promise<{data: {data: *[], message: string, status: number}}>}
 * @constructor
 */
export async function APIPOSTCRM(url, body = {}, needAuth = false) {
  const LANG_CODE = i18n.locale.substring(0, 2);
  try {
    const htmlResponse = await CRM_INSTANCE({
      method: 'post',
      url: `${CRM_URL}/${url}`,
      headers: await setCrmHeaders(needAuth),
      data: body,
    });
    // console.log(`%cAPIPOSTCRM - ${url} htmlResponse:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    // console.log(htmlResponse);
    return htmlResponse;
  } catch (error) {
    // console.log(`%cAPIPOSTCRM - ${url} CATCH error:`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    console.log(error);
    return {
      data: {
        status: 999,
        data: [],
        message: `CATCH error: ${error}`,
      },
    };
  }
}

export async function APIFILEDOWNLOADCRM(url, body = {}) {
  try {
    const htmlResponse = await CRM_INSTANCE({
      method: 'post',
      url: `${CRM_URL}/${url}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      responseType: 'blob',
      data: body,
      validateStatus: function (status) {
        return status >= 200;
      },
    });
    // console.log(`%cAPIFILEDOWNLOADCRM - ${url} htmlResponse:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    // console.log(htmlResponse);
    return htmlResponse;
  } catch (error) {
    // console.log(`%cAPIFILEDOWNLOADCRM - ${url} CATCH error:`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    console.log(error);
    return {
      data: {
        status: 999,
        data: [],
        message: `CATCH error: ${error}`,
      },
    };
  }
}

export async function APIPATCHCRM(url, body = {}, needAuth = false) {
  try {
    const htmlResponse = await CRM_INSTANCE({
      method: 'patch',
      url: `${CRM_URL}/${url}`,
      headers: await setCrmHeaders(needAuth),
      data: body,
      validateStatus: function (status) {
        return status >= 200;
      },
    });
    // console.log(`%cAPIPATCHCRM - ${url} htmlResponse:`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
    // console.log(htmlResponse);
    return htmlResponse;
  } catch (error) {
    // console.log(`%cAPIPATCHCRM - ${url} CATCH error:`, 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
    console.log(error);
    return {
      data: {
        status: 999,
        data: [],
        message: `CATCH error: ${error}`,
      },
    };
  }
}
