<template>
  <v-avatar tile :size="size" @click="toggleSnackbar">
    <v-icon color="white" :size="iconSize" class="cursor-pionter">
      mdi-bell
    </v-icon>
    <div v-show="showBadge"
         class="tbadge white--text cursor-pionter"
         @click="toggleSnackbar">
      <span style="margin-left: -2px;"><b>1</b></span>
    </div>
    <SystemMessageSnackbar :is-visible.sync="showSnackbar"
                           :title="$store.getters.getAppSystemInfoContent.title"
                           :description="$store.getters.getAppSystemInfoContent.description" />
  </v-avatar>
</template>

<script>

import SystemMessageSnackbar from '@/components/shared/SystemMessageSnackbar.vue';

export default {
  name: 'Notifications',
  components: {
    SystemMessageSnackbar,
  },
  props: {
    size: {
      type: Number,
      default: 64,
    },
    iconSize: {
      type: Number,
      default: 26,
    },
  },
  mounted() {
    if (this.$store.state.shared.appSystemInfo) {
      this.showBadge = true;
      this.showSnackbar = true;
    }
  },
  data() {
    return {
      showSnackbar: false,
      showBadge: false,
    };
  },
  methods: {
    toggleSnackbar() {
      this.showSnackbar = !this.showSnackbar;
      this.showBadge = false;
    },
  },
  watch: {
    '$store.state.shared.appSystemInfo': function (value) {
      if (value && value.status === this.$HTTP_OK) {
        this.showBadge = true;
        this.showSnackbar = true;
      }
    },
  },
};
</script>

<style scoped>

</style>
