<template>
  <div>
    <v-container :class="editorMode ? 'b-wrapper': ''" :fluid="elementProps.isContainerFluid">
      <!-- PAGE BUILDER ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            Split1
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- PAGE BUILDER ACTIONBOX END -->
      <!-- CONTENT START -->
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-card class="fill-height">
              <v-card-title>
                {{ translatedTitle1 }}
              </v-card-title>
              <v-divider/>
              <v-card-text>
                <v-form ref="repiarForm">
                  <v-autocomplete v-model="selectedDevice"
                                  :label="$t('labels.productType')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('hints.requiredField')"
                                  :no-data-text="deviceList.status !== 200 ? deviceList.message : $t('noDataText.general')"
                                  persistent-hint
                                  :items="deviceList.data"
                                  item-text="fonix_tipus_gyartoi_nev"
                                  item-value="fonix_tipus_id"
                                  return-object
                                  :search-input.sync="deviceSearchText"
                                  :loading="isDeviceDataLoading"
                                  :disabled="isDataLoading"
                                  outlined
                                  class="mb-3"
                                  @change="initRepairGroupList">
                  </v-autocomplete>
                  <v-autocomplete v-model="selectedRepairGroup"
                                  :label="$t('labels.repairGroup')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('hints.requiredField')"
                                  :no-data-text="repairGroupsList.status !== 200 ? repairGroupsList.message : $t('noDataText.general')"
                                  persistent-hint
                                  :items="repairGroupsList.data"
                                  item-text="repair_group_type_name"
                                  item-value="repair_group_type_id"
                                  return-object
                                  :search-input.sync="repairGroupSearchText"
                                  :loading="isRepairGroupDataLoading"
                                  :disabled="isDataLoading"
                                  outlined
                                  @change="initRepiarGroupPrice">
                  </v-autocomplete>
                  <div class="text-right">
                    <v-btn v-if="(selectedDevice && selectedRepairGroup) && !isDataLoading"
                           color="primary" @click="startNewSearch">
                      {{ $t('button.newSearch') }}
                    </v-btn>
                  </div>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="fill-height">
              <v-card-title>
                {{ translatedTitle2 }}
              </v-card-title>
              <v-divider/>
              <v-card-text>
                <template v-if="(selectedDevice && selectedRepairGroup) && !isDataLoading">
                  <div class="text-center text-caption">
                    <div v-html="translatedText3"></div>
                  </div>
                  <div class="text-h4 text-center my-6">
                    {{ priceToDisplay }}
                  </div>
                  <div class="text-center mb-6"
                       v-show="elementProps.button.isVisible">
                    <v-btn :color="elementProps.button.color"
                           @click="onMainButtonClick"
                           :dark="elementProps.button.isDark">
                      {{ translatedButtonText }}
                    </v-btn>
                  </div>
                  <div class="text-center text-caption">
                    <div v-html="translatedText4"></div>
                  </div>
                </template>
                <template v-else-if="(selectedDevice && selectedRepairGroup) && isDataLoading">
                  <v-row class="fill-height" align-content="center" justify="center">
                    <v-col cols="12">
                      <div v-html="translatedText2"></div>
                    </v-col>
                    <v-col cols="6">
                      <v-progress-linear height="6"
                                         color="primary"
                                         indeterminate>
                      </v-progress-linear>
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <div v-html="translatedText1"></div>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <!-- CONTENT END -->
    </v-container>
    <!-- EDITOR PANEL START -->
    <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
      <v-card min-height="690">
        <v-toolbar color="primary" height="64" max-height="64" dark flat tile>
          <v-btn icon>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            Árlista 1 - Szerkesztés
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorPanel = !editorPanel">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="6" class="text-subtitle-1 text--primary">
              {{ $t('editTexts') }}
            </v-col>
            <v-col cols="6">
              <v-row class="mx-0" justify="end">
                <v-btn color="primary" @click="addNewTranslation('OFF')">
                  {{ $t('button.addNewTranslation') }}
                </v-btn>
              </v-row>
            </v-col>
            <!-- FORDÍTÁS START -->
            <v-col cols="12">
              <v-tabs v-model="translationTab"
                      background-color="teal darken-1"
                      dark>
                <v-tab v-for="(item, index) in elementProps.translations"
                       :key="`lt-${index}`">
                  {{ item.name }}
                </v-tab>
                <v-tabs-items v-model="translationTab">
                  <v-tab-item v-for="(item, index) in elementProps.translations"
                              :key="`lc-${index}`">
                    <v-card outlined tile>
                      <v-card-text>
                        <v-col cols="12">
                          <div class="text-overline">
                            {{ $t('labels.title') }}:
                          </div>
                          <div class="text-h5">
                            {{ item.title }}
                          </div>
                        </v-col>
                        <v-divider></v-divider>
                        <v-col cols="12">
                          <div class="text-overline">
                            {{ $t('labels.title') }}:
                          </div>
                          <div class="text-h5">
                            {{ item.title2 }}
                          </div>
                        </v-col>
                        <v-divider></v-divider>
                        <v-col cols="12">
                          <div class="text-overline">
                            {{ $t('labels.description') }}:
                          </div>
                          <div class="text-body-1" v-html="item.text"></div>
                        </v-col>
                        <v-divider></v-divider>
                        <v-col cols="12">
                          <div class="text-overline">
                            {{ $t('labels.description') }}:
                          </div>
                          <div class="text-body-1" v-html="item.text2"></div>
                        </v-col>
                        <v-divider></v-divider>
                        <v-col cols="12">
                          <div class="text-overline">
                            {{ $t('labels.description') }}:
                          </div>
                          <div class="text-body-1" v-html="item.text3"></div>
                        </v-col>
                        <v-divider></v-divider>
                        <v-col cols="12">
                          <div class="text-overline">
                            {{ $t('labels.description') }}:
                          </div>
                          <div class="text-body-1" v-html="item.text4"></div>
                        </v-col>
                        <v-divider></v-divider>
                        <v-col cols="12">
                          <div class="text-overline">
                            {{ $t('labels.buttonText') }}:
                          </div>
                          <div>
                            {{ item.buttonText }}
                          </div>
                        </v-col>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="pa-4">
                        <v-spacer></v-spacer>
                        <v-btn v-if="elementProps.translations.length > 1"
                               color="error"
                               text
                               class="mr-4"
                               @click="deleteTranslation(index, 'OFF')">
                          {{ $t('button.delete') }}
                        </v-btn>
                        <v-btn color="primary"
                               @click="editTranslation(item, index)">
                          {{ $t('button.edit') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-divider></v-divider>
            </v-col>
            <!-- SECTION ACTION BUTTON START -->
            <v-col cols="12">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-row justify="space-between" align="center" class="mx-0">
                  <span class="text-subtitle-1 text--primary">
                    Gomb láthatósága
                  </span>
                    <v-switch v-model="elementProps.button.isVisible"
                              inset>
                    </v-switch>
                  </v-row>
                </v-col>
              </v-row>
              <v-slide-y-transition>
                <v-row v-if="elementProps.button.isVisible" class="mx-0">
                  <v-col cols="12">
                    <v-row class="mx-0">
                      <v-col>
                        Gomb háttérszíne:
                      </v-col>
                      <v-col cols="auto">
                        <v-row justify="end" class="mx-0">
                          <v-avatar height="52" width="52" tile :color="`${elementProps.button.color}`" @click="openColorPicker(2)"></v-avatar>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="8">
                    <v-select v-model="elementProps.button.isExternalLink"
                              label="Gomb hivatkozás típusa"
                              :items="selectFieldOptions.buttonIsExternalLink"
                              filled
                              dense>
                    </v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select v-model="elementProps.button.isDark"
                              label="Gomb felirat színe"
                              :items="selectFieldOptions.buttonTextColor"
                              filled
                              dense>
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="elementProps.button.url"
                                  v-if="elementProps.button.isExternalLink"
                                  label="Gomb hivatkozása"
                                  placeholder="https://www.google.com"
                                  persistent-placeholder
                                  filled
                                  dense>
                    </v-text-field>
                    <v-autocomplete v-model="elementProps.button.url"
                                    v-else
                                    label="Gomb hivatkozása"
                                    :items="$store.state.general.availablePages"
                                    item-text="name"
                                    item-value="url"
                                    filled
                                    dense>
                      <template v-slot:selection="data">
                        {{ data.item.name }} - {{ data.item.group }}
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ data.item.group }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-slide-y-transition>
            </v-col>
            <!-- SECTION ACTION BUTTON END -->
            <v-col cols="12" class="py-0">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="mx-0">
            <v-col cols="12" class="pb-0">
              <v-row justify="space-between" align="center" class="mx-0">
                <span class="text-subtitle-1 text--primary">
                  A befoglaló container 100% szélességű legyen?
                </span>
                <v-switch v-model="elementProps.isContainerFluid"
                          inset>
                </v-switch>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="saveElementProps">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDITOR PANEL END -->
    <!-- COLOR PICKER POPUP START -->
    <v-dialog v-model="popupColorPicker.isVisible" max-width="340">
      <v-card>
        <v-card-title class="indigo darken-2">
          <span class="white--text">
            {{ popupColorPicker.title }}
          </span>
        </v-card-title>
        <v-card-text class="pa-5">
          <v-color-picker v-model="popupColorPicker.pickerColor"
                          mode="hexa"
                          show-swatches>
          </v-color-picker>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="popupColorPicker.isVisible = !popupColorPicker.isVisible" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="onSaveColor">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- COLOR PICKER POPUP END -->
    <!-- FORDÍTÁS DIALOG START -->
    <translation-editor-dialog :content="translationDialog"
                               :visible-elements="visibleElements"
                               v-on:@cancel="cancelNewTranslation"
                               v-on:@save="saveNewTranslation" />
  </div>
</template>

<script>
import TranslationEditorDialog from '@/components/shared/TranslationEditorDialog.vue';

export default {
  name: 'PriceList1',
  components: {
    TranslationEditorDialog,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          button: {
            isVisible: true,
            isDark: true,
            isExternalLink: false,
            color: 'blue',
            url: '',
          },
          isContainerFluid: false,
          translations: [
            {
              code: 'hu',
              buttonText: 'Read more',
              name: 'Magyar',
              title: 'Válaszd ki a javítani kívánt készüléked',
              title2: 'A javítás ára',
              text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
              text2: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
              text3: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
              text4: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
            },
          ],
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.initDeviceList();
    this.changeTranslations();
  },
  data() {
    return {
      deviceList: {
        data: [],
        message: '',
        status: 200,
      },
      deviceSearchText: null,
      isDataLoading: false,
      isDeviceDataLoading: false,
      isRepairGroupDataLoading: false,
      price: {},
      repairGroupsList: {
        data: [],
        message: '',
        status: 200,
      },
      repairGroupSearchText: null,
      selectedDevice: null,
      selectedRepairGroup: null,
      // EDITOR DATA
      editorPanel: false,
      /**
       * type: 1 = a címsor színe
       * type: 2 = a gomb színe
       */
      popupColorPicker: {
        isVisible: false,
        pickerColor: '#000000',
        type: 1,
        title: 'Címsor színének kiválasztása',
      },
      selectFieldOptions: {
        buttonTextColor: [
          { text: 'Fehér', value: true },
          { text: 'Fekete', value: false },
        ],
        buttonIsExternalLink: [
          { text: 'Belső hivatkozás', value: false },
          { text: 'Külső hivatkozás', value: true },
        ],
        buttonUrls: this.$store.state.general.availablePages,
      },
      translationTab: 0,
      translatedButtonText: '',
      translatedTitle1: '',
      translatedTitle2: '',
      translatedText1: '',
      translatedText2: '',
      translatedText3: '',
      translatedText4: '',
      visibleElements: ['button', 'title', 'title2', 'text1', 'text2', 'text3', 'text4'],
    };
  },
  methods: {
    editElement() {
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    onMainButtonClick() {
      if (this.elementProps.button.isExternalLink) {
        window.open(`${this.elementProps.button.url}`, '_blank');
      } else {
        this.$router.push({ path: this.elementProps.button.url });
        // window.open(`${this.elementProps.button.url}`, '_self');
      }
    },
    openColorPicker(type) {
      if (type === 1) {
        this.popupColorPicker.title = 'Címsor színének kiválasztása';
        this.popupColorPicker.pickerColor = this.elementProps.titleColor;
      } else if (type === 2) {
        this.popupColorPicker.title = 'Gomb színének kiválasztása';
        this.popupColorPicker.pickerColor = this.elementProps.button.color;
      }
      this.popupColorPicker.type = type;
      this.popupColorPicker.isVisible = true;
    },
    onSaveColor() {
      if (this.popupColorPicker.type === 1) {
        this.elementProps.titleColor = this.popupColorPicker.pickerColor;
      } else if (this.popupColorPicker.type === 2) {
        this.elementProps.button.color = this.popupColorPicker.pickerColor;
      }
      this.popupColorPicker.isVisible = false;
    },
    saveElement() {
      this.$emit('save-element');
    },
    saveElementProps() {
      this.translationTab = 0;
      this.changeTranslations();
      this.editorPanel = false;
    },
    changeTranslations() {
      const index = this.elementProps.translations.findIndex((item) => item.code === this.$i18n.locale.substring(0, 2));
      const currentLanguageIndex = index === -1 ? 0 : index;
      this.translatedButtonText = this.elementProps.translations[currentLanguageIndex].buttonText;
      this.translatedTitle1 = this.elementProps.translations[currentLanguageIndex].title;
      this.translatedTitle2 = this.elementProps.translations[currentLanguageIndex].title2;
      this.translatedText1 = this.elementProps.translations[currentLanguageIndex].text;
      this.translatedText2 = this.elementProps.translations[currentLanguageIndex].text2;
      this.translatedText3 = this.elementProps.translations[currentLanguageIndex].text3;
      this.translatedText4 = this.elementProps.translations[currentLanguageIndex].text4;
    },
    async initDeviceList() {
      this.isDataLoading = true;
      this.isDeviceDataLoading = true;
      this.deviceList = await this.$store.dispatch('GetRepairLocationDevicelist', { locationId: this.$store.getters.GetLocationId });
      this.isDeviceDataLoading = false;
      this.isDataLoading = false;
    },
    async initRepairGroupList() {
      this.isDataLoading = true;
      this.isRepairGroupDataLoading = true;
      this.repairGroupsList = {
        data: [],
        message: '',
        status: 200,
      };
      this.selectedRepairGroup = null;
      this.repairGroupsList = await this.$store.dispatch('GetRepairDevicelistRepairGroups',
        {
          deviceId: this.selectedDevice.fonix_tipus_id,
          locationId: this.$store.getters.GetLocationId,
        }
      );
      this.isRepairGroupDataLoading = false;
      this.isDataLoading = false;
    },
    async initRepiarGroupPrice() {
      this.isDataLoading = true;
      this.price = await this.$store.dispatch('GetRepairDevicelistRepairGroupPrice',
        {
          deviceId: this.selectedDevice.fonix_tipus_id,
          locationId: this.$store.getters.GetLocationId,
          repairGroupId: this.selectedRepairGroup.repair_group_type_id,
        }
      );
      this.isDataLoading = false;
    },
    startNewSearch() {
      this.$refs.repiarForm.reset();
    },
  },
  computed: {
    priceToDisplay() {
      const price = Intl.NumberFormat('hu-Hu',
        {
          //style: 'currency',
          //currency: this.price.data.currency_id === 1 ? 'HUF' : 'HUF',
          maximumFractionDigits: 0
        }).format(this.price.data.price).toString().replaceAll(/\s/ig, '.');
      return `${price} ${this.price.data.currency_id === 1 ? 'Ft' : 'Ft'}`;
    },
  }
};
</script>

<style scoped>

</style>
