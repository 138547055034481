<template>
  <v-container class="py-0">
    <template v-if="$vuetify.breakpoint.lgAndUp">
      <v-toolbar elevation="0" class="transparent" :height="$APPBARHEIGHT">
        <v-img :src="`${publicPath}public_storage${logo}`"
               height="35px"
               max-width="101px"
               style="cursor: pointer;"
               @click="goHomePage"></v-img>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <template v-for="(item, index) in mainMenu">
            <template v-if="item.children.length < 1">
              <v-btn color="mainMenuItemColor"
                     text
                     plain
                     @click="onItemClick(item)"
                     :key="index">
              <span class="text-body-2 text-none">
                 {{ item.label }}
              </span>
              </v-btn>
            </template>
            <template v-if="item.children.length > 0">
              <v-menu open-on-hover
                      offset-y
                      :key="index">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="mainMenuItemColor"
                         text
                         plain
                         v-bind="attrs"
                         v-on="on"
                         :key="index">
                  <span class="text-body-2 text-none">
                     {{ item.label }}
                  </span>
                  </v-btn>
                </template>
                <v-list>
                  <template v-for="(child, cindex) in item.children">
                    <v-list-item :key="cindex" @click="onItemClick(child)">
                      <v-list-item-title class="text-body-2 text-none">
                        {{ child.label }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
            </template>
          </template>
        </v-toolbar-items>
        <v-spacer></v-spacer>
        <Notifications />
        <language-dropdown></language-dropdown>
        <v-spacer></v-spacer>
        <v-img :src="`${publicPath}public_storage${serviceLogo}`" height="35px" max-width="128px"></v-img>
      </v-toolbar>
    </template>
    <template v-else>
      <v-toolbar elevation="0" class="transparent" :height="$APPBARHEIGHT" dark>
        <v-app-bar-nav-icon @click.stop="toggleNavigationDrawer"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-img :src="`${publicPath}public_storage${logo}`"
               height="35px"
               max-width="101px"
               style="cursor: pointer;"
               @click="goHomePage"></v-img>
        <v-spacer></v-spacer>
        <v-img :src="`${publicPath}public_storage${serviceLogo}`" height="35px" max-width="128px"></v-img>
      </v-toolbar>
    </template>
  </v-container>
</template>

<script>
import LanguageDropdown from 'src/components/global/LanguageDropdown.vue';
import Notifications from '@/components/shared/Notification.vue';

export default {
  name: 'AppBarVersion2',
  components: {
    Notifications,
    LanguageDropdown,
  },
  props: {
    logo: {
      type: String,
      default: '/page/pic/2021/11/19/pic_61979c25a2584_iStuff-logo-70x202.png',
    },
    mainMenu: {
      type: Array,
    },
    serviceLogo: {
      type: String,
      default: '/page/pic/2021/11/19/pic_619796cacb36d_auth-serv-prov-70x256.png',
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    onItemClick(item) {
      this.$emit('on-menu-item-click', item);
    },
    goHomePage() {
      if (this.$route.name !== 'home') {
        this.$router.push({ name: 'home' });
      }
    },
    toggleNavigationDrawer() {
      this.$emit('on-toggle-nav-drawer');
    },
  },
};
</script>

<style scoped>

</style>
