<template>
  <div>
    <v-container :class="editorMode ? 'b-wrapper': ''">
      <!-- PAGE BUILDER ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            GYIK
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- PAGE BUILDER ACTIONBOX END -->
      <!-- CONTENT START -->
      <v-row>
        <v-expansion-panels v-model="activePanels"
                            multiple
                            hover>
          <v-expansion-panel v-for="(item, index) in elementProps.list" :key="index"
                             class="mb-4">
            <v-expansion-panel-header>
              <template v-slot:actions>
                <v-icon :color="elementProps.color">
                  $expand
                </v-icon>
              </template>
              <div class="text-h6" :style="activePanels.includes(index) ? `color: ${elementProps.color};` : ``">
                {{ item.question }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" v-html="item.answer"></v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <!-- CONTENT END -->
    </v-container>
    <!-- EDITOR PANEL START -->
    <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
      <v-card min-height="690">
        <v-toolbar color="primary" height="64" max-height="64" dark flat tile>
          <v-btn icon>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            GYIK - Szerkesztés
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorPanel = !editorPanel">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="py-4">
          <v-row class="mx-0">
            <v-col col="12" sm="6" class="text-subtitle-1 text--primary">
              Elemek szerkesztése
            </v-col>
            <v-col cols="12" sm="6">
              <v-row class="mx-0" justify="end">
                <v-btn color="primary" dark @click="addNewListElement" small>
                  Új hozzáadása
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
          <!-- KÉRDÉS LISTA START -->
          <v-row class="mb-4 mx-0">
            <v-list two-line>
              <template v-for="(item, index) in elementProps.list">
                <v-list-item :key="index">
                  <v-list-item-avatar>
                    {{ index + 1 }}.
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.question }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-html="`${item.answer.substr(0, 60).replace( /(<([^>]+)>)/ig, '')}...`"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon @click="editQuestion(index)" color="primary">
                      mdi-pencil
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-icon @click="deleteListElement(index)">
                      mdi-delete
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider v-if="index < elementProps.list.length - 1" :key="`${index}-div`"></v-divider>
              </template>
            </v-list>
          </v-row>
          <!-- KÉRDÉS LISTA END -->
          <v-divider></v-divider>
          <v-row class="mb-4 mx-0">
            <v-col class="text-subtitle-1">
              Ikon színe
            </v-col>
            <v-col cols="auto">
              <v-row justify="end" class="mx-0">
                <v-avatar height="52" width="52" tile
                          :color="`${elementProps.color}`"
                          class="pageBuilder-color-picker-activator"
                          style="border: 1px solid rgba(0,0,0,0.2);"
                          @click="openColorPicker"></v-avatar>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="editorPanel = !editorPanel">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDITOR PANEL END -->
    <!-- COLOR PICKER POPUP START -->
    <v-dialog v-model="popupColorPicker.isVisible" max-width="340">
      <v-card>
        <v-card-title class="indigo darken-2">
          <span class="white--text">
            Ikon színének kiválasztása
          </span>
        </v-card-title>
        <v-card-text class="pa-5">
          <v-color-picker v-model="popupColorPicker.pickerColor"
                          mode="hexa"
                          disabled
                          hide-canvas
                          hide-inputs
                          show-swatches>
          </v-color-picker>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="popupColorPicker.isVisible = !popupColorPicker.isVisible" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="onSaveColor">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- COLOR PICKER POPUP END -->
    <!-- KÉRDÉS SZERKESZTÉSE START -->
    <v-dialog v-model="questionEditor.isVisible" persistent scrollable max-width="768">
      <v-card>
        <v-card-title class="indigo darken-2">
          <span class="white--text">
            {{ questionEditor.questionNr + 1 }}. kérdés szerkesztése
          </span>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="questionEditor.question"
                            label="Kérdés"
                            filled
                            dense>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <!-- SZÖVEGSZERKESZTŐ START -->
              <!-- SZÖVEGSZERKESZTŐ END -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="cancelQuestionEditor" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="saveQuestion">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- KÉRDÉS SZERKESZTÉSE START -->
  </div>
</template>

<script>
export default {
  name: 'Faq',
  components: {
    //
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          color: '#1565C0',
          list: [
            {
              question: 'Csak regisztráció után vehetem igénybe a szolgáltatásokat?',
              answer: 'Igen, csak akkor tudod igénybe venni és megrendelni a szolgáltatásainkat, ha előbb regisztrálsz'
              + 'weboldalunkon. Ehhez szükséges megadnod néhány személyes adatodat: a teljes nevedet, e-mail címedet és telefonszámodat. ',
            },
            {
              question: 'Csak regisztráció után vehetem igénybe a szolgáltatásokat?',
              answer: 'Igen, csak akkor tudod igénybe venni és megrendelni a szolgáltatásainkat, ha előbb regisztrálsz'
                  + 'weboldalunkon. Ehhez szükséges megadnod néhány személyes adatodat: a teljes nevedet, e-mail címedet és telefonszámodat. ',
            },
            {
              question: 'Csak regisztráció után vehetem igénybe a szolgáltatásokat?',
              answer: 'Igen, csak akkor tudod igénybe venni és megrendelni a szolgáltatásainkat, ha előbb regisztrálsz'
                  + 'weboldalunkon. Ehhez szükséges megadnod néhány személyes adatodat: a teljes nevedet, e-mail címedet és telefonszámodat. ',
            },
          ],
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editorPanel: false,
      activePanels: [],
      editorAccordion: [],
      popupColorPicker: {
        isVisible: false,
        pickerColor: '#000000',
      },
      questionEditor: {
        isVisible: false,
        questionNr: 0,
        question: '',
        answer: '<p>Ide tud írni!</p>',
      },
    };
  },
  methods: {
    editElement() {
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    saveElement() {
      this.$emit('save-element');
    },
    addNewListElement() {
      const item = {
        question: 'Kérdés?',
        answer: 'Válasz',
      };
      this.elementProps.list.push(item);
    },
    deleteListElement(index) {
      this.editorAccordion = [];
      this.elementProps.list.splice(index, 1);
    },
    editQuestion(index) {
      this.questionEditor.questionNr = index;
      this.questionEditor.question = this.elementProps.list[index].question;
      this.questionEditor.answer = this.elementProps.list[index].answer;
      // this.textEditor.setContent(this.elementProps.list[index].answer);
      this.questionEditor.isVisible = true;
    },
    saveQuestion() {
      this.elementProps.list[this.questionEditor.questionNr].answer = this.questionEditor.answer;
      this.elementProps.list[this.questionEditor.questionNr].question = this.questionEditor.question;
      this.questionEditor.isVisible = false;
    },
    cancelQuestionEditor() {
      this.questionEditor = {
        isVisible: false,
        questionNr: 0,
        question: '',
        answer: '<p>Ide tud írni!</p>',
      };
    },
    openColorPicker() {
      this.popupColorPicker.isVisible = true;
      this.popupColorPicker.pickerColor = this.elementProps.color;
    },
    onSaveColor() {
      this.elementProps.color = this.popupColorPicker.pickerColor;
      this.popupColorPicker.isVisible = false;
    },
  },
};
</script>

<style scoped>

</style>
