<template>
  <div>
    <v-container :class="editorMode ? 'b-wrapper': ''" fluid :style="`background: ${elementProps.backgroundColor};`">
      <!-- PAGE BUILDER ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            Stripe 1
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- PAGE BUILDER ACTIONBOX END -->
      <!-- CONTENT START -->
      <v-container>
        <v-row class="py-10" align="center" align-content="center">
          <v-col v-if="elementProps.button.isVisible&&elementProps.button.position == 'btn-pos-left'"
                 :cols="elementProps.button.isVisible&&elementProps.button.position  == 'btn-pos-left' ? 3 : 'auto'">
            <v-row class="mx-0" align="center" align-content="center" justify="end">
              <v-btn :color="elementProps.button.color"
                     class="mx-auto"
                     x-large
                     rounded
                     @click="onCardButtonClick(elementProps.button)"
                     :dark="elementProps.button.isDark">
                {{ translatedButtonText }}
              </v-btn>
            </v-row>
          </v-col>
          <v-col :cols="elementProps.button.isVisible&&elementProps.button.position  != 'btn-pos-center' ? 9 : 12"
                 class="text-h3 font-weight-light text-center"
                 :style="`color: ${elementProps.title.color};`">
            {{ translatedTitle }}
          </v-col>
          <v-col v-if="elementProps.button.isVisible&&elementProps.button.position != 'btn-pos-left'"
                 :cols="elementProps.button.isVisible&&elementProps.button.position  == 'btn-pos-right' ? 3 : 12">
            <v-row class="mx-0" align="center" align-content="center" justify="end">
              <v-btn :color="elementProps.button.color"
                     class="mx-auto"
                     x-large
                     rounded
                     @click="onCardButtonClick(elementProps.button)"
                     :dark="elementProps.button.isDark">
                {{ translatedButtonText }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <!-- CONTENT END -->
    </v-container>
    <!-- EDITOR PANEL START -->
    <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
      <v-card min-height="690">
        <v-toolbar color="primary" height="64" max-height="64" dark flat tile>
          <v-btn icon>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            Stripe1 - Szerkesztés
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorPanel = !editorPanel">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="mb-4">
            <v-col cols="12">
              <v-row class="mx-0">
                <v-col class="text-subtitle-1 text--primary">
                  Konténer háttérszíne
                </v-col>
                <v-col cols="auto">
                  <v-row justify="end" class="mx-0">
                    <v-avatar height="52" width="52" tile
                              :color="`${elementProps.backgroundColor}`"
                              @click="openColorPicker(2)" style="border: 1px solid rgba(0,0,0,0.2);"></v-avatar>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="mb-4 mx-0">
            <!-- SZÖVEGEK SZERKESZTÉSE START -->
            <v-col cols="6" class="text-subtitle-1 text--primary">
              Szövegek szerkesztése
            </v-col>
            <v-col cols="6">
              <v-row class="mx-0" justify="end">
                <v-btn color="primary" @click="addNewTranslation('OFF')">
                  {{ $t('button.addNewTranslation') }}
                </v-btn>
              </v-row>
            </v-col>
            <!-- FORDÍTÁS START -->
            <v-col cols="12">
              <v-tabs v-model="translationTab"
                      background-color="teal darken-1"
                      dark>
                <v-tab v-for="(item, index) in elementProps.translations"
                       :key="`lt-${index}`">
                  {{ item.name }}
                </v-tab>
                <v-tabs-items v-model="translationTab">
                  <v-tab-item v-for="(item, index) in elementProps.translations"
                              :key="`lc-${index}`">
                    <v-card outlined tile>
                      <v-card-text>
                        <v-col cols="12">
                          <div class="text-overline">
                            {{ $t('labels.title') }}:
                          </div>
                          <div class="text-h5">
                            {{ item.title }}
                          </div>
                        </v-col>
                        <v-divider></v-divider>
                        <v-col cols="12">
                          <div class="text-overline">
                            {{ $t('labels.buttonText') }}:
                          </div>
                          <div>
                            {{ item.buttonText }}
                          </div>
                        </v-col>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="pa-4">
                        <v-spacer></v-spacer>
                        <v-btn v-if="elementProps.translations.length > 1"
                               color="error"
                               text
                               class="mr-4"
                               @click="deleteTranslation(index, 'OFF')">
                          {{ $t('button.delete') }}
                        </v-btn>
                        <v-btn color="primary"
                               @click="editTranslation(item, index)">
                          {{ $t('button.edit') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-col>
            <v-col cols="auto">
              <v-row justify="end" class="mx-0">
                <v-avatar height="52" width="52" tile
                          :color="`${elementProps.title.color}`"
                          @click="openColorPicker(3)" style="border: 1px solid rgba(0,0,0,0.2);"></v-avatar>
              </v-row>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <!-- ACTION BUTTONS EDIT START -->
          <v-row class="mx-0">
            <v-col cols="12" class="pb-0">
              <v-row justify="space-between" align="center" class="mx-0">
                <span class="text-subtitle-1 text--primary">
                  Gomb láthatósága
                </span>
                <v-switch v-model="elementProps.button.isVisible"
                          inset>
                </v-switch>
              </v-row>
            </v-col>
          </v-row>
          <v-slide-y-transition>
            <v-row v-if="elementProps.button.isVisible" class="mx-0">
              <v-col cols="12">
                <v-row class="mx-0">
                  <v-col>
                    Gomb háttérszíne:
                  </v-col>
                  <v-col cols="auto">
                    <v-row justify="end" class="mx-0">
                      <v-avatar height="52" width="52" tile
                                :color="`${elementProps.button.color}`"
                                @click="openColorPicker(1)" style="border: 1px solid rgba(0,0,0,0.2);"></v-avatar>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-row class="mx-0">
                <v-col cols="8">
                  <v-select v-model="elementProps.button.isExternalLink"
                            label="Gomb hivatkozás típusa"
                            :items="selectFieldOptions.buttonIsExternalLink"
                            filled
                            dense>
                  </v-select>
                </v-col>
                <v-col cols="4">
                  <v-select v-model="elementProps.button.isDark"
                            label="Gomb felirat színe"
                            :items="selectFieldOptions.buttonTextColor"
                            filled
                            dense>
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="elementProps.button.url"
                                v-if="elementProps.button.isExternalLink"
                                label="Gomb hivatkozása"
                                placeholder="https://www.google.com"
                                persistent-placeholder
                                filled
                                dense>
                  </v-text-field>
                  <v-select v-model="elementProps.button.url"
                            v-if="!elementProps.button.isExternalLink"
                            label="Gomb hivatkozása"
                            :items="selectFieldOptions.buttonUrls"
                            placeholder="Válasszon egy oldalt a listából!"
                            filled
                            dense>
                  </v-select>
                  <v-select v-model="elementProps.button.position"
                            label="Gomb pozíciója"
                            :items="selectFieldOptions.buttonPosition"
                            placeholder="Válasszon egy pozíciót a listából!"
                            filled
                            dense>
                  </v-select>
                </v-col>
              </v-row>
            </v-row>
          </v-slide-y-transition>
          <!-- ACTION BUTTONS EDIT END -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="editorPanel = !editorPanel">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDITOR PANEL END -->
    <!-- COLOR PICKER POPUP START -->
    <v-dialog v-model="popupColorPicker.isVisible" max-width="340">
      <v-card>
        <v-card-title class="indigo darken-2">
          <span class="white--text">
            {{ popupColorPicker.title }}
          </span>
        </v-card-title>
        <v-card-text class="pa-5">
          <v-color-picker v-model="popupColorPicker.pickerColor"
                          mode="hexa"
                          show-swatches>
          </v-color-picker>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="popupColorPicker.isVisible = !popupColorPicker.isVisible" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="onSaveColor">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- COLOR PICKER POPUP END -->
    <!-- FORDÍTÁS DIALOG START -->
    <translation-editor-dialog :content="translationDialog"
                               :visible-elements="visibleElements"
                               v-on:@cancel="cancelNewTranslation"
                               v-on:@save="saveNewTranslation" />
    <!-- FORDÍTÁS DIALOG START -->
  </div>
</template>

<script>
import TranslationEditorDialog from '@/components/shared/TranslationEditorDialog.vue';

export default {
  name: 'Stripe1',
  components: {
    TranslationEditorDialog,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          title: {
            color: '#FFFFFF'
          },
          backgroundColor: '#263238',
          translations: [
            {
              code: 'hu',
              name: 'Magyar',
              title: 'Több mint 350 munkatárs',
              buttonText: 'Tovább',
            },
          ],
          button: {
            isVisible: true,
            isDark: false,
            isExternalLink: false,
            color: '#FFFFFF',
            url: '',
            position: 'btn-pos-right'
          },
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    this.changeTranslations();
  },
  data() {
    return {
      editorPanel: false,
      /**
       * type: 1 = gomb háttérszíne
       * type: 2 = container hattérszíne
       * type: 3 = szöveg színe
       */
      popupColorPicker: {
        isVisible: false,
        pickerColor: '#000000',
        type: 1,
        title: 'Gomb színének kiválasztása',
      },
      selectFieldOptions: {
        buttonTextColor: [
          { text: 'Fehér', value: true },
          { text: 'Fekete', value: false },
        ],
        buttonIsExternalLink: [
          { text: 'Belső hivatkozás', value: false },
          { text: 'Külső hivatkozás', value: true },
        ],
        buttonUrls: [
          { text: '/kezdőlap', value: '/kezdőlap' },
          { text: '/page-builder', value: '/page-builder' },
        ],
        buttonPosition: [
          { text: 'Bal', value: 'btn-pos-left' },
          { text: 'Közép', value: 'btn-pos-center' },
          { text: 'Jobb', value: 'btn-pos-right' }
        ]
      },
      translationTab: 0,
      translatedTitle: '',
      translatedButtonText: '',
      visibleElements: ['title', 'button'],
    };
  },
  methods: {
    editElement() {
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    saveElement() {
      this.$emit('save-element');
    },
    saveElementProps() {
      this.translationTab = 0;
      this.changeTranslations();
      this.editorPanel = false;
    },
    changeTranslations() {
      const index = this.elementProps.translations.findIndex((item) => item.code === this.$i18n.locale.substr(0, 2));
      const currentLanguageIndex = index === -1 ? 0 : index;
      this.translatedTitle = this.elementProps.translations[currentLanguageIndex].title;
      this.translatedButtonText = this.elementProps.translations[currentLanguageIndex].buttonText;
    },
    onCardButtonClick(button) {
      if (button.isExternalLink) {
        // console.log('Külső oldalra visz');
        window.open(`${button.url}`, '_blank');
      } else {
        // console.log('Belső oldalra visz');
        this.$router.push({ path: button.url });
      }
    },
    openColorPicker(type) {
      this.popupColorPicker.type = type;
      this.popupColorPicker.isVisible = true;
    },
    onSaveColor() {
      if (this.popupColorPicker.type === 1) {
        this.elementProps.button.color = this.popupColorPicker.pickerColor;
      } else if (this.popupColorPicker.type === 2) {
        this.elementProps.backgroundColor = this.popupColorPicker.pickerColor;
      } else {
        this.elementProps.title.color = this.popupColorPicker.pickerColor;
      }
      this.popupColorPicker.isVisible = false;
    },
  },
};
</script>

<style scoped>

</style>
