<template>
  <div>
    <v-container :class="editorMode ? 'b-wrapper': ''" :fluid="elementProps.isContainerFluid">

      <!-- PAGE BUILDER ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            Card 3
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- PAGE BUILDER ACTIONBOX END -->

      <!-- CONTENT START -->
      <v-row class="fill-height" justify="center">
        <template v-for="(box, boxIndex) in moddedboxes.boxes">
          <v-col cols="12" sm="4" :key="boxIndex">
            <v-row align="start" justify="center" class="mx-0 fill-height">
              <v-card width="90%" max-width="340" class="fill-height" flat>
                <v-row class="mx-0 px-3 pt-8 pb-4 fill-height" align="stretch" justify="center">
                  <v-col cols="12">
                    <v-row class="mx-0" justify="center">
                      <v-col cols="auto">
                        <template v-if="box.image.length < 1">
                          <v-img :src="`${publicPath}images/dummy/cumi.png`"></v-img>
                        </template>
                        <template v-else>
                          <v-img :src="`${publicPath}public_storage${elementProps.image}`"></v-img>
                        </template>
                      </v-col>
                      <v-col cols="12" class="text-center pb-0">
                        <span class="text-h6 text-center text-capitalize font-weight-bold" :style="`color: ${moddedboxes.titleColor};`">
                          {{ box.translations[box.currentTranslationIndex].title }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
          </v-col>
        </template>
      </v-row>
      <!-- CONTENT END -->

    </v-container>

    <!-- EDITOR PANEL START -->
    <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
      <v-card min-height="690">
        <v-toolbar color="primary" height="64" max-height="64" dark flat tile>
          <v-btn icon>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            Card 3 - Szerkesztés
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorPanel = !editorPanel">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="mx-0">
            <v-col cols="12">
              <v-btn color="primary" dark @click="addNewCard" small>
                Új hozzáadása
              </v-btn>
            </v-col>
          </v-row>
          <!-- KÁRTYÁK SZERKESZTÉSE START -->
          <v-row justify="center" class="mb-4 mx-0">
            <v-expansion-panels accordion>
              <v-expansion-panel v-for="(box, boxIndex) in elementProps.boxes" :key="boxIndex">
                <v-expansion-panel-header>
                  {{ boxIndex+1 }}. kártya
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="my-3 mx-0" >
                    <v-col cols="12">
                      <v-btn color="primary" @click="openMediaGallery(boxIndex)">
                        {{ $t('button.addNewImage') }}
                      </v-btn>
                      <v-row>
                        <v-col cols="4" sm="3" md="2" class="px-2">
                          <v-row v-if="box.image.length > 0" class="mx-0 elevation-1">
                            <v-col cols="12" class="pa-0">
                              <v-img :src="`${publicPath}public_storage${box.image}`"
                                     contain
                                     aspect-ratio="1"
                                     height="100%">
                              </v-img>
                            </v-col>
                            <v-col cols="12" class="pa-2">
                              <v-row class="mx-0" justify="space-around">
                                <v-btn icon color="primary" @click="openMediaGallery(boxIndex)">
                                  <v-icon>
                                    mdi-pencil
                                  </v-icon>
                                </v-btn>
                                <v-btn icon color="warning" @click="deleteImage(boxIndex)">
                                  <v-icon>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6" class="text-subtitle-1 text--primary">
                      Szövegek szerkesztése
                    </v-col>
                    <v-col cols="6">
                      <v-row class="mx-0" justify="end">
                        <v-btn color="primary" @click="addNewTranslation(boxIndex)">
                          {{ $t('button.addNewTranslation') }}
                        </v-btn>
                      </v-row>
                    </v-col>
                    <!-- FORDÍTÁS START -->
                    <v-col cols="12">
                      <v-tabs v-model="translationTab"
                              background-color="teal darken-1"
                              dark>
                        <v-tab v-for="(item, index) in box.translations" :key="`lt-${index}`">
                          {{ item.name }}
                        </v-tab>
                        <v-tabs-items v-model="translationTab">
                          <v-tab-item v-for="(item, index) in box.translations" :key="`lc-${index}`">
                            <v-card outlined tile>
                              <v-card-text>
                                <v-col cols="12">
                                  <div class="text-overline">
                                    {{ $t('labels.title') }}:
                                  </div>
                                  <div class="text-h5">
                                    {{ item.title }}
                                  </div>
                                </v-col>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions class="pa-4">
                                <v-spacer></v-spacer>
                                <v-btn v-if="box.translations.length > 1"
                                       color="error"
                                       text
                                       class="mr-4"
                                       @click="deleteTranslation(index, boxIndex)">
                                  {{ $t('button.delete') }}
                                </v-btn>
                                <v-btn color="primary"
                                       @click="editTranslation(item, index, boxIndex)">
                                  {{ $t('button.edit') }}
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-tab-item>
                        </v-tabs-items>
                      </v-tabs>
                    </v-col>
                    <!-- FORDÍTÁS END -->
                    <v-col cols="12">
                      <v-row class="mx-0" justify="end">
                        <v-btn @click="deleteCard(boxIndex)" color="error" dark small>
                          Kártya törlése
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <!-- KÁRTYÁK SZERKESZTÉSE END -->
          <v-divider></v-divider>
          <!-- MAIN COLOR PICKER START -->
          <v-row class="my-4 mx-0">
            <v-col class="text-subtitle-1 text--primary">
              A címek színe
            </v-col>
            <v-col cols="auto">
              <v-avatar height="52" width="52" tile :color="`${elementProps.titleColor}`" @click="openColorPicker(34, 1)"></v-avatar>
            </v-col>
          </v-row>
          <!-- MAIN COLOR PICKER END -->
          <v-divider></v-divider>
          <v-row class="mx-0">
            <v-col cols="12" class="pb-0">
              <v-row justify="space-between" align="center" class="mx-0">
                      <span class="text-subtitle-1 text--primary">
                        A befoglaló container 100% szélességű legyen?
                      </span>
                <v-switch v-model="elementProps.isContainerFluid"
                          inset>
                </v-switch>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="saveElementProps">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDITOR PANEL END -->

    <!-- COLOR PICKER POPUP START -->
    <v-dialog v-model="popupColorPicker.isVisible" max-width="340">
      <v-card>
        <v-card-title class="indigo darken-2">
          <span class="white--text">
            {{ popupColorPicker.title }}
          </span>
        </v-card-title>
        <v-card-text class="pa-5">
          <v-color-picker v-model="popupColorPicker.pickerColor"
                          mode="hexa"
                          show-swatches>
          </v-color-picker>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="popupColorPicker.isVisible = !popupColorPicker.isVisible" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="onSaveColor">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- COLOR PICKER POPUP END -->

    <!-- MEDIA GALLERY START -->
    <template v-if="editorMode">
      <v-dialog v-model="mediaGallery.isVisible"
                persistent
                fullscreen
                transition="dialog-bottom-transition"
                scrollable>
        <v-card tile>
          <media-page :in-dialog="true"
                      v-on:paste-media="pasteMedia(mediaGallery.imageIndex)"
                      v-on:close-media-dialog="mediaGallery.isVisible = false"></media-page>
        </v-card>
      </v-dialog>
    </template>
    <!-- MEDIA GALLERY END -->

    <!-- FORDÍTÁS DIALOG START -->
    <translation-editor-dialog :content="translationDialog"
                               :visible-elements="visibleElements"
                               v-on:@cancel="cancelNewTranslation"
                               v-on:@save="saveNewTranslation" />
    <!-- FORDÍTÁS DIALOG END -->

  </div>
</template>

<script>
import MediaPage from 'src/views/admin/MediaPage.vue';
import TranslationEditorDialog from 'src/components/shared/TranslationEditorDialog.vue';

export default {
  name: 'Card3',
  components: {
    MediaPage,TranslationEditorDialog,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          isContainerFluid: false,
          boxes: [
            {
              image: [
                //
              ],
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  title: 'Card title'
                },
              ]
            },
            {
              image: [
                //
              ],
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  title: 'Card title'
                },
              ]
            },
            {
              image: [
                //
              ],
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  title: 'Card title'
                },
              ]
            },
          ],
          titleColor: '#000000',
          titleFontSize: ''
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    this.moddedboxes = await this.cloneElementProps();
    await this.changeTranslations();
  },
  data() {
    return {
      editorPanel: false,
      popupIcon: {
        isVisible: false,
        iconName: '',
        boxIndex: null,
      },
      /**
       * type: 1 = a kártya ikonjának színe
       * type: 2 = a kártya gombjának a színe
       * type: 3 = a builder element gombjának a színe
       */
      popupColorPicker: {
        isVisible: false,
        pickerColor: '#000000',
        boxIndex: null,
        type: 1,
        title: 'Ikon színének kiválasztása',
      },
      selectFieldOptions: {
        sizes: [
          { text: 'Cím',    value: 'text-h3' },
          { text: 'Alcím',  value: 'text-subtitle-1' }
        ]
      },
      mediaGallery: {
        isVisible: false,
        imageIndex: 0,
      },
      moddedboxes: [],
      translationTab: 0,
      expansionPanel: null,
      visibleElements: ['title'],
    };
  },
  methods: {
    editElement() {
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    saveElement() {
      this.$emit('save-element');
    },
    async saveElementProps() {
      this.moddedboxes = await this.cloneElementProps();
      await this.changeTranslations();
      this.translationTab = 0;
      this.expansionPanel = null;
      this.editorPanel = false;
    },
    async cloneElementProps() {
      // console.log('cloneElementProps');
      return JSON.parse(JSON.stringify(this.elementProps));
    },
    addNewCard() {
      this.elementProps.boxes.push(JSON.parse(JSON.stringify(this.elementProps.boxes[0])));
    },
    deleteCard(index) {
      this.elementProps.boxes.splice(index, 1);
    },
    onCardButtonClick(button) {
      if (button.isExternalLink) {
        // console.log('Külső oldalra visz');
        window.open(`${button.url}`, '_blank');
      } else {
        // console.log('Belső oldalra visz');
        // this.$router.push(`${button.url}`);
        window.open(`${button.url}`, '_self');
      }
    },
    onMainButtonClick() {
      if (this.elementProps.button.isExternalLink) {
        // console.log('Külső oldalra visz');
        window.open(`${this.elementProps.button.url}`, '_blank');
      } else {
        // console.log('Belső oldalra visz');
        this.$router.push({ path: this.elementProps.button.url });
        // window.open(`${this.elementProps.button.url}`, '_self');
      }
    },
    onCancelPopupIcon() {
      this.popupIcon = {
        isVisible: false,
        iconName: '',
        boxIndex: null,
      };
    },
    onSavePopupIcon(boxIndex) {
      this.popupIcon.isVisible = false;
      this.elementProps.boxes[boxIndex].icon = this.popupIcon.iconName;
    },
    openColorPicker(boxIndex, type) {
      this.popupColorPicker.title = 'Cím színének kiválasztása';
      this.popupColorPicker.pickerColor = this.elementProps.titleColor;
      this.popupColorPicker.isVisible = true;
      this.popupColorPicker.boxIndex = boxIndex;
    },
    onSaveColor() {
      this.elementProps.titleColor = this.popupColorPicker.pickerColor;
      this.popupColorPicker.isVisible = false;
    },
    async changeTranslations() {
      // console.log('changeTranslation');
      for (let i = 0; i < this.moddedboxes.boxes.length; i++) {
        const index = this.moddedboxes.boxes[i].translations.findIndex((item) => item.code === this.$i18n.locale.substr(0, 2));
        this.moddedboxes.boxes[i].currentTranslationIndex = index === -1 ? 0 : index;
      }
    },
    openMediaGallery(index) {
      this.mediaGallery.imageIndex = index;
      this.mediaGallery.isVisible = true;
    },
    deleteImage(index) {
      console.log('Matt - Index: ',index);
      console.log('Matt - Box.image: ', this.elementProps.boxes);
      this.elementProps.boxes[index].image = '';
    },
    pasteMedia(index) {
      const image = this.$store.getters.getSelectedImageInMediaGallery;
      console.log('Matt - Index: ', index);
      this.elementProps.boxes[index].image = image.fileFullPath;
      this.mediaGallery.isVisible = false;
      console.log('Matt - box: ',this.elementProps.boxes);
      this.$store.commit('clearSelectedImageInMediaGallery');
    }
  },
};
</script>

<style scoped>

</style>
