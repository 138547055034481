<template>
  <div v-frag>
    <v-container :class="editorMode ? 'b-wrapper pa-0': 'pa-0'" fluid>
      <!-- PAGE BUILDER ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            Basic Slider
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- PAGE BUILDER ACTIONBOX END -->
      <!-- CONTENT START -->
      <v-carousel cycle
                  :height="$vuetify.breakpoint.xsOnly ? elementProps.sliderProps.xsHeight :
                  $vuetify.breakpoint.smOnly ? elementProps.sliderProps.smHeight : $vuetify.breakpoint.mdOnly ?
                  elementProps.sliderProps.mdHeight : $vuetify.breakpoint.lgOnly ? elementProps.sliderProps.lgHeight : elementProps.sliderProps.xlHeight"
                  :interval="elementProps.sliderProps.interval"
                  :delimiter-icon="elementProps.sliderProps.delimiterIcon"
                  :hide-delimiter-background="!elementProps.sliderProps.showDelimiterBackground"
                  :hide-delimiters="!elementProps.sliderProps.showDelimiter"
                  :show-arrows="elementProps.sliderProps.showArrows"
                  :show-arrows-on-hover="elementProps.sliderProps.showArrowsOnHover"
                  :next-icon="elementProps.sliderProps.arrowsIconSet.nextIcon"
                  :prev-icon="elementProps.sliderProps.arrowsIconSet.prevIcon">
        <v-carousel-item v-for="(slide, index) in elementProps.slides"
                         :key="index"
                         :src="slide.backgroundType === 'image' ? slide.backgroundImage.length < 1 ? `${publicPath}images/dummy/lake.jpg` : `${publicPath}public_storage${slide.backgroundImage}` : ''"
                         :style="slide.backgroundType === 'color' ?
                         `background: linear-gradient(${slide.backgroundColorTop},${slide.backgroundColorBottom})`
                         : ''">
            <v-container class="fill-height">
              <v-row class="fill-height" align="center" align-content="center" :justify="slide.slideAlign">
                <!-- KÉP START -->
                <v-col cols="12" sm="6" :order="slide.textAlign === 'right' ? 1 : 2">
                  <v-row justify="center">
                    <v-img v-show="slide.showImage"
                           :src="slide.imageSrc.length < 1 ? `${publicPath}images/dummy/city.jpg` : `${publicPath}public_storage${slide.imageSrc}`"
                           width="100%"
                           max-width="540px" />
                  </v-row>
                </v-col>
                <!-- KÉP END -->
                <!-- SZÖVEG START -->
                <v-col cols="12" md="6" :order="slide.textAlign === 'right' ? 2 : 1" v-show="slide.showText">
                  <div :class="`text-${slide.text.title.textAlign} ${slide.text.title.textSize} mb-3`" :style="`color: ${slide.text.title.textColor};`">
                    {{ slide.text.translations[slide.currentTranslationIndex].title }}
                  </div>
                  <div :class="`text-${slide.text.subtitle.textAlign} ${slide.text.subtitle.textSize} mb-3`" :style="`color: ${slide.text.subtitle.textColor};`">
                    {{ slide.text.translations[slide.currentTranslationIndex].subtitle }}
                  </div>
                  <div :class="`text-${slide.text.text.textAlign} ${slide.text.text.textSize} mb-3`" :style="`color: ${slide.text.text.textColor};`">
                    {{ slide.text.translations[slide.currentTranslationIndex].text }}
                  </div>
                  <div :class="`text-${slide.buttonAlign}`" v-if="slide.button.isVisible">
                    <v-btn :color="slide.button.color"
                           @click="onButtonClick(slide.button)"
                           :text="slide.button.display === 3"
                           :outlined="slide.button.display === 2"
                           :block="slide.button.isBlock === 2"
                           :rounded="slide.button.rounded === 2"
                           :small="slide.button.size === 2"
                           :x-small="slide.button.size === 3"
                           :large="slide.button.size === 4"
                           :x-large="slide.button.size === 5">
                      <template v-if="slide.button.display === 2 || slide.button.display === 3">
                        <span :style="`color: ${slide.button.color}`">
                          {{ slide.text.translations[slide.currentTranslationIndex].button }}
                        </span>
                      </template>
                      <template v-else>
                        <span :style="slide.button.isDark ? 'color: #FFFFFF;' : 'color: rgba(0, 0, 0, 0.87);'">
                          {{ slide.text.translations[slide.currentTranslationIndex].button }}
                        </span>
                      </template>
                    </v-btn>
                  </div>
                </v-col>
                <!-- SZÖVEG END -->
              </v-row>
            </v-container>
        </v-carousel-item>
      </v-carousel>
      <!-- CONTENT END -->
    </v-container>
    <!-- EDITOR PANEL START -->
    <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
      <v-card min-height="690">
        <v-toolbar color="primary" height="64" max-height="64" dark flat tile>
          <v-btn icon>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            Basic Slider - Szerkesztés
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorPanel = !editorPanel">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-tabs v-model="editorCurrentTab">
            <v-tab v-for="(item, index) in editorTabs" :key="index">
              <v-icon class="mr-3">
                {{ item.icon }}
              </v-icon>
              {{ item.name }}
            </v-tab>
            <v-tabs-items v-model="editorCurrentTab">
              <!-- SLIDER ALAPBEÁLLÍTÁSOK SZERKESZTÉSE START -->
              <v-tab-item>
                <v-row class="my-4">
                  <v-col cols="12">
                    <v-select v-model="elementProps.sliderProps.interval"
                              label="Képváltás időtartama"
                              :items="selectFieldOptions.interval"
                              filled
                              dense>
                    </v-select>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-divider></v-divider>
                  </v-col>
                  <!-- DELIMITER EDIT START -->
                  <v-col cols="12" class="pb-0">
                    <v-row justify="space-between" align="center" class="mx-0">
                      <span class="text-subtitle-1 text--primary">
                        Slider navigáció megjelenítése
                      </span>
                      <v-switch v-model="elementProps.sliderProps.showDelimiter"
                                inset>
                      </v-switch>
                    </v-row>
                    <v-slide-y-transition>
                      <v-row v-if="elementProps.sliderProps.showDelimiter" class="mx-0">
                        <v-col cols="12">
                          <v-row justify="space-between" align="center" class="mx-0">
                            <span class="text-subtitle-1 text--primary">
                              Slider navigáció ikon
                            </span>
                            <v-btn-toggle v-model="elementProps.sliderProps.delimiterIcon" mandatory>
                              <template v-for="(icon, index) in selectFieldOptions.delimiters">
                                <v-btn :key="index" :value="icon">
                                  <v-icon>
                                    {{ icon }}
                                  </v-icon>
                                </v-btn>
                              </template>
                            </v-btn-toggle>
                          </v-row>
                        </v-col>
                        <v-col cols="12">
                          <v-select v-model="elementProps.sliderProps.showDelimiterBackground"
                                    label="Slider navigáció háttér megjelenítése"
                                    :items="selectFieldOptions.showDelimiterBackground"
                                    filled
                                    dense>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-slide-y-transition>
                  </v-col>
                  <!-- DELIMITER EDIT END -->
                  <v-col cols="12" class="py-0">
                    <v-divider></v-divider>
                  </v-col>
                  <!-- ARROWS EDIT START -->
                  <v-col cols="12" class="pb-0">
                    <v-row justify="space-between" align="center" class="mx-0">
                      <span class="text-subtitle-1 text--primary">
                        Nyilak megjelenítése
                      </span>
                      <v-switch v-model="elementProps.sliderProps.showArrows"
                                inset>
                      </v-switch>
                    </v-row>
                    <v-slide-y-transition>
                      <v-row v-if="elementProps.sliderProps.showArrows" class="mx-0">
                        <v-col cols="12">
                          <v-row justify="space-between" align="center" class="mx-0">
                            <span class="text-subtitle-1 text--primary">
                              Nyíl ikon kiválasztása
                            </span>
                            <v-btn-toggle v-model="elementProps.sliderProps.arrowsIconSet" mandatory>
                              <template v-for="(icon, index) in selectFieldOptions.arrows">
                                <v-btn :key="index" :value="icon">
                                  <v-icon>
                                    {{ icon.prevIcon }}
                                  </v-icon>
                                  <v-icon>
                                    {{ icon.nextIcon }}
                                  </v-icon>
                                </v-btn>
                              </template>
                            </v-btn-toggle>
                          </v-row>
                        </v-col>
                        <v-col cols="12">
                          <v-select v-model="elementProps.sliderProps.showArrowsOnHover"
                                    label="Nyilak automatikus megjelenítése/elrejtése"
                                    :items="selectFieldOptions.showDelimiterBackground"
                                    filled
                                    dense>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-slide-y-transition>
                  </v-col>
                  <!-- ARROWS EDIT END -->
                  <v-col cols="12" class="py-0">
                    <v-divider></v-divider>
                  </v-col>
                  <!-- SLIDE MAGASSÁG START -->
                  <v-col cols="12" class="pb-0">
                    <div class="text-subtitle-1 text--primary mt-4">
                      Slide magassága
                    </div>
                    <div class="body-2 mb-4">
                      Pixelben vagy százalékban. Pl. 100% vagy 540px
                    </div>
                    <v-row class="mx-0">
                      <v-col cols="12" md="4" lg="2">
                        <v-text-field v-model="elementProps.sliderProps.xsHeight"
                                      label="XS magasság"
                                      prepend-inner-icon="mdi-cellphone"
                                      hint="screen < 600px"
                                      persistent-hint
                                      filled>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" lg="2">
                        <v-text-field v-model="elementProps.sliderProps.smHeight"
                                      label="SM magasság"
                                      prepend-inner-icon="mdi-tablet"
                                      hint="600px < screen < 960px"
                                      persistent-hint
                                      filled>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" lg="2">
                        <v-text-field v-model="elementProps.sliderProps.mdHeight"
                                      label="MD magasság"
                                      prepend-inner-icon="mdi-laptop"
                                      hint="960px < screen < 1264px"
                                      persistent-hint
                                      filled>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" lg="2">
                        <v-text-field v-model="elementProps.sliderProps.lgHeight"
                                      label="LG magasság"
                                      prepend-inner-icon="mdi-monitor"
                                      hint="1264px < screen < 1904px"
                                      persistent-hint
                                      filled>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" lg="2">
                        <v-text-field v-model="elementProps.sliderProps.xlHeight"
                                      label="XL magasság"
                                      prepend-inner-icon="mdi-television"
                                      hint="1904px < screen"
                                      persistent-hint
                                      filled>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- SLIDE MAGASSÁG END -->
                </v-row>
              </v-tab-item>
              <!-- SLIDER ALAPBEÁLLÍTÁSOK SZERKESZTÉSE END -->
              <!-- SLIDEOK SZERKESZTÉSE START -->
              <v-tab-item>
                <v-row class="my-4">
                  <v-col cols="12">
                    <v-btn color="primary" dark @click="addNewSlide" small>
                      Új hozzáadása
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mx-0 px-3">
                  <template v-for="(slide, index) in elementProps.slides">
                    <!-- SLIDE SZERKESZTÉSE START -->
                    <v-expansion-panels :key="index" class="mb-4">
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          {{ index + 1}}. slide - {{ slide.text.translations[slide.currentTranslationIndex].title }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row class="mb-4">
                            <v-col cols="12" sm="3" class="pt-6">
                              <v-select v-model="slide.backgroundType"
                                        label="Slide háttere"
                                        :items="selectFieldOptions.backgroundType"
                                        filled
                                        dense>
                              </v-select>
                              <div v-if="slide.backgroundType === 'image'">
                                <v-img :src="slide.backgroundImage.length < 1 ? `${publicPath}images/dummy/lake.jpg` : `${publicPath}public_storage${slide.backgroundImage}`"
                                       height="100%"
                                       max-width="800px"
                                       class="image-changeable"
                                       @click="openMediaGallery(index, 'BG')"></v-img>
                              </div>
                              <div v-if="slide.backgroundType === 'color'">
                                <v-row>
                                  <v-col class="text-subtitle-1 text--primary">
                                    Háttérszín alsó
                                  </v-col>
                                  <v-col cols="auto">
                                    <v-avatar height="48" width="48" tile :color="`${slide.backgroundColorBottom}`" @click="openColorPicker(index, 2)"></v-avatar>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col class="text-subtitle-1 text--primary">
                                    Háttérszín felső
                                  </v-col>
                                  <v-col cols="auto">
                                    <v-avatar height="48" width="48" tile :color="`${slide.backgroundColorTop}`" @click="openColorPicker(index, 1)"></v-avatar>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-col>
                            <!-- DIA ELRENDEZÉSE START -->
                            <v-col cols="12" sm="9">
                              <v-row class="mb-4">
                                <v-col cols="12" sm="4">
                                  <v-select v-model="slide.showText"
                                            :label="$t('labels.showTextBlock')"
                                            :items="selectFieldOptions.showDelimiterBackground"
                                            filled
                                            dense>
                                  </v-select>
                                  <v-select v-model="slide.showImage"
                                            :label="$t('labels.showImageBlock')"
                                            :items="selectFieldOptions.showDelimiterBackground"
                                            filled
                                            dense>
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-select v-model="slide.textAlign"
                                            :label="$t('labels.textAndImagePosition')"
                                            :items="selectFieldOptions.textAndImagePosition"
                                            filled
                                            dense>
                                  </v-select>
                                  <v-select v-model="slide.slideAlign"
                                            :label="$t('labels.slideAlign')"
                                            :items="selectFieldOptions.slideAlign"
                                            filled
                                            dense>
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-img :src="slide.imageSrc.length < 1 ? `${publicPath}images/dummy/city.jpg` : `${publicPath}public_storage${slide.imageSrc}`"
                                         height="100%"
                                         max-width="800px"
                                         class="image-changeable"
                                         @click="openMediaGallery(index, 'PIC')"></v-img>
                                </v-col>
                              </v-row>
                            </v-col>
                            <!-- DIA ELRENDEZÉSE END -->
                            <v-col cols="12" class="py-0">
                              <v-divider></v-divider>
                            </v-col>
                          </v-row>
                          <!-- SZÖVEGEK START -->
                          <v-row class="mb-4">
                            <v-col cols="6" class="text-subtitle-1 text--primary">
                              Szövegek szerkesztése
                            </v-col>
                            <v-col cols="6">
                              <v-row class="mx-0" justify="end">
                                <v-btn color="primary" @click="addNewTranslation('OFF')">
                                  {{ $t('button.addNewTranslation') }}
                                </v-btn>
                              </v-row>
                            </v-col>
                            <!-- FORDÍTÁS START -->
                            <v-col cols="12">
                              <v-tabs v-model="translationTab"
                                      background-color="teal darken-1"
                                      dark>
                                <v-tab v-for="(item, index) in slide.text.translations"
                                       :key="`lt-${index}`">
                                  {{ item.name }}
                                </v-tab>
                                <v-tabs-items v-model="translationTab">
                                  <v-tab-item v-for="(item, index) in slide.text.translations"
                                              :key="`lc-${index}`">
                                    <v-card outlined tile>
                                      <v-card-text class="pa-0">
                                        <v-row>
                                          <v-col cols="12" sm="6">
                                            <v-text-field v-model="item.title"
                                                          :label="$t('labels.title')"
                                                          filled
                                                          dense>
                                            </v-text-field>
                                          </v-col>
                                          <v-col cols="12" sm="6">
                                            <v-text-field v-model="item.subtitle"
                                                          :label="$t('labels.subtitle')"
                                                          filled
                                                          dense>
                                            </v-text-field>
                                          </v-col>
                                          <v-col cols="12" sm="6">
                                            <v-textarea v-model="item.text"
                                                        :label="$t('labels.description')"
                                                        filled
                                                        rows="2"
                                                        dense>
                                            </v-textarea>
                                          </v-col>
                                          <v-col cols="12" sm="6">
                                            <v-text-field v-model="item.button"
                                                          :label="$t('labels.buttonText')"
                                                          filled
                                                          dense
                                                          maxlength="32"
                                                          counter="32">
                                            </v-text-field>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                      <v-divider></v-divider>
                                      <v-card-actions class="pa-4">
                                        <v-spacer></v-spacer>
                                        <v-btn v-if="slide.text.translations.length > 1"
                                               color="error"
                                               text
                                               class="mr-4"
                                               @click="deleteTranslation(index)">
                                          {{ $t('button.delete') }}
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-tab-item>
                                </v-tabs-items>
                              </v-tabs>
                            </v-col>
                            <!-- FORDÍTÁS END -->
                          </v-row>
                          <v-row class="mb-4">
                            <!-- CÍM IGAZÍTÁSA START -->
                            <v-col cols="12" md="4" class="pa-0">
                              <v-row class="mx-0">
                                <v-col cols="12" class="text-overline text-center">
                                  {{ $t('labels.titleAlign') }}
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                  <v-row justify="center">
                                    <v-col cols="auto" class="pt-0">
                                      <v-btn-toggle v-model="slide.text.title.textAlign">
                                        <template v-for="(align, index) in selectFieldOptions.textAlign">
                                          <v-btn :key="index" :value="align.value">
                                            <v-icon>
                                              {{ align.icon }}
                                            </v-icon>
                                          </v-btn>
                                        </template>
                                      </v-btn-toggle>
                                    </v-col>
                                    <v-col cols="auto" class="pt-0">
                                      <v-avatar height="48" width="48" tile
                                                class="pageBuilder-color-picker-activator"
                                                style="border: 1px solid rgba(0,0,0,0.2);"
                                                :color="`${slide.text.title.textColor}`"
                                                @click="openColorPicker(index, 3)"></v-avatar>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col col="12" class="pt-0">
                                  <v-row justify="center">
                                    <v-btn-toggle v-model="slide.text.title.textSize">
                                      <template v-for="(size, index) in selectFieldOptions.textSize">
                                        <v-btn :key="index" :value="size.value">
                                          <v-icon>
                                            {{ size.icon }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                    </v-btn-toggle>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-col>
                            <!-- CÍM IGAZÍTÁSA END -->
                            <!-- ALCÍM IGAZÍTÁSA START -->
                            <v-col cols="12" md="4" class="pa-0">
                              <v-row class="mx-0">
                                <v-col cols="12" class="text-overline text-center">
                                  {{ $t('labels.subtitleAlign') }}
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                  <v-row justify="center">
                                    <v-col cols="auto" class="pt-0">
                                      <v-btn-toggle v-model="slide.text.subtitle.textAlign">
                                        <template v-for="(align, index) in selectFieldOptions.textAlign">
                                          <v-btn :key="index" :value="align.value">
                                            <v-icon>
                                              {{ align.icon }}
                                            </v-icon>
                                          </v-btn>
                                        </template>
                                      </v-btn-toggle>
                                    </v-col>
                                    <v-col cols="auto" class="pt-0">
                                      <v-avatar height="48" width="48" tile
                                                class="pageBuilder-color-picker-activator"
                                                style="border: 1px solid rgba(0,0,0,0.2);"
                                                :color="`${slide.text.subtitle.textColor}`"
                                                @click="openColorPicker(index, 4)"></v-avatar>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col col="12" class="pt-0">
                                  <v-row justify="center">
                                    <v-btn-toggle v-model="slide.text.subtitle.textSize">
                                      <template v-for="(size, index) in selectFieldOptions.textSize">
                                        <v-btn :key="index" :value="size.value">
                                          <v-icon>
                                            {{ size.icon }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                    </v-btn-toggle>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-col>
                            <!-- ALCÍM IGAZÍTÁSA END -->
                            <!-- LEÍRÁS IGAZÍTÁSA START -->
                            <v-col cols="12" md="4" class="pa-0">
                              <v-row class="mx-0">
                                <v-col cols="12" class="text-overline text-center">
                                  {{ $t('labels.descriptionAlign') }}
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                  <v-row justify="center">
                                    <v-col cols="auto" class="pt-0">
                                      <v-btn-toggle v-model="slide.text.text.textAlign">
                                        <template v-for="(align, index) in selectFieldOptions.textAlign">
                                          <v-btn :key="index" :value="align.value">
                                            <v-icon>
                                              {{ align.icon }}
                                            </v-icon>
                                          </v-btn>
                                        </template>
                                      </v-btn-toggle>
                                    </v-col>
                                    <v-col cols="auto" class="pt-0">
                                      <v-avatar height="48" width="48" tile
                                                class="pageBuilder-color-picker-activator"
                                                style="border: 1px solid rgba(0,0,0,0.2);"
                                                :color="`${slide.text.text.textColor}`"
                                                @click="openColorPicker(index, 6)"></v-avatar>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col col="12" class="pt-0">
                                  <v-row justify="center">
                                    <v-btn-toggle v-model="slide.text.text.textSize">
                                      <template v-for="(size, index) in selectFieldOptions.textSize">
                                        <v-btn :key="index" :value="size.value">
                                          <v-icon>
                                            {{ size.icon }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                    </v-btn-toggle>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-col>
                            <!-- LEÍRÁS IGAZÍTÁSA END -->
                            <v-col cols="12" class="py-0">
                              <v-divider></v-divider>
                            </v-col>
                          </v-row>
                          <!-- SZÖVEGEK END -->
                          <!-- GOMB START -->
                          <v-row class="mb-4">
                            <v-col cols="12" class="pb-0">
                              <v-row justify="space-between" align="center" class="mx-0">
                                <span class="text-subtitle-1 text--primary">
                                  Gomb láthatósága
                                </span>
                                <v-switch v-model="slide.button.isVisible"
                                          inset>
                                </v-switch>
                              </v-row>
                            </v-col>
                            <v-col cols="12">
                              <v-slide-y-transition>
                                <v-row v-if="slide.button.isVisible" class="mx-0">
                                  <v-col cols="12">
                                    <v-row class="mx-0">
                                      <v-col>
                                        Gomb háttérszíne:
                                      </v-col>
                                      <v-col cols="auto">
                                        <v-row justify="end" class="mx-0">
                                          <v-avatar height="52" width="52" tile :color="`${slide.button.color}`" @click="openColorPicker(index, 5)"></v-avatar>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-row class="mx-0">
                                    <v-col cols="4">
                                      <v-select v-model="slide.button.display"
                                                :label="$t('labels.submitDisplay')"
                                                :items="selectFieldOptions.submitDisplay"
                                                dense
                                                filled>
                                      </v-select>
                                    </v-col>
                                    <v-col cols="4">
                                      <v-select v-model="slide.button.isDark"
                                                label="Gomb felirat színe"
                                                :items="selectFieldOptions.buttonTextColor"
                                                filled
                                                :disabled="slide.button.display === 2 || slide.button.display === 3"
                                                dense>
                                      </v-select>
                                    </v-col>
                                    <v-col cols="4">
                                      <v-select v-model="slide.buttonAlign"
                                                label="Gomb igazítása"
                                                :items="selectFieldOptions.textAlign"
                                                filled
                                                dense>
                                      </v-select>
                                    </v-col>
                                    <v-col cols="4">
                                      <v-select v-model="slide.button.size"
                                                :label="$t('labels.submitSize')"
                                                :items="selectFieldOptions.submitSize"
                                                dense
                                                filled>
                                      </v-select>
                                    </v-col>

                                    <v-col cols="4">
                                      <v-select v-model="slide.button.isBlock"
                                                :label="$t('labels.submitIsBlock')"
                                                :items="selectFieldOptions.submitIsBlock"
                                                dense
                                                filled>
                                      </v-select>
                                    </v-col>
                                    <v-col cols="4">
                                      <v-select v-model="slide.button.rounded"
                                                :label="$t('labels.submitRounded')"
                                                :items="selectFieldOptions.submitRounded"
                                                dense
                                                filled>
                                      </v-select>
                                    </v-col>
                                    <v-col cols="4">
                                      <v-select v-model="slide.button.isExternalLink"
                                                label="Gomb hivatkozás típusa"
                                                :items="$store.state.general.buttonIsExternalLink"
                                                filled
                                                dense>
                                      </v-select>
                                    </v-col>
                                    <v-col cols="8">
                                      <v-text-field v-model="slide.button.url"
                                                    v-if="slide.button.isExternalLink"
                                                    label="Gomb hivatkozása"
                                                    placeholder="https://www.google.com"
                                                    filled
                                                    dense>
                                      </v-text-field>
                                      <v-autocomplete v-model="slide.button.url"
                                                      label="Gomb hivatkozása"
                                                      :items="$store.state.general.availablePages"
                                                      item-text="name"
                                                      item-value="url"
                                                      filled
                                                      dense>
                                        <template v-slot:selection="data">
                                          {{ data.item.name }} - {{ data.item.group }}
                                        </template>
                                        <template v-slot:item="data">
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              {{ data.item.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                              {{ data.item.group }}
                                            </v-list-item-subtitle>
                                          </v-list-item-content>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                  </v-row>
                                </v-row>
                              </v-slide-y-transition>
                            </v-col>
                            <v-col cols="12" class="py-0">
                              <v-divider></v-divider>
                            </v-col>
                          </v-row>
                          <!-- GOMB END -->
                          <v-row>
                            <v-col cols="12">
                              <v-row class="mx-0" justify="end">
                                <v-btn @click="deleteSlide(index)" color="error" dark small>
                                  Slide törlése
                                </v-btn>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <!-- SLIDE SZERKESZTÉSE END -->
                  </template>
                </v-row>
              </v-tab-item>
              <!-- SLIDEOK SZERKESZTÉSE START -->
            </v-tabs-items>
          </v-tabs>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="editorPanel = !editorPanel">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDITOR PANEL END -->
    <!-- COLOR PICKER POPUP START -->
    <v-dialog v-model="popupColorPicker.isVisible" max-width="340">
      <v-card>
        <v-card-title class="indigo darken-2">
          <span class="white--text">
            {{ popupColorPicker.title }}
          </span>
        </v-card-title>
        <v-card-text class="pa-5">
          <v-color-picker v-model="popupColorPicker.pickerColor"
                          mode="hexa"
                          show-swatches>
          </v-color-picker>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="popupColorPicker.isVisible = !popupColorPicker.isVisible" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="onSaveColor()">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- COLOR PICKER POPUP END -->
    <!-- FORDÍTÁS DIALOG START -->
    <v-dialog v-model="translationDialog.isVisible" max-width="440" persistent scrollable>
      <v-card>
        <v-card-title class="indigo white--text">
          {{ $t(`${translationDialog.title}`) }}
          <v-spacer></v-spacer>
          <v-icon color="white" @click="cancelNewTranslation">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-list>
                <v-list-item-group v-model="translationDialog.selectedLanguageIndex"
                                   color="primary">
                  <template v-for="(item, index) in $store.state.languages.availableLanguages">
                    <v-list-item :key="index"
                                 @click="setLanguage(item, index)">
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`languages.${item.text}`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon v-if="!active"
                                  color="grey lighten-1">
                            mdi-checkbox-blank-circle-outline
                          </v-icon>
                          <v-icon v-else
                                  color="primary">
                            mdi-check-circle-outline
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                    <v-divider v-if="index < $store.state.languages.availableLanguages.length - 1" :key="`d-${index}`"></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <v-spacer></v-spacer>
          <v-btn text @click="cancelNewTranslation" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary"
                 :disabled="Number.isNaN(translationDialog.selectedLanguageIndex)"
                 @click="saveNewTranslation(translationDialog.addOrEdit)">
            {{ $t('button.add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- FORDÍTÁS DIALOG START -->
    <!-- MEDIA GALLERY START -->
    <template v-if="editorMode">
      <v-dialog v-model="mediaGallery.isVisible"
                persistent
                fullscreen
                transition="dialog-bottom-transition"
                scrollable>
        <v-card tile>
          <media-page :in-dialog="true"
                      v-on:paste-media="pasteMedia"
                      v-on:close-media-dialog="mediaGallery.isVisible = false"></media-page>
        </v-card>
      </v-dialog>
    </template>
    <!-- MEDIA GALLERY END -->
  </div>
</template>

<script>
import MediaPage from '@/views/admin/MediaPage.vue';
import LanguageList from '@/components/shared/LanguageList.vue';

export default {
  name: 'BasicSlider',
  components: {
    MediaPage, LanguageList,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          sliderProps: {
            xsHeight: '100%',
            smHeight: '540',
            mdHeight: '540',
            lgHeight: '540',
            xlHeight: '540',
            interval: '6000',
            delimiterIcon: 'mdi-circle',
            showDelimiterBackground: true,
            showDelimiter: true,
            showArrows: true,
            showArrowsOnHover: true,
            arrowsIconSet: {
              nextIcon: 'mdi-chevron-right',
              prevIcon: 'mdi-chevron-left',
            },
          },
          slides: [
            {
              backgroundType: 'image',
              backgroundColorBottom: '#00838F',
              backgroundColorTop: '#00ACC1',
              backgroundImage: '',
              buttonAlign: 'left',
              textAlign: 'right',
              slideAlign: 'center',
              showText: true,
              showImage: true,
              imageSrc: '',
              text: {
                translations: [
                  {
                    code: 'hu',
                    name: 'Magyar',
                    title: 'Több mint 350 munkatárs',
                    subtitle: 'Lorem ipsum',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tortor ante, imperdiet id gravida nec, aliquet a sapien.',
                    button: 'Bővebben',
                  },
                ],
                title: {
                  textColor: '#FFFFFF',
                  textAlign: 'left',
                  textSize: 'text-h2',
                },
                subtitle: {
                  textColor: '#FFFFFF',
                  textAlign: 'left',
                  textSize: 'text-h5',
                },
                text: {
                  textColor: '#FFFFFF',
                  textAlign: 'left',
                  textSize: 'text-subtitle-1',
                },
              },
              button: {
                isVisible: true,
                isDark: true,
                isExternalLink: false,
                color: '#E91E63',
                url: '',
                display: 1,
                isBlock: 1,
                rounded: 1,
                size: 1,
              },
            },
          ],
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    await this.changeTranslations();
  },
  data() {
    return {
      editorPanel: false,
      translationTab: 0,
      translationDialog: {
        title: 'button.addNewTranslation',
        isVisible: false,
        selectedLanguageIndex: NaN,
        selectedTranslationIndex: NaN,
        addOrEdit: 'ADD',
        translation: {
          code: '',
          name: '',
          title: '',
          subtitle: '',
          text: '',
        },
      },
      selectFieldOptions: {
        interval: [
          { text: '4000 ms', value: '4000' },
          { text: '5000 ms', value: '5000' },
          { text: '6000 ms', value: '6000' },
          { text: '7000 ms', value: '7000' },
          { text: '8000 ms', value: '8000' },
          { text: '9000 ms', value: '9000' },
        ],
        delimiters: [
          'mdi-circle', 'mdi-circle-medium', 'mdi-square', 'mdi-square-medium', 'mdi-minus',
        ],
        showDelimiterBackground: [
          { text: 'Igen', value: true },
          { text: 'Nem', value: false },
        ],
        arrows: [
          {
            nextIcon: 'mdi-arrow-right',
            prevIcon: 'mdi-arrow-left',
          },
          {
            nextIcon: 'mdi-chevron-right',
            prevIcon: 'mdi-chevron-left',
          },
          {
            nextIcon: 'mdi-menu-right',
            prevIcon: 'mdi-menu-left',
          },
          /* {
            nextIcon: 'mdi-arrow-right-circle',
            prevIcon: 'mdi-arrow-left-circle',
          },
          {
            nextIcon: 'mdi-arrow-right-drop-circle',
            prevIcon: 'mdi-arrow-left-drop-circle',
          },
          {
            nextIcon: 'mdi-arrow-right-circle-outline',
            prevIcon: 'mdi-arrow-left-circle-outline',
          },
          {
            nextIcon: 'mdi-arrow-right-drop-circle-outline',
            prevIcon: 'mdi-arrow-left-drop-circle-outline',
          }, */
        ],
        textAlign: [
          { icon: 'mdi-format-align-left', value: 'left', text: 'Balra' },
          { icon: 'mdi-format-align-center', value: 'center', text: 'Középre' },
          { icon: 'mdi-format-align-right', value: 'right', text: 'Jobbra' },
        ],
        textSize: [
          { icon: 'mdi-format-header-1', text: 'Cím 1', value: 'text-h1' },
          { icon: 'mdi-format-header-2', text: 'Cím 2', value: 'text-h2' },
          { icon: 'mdi-format-header-3', text: 'Cím 3', value: 'text-h3' },
          { icon: 'mdi-format-header-4', text: 'Cím 4', value: 'text-h4' },
          { icon: 'mdi-format-header-5', text: 'Cím 5', value: 'text-h5' },
          { icon: 'mdi-format-header-6', text: 'Cím 6', value: 'text-h6' },
          { icon: 'mdi-format-pilcrow', text: 'Normál', value: 'text-subtitle-1' },
        ],
        buttonTextColor: [
          { text: 'Fehér', value: true },
          { text: 'Fekete', value: false },
        ],
        submitDisplay: [
          { value: 1, text: 'Normál' },
          { value: 2, text: 'Körvonalazott' },
          { value: 3, text: 'Szöveg' },
        ],
        submitIsBlock: [
          { value: 1, text: 'Automatikus' },
          { value: 2, text: 'Blokk - 100%' },
        ],
        submitRounded: [
          { value: 1, text: 'Normál' },
          { value: 2, text: 'Lekerekített' },
        ],
        submitSize: [
          { value: 1, text: 'Normál' },
          { value: 2, text: 'Kicsi' },
          { value: 3, text: 'Extra kicsi' },
          { value: 4, text: 'Nagy' },
          { value: 5, text: 'Extra nagy' },
        ],
        backgroundType: [
          { text: 'Színátmenet', value: 'color' },
          { text: 'Kép', value: 'image' },
        ],
        textAndImagePosition: [
          { text: 'Szöveg jobbra - Kép balra', value: 'right' },
          { text: 'Szöveg balra - Kép jobbra', value: 'left' },
        ],
        slideAlign: [
          { text: 'Jobbra', value: 'start' },
          { text: 'Középre', value: 'center' },
          { text: 'Balra', value: 'end' },
        ],
      },
      editorCurrentTab: null,
      editorTabs: [
        { tab: 'basicSetting', name: 'Alapbeállítások', icon: 'mdi-cog' },
        { tab: 'slideSetting', name: 'Slideok szerkesztése', icon: 'mdi-presentation' },
      ],
      mediaGallery: {
        isVisible: false,
        slideIndex: 1,
        imagePos: 'BG', // BG || PIC
      },
      /**
       * type: 1 = háttér top color
       * type: 2 = háttér bottom color
       * type: 3 = title color
       * type: 4 = subtitle color
       * type: 5 = button color
       */
      popupColorPicker: {
        isVisible: false,
        pickerColor: '#000000',
        slideIndex: null,
        type: 1,
        title: 'Háttér felső színének kiválasztása',
      },
      sliderPrototype: {
        backgroundType: 'image',
        backgroundColorBottom: '#00838F',
        backgroundColorTop: '#00ACC1',
        backgroundImage: '',
        buttonAlign: 'left',
        textAlign: 'right',
        slideAlign: 'center',
        showText: true,
        showImage: true,
        imageSrc: '',
        text: {
          translations: [
            {
              code: 'hu',
              name: 'Magyar',
              title: 'Consectetur adipiscing elit',
              subtitle: 'Lorem ipsum',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tortor ante, imperdiet id gravida nec, aliquet a sapien.',
              button: 'Bővebben',
            },
          ],
          title: {
            textColor: '#FFFFFF',
            textAlign: 'left',
            textSize: 'text-h2',
          },
          subtitle: {
            textColor: '#FFFFFF',
            textAlign: 'left',
            textSize: 'text-h5',
          },
          text: {
            textColor: '#FFFFFF',
            textAlign: 'left',
            textSize: 'text-subtitle-1',
          },
        },
        button: {
          isVisible: true,
          isDark: true,
          isExternalLink: false,
          color: '#E91E63',
          url: '',
          display: 1,
          isBlock: 1,
          rounded: 1,
          size: 1,
        },
      }
    };
  },
  methods: {
    editElement() {
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    saveElement() {
      this.$emit('save-element');
    },
    onButtonClick(button) {
      if (button.isExternalLink) {
        // console.log('Külső oldalra visz');
        window.open(`${button.url}`, '_blank');
      } else {
        // console.log('Belső oldalra visz');
        // window.open(`${button.url}`, '_self');
        this.$router.push({ path: button.url });
      }
    },
    addNewSlide() {
      this.elementProps.slides.push(JSON.parse(JSON.stringify(this.sliderPrototype)));
      this.changeTranslations();
    },
    deleteSlide(index) {
      this.elementProps.slides.splice(index, 1);
    },
    openColorPicker(slideIndex, type) {
      if (type === 1) {
        this.popupColorPicker.title = 'Háttér felső színének kiválasztása';
        this.popupColorPicker.pickerColor = this.elementProps.slides[slideIndex].backgroundColorTop;
      } else if (type === 2) {
        this.popupColorPicker.title = 'Háttér alsó színének kiválasztása';
        this.popupColorPicker.pickerColor = this.elementProps.slides[slideIndex].backgroundColorBottom;
      } else if (type === 3) {
        this.popupColorPicker.title = 'Cím színének kiválasztása';
        this.popupColorPicker.pickerColor = this.elementProps.slides[slideIndex].text.title.textColor;
      } else if (type === 4) {
        this.popupColorPicker.title = 'Alcím színének kiválasztása';
        this.popupColorPicker.pickerColor = this.elementProps.slides[slideIndex].text.subtitle.textColor;
      } else if (type === 5) {
        this.popupColorPicker.title = 'Gomb színének kiválasztása';
        this.popupColorPicker.pickerColor = this.elementProps.slides[slideIndex].button.color;
      } else if (type === 6) {
        this.popupColorPicker.title = 'Leírás színének kiválasztása';
        this.popupColorPicker.pickerColor = this.elementProps.slides[slideIndex].text.text.textColor;
      }
      this.popupColorPicker.isVisible = true;
      this.popupColorPicker.slideIndex = slideIndex;
      this.popupColorPicker.type = type;
    },
    onSaveColor() {
      if (this.popupColorPicker.type === 1) {
        this.elementProps.slides[this.popupColorPicker.slideIndex].backgroundColorTop = this.popupColorPicker.pickerColor;
      } else if (this.popupColorPicker.type === 2) {
        this.elementProps.slides[this.popupColorPicker.slideIndex].backgroundColorBottom = this.popupColorPicker.pickerColor;
      } else if (this.popupColorPicker.type === 3) {
        this.elementProps.slides[this.popupColorPicker.slideIndex].text.title.textColor = this.popupColorPicker.pickerColor;
      } else if (this.popupColorPicker.type === 4) {
        this.elementProps.slides[this.popupColorPicker.slideIndex].text.subtitle.textColor = this.popupColorPicker.pickerColor;
      } else if (this.popupColorPicker.type === 5) {
        this.elementProps.slides[this.popupColorPicker.slideIndex].button.color = this.popupColorPicker.pickerColor;
      } else if (this.popupColorPicker.type === 6) {
        this.elementProps.slides[this.popupColorPicker.slideIndex].text.text.textColor = this.popupColorPicker.pickerColor;
      }
      this.popupColorPicker.isVisible = false;
    },
    async changeTranslations() {
      for (let i = 0; i < this.elementProps.slides.length; i++) {
        const index = this.elementProps.slides[i].text.translations.findIndex((item) => item.code === this.$i18n.locale.substr(0, 2));
        this.elementProps.slides[i].currentTranslationIndex = index === -1 ? 0 : index;
      }
    },
    addNewTranslation() {
      this.translationDialog.isVisible = true;
    },
    setLanguage(language, translationindex) {
      // console.log(language);
      this.translationDialog.selectedLanguageIndex = translationindex;
      this.translationDialog.translation.code = language.value;
      this.translationDialog.translation.name = language.hun;
    },
    saveNewTranslation(type) {
      const translation = JSON.parse(JSON.stringify(this.translationDialog.translation));
      for (let i = 0; i < this.elementProps.slides.length; i++) {
        this.elementProps.slides[i].text.translations.push(translation);
      }
      this.translationTab = this.elementProps.slides[0].text.translations.length - 1;
      this.translationDialog.isVisible = false;
    },
    cancelNewTranslation() {
      this.translationDialog.isVisible = false;
      this.setTranslationDialogToDefault();
    },
    deleteTranslation(translationindex) {
      // az összes boxból töröljük az adott nyelvű fordítást
      for (let i = 0; i < this.elementProps.boxes.length; i++) {
        this.elementProps.boxes[i].translations.splice(translationindex, 1);
      }
    },
    setTranslationDialogToDefault() {
      this.translationDialog.selectedLanguageIndex = NaN;
      this.translationDialog.selectedTranslationIndex = NaN;
      this.translationDialog.selectedBoxindex = NaN;
      this.translationDialog.addOrEdit = 'ADD';
      this.translationDialog.translation = {
        title: 'button.addNewTranslation',
        isVisible: false,
        selectedLanguageIndex: NaN,
        selectedTranslationIndex: NaN,
        addOrEdit: 'ADD',
        translation: {
          code: '',
          name: '',
          title: '',
          subtitle: '',
          text: '',
        },
      };
    },
    openMediaGallery(slideIndex, imagePosition) {
      this.mediaGallery.slideIndex = slideIndex;
      this.mediaGallery.imagePos = imagePosition;
      this.mediaGallery.isVisible = true;
    },
    pasteMedia() {
      const image = this.$store.getters.getSelectedImageInMediaGallery;
      if (this.mediaGallery.imagePos === 'BG') {
        this.elementProps.slides[this.mediaGallery.slideIndex].backgroundImage = image.fileFullPath;
      } else if (this.mediaGallery.imagePos === 'PIC') {
        this.elementProps.slides[this.mediaGallery.slideIndex].imageSrc = image.fileFullPath;
      }
      this.mediaGallery.isVisible = false;
      this.$store.commit('clearSelectedImageInMediaGallery');
    },
  },
  watch: {
    '$i18n.locale': async function (value) {
      await this.changeTranslations();
    },
  },
};
</script>

<style scoped>

</style>
