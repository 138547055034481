import Vue from 'vue';

export default Vue.mixin({
  data() {
    return {
      //
    };
  },
  methods: {
    async getAvailablePages() {
      if (this.$store.state.general.availablePages.length < 1) {
        const response = await this.$store.dispatch('showPages');
        if (response.status === 200) {
          const temp = [];
          response.data.forEach((item) => {
            if (!item.isDraft) {
              temp.push(
                {
                  name: `${item.webPageTranslation[0].name}`,
                  url: `/${item.webPageTranslation[0].url}`,
                  group: 'Oldal',
                },
              );
            }
          });
          this.$store.commit('setAllAvailablePages', temp);
        }
      }
      return this.$store.state.general.availablePages;
    },
  },
})
