<template>
  <v-container class="fill-height">
    <v-row class="fill-height" align-content="center" justify="center" align="center">
      <v-progress-circular color="primary"
                           size="84"
                           width="4"
                           indeterminate>
      </v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LoadingLayout',
  components: {
    //
  },
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
