/* eslint-disable no-shadow */

// initial state
import { APIGET, APIPOST, APIPUT } from '@/utils/apiHelper.js';

const state = {
  //
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async createFooter(context, credentials) {
    const response = await APIPOST('footer', credentials, true);
    return response.data;
  },
  async showAllFooter(context, credentials) {
    const response = await APIGET('footer-all');
    return response.data;
  },
  async showFooterById(context, credentials) {
    const response = await APIGET(`footer/${credentials}`);
    return response.data;
  },
  async showDefaultFooter(context, credentials) {
    const response = await APIGET('footer-default');
    return response.data;
  },
  async updateFooter(context, credentials) {
    const response = await APIPUT(`footer/${credentials.id}`, credentials, true);
    return response.data;
  },
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
