<template>
  <div>
    <v-container :class="editorMode ? 'b-wrapper': ''" :fluid="elementProps.isContainerFluid">
      <!-- PAGE BUILDER ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            Card2
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- PAGE BUILDER ACTIONBOX END -->
      <!-- CONTENT START -->
      <v-row class="fill-height" justify="center">
        <template v-for="(box, boxIndex) in moddedboxes.boxes">
          <v-col cols="12" sm="4" :key="boxIndex">
            <v-row align="start" justify="center" class="mx-0 fill-height">
              <v-card width="90%" max-width="340" class="fill-height" flat>
                <v-row class="mx-0 px-3 pt-8 pb-4 fill-height" align="stretch" justify="center">
                  <v-col cols="12">
                    <v-row class="mx-0" justify="center">
                      <v-col cols="auto">
                        <v-icon size="100" :color="`${moddedboxes.mainColor}`">
                          mdi-{{ box.icon.icon }}
                        </v-icon>
                      </v-col>
                      <v-col cols="12" class="text-center pb-0">
                        <span class="text-h6 text-center text-capitalize font-weight-bold" :style="`color: ${moddedboxes.mainColor};`">
                          {{ box.translations[box.currentTranslationIndex].title }}
                        </span>
                      </v-col>
                      <v-col cols="12" class="body-1 text-center">
                        <div v-html="box.translations[box.currentTranslationIndex].text"></div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
          </v-col>
        </template>
        <!--v-col cols="12" class="my-8" v-if="elementProps.button.isVisible">
          <v-row justify="center" align="center">
            <v-btn :color="elementProps.button.color"
                   @click="onMainButtonClick"
                   :dark="elementProps.button.isDark" x-large rounded>
              {{ elementProps.button.text }}
            </v-btn>
          </v-row>
        </v-col-->
      </v-row>
      <!-- CONTENT END -->
    </v-container>
    <!-- EDITOR PANEL START -->
    <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
      <v-card min-height="690">
        <v-toolbar color="primary" height="64" max-height="64" dark flat tile>
          <v-btn icon>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            Card 2 - Szerkesztés
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorPanel = !editorPanel">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="mx-0">
            <v-col cols="12">
              <v-btn color="primary" dark @click="addNewCard" small>
                Új hozzáadása
              </v-btn>
            </v-col>
          </v-row>
          <!-- KÁRTYÁK SZERKESZTÉSE START -->
          <v-row justify="center" class="mb-4 mx-0">
            <v-expansion-panels accordion>
              <v-expansion-panel v-for="(box, boxIndex) in elementProps.boxes" :key="boxIndex">
                <v-expansion-panel-header>
                  {{ boxIndex+1 }}. kártya
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="my-3 mx-0" >
                    <v-col cols="12">
                      <v-text-field v-model="box.icon.icon"
                                    label="Ikon"
                                    filled
                                    dense
                                    append-icon="mdi-pencil"
                                    @click:append="openPopupIcon(boxIndex)">
                      </v-text-field>
                    </v-col>
                    <v-col cols="6" class="text-subtitle-1 text--primary">
                      Szövegek szerkesztése
                    </v-col>
                    <v-col cols="6">
                      <v-row class="mx-0" justify="end">
                        <v-btn color="primary" @click="addNewTranslation(boxIndex)">
                          {{ $t('button.addNewTranslation') }}
                        </v-btn>
                      </v-row>
                    </v-col>
                    <!-- FORDÍTÁS START -->
                    <v-col cols="12">
                      <v-tabs v-model="translationTab"
                              background-color="teal darken-1"
                              dark>
                        <v-tab v-for="(item, index) in box.translations"
                               :key="`lt-${index}`">
                          {{ item.name }}
                        </v-tab>
                        <v-tabs-items v-model="translationTab">
                          <v-tab-item v-for="(item, index) in box.translations"
                                      :key="`lc-${index}`">
                            <v-card outlined tile>
                              <v-card-text>
                                <v-col cols="12">
                                  <div class="text-overline">
                                    {{ $t('labels.title') }}:
                                  </div>
                                  <div class="text-h5">
                                    {{ item.title }}
                                  </div>
                                </v-col>
                                <v-divider></v-divider>
                                <v-col cols="12">
                                  <div class="text-overline">
                                    {{ $t('labels.description') }}:
                                  </div>
                                  <div v-html="item.text"></div>
                                </v-col>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions class="pa-4">
                                <v-spacer></v-spacer>
                                <v-btn v-if="box.translations.length > 1"
                                       color="error"
                                       text
                                       class="mr-4"
                                       @click="deleteTranslation(index, boxIndex)">
                                  {{ $t('button.delete') }}
                                </v-btn>
                                <v-btn color="primary"
                                       @click="editTranslation(item, index, boxIndex)">
                                  {{ $t('button.edit') }}
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-tab-item>
                        </v-tabs-items>
                      </v-tabs>
                    </v-col>
                    <!-- FORDÍTÁS END -->
                    <v-col cols="12">
                      <v-row class="mx-0" justify="end">
                        <v-btn @click="deleteCard(boxIndex)" color="error" dark small>
                          Kártya törlése
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <!-- KÁRTYÁK SZERKESZTÉSE END -->
          <v-divider></v-divider>
          <!-- MAIN COLOR PICKER START -->
          <v-row class="my-4 mx-0">
            <v-col class="text-subtitle-1 text--primary">
              Az ikonok és a címek színe
            </v-col>
            <v-col cols="auto">
              <v-avatar height="52" width="52" tile :color="`${elementProps.mainColor}`" @click="openColorPicker(34, 1)"></v-avatar>
            </v-col>
          </v-row>
          <!-- MAIN COLOR PICKER END -->
          <v-divider></v-divider>
          <!-- SECTION ACTION BUTTON START -->
          <!--v-row class="mx-0">
            <v-col cols="12" class="pb-0">
              <v-row justify="space-between" align="center">
                <span class="text-subtitle-1 text--primary">
                  Gomb láthatósága
                </span>
                <v-switch v-model="elementProps.button.isVisible"
                          inset>
                </v-switch>
              </v-row>
            </v-col>
          </v-row>
          <v-slide-y-transition>
            <v-row v-if="elementProps.button.isVisible">
              <v-col cols="12">
                <v-row class="mx-0">
                  <v-col>
                    Gomb háttérszíne:
                  </v-col>
                  <v-col cols="auto">
                    <v-row justify="end" class="mx-0">
                      <v-avatar height="52" width="52" tile :color="`${elementProps.button.color}`" @click="openColorPicker('BUTTON', 3)"></v-avatar>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-row class="mx-0">
                <v-col cols="8">
                  <v-text-field v-model="elementProps.button.text"
                                label="Gomb felirata"
                                filled
                                dense
                                maxlength="32"
                                counter="32">
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select v-model="elementProps.button.isDark"
                            label="Gomb felirat színe"
                            :items="selectFieldOptions.buttonTextColor"
                            filled
                            dense>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="mx-0">
                <v-col cols="12">
                  <v-select v-model="elementProps.button.isExternalLink"
                            label="Gomb hivatkozás típusa"
                            :items="selectFieldOptions.buttonIsExternalLink"
                            filled
                            dense>
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="elementProps.button.url"
                                v-if="elementProps.button.isExternalLink"
                                label="Gomb hivatkozása"
                                placeholder="https://www.google.com"
                                filled
                                dense>
                  </v-text-field>
                  <v-autocomplete v-model="elementProps.button.url"
                                  label="Gomb hivatkozása"
                                  :items="$store.state.general.availablePages"
                                  item-text="name"
                                  item-value="url"
                                  filled
                                  dense>
                    <template v-slot:selection="data">
                      {{ data.item.name }} - {{ data.item.group }}
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ data.item.group }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-row>
          </v-slide-y-transition-->
          <!-- SECTION ACTION BUTTON END -->
          <v-divider></v-divider>
          <v-row class="mx-0">
            <v-col cols="12" class="pb-0">
              <v-row justify="space-between" align="center" class="mx-0">
                      <span class="text-subtitle-1 text--primary">
                        A befoglaló container 100% szélességű legyen?
                      </span>
                <v-switch v-model="elementProps.isContainerFluid"
                          inset>
                </v-switch>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="saveElementProps">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDITOR PANEL END -->
    <!-- COLOR PICKER POPUP START -->
    <v-dialog v-model="popupColorPicker.isVisible" max-width="340">
      <v-card>
        <v-card-title class="indigo darken-2">
          <span class="white--text">
            {{ popupColorPicker.title }}
          </span>
        </v-card-title>
        <v-card-text class="pa-5">
          <v-color-picker v-model="popupColorPicker.pickerColor"
                          mode="hexa"
                          show-swatches>
          </v-color-picker>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="popupColorPicker.isVisible = !popupColorPicker.isVisible" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="onSaveColor">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- COLOR PICKER POPUP END -->
    <!-- ICON SELECTOR POPUP START -->
    <!--<v-dialog v-model="popupIcon.isVisible" scrollable fullscreen>
      <md-icons-all-card :icon-name-to-parent.sync="popupIcon.iconName"
                         v-on:cancel-click="onCancelPopupIcon"
                         v-on:save-click="onSavePopupIcon(popupIcon.cardIndex)"></md-icons-all-card>
    </v-dialog>-->
    <!-- ICON SELECTOR POPUP END -->
    <!-- FORDÍTÁS DIALOG START -->
    <translation-editor-dialog :content="translationDialog"
                               :visible-elements="visibleElements"
                               v-on:@cancel="cancelNewTranslation"
                               v-on:@save="saveNewTranslation" />
    <!-- FORDÍTÁS DIALOG END -->
  </div>
</template>

<script>
// import mdIconsAllCard from '../shared/mdIconsAllCard.vue';
import TranslationEditorDialog from '@/components/shared/TranslationEditorDialog.vue';

export default {
  name: 'Card2',
  components: {
    TranslationEditorDialog,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          isContainerFluid: false,
          mainColor: '#1565C0',
          boxes: [
            {
              icon: {
                icon: 'emoticon-cool-outline',
              },
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  title: 'Card title',
                  text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                },
              ],
            },
            {
              icon: {
                icon: 'emoticon-cool-outline',
              },
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  title: 'Card title',
                  text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                },
              ],
            },
            {
              icon: {
                icon: 'emoticon-cool-outline',
              },
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  title: 'Card title',
                  text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                },
              ],
            },
          ],
          button: {
            isVisible: true,
            isDark: true,
            isExternalLink: false,
            color: 'red',
            text: 'Bővebben',
            url: '',
          },
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    this.moddedboxes = await this.cloneElementProps();
    await this.changeTranslations();
  },
  data() {
    return {
      editorPanel: false,
      popupIcon: {
        isVisible: false,
        iconName: '',
        boxIndex: null,
      },
      /**
       * type: 1 = a kártya ikonjának színe
       * type: 2 = a kártya gombjának a színe
       * type: 3 = a builder element gombjának a színe
       */
      popupColorPicker: {
        isVisible: false,
        pickerColor: '#000000',
        boxIndex: null,
        type: 1,
        title: 'Ikon színének kiválasztása',
      },
      selectFieldOptions: {
        buttonTextColor: [
          { text: 'Fehér', value: true },
          { text: 'Fekete', value: false },
        ],
        buttonIsExternalLink: [
          { text: 'Belső hivatkozás', value: false },
          { text: 'Külső hivatkozás', value: true },
        ],
        buttonUrls: this.$store.state.general.availablePages,
        /* buttonUrls: [
          { text: '/kezdőlap', value: '/kezdőlap' },
          { text: '/page-builder', value: '/page-builder' },
          { text: '/bemutatkozas', value: '/bemutatkozas' },
        ], */
      },
      moddedboxes: [],
      translationTab: 0,
      expansionPanel: null,
      visibleElements: ['title', 'text1'],
    };
  },
  methods: {
    editElement() {
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    saveElement() {
      this.$emit('save-element');
    },
    async saveElementProps() {
      this.moddedboxes = await this.cloneElementProps();
      await this.changeTranslations();
      this.translationTab = 0;
      this.expansionPanel = null;
      this.editorPanel = false;
    },
    async cloneElementProps() {
      // console.log('cloneElementProps');
      return JSON.parse(JSON.stringify(this.elementProps));
    },
    addNewCard() {
      this.elementProps.boxes.push(JSON.parse(JSON.stringify(this.elementProps.boxes[0])));
    },
    deleteCard(index) {
      this.elementProps.boxes.splice(index, 1);
    },
    openPopupIcon(cardIndex) {
      /* this.popupIcon.isVisible = true;
      this.popupIcon.cardIndex = cardIndex; */
      window.open('http://materialdesignicons.com/', '_blank');
    },
    onCardButtonClick(button) {
      if (button.isExternalLink) {
        // console.log('Külső oldalra visz');
        window.open(`${button.url}`, '_blank');
      } else {
        // console.log('Belső oldalra visz');
        // this.$router.push(`${button.url}`);
        window.open(`${button.url}`, '_self');
      }
    },
    onMainButtonClick() {
      if (this.elementProps.button.isExternalLink) {
        // console.log('Külső oldalra visz');
        window.open(`${this.elementProps.button.url}`, '_blank');
      } else {
        // console.log('Belső oldalra visz');
        this.$router.push({ path: this.elementProps.button.url });
        // window.open(`${this.elementProps.button.url}`, '_self');
      }
    },
    onCancelPopupIcon() {
      this.popupIcon = {
        isVisible: false,
        iconName: '',
        boxIndex: null,
      };
    },
    onSavePopupIcon(boxIndex) {
      this.popupIcon.isVisible = false;
      this.elementProps.boxes[boxIndex].icon = this.popupIcon.iconName;
    },
    openColorPicker(boxIndex, type) {
      if (type === 1) {
        this.popupColorPicker.title = 'Ikon és cím színének kiválasztása';
        this.popupColorPicker.pickerColor = this.elementProps.mainColor;
      } else if (type === 3) {
        this.popupColorPicker.title = 'Gomb színének kiválasztása';
        this.popupColorPicker.pickerColor = this.elementProps.button.color;
      }
      this.popupColorPicker.isVisible = true;
      this.popupColorPicker.boxIndex = boxIndex;
      this.popupColorPicker.type = type;
    },
    onSaveColor() {
      if (this.popupColorPicker.type === 1) {
        this.elementProps.mainColor = this.popupColorPicker.pickerColor;
      } else if (this.popupColorPicker.type === 3) {
        this.elementProps.button.color = this.popupColorPicker.pickerColor;
      }
      this.popupColorPicker.isVisible = false;
    },
    async changeTranslations() {
      // console.log('changeTranslation');
      for (let i = 0; i < this.moddedboxes.boxes.length; i++) {
        const index = this.moddedboxes.boxes[i].translations.findIndex((item) => item.code === this.$i18n.locale.substr(0, 2));
        this.moddedboxes.boxes[i].currentTranslationIndex = index === -1 ? 0 : index;
      }
    },
  },
};
</script>

<style scoped>

</style>
