<template>
  <v-container fluid>
    <v-container :fluid="layoutProps.isFluid">
      <v-row>
        <v-col cols="12"
               md="3"
               order="2"
               order-md="1"
               style="max-width: 320px;">
          sidebar
        </v-col>
        <v-col cols="12"
               md="auto"
               order="1"
               order-md="2">
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'LeftSidebarLayout',
  components: {
    //
  },
  props: {
    layoutProps: {
      type: Object,
      default() {
        return {
          isFluid: false,
        };
      },
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
