/* eslint-disable no-shadow */
import { APIGET } from '@/utils/apiHelper';
import router from '@/router/router';

// initial state
const state = {
  //
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async GetAvailableRoutes(context, credentials) {
    const response = await APIGET('available-routes');
    if (Array.isArray(response.data.data) && response.data.data.length > 0) {
      for (let i = 0; i < response.data.data.length; i++) {
        const route = {
          path: response.data.data[i].path,
          name: response.data.data[i].name,
          meta: { layout: response.data.data[i].layout },
          component: () => import(/* webpackChunkName: "about" */ '../views/' + response.data.data[i].component + '.vue'),
        };
        router.addRoute(route);
      }
    }
    return response.status;
  },
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
