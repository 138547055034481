<template>
  <div>
    <!-- CONTENT START -->
    <v-container :class="editorMode ? 'b-wrapper': ''" :fluid="elementProps.isContainerFluid">
      <!-- EDITOR ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            Promobox
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- EDITOR ACTIONBOX END -->
      <!-- CONTENT START -->
      <v-row>
        <template v-for="(box, index) in moddedboxes.boxes">
          <v-col cols="12" sm="6" :key="index">
            <v-row class="mb-6 text-h2" :justify="box.justify.title" :class="`text-${box.justify.title}`">
              {{ box.translations[box.currentTranslationIndex].title }}
            </v-row>
            <v-row class="mb-10 px-6">
              <div :class="`text-${box.justify.title}`"
                   class="text-subtitle-1 d-flex"
                   v-html="box.translations[box.currentTranslationIndex].text">
              </div>
            </v-row>
            <v-row :justify="box.justify.button" class="pt-3 px-6">
              <template v-if="box.button.isVisible">
                <v-btn :color="box.button.color"
                       :dark="box.button.isDark"
                       @click="onButtonClick(box.button.url, box.button.isExternalLink)">
                  {{ box.translations[box.currentTranslationIndex].buttonText }}
                </v-btn>
              </template>
            </v-row>
          </v-col>
        </template>
      </v-row>
      <!-- CONTENT END -->
    </v-container>
    <!-- CONTENT END -->
    <!-- EDITOR PANEL START -->
    <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
      <v-card>
        <v-toolbar color="primary darken-1" dark flat tile>
          <v-btn icon>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            Promobox - Szerkesztés
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorPanel = !editorPanel">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-3">
          <v-row>
            <v-btn color="primary" @click="addNewBox">
              {{ $t('button.addNew') }}
            </v-btn>
          </v-row>
          <v-row>
            <v-tabs v-model="boxtab"
                    background-color="white"
                    vertical
                    slider-color="primary">
              <v-tab v-for="(box, boxindex) in elementProps.boxes"
                     :key="`bt-${boxindex}`">
                {{ boxindex + 1 }}. box
              </v-tab>
              <v-tabs-items v-model="boxtab">
                <v-tab-item v-for="(box, boxindex) in elementProps.boxes"
                            :key="`bc-${boxindex}`">
                  <v-card outlined tile>
                    <v-card-text>
                      <v-row>
                        <v-col cols="6" class="text-subtitle-1 text--primary">
                          Szövegek szerkesztése
                        </v-col>
                        <v-col cols="6">
                          <v-row class="mx-0" justify="end">
                            <v-btn color="primary" @click="addNewTranslation(boxindex)">
                              {{ $t('button.addNewTranslation') }}
                            </v-btn>
                          </v-row>
                        </v-col>
                        <!-- FORDÍTÁS START -->
                        <v-col cols="12">
                          <v-tabs v-model="translationTab"
                                  background-color="teal darken-1"
                                  dark>
                            <v-tab v-for="(item, index) in box.translations"
                                   :key="`lt-${index}`">
                              {{ item.name }}
                            </v-tab>
                            <v-tabs-items v-model="translationTab">
                              <v-tab-item v-for="(item, index) in box.translations"
                                          :key="`lc-${index}`">
                                <v-card outlined tile>
                                  <v-card-text class="pa-0">
                                    <v-col cols="12">
                                      <div class="text-overline">
                                        {{ $t('labels.title') }}:
                                      </div>
                                      <div class="text-h5">
                                        {{ item.title }}
                                      </div>
                                    </v-col>
                                    <v-divider></v-divider>
                                    <v-col cols="12">
                                      <div class="text-overline">
                                        {{ $t('labels.description') }}:
                                      </div>
                                      <div v-html="item.text"></div>
                                    </v-col>
                                    <v-divider></v-divider>
                                    <v-col cols="12">
                                      <div class="text-overline">
                                        {{ $t('labels.buttonText') }}:
                                      </div>
                                      <div>
                                        {{ item.buttonText }}
                                      </div>
                                    </v-col>
                                  </v-card-text>
                                  <v-divider></v-divider>
                                  <v-card-actions class="pa-4">
                                    <v-spacer></v-spacer>
                                    <v-btn v-if="box.translations.length > 1"
                                           color="error"
                                           text
                                           class="mr-4"
                                           @click="deleteTranslation(index, boxindex)">
                                      {{ $t('button.delete') }}
                                    </v-btn>
                                    <v-btn color="primary"
                                           @click="editTranslation(item, index, boxindex)">
                                      {{ $t('button.edit') }}
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-tab-item>
                            </v-tabs-items>
                          </v-tabs>
                        </v-col>
                        <!-- FORDÍTÁS END -->
                        <!-- SECTION ACTION BUTTON START -->
                        <v-col cols="12" class="pa-0">
                          <v-row>
                            <v-col cols="12">
                              <v-row justify="space-between" align="center" class="mx-0">
                                <span class="text-subtitle-1 text--primary">
                                  Gomb láthatósága
                                </span>
                                <v-switch v-model="box.button.isVisible"
                                          inset>
                                </v-switch>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-slide-y-transition>
                            <v-row v-if="box.button.isVisible" class="mt-0">
                              <v-col cols="12">
                                <v-row>
                                  <v-col class="pa-0 text-overline">
                                    Gomb háttérszíne:
                                  </v-col>
                                  <v-col cols="auto" class="pa-0">
                                    <v-row justify="end" class="mx-0">
                                      <v-avatar height="52"
                                                width="52"
                                                tile
                                                color="primary"
                                                class="mx-1 pageBuilder-color-picker-activator"
                                                style="border: 1px solid rgba(0,0,0,0.2);"
                                                @click="setButtonColorToPredefined(boxindex, 'primary')">
                                        <v-icon color="warning"
                                                v-if="box.button.color === 'primary'">
                                          mdi-check-circle
                                        </v-icon>
                                      </v-avatar>
                                      <v-avatar height="52"
                                                width="52"
                                                tile
                                                color="secondary"
                                                class="mx-1 pageBuilder-color-picker-activator"
                                                style="border: 1px solid rgba(0,0,0,0.2);"
                                                @click="setButtonColorToPredefined(boxindex, 'secondary')">
                                        <v-icon color="warning"
                                                v-if="box.button.color === 'secondary'">
                                          mdi-check-circle
                                        </v-icon>
                                      </v-avatar>
                                      <v-avatar height="52"
                                                width="52"
                                                tile
                                                class="ml-1 pageBuilder-color-picker-activator"
                                                :class="box.button.color === 'primary' || box.button.color === 'secondary' ? 'pageBuilder-color-picker-activator-custom' : ''"
                                                style="border: 1px solid rgba(0,0,0,1);"
                                                :color="box.button.color === 'primary' || box.button.color === 'secondary' ? 'grey' : `${box.button.color}`"
                                                @click="openColorPicker(boxindex)">
                                        <v-icon color="warning"
                                                v-if="box.button.color !== 'primary' && box.button.color !== 'secondary'">
                                          mdi-check-circle
                                        </v-icon>
                                      </v-avatar>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-row class="mx-0">
                                <v-col cols="12" sm="6">
                                  <v-select v-model="box.button.isDark"
                                            label="Gomb felirat színe"
                                            :items="selectFieldOptions.buttonTextColor"
                                            filled
                                            dense>
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-select v-model="box.button.isExternalLink"
                                            label="Gomb hivatkozás típusa"
                                            :items="selectFieldOptions.buttonIsExternalLink"
                                            filled
                                            dense>
                                  </v-select>
                                </v-col>
                                <v-col cols="12">
                                  <template v-if="box.button.isExternalLink">
                                    <v-text-field v-model="box.button.url"
                                                  label="Gomb hivatkozása"
                                                  placeholder="https://www.google.com"
                                                  filled
                                                  dense>
                                    </v-text-field>
                                  </template>
                                  <template v-else>
                                    <v-autocomplete v-model="box.button.url"
                                                    label="Gomb hivatkozása"
                                                    :items="availablePages"
                                                    item-text="name"
                                                    item-value="url"
                                                    clearable
                                                    filled
                                                    dense>
                                      <template v-slot:selection="data">
                                        {{ data.item.name }} - {{ data.item.group }}
                                      </template>
                                      <template v-slot:item="data">
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            {{ data.item.name }}
                                          </v-list-item-title>
                                          <v-list-item-subtitle>
                                            {{ data.item.group }}
                                          </v-list-item-subtitle>
                                        </v-list-item-content>
                                      </template>
                                    </v-autocomplete>
                                  </template>
                                </v-col>
                              </v-row>
                            </v-row>
                          </v-slide-y-transition>
                        </v-col>
                        <!-- SECTION ACTION BUTTON END -->
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="saveElementProps">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDITOR PANEL END -->
    <!-- FORDÍTÁS DIALOG START -->
    <!-- FORDÍTÁS DIALOG START -->
    <!-- COLOR PICKER POPUP START -->
    <v-dialog v-model="popupColorPicker.isVisible" max-width="340">
      <v-card>
        <v-card-title class="indigo darken-2">
          <span class="white--text">
            {{ popupColorPicker.title }}
          </span>
        </v-card-title>
        <v-card-text class="pa-5">
          <v-color-picker v-model="popupColorPicker.pickerColor"
                          mode="hexa"
                          show-swatches>
          </v-color-picker>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="popupColorPicker.isVisible = !popupColorPicker.isVisible" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="onSaveColor(popupColorPicker.boxIndex)">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- COLOR PICKER POPUP END -->
    <!-- FORDÍTÁS DIALOG START -->
    <translation-editor-dialog :content="translationDialog"
                               :visible-elements="visibleElements"
                               v-on:@cancel="cancelNewTranslation"
                               v-on:@save="saveNewTranslation" />
  </div>
</template>

<script>
import TranslationEditorDialog from '@/components/shared/TranslationEditorDialog.vue';

export default {
  name: 'PromoBox',
  components: {
    TranslationEditorDialog,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          isContainerFluid: false,
          boxes: [
            {
              justify: {
                title: 'center',
                text: 'center',
                button: 'center',
              },
              button: {
                isVisible: true,
                isDark: true,
                isExternalLink: false,
                color: 'primary',
                url: '',
              },
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  title: 'Több mint 350 munkatárs',
                  text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tortor ante, imperdiet id gravida nec, aliquet a sapien.</p>',
                  buttonText: 'Tovább',
                },
              ],
            },
            {
              justify: {
                title: 'center',
                text: 'center',
                button: 'center',
              },
              button: {
                isVisible: true,
                isDark: true,
                isExternalLink: false,
                color: 'primary',
                url: '',
              },
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  title: 'Több mint 350 munkatárs',
                  text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tortor ante, imperdiet id gravida nec, aliquet a sapien.</p>',
                  buttonText: 'Tovább',
                },
              ],
            },
          ],
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    this.moddedboxes = await this.cloneElementProps();
    await this.changeTranslations();
    this.availablePages = await this.getAvailablePages();
  },
  data() {
    return {
      editorPanel: false,
      translationTab: 0,
      visibleElements: ['title', 'button', 'text1'],
      mediaGallery: {
        isVisible: false,
      },
      moddedboxes: [],
      boxtab: 0,
      selectFieldOptions: {
        buttonTextColor: [
          { text: 'Fehér', value: true },
          { text: 'Fekete', value: false },
        ],
        buttonIsExternalLink: [
          { text: 'Belső hivatkozás', value: false },
          { text: 'Külső hivatkozás', value: true },
        ],
        buttonUrls: this.$store.state.general.availablePages,
      },
      popupColorPicker: {
        isVisible: false,
        pickerColor: '#000000',
        boxIndex: null,
        type: 1,
        title: 'Gomb színének kiválasztása',
      },
      availablePages: [],
      boxPrototype: {
        justify: {
          title: 'center',
          text: 'center',
          button: 'center',
        },
        button: {
          isVisible: true,
          isDark: true,
          isExternalLink: false,
          color: 'primary',
          url: '',
        },
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Több mint 350 munkatárs',
            text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tortor ante, imperdiet id gravida nec, aliquet a sapien.</p>',
            buttonText: 'Tovább',
          },
        ],
      },
    };
  },
  methods: {
    editElement() {
      this.textEditorContent = this.translationDialog.translation.text;
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    saveElement() {
      this.$emit('save-element');
    },
    async saveElementProps() {
      this.moddedboxes = await this.cloneElementProps();
      await this.changeTranslations();
      this.translationTab = 0;
      this.expansionPanel = null;
      this.editorPanel = false;
    },
    openMediaGallery(index) {
      this.mediaGallery.imageIndex = index;
      this.mediaGallery.isVisible = true;
    },
    pasteMedia() {
      const image = this.$store.getters.getSelectedImageInMediaGallery;
      this.elementProps.images[this.mediaGallery.imageIndex] = image.fileFullPath;
      this.mediaGallery.isVisible = false;
      this.$store.commit('clearSelectedImageInMediaGallery');
    },
    addNewBox() {
      if (this.elementProps.boxes[0].translations.length > 1) {
        this.boxPrototype.translations = JSON.parse(JSON.stringify(this.elementProps.boxes[0].translations));
      }
      this.elementProps.boxes.push(JSON.parse(JSON.stringify(this.boxPrototype)));
    },
    async changeTranslations() {
      // console.log('changeTranslation');
      for (let i = 0; i < this.moddedboxes.boxes.length; i++) {
        const index = this.moddedboxes.boxes[i].translations.findIndex((item) => item.code === this.$i18n.locale.substr(0, 2));
        this.moddedboxes.boxes[i].currentTranslationIndex = index === -1 ? 0 : index;
      }
    },
    onButtonClick(url, external) {
      if (external) {
        window.open(`${url}`, '_blank');
      } else {
        this.$router.push({ path: `${url}` });
      }
    },
    async cloneElementProps() {
      // console.log('cloneElementProps');
      return JSON.parse(JSON.stringify(this.elementProps));
    },
    openColorPicker(boxindex) {
      this.popupColorPicker.pickerColor = this.elementProps.boxes[boxindex].button.color;
      this.popupColorPicker.isVisible = true;
      this.popupColorPicker.boxIndex = boxindex;
    },
    onSaveColor(boxindex) {
      this.elementProps.boxes[boxindex].button.color = this.popupColorPicker.pickerColor;
      this.popupColorPicker.isVisible = false;
    },
    setButtonColorToPredefined(boxindex, color) {
      this.elementProps.boxes[boxindex].button.color = color;
    },
  },
};
</script>

<style scoped>

</style>
