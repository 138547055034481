<template>
  <div>
    <v-container :class="editorMode ? 'b-wrapper py-10': 'py-12'" fluid
                 :style="elementProps.backgroundType === 'color' ?
                 `background: linear-gradient(${elementProps.backgroundColorTop},${elementProps.backgroundColorBottom})` :
                 `background-image: url(${publicPath}public_storage${elementProps.images[0]});
                 background-size: cover; background-position: ${elementProps.backgroundPosition};`">
      <!-- PAGE BUILDER ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            Header Type 1
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- PAGE BUILDER ACTIONBOX END -->
      <!-- CONTENT START -->
      <v-container class="py-12">
        <v-row justify="center" align="center" :class="`${elementProps.subtitleFont} mb-3`">
          <span :style="`color: ${elementProps.textColor};`">
            {{ translatedSubtitle }}
          </span>
        </v-row>
        <v-row justify="center" align="center" :class="elementProps.titleFont">
          <span :style="`color: ${elementProps.textColor};`">
            {{ translatedTitle }}
          </span>
        </v-row>
      </v-container>
      <!-- CONTENT END -->
    </v-container>
    <!-- EDITOR PANEL START -->
    <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
      <v-card min-height="690">
        <v-toolbar color="primary" height="64" max-height="64" dark flat tile>
          <v-btn icon>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            Header Type 1 - Szerkesztés
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorPanel = !editorPanel">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="my-4 mx-0">
            <v-col cols="6" class="text-subtitle-1 text--primary">
              Szövegek szerkesztése
            </v-col>
            <v-col cols="6">
              <v-row class="mx-0" justify="end">
                <v-btn color="primary" @click="addNewTranslation('OFF')">
                  {{ $t('button.addNewTranslation') }}
                </v-btn>
              </v-row>
            </v-col>
            <!-- FORDÍTÁS START -->
            <v-col cols="12">
              <v-tabs v-model="translationTab"
                      background-color="teal darken-1"
                      dark>
                <v-tab v-for="(item, index) in elementProps.translations"
                       :key="`lt-${index}`">
                  {{ item.name }}
                </v-tab>
                <v-tabs-items v-model="translationTab">
                  <v-tab-item v-for="(item, index) in elementProps.translations"
                              :key="`lc-${index}`">
                    <v-card outlined tile>
                      <v-card-text>
                        <v-col cols="12">
                          <div :class="`${elementProps.subtitleFont} mb-3`">
                            {{ item.subtitle }}
                          </div>
                          <div :class="`${elementProps.titleFont} mb-3`">
                            {{ item.title }}
                          </div>
                        </v-col>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="pa-4">
                        <v-spacer></v-spacer>
                        <v-btn v-if="elementProps.translations.length > 1"
                               color="error"
                               text
                               class="mr-4"
                               @click="deleteTranslation(index, 'OFF')">
                          {{ $t('button.delete') }}
                        </v-btn>
                        <v-btn color="primary"
                               @click="editTranslation(item, index)">
                          {{ $t('button.edit') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-col>
            <v-col cols="6">
              <v-select v-model="elementProps.subtitleFont"
                        :items="selectFieldOptions.sizes"
                        :label="$t('labels.textSize')"
                        filled
                        dense>
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-select v-model="elementProps.titleFont"
                        :items="selectFieldOptions.sizes"
                        :label="$t('labels.textSize')"
                        filled
                        dense>
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-row class="mx-0" align="start" justify="space-between">
                Szövegek színe:
                <v-avatar height="52" width="52" tile
                          :color="`${elementProps.textColor}`"
                          @click="openColorPicker(3)" style="border: 1px solid rgba(0,0,0,0.2);"></v-avatar>
              </v-row>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="my-4 mx-0">
            <v-col cols="6" class="text-subtitle-1 text--primary align-self-center">
              Háttér típusa
            </v-col>
            <v-col cols="6">
              <v-radio-group v-model="elementProps.backgroundType"
                             mandatory
                             row>
                <v-radio :label="$t('labels.color')"
                         value="color">
                </v-radio>
                <v-radio :label="$t('labels.image')"
                         value="image">
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="my-4 mx-0" v-if="elementProps.backgroundType === 'color'">
            <v-col cols="12" class="text-subtitle-1 text--primary">
              Háttér színének szerkesztése
            </v-col>
            <v-col cols="6">
              <v-row class="mx-0" align="start" justify="space-between">
                Háttér színe - Felső:
                <v-avatar height="52" width="52" tile
                          :color="`${elementProps.backgroundColorTop}`"
                          @click="openColorPicker(1)" style="border: 1px solid rgba(0,0,0,0.2);"></v-avatar>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row class="mx-0" align="start" justify="space-between">
                Háttér színe - Alsó:
                <v-avatar height="52" width="52" tile
                          :color="`${elementProps.backgroundColorBottom}`"
                          @click="openColorPicker(2)" style="border: 1px solid rgba(0,0,0,0.2);"></v-avatar>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="my-4 mx-0" v-if="elementProps.backgroundType === 'image'">
            <v-col col="12">
              <v-row class="mx-n3">
                <v-col cols="12" class="text-subtitle-1 text--primary">
                  Háttérkép szerkesztése <span class="caption">(kattintson a képre a kép cseréjéhez)</span>
                </v-col>
                <v-col cols="12" class="text-subtitle-1 text--primary">
                  <v-row class="mx-n3">
                    <v-col cols="12">
                      <v-img :src="elementProps.images ? `${publicPath}public_storage${elementProps.images[0]}` : `${publicPath}images/dummy/city.jpg`"
                             width="100%"
                             height="200px"
                             class="image-changeable"
                             @click="openMediaGallery(0)"
                             max-width="800px"></v-img>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="text-subtitle-1 text--primary align-self-center">
                  Háttérkép igazítása
                </v-col>
                <v-col cols="6">
                  <v-radio-group v-model="elementProps.backgroundPosition"
                                 mandatory
                                 row>
                    <v-radio :label="$t('labels.toStart')"
                             value="top"></v-radio>
                    <v-radio :label="$t('labels.toCenter')"
                             value="center"></v-radio>
                    <v-radio :label="$t('labels.toBottom')"
                             value="bottom"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="saveElementProps">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDITOR PANEL END -->
    <!-- COLOR PICKER POPUP START -->
    <v-dialog v-model="popupColorPicker.isVisible" max-width="340">
      <v-card>
        <v-card-title class="indigo darken-2">
          <span class="white--text">
            {{ popupColorPicker.title }}
          </span>
        </v-card-title>
        <v-card-text class="pa-5">
          <v-color-picker v-model="popupColorPicker.pickerColor"
                          mode="hexa"
                          show-swatches>
          </v-color-picker>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="popupColorPicker.isVisible = !popupColorPicker.isVisible" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="onSaveColor">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- COLOR PICKER POPUP END -->
    <!-- MEDIA GALLERY START -->
    <template v-if="editorMode">
      <v-dialog v-model="mediaGallery.isVisible"
                persistent
                fullscreen
                transition="dialog-bottom-transition"
                scrollable>
        <v-card tile>
          <media-page :in-dialog="true"
                      v-on:paste-media="pasteMedia"
                      v-on:close-media-dialog="mediaGallery.isVisible = false"></media-page>
        </v-card>
      </v-dialog>
    </template>
    <!-- MEDIA GALLERY END -->
    <!-- FORDÍTÁS DIALOG START -->
    <translation-editor-dialog :content="translationDialog"
                               :visible-elements="visibleElements"
                               v-on:@cancel="cancelNewTranslation"
                               v-on:@save="saveNewTranslation" />
    <!-- FORDÍTÁS DIALOG START -->
  </div>
</template>

<script>
import MediaPage from '@/views/admin/MediaPage.vue';
import TranslationEditorDialog from '@/components/shared/TranslationEditorDialog.vue';

export default {
  name: 'HeaderType1',
  components: {
    MediaPage, TranslationEditorDialog,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          titleFont: 'text-h3',
          subtitleFont: 'text-subtitle-1',
          textColor: '#FFFFFF',
          backgroundColorTop: '#42A5F5',
          backgroundColorBottom: '#1976D2',
          images: [],
          backgroundType: 'color',
          backgroundPosition: 'center',
          translations: [
            {
              code: 'hu',
              name: 'Magyar',
              title: 'Szolgáltatásaink',
              subtitle: 'Lorem ipsum',
            },
          ],
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    this.changeTranslations();
  },
  data() {
    return {
      editorPanel: false,
      /**
       * type: 1 = container hattérszíne felső
       * type: 2 = container hattérszíne alsó
       * type: 3 = szöveg színe
       */
      popupColorPicker: {
        isVisible: false,
        pickerColor: '#000000',
        type: 1,
        title: 'Szöveg színének kiválasztása',
      },
      selectFieldOptions: {
        sizes: [
          { text: 'Cím', value: 'text-h3' },
          { text: 'Alcím', value: 'text-subtitle-1' },
        ],
        backgroundType: [
          { text: 'Szín', value: 'color' },
          { text: 'Kép', value: 'image' },
        ],
      },
      mediaGallery: {
        isVisible: false,
      },
      translationTab: 0,
      translatedTitle: '',
      translatedSubtitle: '',
      visibleElements: ['title', 'subtitle'],
    };
  },
  methods: {
    editElement() {
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    saveElement() {
      this.$emit('save-element');
    },
    saveElementProps() {
      this.translationTab = 0;
      this.changeTranslations();
      this.editorPanel = false;
    },
    changeTranslations() {
      const index = this.elementProps.translations.findIndex((item) => item.code === this.$i18n.locale.substr(0, 2));
      const currentLanguageIndex = index === -1 ? 0 : index;
      this.translatedTitle = this.elementProps.translations[currentLanguageIndex].title;
      this.translatedSubtitle = this.elementProps.translations[currentLanguageIndex].subtitle;
    },
    openColorPicker(type) {
      this.popupColorPicker.type = type;
      if (type === 1) {
        this.popupColorPicker.title = 'Háttér színátmenet - Felső szín kiválasztása';
      } else if (type === 2) {
        this.popupColorPicker.title = 'Háttér színátmenet - Alsó szín kiválasztása';
      } else {
        this.popupColorPicker.title = 'Szöveg színének kiválasztása';
      }
      this.popupColorPicker.isVisible = true;
    },
    onSaveColor() {
      if (this.popupColorPicker.type === 1) {
        this.elementProps.backgroundColorTop = this.popupColorPicker.pickerColor;
      } else if (this.popupColorPicker.type === 2) {
        this.elementProps.backgroundColorBottom = this.popupColorPicker.pickerColor;
      } else {
        this.elementProps.textColor = this.popupColorPicker.pickerColor;
      }
      this.popupColorPicker.isVisible = false;
    },
    openMediaGallery(index) {
      this.mediaGallery.imageIndex = index;
      this.mediaGallery.isVisible = true;
    },
    pasteMedia() {
      const image = this.$store.getters.getSelectedImageInMediaGallery;
      if (this.elementProps.images) {
        this.elementProps.images[this.mediaGallery.imageIndex] = image.fileFullPath;
      } else {
        this.elementProps.images = [image.fileFullPath];
      }
      this.mediaGallery.isVisible = false;
      this.$store.commit('clearSelectedImageInMediaGallery');
    },
  },
};
</script>

<style scoped>

</style>
