<template>
  <v-container class="pa-0"
               fluid
               :style="`background-image: url(${publicPath}images/dummy/header-bg-05.jpg);
                 background-size: cover; background-position: center center;`">
    <v-container class="py-15">
      <v-row class="py-10">
        <v-col cols="12" class="text-center white--text mb-4">
          <div class="text-h2 mb-3">
            Nyomonkövetés
          </div>
          <div class="text-subtitle-1">

          </div>
        </v-col>
        <v-col cols="12">
          <v-row justify="center">
            <v-card width="100%" max-width="360px" elevation="0" color="transparent">
              <v-card-text class="pa-2">
                <v-form v-model="trackingLoginFormIsValid">
                  <v-text-field v-model="trackingLoginForm.email"
                                :label="$t('labels.email')"
                                :rules="inputRules.emailRules"
                                :hint="$t('hints.requiredField')"
                                persistent-hint
                                background-color="white"
                                type="email"
                                filled>
                  </v-text-field>
                  <v-text-field v-model="trackingLoginForm.identifier"
                                :label="$t('labels.serialNumber')"
                                :rules="inputRules.required"
                                :hint="$t('hints.requiredField')"
                                counter="22"
                                maxlength="22"
                                persistent-hint
                                background-color="white"
                                filled>
                  </v-text-field>
                  <v-text-field v-model="trackingLoginForm.crmRequestId"
                                :label="$t('labels.serviceRequestId')"
                                :rules="inputRules.required"
                                :hint="$t('hints.requiredField')"
                                persistent-hint
                                background-color="white"
                                filled>
                  </v-text-field>
                  <v-btn color="primary"
                         :disabled="!trackingLoginFormIsValid"
                         @click="trackingLogin"
                         block>
                    {{ $t('button.login') }}
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- LAR DIALOG START -->
    <loading-and-response-dialog :element-props.sync="loadingAndResponseDialog"
                                 v-on:on-error-click="onErrorClickInLARDialog"
                                 v-on:on-success-click="onSuccussCickInLARDialog"></loading-and-response-dialog>
    <!-- LAR DIALOG END -->
  </v-container>
</template>

<script>
import LoadingAndResponseDialog from '@/components/shared/LoadingAndResponseDialog.vue';

export default {
  name: 'TrackingLoginForm',
  components: {
    LoadingAndResponseDialog,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          isContainerFluid: true,
          subtitle: '',
          title: '',
          languages: [
            {
              code: 'en',
              name: 'Angol',
              title: '',
              subtitle: '',
            },
            {
              code: 'de',
              name: 'Német',
              title: '',
              subtitle: '',
            },
          ],
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editorPanel: false,
      trackingLoginFormIsValid: false,
      trackingLoginForm: {
        email: '',
        identifier: '',
        crmRequestId: '',
        source: 'APPLESTORE',
      },
      inputRules: {
        required: [v => !!v || this.$t('hints.requiredField')],
        emailRules: [
          v => /.+@.+\..+/.test(v) || this.$t('hints.emailMatch'),
          v => !!v || this.$t('hints.requiredField'),
        ],
      },
      loadingAndResponseDialog: {
        isVisible: false,
        stepper: 1,
        loadingText: 'LOADING_MESSAGES.LOGIN_IN_PROGRESS',
        successText: 'SUCCESS_MESSAGES.APPLEWORKSHEET_SEND',
        errorText: 'ERROR_MESSAGES.LOGIN',
        successBtnText: 'button.ok',
        errorBtnText: 'button.ok',
      },
    };
  },
  methods: {
    editElement() {
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    saveElement() {
      this.$emit('save-element');
    },
    async trackingLogin() {
      this.openLARDialog();
      const response = await this.$store.dispatch('trackingLogin', this.trackingLoginForm);
      // console.log(response);
      if (response.token) {
        this.$router.push({name: 'nyomonkovetes-reszletek', params: {vs: response.token}});
        this.closeLARDialog();
      } else {
        this.changeLARDialogContentToError();
      }
    },
    /**
     *
     * Loading and response dialog
     *
     */
    openLARDialog() {
      this.loadingAndResponseDialogloadingText = 'LOADING_MESSAGES.LOGIN_IN_PROGRESS';
      this.loadingAndResponseDialog.stepper = 1;
      this.loadingAndResponseDialog.isVisible = true;
    },
    closeLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
    },
    changeLARDialogContentToSuccess() {
      this.loadingAndResponseDialog.stepper = 2;
    },
    changeLARDialogContentToError() {
      this.loadingAndResponseDialog.stepper = 3;
    },
    onSuccussCickInLARDialog() {
      this.closeLARDialog();
    },
    onErrorClickInLARDialog() {
      this.closeLARDialog();
    },
  },
};
</script>

<style scoped>

</style>
