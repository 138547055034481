export default {
  navigation: {
    dashboard: 'Irányítópult',
    pages: 'Oldalak',
    projects: 'Projektek',
    formBuilder: 'Űrlapok',
    footerBuilder: 'Footer szerkesztő',
    masterData: 'Törzsadatok',
    pageBasicElements: 'Oldal alapelemek',
    formBasicElements: 'Űrlap alapelemek',
    menuBiulder: 'Menü szerkesztő',
    allMenus: 'Összes menü',
    apiServices: 'API szervizek',
    login: 'Bejelentkezés',
    logout: 'Kijelentkezés',
    mediaGallery: 'Médiatár',
    categories: 'Kategóriák',
    allPages: 'Összes oldal',
    career: 'Karrier',
    divisions: 'Szervezetek',
    jobs: 'Álláshirdetések',
  },
  loadingText: {
    tableDataLoading: 'Adatok betöltése folyamatban... Kérjük várjon!',
  },
  noDataText: {
    general: 'Nincs megjeleníthető elem',
    noEditableProjects: 'Nincsenek szerkeszthető projektek!',
  },
  noResultText: {
    tableNoResult: 'A keresésnek nincs eredménye',
  },
  hint: {
    tableSearch: 'Begépeléssel rá tud keresni a táblázatban megadott adatok bármelyikére',
  },
  button: {
    addNew: 'Új hozzáadása',
    add: 'Hozzáadás',
    duplicateProject: 'Projekt duplikálása',
    editProject: 'Projekt szerkesztése',
    deleteProject: 'Projekt törlése',
    deleteQuestion: 'Kérdés törlése',
    upload: 'Feltöltés',
    download: 'Letöltés',
    delete: 'Törlés',
    edit: 'Szerkesztés',
    search: 'Keresés',
    clearFilter: 'Törlés',
    editCategory: 'Kategória szerkesztése',
    editDivision: 'Szervezet szerkesztése',
    ok: 'Rendben',
    view: 'Megtekint',
    details: 'Részletek',
    interested: 'Érdekel',
    next: 'Tovább',
    more: 'Bővebben',
    changeImage: 'Kép cseréje',
    pasteImage: 'Kép beillesztése',
    addNewTranslation: 'Új fordítás hozzáadása',
    deleteTranslation: 'Fordítás törlése',
    addNewImage: 'Új kép hozzáadása',
    save: 'Mentés',
    cancel: 'Mégsem',
    update: 'Frissítés',
    openPreview: 'Előnézet',
    send: 'Küldés',
    newThemeGroup: 'Új témakör',
    newQuestion: 'Új kérdés',
    login: 'Bejelentkezés',
    quoteAcceptText: 'Elfogadom az ajánlatot',
    quoteRejectText: 'Elutasítom az ajánlatot',
    expertReportAcceptText: 'Kérek ajánlatot',
    expertReportRejectText: 'Nem kérek ajánlatot',
    direction: 'Útvonalterv',
    orderRepair: 'Futárrendelés',
    newSearch: 'Új keresés',
    generate: 'Generálás',
    setBookingDetails: 'Időpont lefoglalása',
  },
  $vuetify: {
    badge: 'Jelvény',
    close: 'Bezárás',
    dataIterator: {
      noResultsText: 'Nincs egyező találat',
      loadingText: 'Betöltés...',
    },
    dataTable: {
      itemsPerPageText: 'Elem oldalanként:',
      ariaLabel: {
        sortDescending: 'Csökkenő sorrendbe rendezve.',
        sortAscending: 'Növekvő sorrendbe rendezve.',
        sortNone: 'Rendezetlen.',
        activateNone: 'Rendezés törlése.',
        activateDescending: 'Aktiváld a csökkenő rendezésért.',
        activateAscending: 'Aktiváld a növekvő rendezésért.',
      },
      sortBy: 'Rendezés',
    },
    dataFooter: {
      itemsPerPageText: 'Elem oldalanként:',
      itemsPerPageAll: 'Mind',
      nextPage: 'Következő oldal',
      prevPage: 'Előző oldal',
      firstPage: 'Első oldal',
      lastPage: 'Utolsó oldal',
      pageText: '{0}-{1} / {2}',
    },
    datePicker: {
      itemsSelected: '{0} kiválasztva',
      nextMonthAriaLabel: 'Következő hónap',
      nextYearAriaLabel: 'Következő év',
      prevMonthAriaLabel: 'Előző hónap',
      prevYearAriaLabel: 'Előző év',
    },
    noDataText: 'Nincs elérhető adat',
    carousel: {
      prev: 'Előző',
      next: 'Következő',
      ariaLabel: {
        delimiter: 'Dia {0}/{1}',
      },
    },
    calendar: {
      moreEvents: '{0} további',
    },
    fileInput: {
      counter: '{0} fájl',
      counterSize: '{0} fájl ({1} összesen)',
    },
    timePicker: {
      am: 'de',
      pm: 'du',
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Oldal navigáció',
        next: 'Következő oldal',
        previous: 'Előző oldal',
        page: 'Menj a(z) {0}. oldalra',
        currentPage: 'Aktuális oldal: {0}',
      },
    },
  },
  alert: 'Figyelem',
  email: 'E-mail cím',
  password: 'Jelszó',
  projectName: 'Projekt neve',
  status: 'Státusz',
  modifiedAt: 'Utolsó módosítás',
  modifiedBy: 'Utoljára módosította',
  search: 'Keresés',
  hello: 'Helló',
  fileUpload: 'Fájl feltöltése',
  updateCategory: 'Kategória frissítése',
  createCategory: 'Kategória létrehozása',
  updateDivision: 'Szervezet frissítése',
  createDivision: 'Szervezet létrehozása',
  active: 'Aktív',
  notActive: 'Nem aktív',
  closed: 'Lezárt',
  backToAllJobs: 'Vissza az álláshirdetésekhez',
  backToAllMenus: 'Vissza a menükhöz',
  jobSettings: 'Álláshirdetés beállításai',
  menuSettings: 'Menü beállításai',
  footerSettings: 'Footer beállításai',
  working: 'Dolgozunk',
  yuppee: 'Sikeres művelet',
  ooops: 'Hiba',
  published: 'Közzétett',
  draft: 'Vázlat',
  uploadNewMedia: 'Új fájl feltöltése',
  default: 'Alapértelmezett',
  availablePages: 'Elérhető oldalak',
  menuStructure: 'Menü elrendezése',
  editTexts: 'Szövegek szerkesztése',
  titleColor: 'Cím színe',
  availableLanguages: 'Elérhető nyelvek',
  addNewField: 'Új mező hozzáadása',
  editField: 'Mező szerkesztése',
  addNewBlock: 'Új blokk hozzáadása',
  editBlock: 'Blokk szerkesztése',
  formSettings: 'Űrlap beállításai',
  text: 'Szöveg',
  value: 'Érték',
  editText: 'Szöveg szerkesztése',
  editChbText: 'Jelölőnégyzet cimke szerkesztése',
  editRadioText: 'Rádiógomb cimke szerkesztése',
  chbText: 'Jelölőnégyzet cimkéje',
  radioText: 'Rádiógomb cimkéje',
  radioValue: 'Rádiógomb értéke',
  chbIsReq: 'Jelölőnégyzet kötelezőség',
  mandatory: 'Kötelezőség',
  original: 'Eredeti',
  customLink: 'Egyedi link',
  themeGroups: 'Témakörök',
  themeGroup: 'Témakör',
  showAll: 'Összes megjelenítése',
  questions: 'Kérdések',
  addNewThemeGroup: 'Új témakör létrehozása',
  editThemeGroup: 'Témakör szerkesztése',
  tracking: 'Követés',
  deviceDetails: 'Készülékadatok',
  customerDetails: 'Ügyféladatok',
  downloadableDocuments: 'Letölthető dokumentumok',
  isCompanyWarrantyAlert: 'Az Apple korlátozott jótállása nem fogyasztók részére a készülék első aktiválástól '
    + 'számított 1 évig tart és nem indul újra!',
  quoteDone: 'Elkészült árajánlat',
  exportReportDone: 'Elkészült jegyzőkönyv',
  generateRandomImei: 'Amennyiben nem tudja ezt az azonosítót kérjük kattintson a Generálás gombra.',
  appointmentDetails: 'Foglalás részletei',
  SUCCESS_MESSAGES: {
    PAGE_PUBLISHED: 'Oldal közzétéve',
    PAGE_DRAFT: 'Oldal vázlatként elmentve',
    PAGE_COPY: 'Oldal másolat létrehozva',
    PAGE_UPDATED: 'Oldal frissítése sikerült',
    CATEGORY_CREATED: 'Kategória létrehozva',
    CATEGORY_UPDATED: 'Kategória frissítése sikerült',
    DIVISION_CREATED: 'Szervezet létrehozva',
    DIVISION_UPDATED: 'Szervezet frissítése sikerült',
    JOB_CREATED: 'Álláshirdetés létrehozva',
    JOB_UPDATED: 'Álláshirdetés frissítése sikerült',
    JOB_COPY: 'Álláshirdetés másolat létrehozva',
    CONTACT_FORM_SEND: 'Üzenetét megkaptuk! Kollégánk hamarosan felveszi Önnel a kapcsolatot a megadott elérhetőségei egyikén!',
    FILE_UPLOAD: 'A fájlok feltöltése sikerült!',
    MENU_CREATED: 'Menü létrehozva',
    MENU_UPDATED: 'Menü frissítése sikerült',
    APPLEWORKSHEET_SAVE: 'A munkalap mentése sikeres!',
    APPLEWORKSHEET_UPDATE: 'A munkalap adatainak frissítése sikeres!',
    APPLEWORKSHEET_SEND: 'A munkalapot sikeresen elküldtük e-mailben!',
    CRM_REQUEST_DETAILS: 'Az adatok betöltése sikerült!',
    FOOTER_CREATED: 'Footer létrehozva',
    FOOTER_UPDATED: 'Footer frissítése sikerült',
    ACCEPT_QUOTE: 'Árajánlat elfogadása sikerült',
    REJECT_QUOTE: 'Árajánlat elutasítása sikerült',
    ACCEPT_EXPERTREPORT: 'Jegyzőkönyv elfogadása sikerült',
    REJECT_EXPERTREPORT: 'Jegyzőkönyv elutasítása sikerült',
    BOOKING: 'A kiválasztott időpontot sikeresen lefoglaltad!',
  },
  ERROR_MESSAGES: {
    PAGE_PUBLISHED: 'Hiba a közzététel során!',
    PAGE_DRAFT: 'Hiba a vázlat mentése közben!',
    PAGE_COPY: 'Hiba a másolás közben!',
    PAGE_UPDATED: 'Hiba az oldal frissítése közben!',
    CATEGORY_CREATED: 'Hiba a kategória létrehozása közben!',
    CATEGORY_UPDATED: 'Hiba a kategória frissítése közben!',
    DIVISION_CREATED: 'Hiba a szervezet létrehozása közben!',
    DIVISION_UPDATED: 'Hiba a szervezet frissítése közben!',
    JOB_CREATED: 'Hiba az álláshirdetés létrehozása közben!',
    JOB_UPDATED: 'Hiba az álláshirdetés frissítése közben!',
    JOB_COPY: 'Hiba az álláshirdetés másolása közben!',
    CONTACT_FORM_SEND: 'Hiba az ünetet elküldése közben! Kérjük próbálja meg pár perc múlva!',
    FILE_UPLOAD: 'Hiba a fájlok feltöltése közben!',
    FILE_UPLOAD_PARTIAL: 'A fájlok feltöltése részben sikerült! Megtekintheti, hogy mely fájlok nem töltődtek fel a szerverre.',
    MENU_CREATED: 'Hiba a menü létrehozása közben!',
    MENU_UPDATED: 'Hiba a menü frissítése közben!',
    APPLEWORKSHEET_SAVE: 'A munkalap mentése sikeres!',
    APPLEWORKSHEET_UPDATE: 'A munkalap adatainak frissítése sikeres!',
    APPLEWORKSHEET_SEND: 'A munkalapot sikeresen elküldtük e-mailben!',
    LOGIN: 'Sikertelen bejelentkezés! Kérjük ellenőrizze a megadott adatokat és próbálja meg újra!',
    CRM_REQUEST_DETAILS: 'Sajnos nem sikerült az adatok betöltése! Kérjük próbálja meg újra pár perc múlva!',
    FOOTER_CREATED: 'Hiba a footer létrehozása közben!',
    FOOTER_UPDATED: 'Hiba a footer frissítése közben!',
    ACCEPT_QUOTE: 'Hiba az Árajánlat elfogadása közben!',
    REJECT_QUOTE: 'Hiba az Árajánlat elutasítása közben!',
    ACCEPT_EXPERTREPORT: 'Hiba a Jegyzőkönyv elfogadása közben!',
    REJECT_EXPERTREPORT: 'Hiba a Jegyzőkönyv elutasítása közben!',
    BOOKING: 'Hiba az időpontot lefoglalása közben!',
  },
  LOADING_MESSAGES: {
    PROCESS_IN_PROGRESS: 'Küldés folyamatban...',
    LOADING_IN_PROGRESS: 'Betöltés folyamatban...',
    LOGIN_IN_PROGRESS: 'Bejelentkezés folyamatban...',
    DOWNLOAD_IN_PROGRESS: 'Letöltés folyamatban...',
  },
  labels: {
    name: 'Név',
    firstName: 'Keresztnév',
    lastName: 'Vezetéknév',
    email: 'E-mail cím',
    phone: 'Telefonszám',
    message: 'Üzenet',
    subject: 'Témakör',
    code: 'Kód',
    parentCategory: 'Szülő kategória',
    title: 'Cím',
    description: 'Leírás',
    chooseDivision: 'Szervezet kiválasztása',
    validFrom: 'Érvényesség kezdete',
    validTo: 'Érvényesség vége',
    jobAppliedFor: 'Megpályázott pozíció',
    netSalary: 'Nettó bérigény',
    sourcePage: 'Honnan értesült az álláslehetőségről?',
    sourceSponsor: 'Ki ajánlotta az állást? / Kitől értesült az állásról?',
    other: 'Egyéb',
    cv: 'Önéletrajz',
    otherDocument: 'Egyéb dokumentum',
    isDefaultMenu: 'Ez legyen az alapértelmezett menü?',
    buttonText: 'Gomb felirata',
    language: 'Nyelv',
    type: 'Típus',
    label: 'Cimke',
    isRequired: 'Kötelező mező?',
    width: 'Szélesség',
    formTitle: 'Űrlap címe',
    formVisualization: 'Űrlap megjelenítése',
    blockVisualization: 'Blokk megjelenítése',
    showFormTitle: 'Cím megjelenítése',
    titleSize: 'Cím mérete',
    titleAlign: 'Cím igazítása',
    subtitleAlign: 'Alcím igazítása',
    descriptionAlign: 'Leírás igazítása',
    textSize: 'Szöveg mérete',
    textAlign: 'Szöveg igazítása',
    blockTitle: 'Blokk címe',
    submitText: 'Gomb felirata',
    submitAlign: 'Gomb igazítása',
    submitDisplay: 'Gomb megjelenése',
    submitIsBlock: 'Gomb szélessége',
    submitRounded: 'Gomb alakja',
    submitSize: 'Gomb mérete',
    showLabel: 'Cimke megjelenítése',
    radioValue: 'Rádiógomb értéke',
    navigationLabel: 'Navigációs cimke',
    href: 'Hivatkozás',
    toRight: 'Jobbra',
    toLeft: 'Balra',
    toStart: 'Fent',
    toCenter: 'Közép',
    toBottom: 'Lent',
    color: 'Szín/Színátmenet',
    image: 'Kép',
    productGroup: 'Termékcsoport',
    productType: 'Készülék típus',
    serialNumber: 'Gyári szám',
    serialNumber2: 'Készülékazonosító (IMEI/SN)',
    deviceIdentifier: 'Készülékazonosító',
    dateOfPurchase: 'Vásárlás dátuma',
    invoice: 'Számla',
    repairType: 'Javítás típusa',
    claimedServices: 'Igényelt szolgáltatások',
    errorDescription: 'Hibaleírás',
    shippingAddress: 'Átadás/Átvételi cím',
    billingAddress: 'Számlázási cím',
    billingName: 'Számlázási név',
    billingData: 'Számlázási adatok',
    billingIsEqualToShipping: 'Megegyezik az Átadás/Átvételi címmel.',
    privatePerson: 'Magánszemély',
    company: 'Cég, Egyéni vállalkozás',
    vatNumber: 'Adószám',
    postalCode: 'Irányítószám',
    city: 'Város',
    publicPlaceName: 'Közterület neve',
    streetNumber: 'Házszám',
    otherDescription: 'Egyéb megjegyzés',
    otherDescriptionToCourier: 'Egyéb megjegyzés a futárnak',
    spacerHeight: 'Térköz magassága',
    subtitle: 'Alcím',
    showTextBlock: 'Szöveges blokk megjelenítése',
    showImageBlock: 'Kép blokk megjelenítése',
    textAndImagePosition: 'Kép és szöveg helyzete',
    slideAlign: 'Blokkok rendezése',
    warrantyType2: 'Jótállás típusa',
    dateOfDelivery: 'Átvétel/leadás dátuma',
    repairCostLimit: 'Javítási költséghatár',
    attachedDocs: 'Csatolt dokumentumok',
    serviceRequestId: 'Igény azonosító',
    purchasePrice: 'Vásárlási ár',
    warrantyTicket: 'Garancia jegy',
    repairGroup: 'Javítási csoport',
    imei1: 'IMEI1',
    imei2: 'IMEI2',
    serialNr: 'Széria szám - SN',
    bookingDisplayDaysLength: 'Látható napok száma',
  },
  hints: {
    requiredField: 'Kötelező kitölteni',
    optionalField: 'Opcionális',
    requiredFieldCodeLowerCase: 'Kötelező kitölteni! Ékezet nélküli kisbetűket, számokat és kötőjelet tartalmazhat!',
    requiredFieldCodeUpperCase: 'Kötelező kitölteni! Ékezet nélküli nagybetűket, számokat és kötőjelet tartalmazhat!',
    optionalFieldCodeLowerCase: 'Opcionális! Ékezet nélküli kisbetűket, számokat és kötőjelet tartalmazhat!',
    optionalFieldCodeUpperCase: 'Opcionális! Ékezet nélküli nagybetűket, számokat és kötőjelet tartalmazhat!',
    optionalUniqueIdentifier: 'A gyorsabb és pontosabb ügyintézés érdekében adja meg készüléke egyedi azonosítóját!',
    requiredFieldDate: 'Kötelező kitölteni! Pl. 2021-01-02',
    optionalFieldDate: 'Opcionális! Pl. 2021-01-02',
    dateMustBeValid: 'Valós dátumot adjon meg! Pl. 2021-01-02',
    atLeastOneOptionIsMandatory: 'Min. egy választási opciót kötelező megadni!',
    startTypingPostalCode: 'Begépeléssel rá tud keresni az irányítószámra',
    startTypingCity: 'Begépeléssel rá tud keresni a városra',
    startTypingStreet: 'Begépeléssel rá tud keresni az utcára',
    noDataText: 'Nincs megjeleníthető adat.',
    purchaseDateMax: 'Nem lehet későbbi a dátum, mint',
    purchaseDateReq: 'Kötelező kitölteni! Pl. 2020-03-07',
    insuranceNumberRule: 'Nem tartalmazhat szóközt és speciális karaktereket!',
    purchaseDateMin: 'Nem lehet korábbi a dátum, mint',
    optionalFieldOnlyNumbers: 'Opcionális! Csak számokat adjon meg!',
    maxFileSizeRule: 'Maximális feltölthető fájlméret 5 MB!',
    maxFileSizeRuleOptional: 'Opcionális! Maximális feltölthető fájlméret 5 MB!',
    requiredPrivacy: 'Az űrlap beküldéséhez el kell fogadnia az Adatvédelmi tájékoztatót!',
    requiredTermsAndCond: 'Az űrlap beküldéséhez el kell fogadnia az Általános Szerződési Feltételeket!',
    urlRule: 'Kötelező kitölteni! Ékezet nélküli kisbetűket, számokat, per jelet és kötőjelet tartalmazhat!',
    colorRequired: 'Kötelező kitölteni! Pl: #FFFFFF, rgba(157,23,76,0.6)',
    imeiHint: 'Kötelező kitölteni! Lekérdezhető a *#06# kód beírással',
    imeiHintOptional: 'Opcionális! Lekérdezhető a *#06# kód beírással',
    emailMatch: 'Valós e-mail címet adjon meg!',
  },
  languages: {
    hu: 'Magyar',
    en: 'Angol',
    de: 'Német',
    sl: 'Szlovén',
  },
  footer: {
    footerWidgetAreaIsVisible: 'Widget area megjelenítése',
    footerWidgetAreaIsFluid: 'Widget area 100% szélességű',
    footerWidgetAreaLayout: 'Widget area felosztása',
    footerWidgetAreaBackground: 'Widget area háttérszíne',
    footerWidgetAreaTextColor: 'Widget area szöveg színe',
    footerWidgetAreaPaddingTop: 'Felső padding',
    footerWidgetAreaPaddingBottom: 'Alsó padding',
    footerCopyRightAreaIsVisible: 'Copyright area megjelenítése',
    footerCopyRightAreaIsFluid: 'Copyright area 100% szélességű',
    footerCopyrightAreaBackground: 'Copyright area háttérszíne',
    footerCopyrightAreaTextColor: 'Copyright area szöveg színe',
  },
  hasActiveQuoteBox: {
    title: 'Elfogadod az ajánlatot?',
    desc: 'Árajánlatunk 14 munkanapig érvényes. Kérjük, hogy mindenképpen válaszolj nekünk a megadott határidőn belül, különben megrendelésed töröljük!',
    dowloadSentenceText: 'Az elkészült árajánlatot',
    dowloadSentenceLinkText: 'itt tudod megtekinteni',
  },
  hasActiveExpertReportBox: {
    title: 'Elfogadod a jegyzőkönyvet?',
    desc: 'A jegyzőkönyv 5 munkanapig érvényes. Kérjük, hogy mindenképpen válaszolj nekünk a megadott határidőn belül, különben megrendelésed töröljük!',
    dowloadSentenceText: 'Az elkészült Jegyzőkönyvet',
    dowloadSentenceLinkText: 'itt tudod megtekinteni',
  },
  fileDownloadAlertText: 'A dokumentumok letöltése jelenleg iOS Safari böngészőben nem lehetséges! Kérjük a letöltéshez használjon másik böngészőt, '
    + 'pl. Firefox-ot vagy Chrome-ot.',
};
