/* eslint-disable no-shadow */

// initial state
import { APIGETCRM, APIPOSTCRM } from '@/utils/apiHelper.js';

const state = {
  //
};

// getters
const getters = {
  //
};

// actions
const actions = {
  /**
   *
   * Szabad időpontok adatainak lekérdezése
   * /booking/bookings/free-appointments/{day}
   *
   * @param context
   * @param credentials
   * @returns {Promise<{data: [], message: string, status: number}>}
   * @constructor
   */
  async GetFreeAppointments(context, credentials) {
    const response = await APIGETCRM(`booking/bookings/free-appointments/${credentials.days}`, {}, false);
    return response.data;
  },
  /**
   *
   * Foglalás rögzítése
   * /booking/bookings/{id}/details
   *
   * @param context
   * @param credentials
   * @returns {Promise<{data: *[], message: string, status: number}>}
   * @constructor
   */
  async SetBookingDetails(context, credentials) {
    const response = await APIPOSTCRM(`booking/bookings/${credentials.bookingDetails.id}/details`, credentials.bookingDetails, false);
    return response.data;
  },
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
