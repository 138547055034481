<template>
  <div>
    <v-container :class="editorMode ? 'b-wrapper': ''" :fluid="elementProps.isContainerFluid">
      <!-- PAGE BUILDER ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            About1
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- PAGE BUILDER ACTIONBOX END -->
      <!-- CONTENT START -->
      <v-container fluid>
        <!-- FEJLÉC START -->
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <div class="text-h4 mb-4">
                {{ translations.calendarTitle }}
              </div>
              <div class="text-body-2" v-html="translations.calendarSubtitle"/>
            </v-col>
            <template v-if="!calendarIsLoading">
              <v-col cols="12" sm="6" v-if="calendar.data.length > 0 && calendar.splittedLocation">
                <div class="text-overline line-height-1">
                  {{ calendar.splittedLocation[0] }}
                </div>
                <div class="text-subtitle-1">
                  {{ calendar.splittedLocation[1] }}
                </div>
                <div class="text-body-2 grey--text text--darken-1">
                  {{ $t('labels.phone') }}: {{ elementProps.address.phoneNumber }}
                </div>
              </v-col>
            </template>
          </v-row>
        </v-container>
        <!-- FEJLÉC END -->
        <!-- NAPTÁR LAPOZÁS START -->
        <v-container>
          <v-row justify="space-between" align-content="center" align="center">
            <v-col cols="6" sm="auto" class="text-left" order="1">
              <v-btn text
                     small
                     disabled
                     @click="prevWeek">
                ELŐZŐ HÉT
              </v-btn>
            </v-col>
            <v-col cols="12" sm="auto" class="text-center text-h6" order="3" order-sm="2">
              <template v-if="calendarIsLoading">
                <v-progress-linear color="primary"
                                   indeterminate
                                   height="2"/>
              </template>
              <template v-else>
                {{ calendarStart }}. - {{ calendarEnd }}.
              </template>
            </v-col>
            <v-col cols="6" sm="auto" class="text-right" order="2" order-sm="3">
              <v-btn text
                     small
                     disabled
                     @click="nextWeek">
                KÖVETKEZŐ HÉT
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <!-- NAPTÁR LAPOZÁS END -->
        <!-- NAPTÁR NAPOK START -->
        <v-container>
          <template v-if="calendarIsLoading">
            <div class="text-center">
              <v-progress-circular color="primary"
                                   width="4"
                                   size="40"
                                   indeterminate>
              </v-progress-circular>
            </div>
          </template>
          <template v-else>
            <v-slide-group show-arrows class="slide-group-center">
              <template v-for="(item, index) in calendar.data">
                <v-slide-item v-if="!$WEEKENDS.includes(item.splittedDateToDisplay[3])"
                              :key="`day_${index}`">
                  <v-card width="100%"
                          max-width="130" class="ma-3">
                    <v-card-text class="text-center py-0">
                      <div class="py-5">
                        <div>
                          {{ item.splittedDateToDisplay[1] }}
                        </div>
                        <div class="text-h4 my-1">
                          {{ item.splittedDateToDisplay[2] }}
                        </div>
                        <div class="font-weight-bold">
                          {{ item.splittedDateToDisplay[3] }}
                        </div>
                      </div>
                      <v-divider/>
                      <div class="py-4">
                        <template v-for="(appointment, aIndex) in item.appointments">
                          <v-btn :key="`app_${aIndex}`"
                                 color="primary"
                                 class="mb-3"
                                 @click="setSelectedBookingItem(appointment, item.splittedDateToDisplay)"
                                 :disabled="appointment.is_active === 0"
                                 :outlined="selectedBookingItem !== appointment && appointment.is_active === 1">
                            {{ appointment.time_start.substring(0, 5) }}
                          </v-btn>
                        </template>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-slide-item>
              </template>
            </v-slide-group>
          </template>
        </v-container>
        <!-- NAPTÁR NAPOK END -->
        <!-- ŰRLAP START -->
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <div class="text-h4 mb-4">
                {{ translations.customerDataTitle }}
              </div>
              <div class="text-body-2" v-html="translations.customerDataSubtitle"/>
            </v-col>
          </v-row>
          <!-- ADATOK START -->
          <v-row>
            <!-- KÉSZÜLÉKADATOK START -->
            <v-col cols="12" sm="6">
              <v-card class="fill-height">
                <v-card-title>
                  {{ $t('deviceDetails') }}
                </v-card-title>
                <v-divider />
                <v-card-text class="pa-1">
                  <v-form v-model="isDeviceForm">
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete v-model="selectedDevice"
                                        :label="$t('labels.productType')"
                                        :rules="inputRules.generalRules"
                                        :hint="$t('hints.requiredField')"
                                        :no-data-text="deviceList.status !== 200 ? deviceList.message : $t('noDataText.general')"
                                        persistent-hint
                                        :items="deviceList.data"
                                        item-text="fonix_tipus_gyartoi_nev"
                                        item-value="fonix_tipus_id"
                                        return-object
                                        :search-input.sync="deviceSearchText"
                                        :loading="isDeviceDataLoading"
                                        :disabled="isDataLoading"
                                        outlined
                                        @change="initRepairGroupList">
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete v-model="selectedRepairGroup"
                                        :label="$t('labels.repairGroup')"
                                        :rules="inputRules.generalRules"
                                        :hint="$t('hints.requiredField')"
                                        :no-data-text="repairGroupsList.status !== 200 ? repairGroupsList.message : $t('noDataText.general')"
                                        persistent-hint
                                        :items="repairGroupsList.data"
                                        item-text="repair_group_type_name"
                                        item-value="repair_group_type_id"
                                        return-object
                                        :search-input.sync="repairGroupSearchText"
                                        :loading="isRepairGroupDataLoading"
                                        :disabled="isDataLoading"
                                        outlined>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- KÉSZÜLÉKADATOK END -->
            <!-- ÜGYFÉLADATOK START -->
            <v-col cols="12" sm="6">
              <v-card class="fill-height">
                <v-card-title>
                  {{ $t('customerDetails') }}
                </v-card-title>
                <v-divider />
                <v-card-text class="pa-1">
                  <v-form v-model="isCustomerForm">
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="bookingDetails.last_name"
                                      :label="$t('labels.lastName')"
                                      :rules="inputRules.generalRules"
                                      :hint="$t('hints.requiredField')"
                                      persistent-hint
                                      counter="30"
                                      outlined
                                      required>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="bookingDetails.first_name"
                                      :label="$t('labels.firstName')"
                                      :rules="inputRules.generalRules"
                                      :hint="$t('hints.requiredField')"
                                      persistent-hint
                                      counter="30"
                                      outlined
                                      required>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="bookingDetails.email"
                                      :label="$t('labels.email')"
                                      :rules="inputRules.emailRules"
                                      :hint="$t('hints.requiredField')"
                                      persistent-hint
                                      counter="40"
                                      outlined
                                      required>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="bookingDetails.phone"
                                      :label="$t('labels.phone')"
                                      :rules="inputRules.phoneRules"
                                      :hint="$t('hints.requiredField')"
                                      persistent-hint
                                      v-mask="mask.phoneMask"
                                      outlined
                                      required>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- ÜGYFÉLADATOK END -->
            <!-- FOGLALÁS RÉSZLETEI START -->
            <v-col cols="12" sm="6">
              <v-card class="mb-4">
                <v-card-title>
                  {{ $t('appointmentDetails') }}
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="primary">
                          mdi-hammer-screwdriver
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content v-if="selectedRepairGroup">
                        {{ selectedRepairGroup.repair_group_type_name }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="primary">
                          mdi-calendar
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content v-if="selectedBookingItem && selectedBookingItem.splittedDateToDisplay">
                        {{ `${selectedBookingItem.splittedDateToDisplay[0]} ${selectedBookingItem.splittedDateToDisplay[1]} `
                      + `${selectedBookingItem.splittedDateToDisplay[2]}. ${selectedBookingItem.splittedDateToDisplay[3]}` }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="primary">
                          mdi-alarm
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content v-if="selectedBookingItem">
                        {{ selectedBookingItem.time_start.substring(0, 5) }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="primary">
                          mdi-map-marker-outline
                        </v-icon>
                      </v-list-item-icon>
                      <template v-if="!calendarIsLoading">
                        <v-list-item-content v-if="calendar.data.length > 0 && calendar.data[0].appointments[0]">
                          {{ calendar.data[0].appointments[0].location }}
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- FOGLALÁS RÉSZLETEI END -->
          </v-row>
          <!-- ADATOK END -->
          <!-- CHB, SUBMIT START -->
          <v-row>
            <v-form v-model="isGDPRForm">
              <v-col cols="12">

              </v-col>
            </v-form>
            <v-col cols="12">
              <v-btn color="primary"
                     :disabled="!((isCustomerForm && isDeviceForm) && isGDPRForm)"
                     block
                     large
                     @click="saveBookingDetails">
                {{ $t('button.setBookingDetails') }}
              </v-btn>
            </v-col>
          </v-row>
          <!-- CHB, SUBMIT END -->
        </v-container>
        <!-- ŰRLAP START -->
      </v-container>
      <!-- CONTENT END -->
      <!-- EDITOR PANEL START -->
      <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
        <v-card min-height="690">
          <v-toolbar color="primary" height="64" max-height="64" dark flat tile>
            <v-btn icon>
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-toolbar-title>
              Időpontfoglaló - Szerkesztés
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="editorPanel = !editorPanel">
              <v-icon>
                mdi-content-save
              </v-icon>
            </v-btn>
            <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="6" class="text-subtitle-1 text--primary">
                {{ $t('editTexts') }}
              </v-col>
              <v-col cols="6">
                <v-row class="mx-0" justify="end">
                  <v-btn color="primary" @click="addNewTranslation('OFF')">
                    {{ $t('button.addNewTranslation') }}
                  </v-btn>
                </v-row>
              </v-col>
              <!-- FORDÍTÁS START -->
              <v-col cols="12">
                <v-tabs v-model="translationTab"
                        background-color="teal darken-1"
                        dark>
                  <v-tab v-for="(item, index) in elementProps.translations"
                         :key="`lt-${index}`">
                    {{ item.name }}
                  </v-tab>
                  <v-tabs-items v-model="translationTab">
                    <v-tab-item v-for="(item, index) in elementProps.translations"
                                :key="`lc-${index}`">
                      <v-card outlined tile>
                        <v-card-text>
                          <v-col cols="12">
                            <div class="text-h5 mb-6">
                              {{ item.title }}
                            </div>
                            <div class="mb-6">
                              {{ item.subtitle }}
                            </div>
                            <div class="text-h5 mb-6">
                              {{ item.title2 }}
                            </div>
                            <div class="mb-6">
                              {{ item.subtitle2 }}
                            </div>
                          </v-col>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="pa-4">
                          <v-spacer></v-spacer>
                          <v-btn v-if="elementProps.translations.length > 1"
                                 color="error"
                                 text
                                 class="mr-4"
                                 @click="deleteTranslation(index, 'OFF')">
                            {{ $t('button.delete') }}
                          </v-btn>
                          <v-btn color="primary"
                                 @click="editTranslation(item, index)">
                            {{ $t('button.edit') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </v-col>
              <!-- FORDÍTÁS END -->
              <v-col cols="12" class="pb-0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="mx-0">
              <v-col cols="12" sm="6" md="4">
                <v-select v-model="elementProps.bookingDisplayDaysLength"
                          :label="$t('labels.bookingDisplayDaysLength')"
                          :items="bookingDaysSelect"
                          item-text="text"
                          item-value="id"
                          :rules="[inputRules.selectRule]"
                          :hint="$t('hints.requiredField')"
                          persistent-hint
                          filled>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-row justify="space-between" align="center" class="mx-0">
                <span class="text-subtitle-1 text--primary">
                  A befoglaló container 100% szélességű legyen?
                </span>
                  <v-switch v-model="elementProps.isContainerFluid"
                            inset>
                  </v-switch>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="px-4">
            <v-spacer></v-spacer>
            <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
              Mégsem
            </v-btn>
            <v-btn color="primary" @click="saveElementProps">
              Mentés
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- EDITOR PANEL END -->
      <!-- FORDÍTÁS DIALOG START -->
      <translation-editor-dialog :content="translationDialog"
                                 :visible-elements="visibleElements"
                                 v-on:@cancel="cancelNewTranslation"
                                 v-on:@save="saveNewTranslation" />
      <!-- FORDÍTÁS DIALOG START -->
    </v-container>
    <!-- LAR DIALOG START -->
    <loading-and-response-dialog :element-props.sync="loadingAndResponseDialog"
                                 v-on:on-error-click="onErrorClickInLARDialog"
                                 v-on:on-success-click="onSuccussCickInLARDialog"></loading-and-response-dialog>
    <!-- LAR DIALOG END -->
  </div>
</template>

<script>
import dayjs from 'dayjs';
import 'dayjs/locale/hu.js';
import LoadingAndResponseDialog from '@/components/shared/LoadingAndResponseDialog.vue';
import TranslationEditorDialog from '@/components/shared/TranslationEditorDialog.vue';

export default {
  name: 'AppointmentBooking',
  components: {
    LoadingAndResponseDialog,
    TranslationEditorDialog,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          address: {
            city: 'Budapest',
            phoneNumber: '20/123-4567, 30/987-6543',
            street: 'Valamilyen utca 67.',
            zipCode: '1112',
          },
          bookingDisplayDaysLength: this.$BOOKING_DISPLAY_DAYS_LENGTH,
          isContainerFluid: false,
          translations: [
            {
              code: 'hu',
              name: 'Magyar',
              title: 'Időpont választása',
              title2: 'Adatok megadása',
              subtitle: 'Foglalja le az Önnek megfelelő szabad időpontot!',
              subtitle2: 'Kérjük, adja meg a foglalás véglegesítéséhez szükséges adatokat.',
            },
          ],
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    this.calendarIsLoading = true;
    dayjs.locale('hu');
    this.changeTranslations();
    this.initDeviceList();
    await this.initCalendar();
  },
  data() {
    return {
      bookingDetails: {
        id: 0,
        booking_id: 0,
        booking_event_type_name: 'Apple javítás',
        booking_day: '',
        time_start: '',
        time_end: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        crm_customer_id: 2,
        repair_group_type_id: null,
        repair_group_type_name: '',
        gsm_tipus_id: null,
        fonix_tipus_id: null,
        fonix_tipus_gyartoi_nev: '',
        is_realized: 0,
        is_active: 1,
        created_at: '',
        created_by: 0,
      },
      bookingDaysSelect: [
        { text: 1, id: 1 },
        { text: 2, id: 2 },
        { text: 3, id: 3 },
        { text: 4, id: 4 },
        { text: 5, id: 5 },
        { text: 6, id: 6 },
        { text: 7, id: 7 },
      ],
      calendar: [],
      calendarIsLoading: false,
      calendarStart: null,
      calendarEnd: null,
      deviceList: {
        data: [],
        message: '',
        status: 200,
      },
      deviceSearchText: null,
      deltaWeek: 0,
      editorPanel: false,
      isCustomerForm: false,
      isDataLoading: false,
      isDeviceDataLoading: false,
      isDeviceForm: false,
      isGDPRForm: false,
      isRepairGroupDataLoading: false,
      loadingAndResponseDialog: {
        isVisible: false,
        stepper: 1,
        loadingText: 'LOADING_MESSAGES.PROCESS_IN_PROGRESS',
        successText: 'SUCCESS_MESSAGES.BOOKING',
        errorText: 'ERROR_MESSAGES.BOOKING',
        successBtnText: 'button.ok',
        errorBtnText: 'button.ok',
      },
      repairGroupsList: {
        data: [],
        message: '',
        status: 200,
      },
      repairGroupSearchText: null,
      selectedBookingItem: null,
      selectedDay: null,
      selectedDevice: null,
      selectedRepairGroup: null,
      translations: {
        calendarTitle: '',
        calendarSubtitle: '',
        customerDataTitle: '',
        customerDataSubtitle: '',
      },
      translationTab: 0,
      visibleElements: ['title', 'title2', 'subtitle', 'subtitle2'],
    };
  },
  methods: {
    editElement() {
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    saveElement() {
      this.$emit('save-element');
    },
    async saveElementProps() {
      this.translationTab = 0;
      this.changeTranslations();
      await this.initCalendar();
      this.editorPanel = false;
    },
    changeTranslations() {
      const index = this.elementProps.translations.findIndex((item) => item.code === this.$i18n.locale.substr(0, 2));
      const currentLanguageIndex = index === -1 ? 0 : index;
      this.translations.calendarTitle = this.elementProps.translations[currentLanguageIndex].title;
      this.translations.calendarSubtitle = this.elementProps.translations[currentLanguageIndex].subtitle;
      this.translations.customerDataTitle = this.elementProps.translations[currentLanguageIndex].title2;
      this.translations.customerDataSubtitle = this.elementProps.translations[currentLanguageIndex].subtitle2;
    },
    async initCalendar(deltaWeek = 0) {
      this.calendarIsLoading = true;
      this.calendar = await this.getFreeAppointments(this.elementProps.bookingDisplayDaysLength);
      if (this.calendar.status === this.$HTTP_OK) {
        const calendarLength = this.calendar.data.length;
        for (let i = 0; i < calendarLength; i++) {
          this.calendar.data[i].splittedDateToDisplay = await this.transformDateToDisplayable(this.calendar.data[i].booking_day);
          if (calendarLength === 1) {
            this.calendarStart = `${this.calendar.data[i].splittedDateToDisplay[0]} ${this.calendar.data[i].splittedDateToDisplay[1]} ${this.calendar.data[i].splittedDateToDisplay[2]}`;
            this.calendarEnd = this.calendarStart;
          } else if (i === 0) {
            this.calendarStart = `${this.calendar.data[i].splittedDateToDisplay[0]} ${this.calendar.data[i].splittedDateToDisplay[1]} ${this.calendar.data[i].splittedDateToDisplay[2]}`;
          } else if (i === calendarLength - 1) {
            this.calendarEnd = `${this.calendar.data[i].splittedDateToDisplay[0]} ${this.calendar.data[i].splittedDateToDisplay[1]} ${this.calendar.data[i].splittedDateToDisplay[2]}`;
          }
        }
        if (calendarLength > 0) {
          this.calendar.splittedLocation = this.calendar.data[0].appointments[0].location.split(',');
        }
      }
      /* const now = new Date();
      const firstday = dayjs(now).add(deltaWeek, 'week');
      for (let i = 0; i < 7; i++) {
        let date = dayjs(firstday).add(i, 'day').format('YYYY-MMMM-DD-dddd');
        let splittedDate = date.split('-');
        this.calendar[i].splittedDateToDisplay = splittedDate;
        switch (i) {
          case 0:
            this.calendarStart = `${splittedDate[0]} ${splittedDate[1]} ${splittedDate[2]}`;
            break;
          case 6:
            this.calendarEnd = `${splittedDate[0]} ${splittedDate[1]} ${splittedDate[2]}`;
            break;
        }
      } */
      this.calendarIsLoading = false;
    },
    async initDeviceList() {
      this.isDataLoading = true;
      this.isDeviceDataLoading = true;
      this.deviceList = await this.$store.dispatch('GetRepairLocationDevicelist', { locationId: this.$store.getters.GetLocationId });
      this.isDeviceDataLoading = false;
      this.isDataLoading = false;
    },
    async initRepairGroupList() {
      this.isDataLoading = true;
      this.isRepairGroupDataLoading = true;
      this.repairGroupsList = {
        data: [],
        message: '',
        status: 200,
      };
      this.selectedRepairGroup = null;
      this.repairGroupsList = await this.$store.dispatch('GetRepairDevicelistRepairGroups',
        {
          deviceId: this.selectedDevice.fonix_tipus_id,
          locationId: this.$store.getters.GetLocationId,
        }
      );
      this.isRepairGroupDataLoading = false;
      this.isDataLoading = false;
    },
    async nextWeek() {
      if (this.deltaWeek < 3) {
        this.deltaWeek += 1;
        await this.initCalendar(this.deltaWeek);
      }
    },
    async prevWeek() {
      if (this.deltaWeek > 0) {
        this.deltaWeek -= 1;
        await this.initCalendar(this.deltaWeek);
      }
    },
    async getFreeAppointments(days) {
      this.calendarIsLoading = true;
      const response = await this.$store.dispatch('GetFreeAppointments',
        {
          days: days,
        }
      );
      response.data.sort((a, b) => ((a.booking_day > b.booking_day) ? 1 : -1));
      console.log('response: ', response);
      return response;
    },
    async saveBookingDetails() {
      this.openLARDialog();
      this.bookingDetails.id = this.selectedBookingItem.id;
      this.bookingDetails.booking_id = this.selectedBookingItem.id;
      this.bookingDetails.booking_day = this.selectedBookingItem.booking_day;
      this.bookingDetails.time_start = this.selectedBookingItem.time_start;
      this.bookingDetails.time_end = this.selectedBookingItem.time_end;
      this.bookingDetails.repair_group_type_id = this.selectedRepairGroup.repair_group_type_id;
      this.bookingDetails.repair_group_type_name = this.selectedRepairGroup.repair_group_type_name;
      this.bookingDetails.gsm_tipus_id = this.selectedDevice.gsm_tipus_id;
      this.bookingDetails.fonix_tipus_id = this.selectedDevice.fonix_tipus_id;
      this.bookingDetails.fonix_tipus_gyartoi_nev = this.selectedDevice.fonix_tipus_gyartoi_nev;
      this.bookingDetails.created_at = dayjs().format('YYYY-MM-DD HH:mm:ss');
      const response = await this.$store.dispatch('SetBookingDetails',{
        bookingDetails: this.bookingDetails,
      });
      if (response.status === this.$HTTP_CREATED) {
        this.changeLARDialogContentToSuccess();
      } else {
        this.changeLARDialogContentToError();
      }
    },
    async setSelectedBookingItem(bookingItem) {
      this.selectedBookingItem = bookingItem;
      this.selectedBookingItem.splittedDateToDisplay = dayjs(bookingItem.booking_day).format('YYYY-MMMM-DD-dddd').split('-');
    },
    async transformDateToDisplayable(datestring) {
      const date = dayjs(datestring).format('YYYY-MMMM-DD-dddd');
      return date.split('-');
    },
    /**
     *
     * Loading and response dialog
     *
     */
    openLARDialog() {
      this.loadingAndResponseDialogloadingText = 'LOADING_MESSAGES.PROCESS_IN_PROGRESS';
      this.loadingAndResponseDialog.stepper = 1;
      this.loadingAndResponseDialog.isVisible = true;
    },
    closeLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
    },
    changeLARDialogContentToSuccess() {
      this.loadingAndResponseDialog.stepper = 2;
    },
    changeLARDialogContentToError() {
      this.loadingAndResponseDialog.stepper = 3;
    },
    onSuccussCickInLARDialog() {
      this.closeLARDialog();
      this.$router.push({ name: 'kezdolap' });
    },
    onErrorClickInLARDialog() {
      this.closeLARDialog();
    },
  },
};
</script>

<style scoped>

</style>
