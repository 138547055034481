<template>
  <v-menu offset-y transition="scroll-y-transition" open-on-click>
    <template v-slot:activator="{ on }">
      <div class="d-flex flex-column mx-4" align="center" style="height: 100%" v-on="on">
        <v-img contain :src="`${publicPath}images/flags/flag_${$i18n.locale.substring( 0, 2 )}.png`"
               width="24" height="24" aspect-ratio="1"
               style="cursor: pointer;" />
      </div>
    </template>
    <v-list dense>
      <v-list-item v-show="$i18n.locale !== 'hu-HU' && $ENABLED_LANGUAGES.hun" @click="changeLanguage('hu-HU')" dense>
        <v-list-item-avatar>
          <v-img :src="`${publicPath}images/flags/flag_hu.png`"
                 width="24" height="24" aspect-ratio="1" contain/>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            Magyar
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-show="$i18n.locale !== 'en-EN' && $ENABLED_LANGUAGES.eng" @click="changeLanguage('en-EN')" dense>
        <v-list-item-avatar>
          <v-img :src="`${publicPath}images/flags/flag_en.png`"
                 width="24" height="24" aspect-ratio="1" contain/>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            English
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-show="$i18n.locale !== 'de-DE' && $ENABLED_LANGUAGES.deu" @click="changeLanguage('de-DE')" dense>
        <v-list-item-avatar>
          <v-img :src="`${publicPath}images/flags/flag_de.png`"
                 width="24" height="24" aspect-ratio="1" contain/>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            Deutsch
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-show="$i18n.locale !== 'sl-SL' && $ENABLED_LANGUAGES.sl" @click="changeLanguage('sl-SL')" dense>
        <v-list-item-avatar>
          <v-img :src="`${publicPath}images/flags/flag_sl.png`"
                 width="24" height="24" aspect-ratio="1" contain/>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            Slovenian
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'LanguageDropdown',
  components: {
    //
  },
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
