<template>
  <div>
    <!-- PAGE TOOLBAR START -->
    <v-app-bar app class="appbarcolor" tile elevation="3" v-if="$route.name !== 'PageBuilderEditor'">
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn icon v-on="on" @click="toggleSidebar">
            <v-icon>
              {{ isMiniVariant ? 'mdi-menu' : 'mdi-backburger' }}
            </v-icon>
          </v-btn>
        </template>
        <span>Oldalsáv megjelenítése</span>
      </v-tooltip>
      <v-toolbar-title>
        Webportal CMS
      </v-toolbar-title>
      <v-spacer></v-spacer>
      {{ $t('hello') }} XY!
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn icon v-on="on">
            <v-icon color="primary">
              mdi-account-circle
            </v-icon>
          </v-btn>
        </template>
        <span>Admin profil</span>
      </v-tooltip>
    </v-app-bar>
    <!-- PAGE TOOLBAR END -->
    <!-- SIDEBAR NAVIGATION START -->
    <v-navigation-drawer
        app
        :mini-variant="$vuetify.breakpoint.mdAndUp ? isMiniVariant : false"
        mini-variant-width="72"
        width="280"
        :permanent="$vuetify.breakpoint.mdAndUp"
        class="sidebar">
      <v-list dark>
        <template v-for="(item, index) in $store.state.adminNavigation.navigation">
          <template v-if="item.children.length < 1">
            <v-list-item :key="index" @click="item.click()">
              <v-list-item-icon>
                <v-icon>
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t(`${item.text}`) }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-group :key="index" :prepend-icon="item.icon">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t(`${item.text}`) }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item v-for="(child, childIndex) in item.children" :key="childIndex" @click="child.click()">
                <v-list-item-title>
                  {{ $t(`${child.text}`) }}
                </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>
                    {{ child.icon }}
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>
    <!-- SIDEBAR NAVIGATION END -->
    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
export default {
  name: 'InnerLayout',
  props: {
    layoutProps: {
      type: String,
      default: 'NOTHING',
    },
    footerProps: {
      type: String,
      default: 'NOTHING',
    },
  },
  data() {
    return {
      isMiniVariant: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.isMiniVariant = !this.isMiniVariant;
    },
    logout() {
      //
    },
  },
};
</script>

<style scoped>

</style>
