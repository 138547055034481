<template>
  <div>
    <v-container :class="editorMode ? 'b-wrapper': ''" :fluid="elementProps.isContainerFluid">
      <!-- PAGE BUILDER ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            Article Layout 4
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon >
              mdi-pencil
            </v-icon>
          </v-btn>
          <!--v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn-->
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- PAGE BUILDER ACTIONBOX END -->
      <!-- CONTENT START -->
      <v-row :style="`height: ${mapHeight};`" ref="myMap">
        <gmaps-map :options="mapOptions">
          <gmaps-marker v-for="(item, i) in locations"
                        :key="i"
                        :options="item.options" @click="onLocationClick(item)"/>
          <gmaps-info-window v-if="infoWindow.isVisible"
                             :options="infoWindow"
                             @closed="onCloseInfoWindow">
            <v-card elevation="0" class="gm-my-iw">
              <v-card-text class="pa-2">
                <div v-html="translatedText"></div>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" text @click="googleDirection">
                  {{ $t('button.direction') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </gmaps-info-window>
        </gmaps-map>
      </v-row>
      <!-- CONTENT END -->
    </v-container>
    <!-- EDITOR PANEL START -->
    <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
      <v-card min-height="690">
        <v-toolbar color="primary" height="64" max-height="64" dark flat tile>
          <v-btn icon>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            Article Layout 4 - Szerkesztés
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorPanel = !editorPanel">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="mx-0 mb-3">
            <v-col cols="6" class="text-subtitle-1 text--primary">
              Szövegek szerkesztése
            </v-col>
            <v-col cols="6">
              <v-row class="mx-0" justify="end">
                <v-btn color="primary" @click="addNewTranslation('OFF')">
                  {{ $t('button.addNewTranslation') }}
                </v-btn>
              </v-row>
            </v-col>
            <!-- FORDÍTÁS START -->
            <v-col cols="12">
              <v-tabs v-model="translationTab"
                      background-color="teal darken-1"
                      dark>
                <v-tab v-for="(item, index) in elementProps.translations"
                       :key="`lt-${index}`">
                  {{ item.name }}
                </v-tab>
                <v-tabs-items v-model="translationTab">
                  <v-tab-item v-for="(item, index) in elementProps.translations"
                              :key="`lc-${index}`">
                    <v-card outlined tile>
                      <v-card-text>
                        <v-col cols="12">
                          <div class="text-h5 mb-6" v-if="visibleElements.includes('title')">
                            {{ item.title }}
                          </div>
                          <div v-html="item.text"></div>
                        </v-col>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="pa-4">
                        <v-spacer></v-spacer>
                        <v-btn v-if="elementProps.translations.length > 1"
                               color="error"
                               text
                               class="mr-4"
                               @click="deleteTranslation(index, 'OFF')">
                          {{ $t('button.delete') }}
                        </v-btn>
                        <v-btn color="primary"
                               @click="editTranslation(item, index)">
                          {{ $t('button.edit') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-col>
            <!-- FORDÍTÁS END -->
          </v-row>
          <v-divider></v-divider>
          <!-- TÉRKÉP MAGASSÁG START -->
          <v-col cols="12" class="pb-0">
            <div class="text-subtitle-1 text--primary mt-4">
              Térkép magassága
            </div>
            <div class="body-2 mb-4">
              Pixelben vagy százalékban. Pl. 100% vagy 540px
            </div>
            <v-row class="mx-0">
              <v-col cols="12" md="4" lg="2">
                <v-text-field v-model="elementProps.xsHeight"
                              label="XS magasság"
                              prepend-inner-icon="mdi-cellphone"
                              hint="screen < 600px"
                              persistent-hint
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4" lg="2">
                <v-text-field v-model="elementProps.smHeight"
                              label="SM magasság"
                              prepend-inner-icon="mdi-tablet"
                              hint="600px < screen < 960px"
                              persistent-hint
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4" lg="2">
                <v-text-field v-model="elementProps.mdHeight"
                              label="MD magasság"
                              prepend-inner-icon="mdi-laptop"
                              hint="960px < screen < 1264px"
                              persistent-hint
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4" lg="2">
                <v-text-field v-model="elementProps.lgHeight"
                              label="LG magasság"
                              prepend-inner-icon="mdi-monitor"
                              hint="1264px < screen < 1904px"
                              persistent-hint
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4" lg="2">
                <v-text-field v-model="elementProps.xlHeight"
                              label="XL magasság"
                              prepend-inner-icon="mdi-television"
                              hint="1904px < screen"
                              persistent-hint
                              filled>
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <!-- TÉRKÉP MAGASSÁG END -->
          <v-divider></v-divider>
          <v-row class="mx-0">
            <v-col cols="12" class="pb-0">
              <v-row justify="space-between" align="center" class="mx-0">
                <span class="text-subtitle-1 text--primary">
                  A befoglaló container 100% szélességű legyen?
                </span>
                <v-switch v-model="elementProps.isContainerFluid"
                          inset>
                </v-switch>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="saveElementProps">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDITOR PANEL END -->
    <!-- FORDÍTÁS DIALOG START -->
    <translation-editor-dialog :content="translationDialog"
                               :visible-elements="visibleElements"
                               v-on:@cancel="cancelNewTranslation"
                               v-on:@save="saveNewTranslation" />
    <!-- FORDÍTÁS DIALOG START -->
  </div>
</template>

<script>
import TranslationEditorDialog from '@/components/shared/TranslationEditorDialog.vue';
import { gmaps, gmapsMap, gmapsMarker, gmapsInfoWindow } from 'x5-gmaps';

export default {
  name: 'MyGoogleMap',
  components: {
    gmaps, gmapsMap, gmapsMarker, gmapsInfoWindow, TranslationEditorDialog,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          isContainerFluid: false,
          xsHeight: '100%',
          smHeight: '540px',
          mdHeight: '540px',
          lgHeight: '540px',
          xlHeight: '440px',
          translations: [
            {
              code: 'hu',
              name: 'Magyar',
              text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
            },
          ],
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    this.setMapHeight();
    gmaps().then((maps) => {
      // console.log('maps: ', maps);
      this.infoWindow.pixelOffset = new maps.Size(-1, -30);
    });
    this.changeTranslations();
  },
  data() {
    return {
      editorPanel: false,
      infoWindow: {
        isVisible: false,
        position: null,
        location: null,
        pixelOffset: null,
        minWidth: 300,
      },
      locations: [
        {
          options: {
            position: {
              lat: 47.526550504269224,
              lng: 19.0644136711166,
            },
            title: 'iStuff',
          },
          props: {
            opening: '8-18',
            title: 'iStuff',
          },
          selected: false,
        }
      ],
      mapHeight: '540px',
      mapOptions: {
        center: { lat: 47.526550504269224, lng: 19.0644136711166, },
        zoom: 16,
      },
      translationTab: 0,
      translatedText: '',
      visibleElements: ['text1'],
    };
  },
  methods: {
    deleteElement() {
      this.$emit('delete-element');
    },
    editElement() {
      this.textEditorContent = this.elementProps.text;
      this.editorPanel = true;
    },
    googleDirection() {
      window.open(
        'https://www.google.com/maps/dir//Budapest,+V%C3%A1ci+%C3%BAt+43,+1134/@47.5265872,19.0622677,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4741dbf213d41dfd:0x603d8acc4d6025cf!2m2!1d19.0644564!2d47.5265836!3e0',
        // 'https://goo.gl/maps/FC4qGLqFvtFcED946',
        '_blank');
    },
    onLocationClick(item) {
      this.infoWindow.position = item.options.position;
      this.infoWindow.location = item.props;
      this.infoWindow.isVisible = true;
    },
    onCloseInfoWindow() {
      this.infoWindow.isVisible = false;
    },
    saveElementProps() {
      this.translationTab = 0;
      this.changeTranslations();
      this.editorPanel = false;
    },
    changeTranslations() {
      const index = this.elementProps.translations.findIndex((item) => item.code === this.$i18n.locale.substr(0, 2));
      const currentLanguageIndex = index === -1 ? 0 : index;
      this.translatedText = this.elementProps.translations[currentLanguageIndex].text;
    },
    setMapHeight() {
      this.mapHeight = this.$vuetify.breakpoint.xsOnly ? this.elementProps.xsHeight :
        this.$vuetify.breakpoint.smOnly ? this.elementProps.smHeight : this.$vuetify.breakpoint.mdOnly ?
          this.elementProps.mdHeight : this.$vuetify.breakpoint.lgOnly ? this.elementProps.lgHeight : this.elementProps.xlHeight;
    },
  },
};
</script>

<style scoped>

</style>
