<template>
  <div v-frag>
    <v-app-bar app color="mainMenuBgColor" :height="$APPBARHEIGHT">
      <template v-if="mainMenu.length < 1">
        <v-container>
          nincs alapértelmezett menü
        </v-container>
        <Notification />
      </template>
      <template v-else>
        <app-bar-version2 :main-menu="mainMenu"
                          v-on:on-menu-item-click="onMenuItemClick"
                          v-on:on-toggle-nav-drawer="navigationDrawer = !navigationDrawer">
        </app-bar-version2>
      </template>
    </v-app-bar>
    <v-navigation-drawer v-model="navigationDrawer"
                         app
                         color="mainMenuBgColor"
                         temporary>
      <div class="py-5 px-4">
        <v-img :src="`${publicPath}public_storage${logo}`"
               height="35px"
               max-width="101px"></v-img>
      </div>
      <v-list dark>
        <template v-for="(item, index) in mainMenu">
          <template v-if="item.children.length < 1">
            <v-list-item :key="index" @click="onMenuItemClick(item)">
              <v-list-item-title>
                {{ item.label }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-group :key="index">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.label }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item v-for="(child, childIndex) in item.children" :key="childIndex" @click="onMenuItemClick(child)">
                <v-list-item-title>
                  {{ child.label }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import AppBarVersion1 from 'src/components/appBars/AppBarVersion1.vue';
import AppBarVersion2 from 'src/components/appBars/AppBarVersion2.vue';
import Notification from '@/components/shared/Notification.vue';

export default {
  name: 'AppBarSimple',
  components: {
    Notification,
    AppBarVersion1, AppBarVersion2,
  },
  async mounted() {
    if (this.menu !== 'FAILED') {
      await this.convertJsonToObject(this.menu);
    }
  },
  props: {
    menu: {
      type: String,
      default: 'NOTHING',
    },
  },
  data() {
    return {
      mainMenu: [],
      navigationDrawer: false,
      logo: '/page/pic/2021/11/19/pic_61979c25a2584_iStuff-logo-70x202.png',
    };
  },
  methods: {
    async convertJsonToObject(jsonString) {
      this.mainMenu = JSON.parse(jsonString);
    },
    onMenuItemClick(item) {
      // console.log(item);
      if (item.type === this.$navigationLinkNormal) {
        this.$router.push({ path: `${item.url}` });
      } else {
        window.open(`${item.url}`, '_blank');
      }
      // this.$router.replace({ path: `${url}` });
      // window.open(`${url}`, '_blank');
    },
  },
};
</script>

<style scoped>

</style>
