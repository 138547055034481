import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import adminNavigation from '@/store/adminNavigation.module';
import authModule from './auth.module';
import crm from '@/store/crm.module';
import general from './general.module';
import builderElements from './builderElements.module';
import mdIcons from './mdIcons.module';
import inputRules from './inputRules.module';
import mask from './mask.module';
import project from './project.module';
import media from './media.module';
import pages from './pages.module';
import categories from './categories.module';
import shared from './shared.module';
import divisions from './divisions.module';
import jobs from './jobs.module';
import contact from './contact.module';
import menu from './menu.module';
import languages from './languages.module';
import routes from './routes.module';
import footer from './footer.module.js';
import booking from './booking.module.js';
import priceListModule from './priceList.module.js';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createLogger()],
  modules: {
    authModule,
    adminNavigation,
    crm,
    general,
    builderElements,
    mdIcons,
    inputRules,
    mask,
    project,
    pages,
    media,
    categories,
    shared,
    divisions,
    jobs,
    contact,
    menu,
    languages,
    routes,
    footer,
    booking,
    priceListModule,
  },
});
