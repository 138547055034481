import Vue from 'vue';

export default Vue.mixin({
  data() {
    return {
      translationDialog: {
        title: 'button.addNewTranslation',
        isVisible: false,
        selectedLanguageIndex: NaN,
        selectedTranslationIndex: NaN,
        selectedBoxindex: 'OFF',
        addOrEdit: 'ADD',
        translation: {
          buttonText: '',
          code: '',
          name: '',
          title: '',
          text: '<p>Írjunk ide valamit...</p>',
          text2: '<p>Írjunk is ide valamit...</p>',
        },
      },
    };
  },
  methods: {
    cancelNewTranslation() {
      if (this.translationDialog.addOrEdit === 'EDIT') {
        if (this.translationDialog.selectedBoxindex === 'OFF') {
          // console.log(5);
          this.elementProps.translations.pop();
        } else {
          // console.log(6);
          this.elementProps.boxes[this.translationDialog.selectedBoxindex].translations.pop();
        }
      }
      this.translationDialog.isVisible = false;
      this.setTranslationDialogToDefault();
    },
    setTranslationDialogToDefault() {
      this.translationDialog.selectedLanguageIndex = NaN;
      this.translationDialog.selectedTranslationIndex = NaN;
      this.translationDialog.selectedBoxindex = 'OFF';
      this.translationDialog.addOrEdit = 'ADD';
      this.translationDialog.translation = {
        buttonText: '',
        code: '',
        name: '',
        title: '',
        text: '<p>Írjunk ide valamit...</p>',
        text2: '<p>Írjunk ide is valamit...</p>',
      };
    },
    addNewTranslation(boxindex = 'OFF') {
      this.translationDialog.selectedBoxindex = boxindex;
      this.translationDialog.isVisible = true;
    },
    editTranslation(language, index, elementindex = 'OFF') {
      // console.log(elementindex);
      // console.log(language);
      this.translationDialog.selectedBoxindex = elementindex;
      this.translationDialog.selectedTranslationIndex = index;
      this.translationDialog.selectedLanguageIndex = this.$store.state.languages.availableLanguages.findIndex(
        (item) => item.value === language.code,
      );
      this.translationDialog.translation = JSON.parse(JSON.stringify(language));
      this.translationDialog.addOrEdit = 'EDIT';
      this.translationDialog.isVisible = true;
      // TAB FORCE TO REFRESH
      const translation = JSON.parse(JSON.stringify(this.translationDialog.translation));
      if (this.translationDialog.selectedBoxindex === 'OFF') {
        // console.log(1);
        this.elementProps.translations.push(translation);
        this.translationTab = this.elementProps.translations.length - 1;
      } else {
        // console.log(2);
        this.elementProps.boxes[elementindex].translations.push(translation);
        this.translationTab = this.elementProps.boxes[elementindex].translations.length - 1;
      }
    },
    setLanguage(language, index) {
      // console.log('setLanguage - ', language);
      this.content.selectedLanguageIndex = index;
      this.content.translation.code = language.value;
      this.content.translation.name = language.hun;
    },
    saveNewTranslation() {
      const translation = JSON.parse(JSON.stringify(this.translationDialog.translation));
      if (this.translationDialog.addOrEdit === 'ADD') {
        if (this.translationDialog.selectedBoxindex === 'OFF') {
          // console.log(1);
          this.elementProps.translations.push(translation);
          this.translationTab = this.elementProps.translations.length - 1;
          if (this.useTT2) {
            // console.log('tt2');
            this.translationTab2 = this.elementProps.translations.length - 1;
          }
        } else {
          // console.log(2);
          for (let i = 0; i < this.elementProps.boxes.length; i++) {
            this.elementProps.boxes[i].translations.push(translation);
            this.translationTab = this.elementProps.boxes[0].translations.length - 1;
          }
        }
      } else {
        if (this.translationDialog.selectedBoxindex === 'OFF') {
          // console.log(3);
          this.elementProps.translations[this.translationDialog.selectedTranslationIndex] = translation;
          this.elementProps.translations.pop();
        } else {
          // console.log(4);
          this.elementProps.boxes[this.translationDialog.selectedBoxindex].translations[this.translationDialog.selectedTranslationIndex] = translation;
          this.elementProps.boxes[this.translationDialog.selectedBoxindex].translations.pop();
        }
        this.translationTab = this.translationDialog.selectedTranslationIndex;
      }
      this.translationDialog.isVisible = false;
      this.setTranslationDialogToDefault();
    },
    deleteTranslation(index, boxindex = 'OFF') {
      if (boxindex === 'OFF') {
        this.elementProps.translations.splice(index, 1);
      } else {
        this.elementProps.boxes[boxindex].translations.splice(index, 1);
      }
    },
  },
})
