/* eslint-disable no-shadow */
import { APIGET, APIPOST, APIPUT } from '@/utils/apiHelper';

// initial state
const state = {
  pages: null,
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async createPage(context, credentials) {
    const response = await APIPOST('pages', credentials, true);
    return response.data;
  },
  async updatePage(context, credentials) {
    const response = await APIPUT(`pages/${credentials.pageId}`, credentials.settings, true);
    return response.data;
  },
  async showPages(context, credentials) {
    const response = await APIGET('pages');
    context.commit('setPages', response.data.data);
    return response.data;
  },
  async showPage(context, credentials) {
    const response = await APIGET(`pages/${credentials}`);
    return response.data;
  },
  async showPageByUrl(context, credentials) {
    const response = await APIGET(`pages-url?u=${credentials}`);
    return response.data;
  },
  async copyPage(context, credentials) {
    const response = await APIPOST(`pages/${credentials}/copy`, {}, true);
    return response.data;
  },
};

// mutations
const mutations = {
  setPages(s, credentials) {
    state.pages = credentials;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
