/* eslint-disable no-shadow */

// initial state
import { APIGET, APIPOST } from '@/utils/apiHelper';

const state = {
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async sendContactForm(context, credentials) {
    const response = await APIPOST('contact-form', credentials, false);
    return response.data;
  },
  async sendJobApplicationForm(context, credentials) {
    const formData = new FormData();
    formData.append('data', JSON.stringify(credentials.form));
    formData.append('cv', credentials.cv);
    formData.append('motivation_letter', credentials.motivationLetter);
    const response = await APIPOST('jobs-application', formData, false);
    return response.data;
  },
  async getContactFormContactTypes(context, credentials) {
    const response = await APIGET('contact-types');
    return response.data;
  },
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
