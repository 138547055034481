<template>
  <v-container>
    <v-row>
      <template v-for="(item, index) in jobs">
        <v-col cols="12" md="6" :key="index">
          <v-card class="fill-height">
            <v-row class="mx-0 fill-height">
              <v-col cols="12" sm="4">
                <v-img :src="`${publicPath}images/drop.jpg`" aspect-ratio="1" width="100%"></v-img>
              </v-col>
              <v-col cols="12" sm="8">
                <v-row align-content="space-between" class="fill-height">
                  <v-col cols="12" class="py-0">
                    <div class="subtitle-1 font-weight-bold">
                      {{ item.translation.title }}
                    </div>
                    <div class="body-2">
                      {{ item.division.translation.name }}
                    </div>
                    <div class="body-2 mt-3">
                      {{ item.translation.desc.substr(0, 170) }} ...
                    </div>
                  </v-col>
                  <v-col cols="12" :class="$vuetify.breakpoint.smAndDown ? 'pt-4 pb-2' : 'py-0'">
                    <v-btn color="indigo lighten-1"
                           dark
                           @click="viewJobDetails(item.translation.url)">
                      {{ $t('button.interested') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <!--v-card>
            <v-card-title>
              {{ item.translation.title }}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary"
                    @click="viewJobDetails(item.translation.url)">
                Részletek
              </v-btn>
            </v-card-actions>
          </v-card-->
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ActualJobList1',
  components: {
    //
  },
  async beforeMount() {
    this.jobs = await this.getActualJobs();
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          //
        };
      },
    },
  },
  data() {
    return {
      jobs: [],
    };
  },
  methods: {
    async getActualJobs() {
      const response = await this.$store.dispatch('showActualJobs');
      if (response.status === 200) {
        return response.data;
      }
      return [];
    },
    viewJobDetails(url) {
      this.$router.push({ path: url });
    },
  },
};
</script>

<style scoped>

</style>
