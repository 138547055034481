/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */

import { APIGET, APIPOST } from '@/utils/apiHelper';
import Vue from 'vue';

// initial state
const state = {
  selectedImageInMediaGallery: null,
};

// getters
const getters = {
  getSelectedImageInMediaGallery: (state) => state.selectedImageInMediaGallery,
};

// actions
const actions = {
  async showMediaFiles(context, credentials) {
    const response = await APIGET(`media?page=${credentials}`, {}, true);
    return response.data;
  },
  async UploadMedia(context, credentials) {
    // console.log('UploadMedia: ', credentials);
    const formData = new FormData();
    // formData.append('media[]', credentials[0], 'jani.jpg');
    // formData.append('media[]', credentials[1], 'sanyi.pdf');
    for (let i = 0; i < credentials.length; i++) {
      formData.append('media[]', credentials[i]);
    }
    /* credentials.forEach((item) => {
      formData.append('media[]', item);
    }); */
    // formData.append('media[]', credentials);
    // console.log(formData.getAll('media[]'));
    const response = await APIPOST('media', formData, true);
    return response.data;
  },
  async MediaTypeChecker(context, credentials) {
    const field = this._vm.$acceptedFileTypesAndFieldNames.find((item) => {
      const regexp = new RegExp(item.regexp, 'g');
      const result = regexp.test(credentials);
      if (result) {
        return item;
      }
      return false;
    });
    return field ? field.fieldName : 'OTHER';
  },
};

// mutations
const mutations = {
  setSelectedImageInMediaGallery(s, payload) {
    state.selectedImageInMediaGallery = payload;
  },
  clearSelectedImageInMediaGallery(s, payload) {
    state.selectedImageInMediaGallery = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
