// initial state
import router from '@/router/router';

const state = {
  buttonTextColor: [
    { text: 'Fehér', value: true },
    { text: 'Fekete', value: false },
  ],
  buttonIsExternalLink: [
    { text: 'Belső hivatkozás', value: false },
    { text: 'Külső hivatkozás', value: true },
  ],
  buttonUrls: [
    { text: '/kezdőlap', value: '/kezdőlap' },
    { text: '/bemutatkozas', value: '/bemutatkozas' },
    { text: '/allasajanlatok', value: '/allasajanlatok' },
  ],
  availablePages: [],
  simpleContactFormSubjects: [
    {
      id: 1,
      name: 'Érdeklődés',
    },
    {
      id: 2,
      name: 'Technikai kérdés',
    },
    {
      id: 3,
      name: 'Rendeléssel kapcsolatos kérdés',
    },
    {
      id: 4,
      name: 'Hibabejelentés',
    },
    {
      id: 5,
      name: 'Egyéb',
    },
  ],
  jobApplicationSourcePages: [
    {
      id: 1,
      name: 'Google (Internet)',
    },
    {
      id: 2,
      name: 'Facebook',
    },
    {
      id: 3,
      name: 'LinkedIn',
    },
    {
      id: 4,
      name: 'Profession hirdetés',
    },
    {
      id: 5,
      name: 'CV Online hirdetés',
    },
    {
      id: 6,
      name: 'Állásbörze',
    },
    {
      id: 7,
      name: 'Óriás plakát',
    },
    {
      id: 8,
      name: 'Szórólap/újság hirdetés',
    },
    {
      id: 9,
      name: 'Dolgozói ajánlás',
    },
    {
      id: 10,
      name: 'Egyéb',
    },
  ],
  dynamicRoutes: [],
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async GetRoutes(context) {
    // return context.state.dynamicRoutes;
    const rt = [];
    const routingArray = [
      {
        path: '/allasajanlatok',
        name: 'allasajanlatok',
        layout: 'outer',
        component: 'SimplePage',
      },
      {
        path: '/szolgaltatasok',
        name: 'szolgaltatasok',
        layout: 'outer',
        component: 'SimplePage',
      },
      {
        path: '/kapcsolat',
        name: 'kapcsolat',
        layout: 'outer',
        component: 'SimplePage',
      },
      {
        path: '/partnerek',
        name: 'partnerek',
        layout: 'outer',
        component: 'SimplePage',
      },
      {
        path: '/hirek',
        name: 'hirek',
        layout: 'outer',
        component: 'SimplePage',
      },
      {
        path: '/bemutatkozas',
        name: 'bemutatkozas',
        layout: 'outer',
        component: 'SimplePage',
      },
    ];
    for (let i = 0; i < routingArray.length; i++) {
      const route = {
        path: routingArray[i].path,
        name: routingArray[i].name,
        meta: { layout: routingArray[i].layout },
        component: () => import(/* webpackChunkName: "about" */ '../views/' + routingArray[i].component + '.vue'),
      };
      // context.commit('addRouteToRoutingTable', route);
      // rt.push(route);
      router.addRoute(route);
    }
    // return context.state.dynamicRoutes;
    return 'done';
  },
  /**
   *
   * AZONOSÍTÓ GENERÁLÁS
   *
   * @param context
   * @param credentials
   * @returns {Promise<string>}
   */
  async randomImeiGenerator(context, credentials) {
    const now = new Date();
    const year = now.getFullYear();
    const sYear = year.toString(10).substr(2, 2);
    const month = now.getMonth() + 1;
    const day = now.getDate();
    let sMonth = month;
    let sDay = day;
    if (month < 10) {
      sMonth = `0${month}`;
    }
    if (day < 10) {
      sDay = `0${day}`;
    }
    const hours = now.getHours();
    const min = now.getMinutes();
    const sec = now.getSeconds();
    let sHours = hours;
    let sMin = min;
    let sSec = sec;
    if (hours < 10) {
      sHours = `0${hours}`;
    }
    if (min < 10) {
      sMin = `0${min}`;
    }
    if (sec < 10) {
      sSec = `0${sec}`;
    }
    const sum = parseInt(sYear, 10) + month + day + hours + min + sec;
    let sSum = sum.toString(10);
    if (sum < 100) {
      sSum = `0${sum}`;
    } else if (sum > 1000) {
      sSum = sum.toString(10).substr(0, 3);
    }
    const sImei = `${sYear}${sMonth}${sDay}${sHours}${sMin}${sSec}${sSum}`;
    // console.log(`%cIMEI: ${sImei} - hossz: ${sImei.length}`, 'background-color: #a29bfe; color: #ffffff; font-size: 12px;');
    // console.log(`%cösszeg: ${sSum} - hossz: ${sSum.toString().length}`, 'background-color: #ff7675; color: #ffffff; font-size: 12px;');
    return sImei;
  },
};

// mutations
const mutations = {
  setAvailablePages(s, payload) {
    state.availablePages.push(payload);
  },
  setAllAvailablePages(s, payload) {
    state.availablePages = payload;
  },
  deleteAvailablePages(s, payload) {
    state.availablePages = [];
  },
  addRouteToRoutingTable(stat, route) {
    state.dynamicRoutes.push(route);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
