<template>
  <div>
    <v-container :class="editorMode ? 'b-wrapper': ''" :fluid="elementProps.isContainerFluid"
                 :style="`background: url('${publicPath}images/sampleBackground.png') center center no-repeat;`">
      <!-- PAGE BUILDER ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            ContactForm1
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- PAGE BUILDER ACTIONBOX END -->
      <!-- CONTENT START -->
      <v-container>
        <v-row>
          <!-- BAL OLDALI SZÖVEG START -->
          <v-col cols="12" md="6">
            <v-row class="mx-0">
              <v-col cols="12" class="text-h3 white--text pr-10" v-show="elementProps.showTitle">
                {{ elementProps.title }}
              </v-col>
              <v-col cols="12" class="white--text mb-10" v-html="elementProps.text"></v-col>
              <v-col cols="12">
                <template v-for="(value, name, index) in elementProps.contact">
                  <v-row class="mx-0" :key="index" align-content="center" align="center" v-show="value.show">
                    <v-col cols="auto">
                      <v-avatar color="#FFA726" tile>
                        <v-icon color="white">
                          {{ value.icon }}
                        </v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col class="text-h6 white--text">
                      {{ value.text }}
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-row>
          </v-col>
          <!-- BAL OLDALI SZÖVEG END -->
          <!-- FORM CARD START -->
          <v-col cols="12" md="6">
            <v-card elevation="6">
              <v-card-title class="pa-6">
                Jelentkezés
              </v-card-title>
              <v-card-text class="px-6">
                <v-form v-model="formIsValid">
                  <v-row>
                    <v-col cols="12" class="py-1">
                      <v-select v-model="contactForm.job"
                                :label="$t('labels.jobAppliedFor')"
                                filled
                                :rules="[$store.state.inputRules.requiredField,$store.state.inputRules.selectRule]"
                                :items="selectFieldOptions.jobs"
                                item-value="id"
                                item-text="name"
                                return-object
                                :hint="$t('hints.requiredField')"
                                persistent-hint
                                :disabled="jobSelectIsDisabled">
                      </v-select>
                    </v-col>
                    <v-col cols="12" class="pt-1">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" md="6" class="py-1">
                      <v-text-field v-model="contactForm.visitor.lastName"
                                    :label="$t('labels.lastName')"
                                    filled
                                    :rules="[$store.state.inputRules.requiredField]"
                                    :hint="$t('hints.requiredField')"
                                    persistent-hint>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-1">
                      <v-text-field v-model="contactForm.visitor.firstName"
                                    :label="$t('labels.firstName')"
                                    filled
                                    :rules="[$store.state.inputRules.requiredField]"
                                    :hint="$t('hints.requiredField')"
                                    persistent-hint>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-1">
                      <v-text-field v-model="contactForm.visitor.email"
                                    :label="$t('labels.email')"
                                    filled
                                    :rules="[$store.state.inputRules.requiredField, $store.state.inputRules.emailValidator]"
                                    :hint="$t('hints.requiredField')"
                                    persistent-hint>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-1">
                      <v-text-field v-model="contactForm.visitor.phone"
                                    :label="$t('labels.phone')"
                                    filled
                                    :rules="[$store.state.inputRules.requiredField,$store.state.inputRules.phoneValidator]"
                                    :hint="$t('hints.requiredField')"
                                    prefix="+36-"
                                    persistent-hint
                                    v-mask="phoneMask.mask">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <v-file-input v-model="cv"
                                    accept=".pdf"
                                    :label="$t('labels.cv')"
                                    filled>
                        <template v-slot:selection="{ file }">
                          <v-chip label color="primary" close @click:close="removeSelectedDocument(1)">
                            {{ file.name }}
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <v-file-input v-model="motivationLetter"
                                    :label="$t('labels.otherDocument')"
                                    accept=".pdf"
                                    filled>
                        <template v-slot:selection="{ file }">
                          <v-chip label color="primary" close @click:close="removeSelectedDocument(2)">
                            {{ file.name }}
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <v-text-field v-model="contactForm.netSalary"
                                    :label="$t('labels.netSalary')"
                                    filled
                                    :rules="[$store.state.inputRules.requiredField, $store.state.inputRules.numberValidator]"
                                    :hint="$t('hints.requiredField')"
                                    persistent-hint
                                    suffix="Ft">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <v-select v-model="contactForm.sourcePage"
                                :label="$t('labels.sourcePage')"
                                filled
                                :rules="[$store.state.inputRules.requiredField]"
                                :items="$store.state.general.jobApplicationSourcePages"
                                item-value="name"
                                item-text="name"
                                :hint="$t('hints.requiredField')"
                                persistent-hint>
                      </v-select>
                    </v-col>
                    <template v-if="contactForm.sourcePage === 'Dolgozói ajánlás'">
                      <v-col cols="12" class="py-1">
                        <v-text-field v-model="contactForm.sourceSponsor"
                                      :label="$t('labels.sourceSponsor')"
                                      filled
                                      :rules="[$store.state.inputRules.requiredField]"
                                      :hint="$t('hints.requiredField')"
                                      persistent-hint>
                        </v-text-field>
                      </v-col>
                    </template>
                    <template v-if="contactForm.sourcePage === 'Egyéb'">
                      <v-col cols="12" class="py-1">
                        <v-text-field v-model="contactForm.sourceOther"
                                      :label="$t('labels.other')"
                                      filled
                                      :rules="[$store.state.inputRules.requiredField]"
                                      :hint="$t('hints.requiredField')"
                                      persistent-hint>
                        </v-text-field>
                      </v-col>
                    </template>
                    <v-col cols="12" class="pb-0">
                      <v-checkbox v-model="checkboxGDPR"
                                  :rules="[$store.state.inputRules.requiredField]">
                        <template v-slot:label>
                          <div class="text-body-2">
                            Az <a href="#" @click.stop>Adatvédelmi tájékoztatót</a> elolvastam és elfogadom.
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-checkbox v-model="checkboxASZF"
                                  :rules="[$store.state.inputRules.requiredField]"
                                  class="mt-0">
                        <template v-slot:label>
                          <div class="text-body-2">
                            Az <a href="#" @click.stop>Általános Szerződési Feltételeket</a>&nbsp;elolvastam és elfogadom.
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="px-6 pb-6">
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="sendContactForm" :disabled="!formIsValid">
                  Küldés
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <!-- FORM CARD END -->
        </v-row>
      </v-container>
      <!-- CONTENT END -->
    </v-container>
    <!-- LAR DIALOG START -->
    <loading-and-response-dialog :element-props.sync="loadingAndResponseDialog"
                                 v-on:on-error-click="onErrorClickInLARDialog"
                                 v-on:on-success-click="onSuccussCickInLARDialog">
    </loading-and-response-dialog>
    <!-- LAR DIALOG END -->
    <!-- EDITOR PANEL START -->
    <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
      <v-card min-height="690">
        <v-toolbar color="primary" height="64" max-height="64" dark flat tile>
          <v-btn icon>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            ContactForm1 - Szerkesztés
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorPanel = !editorPanel">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="elementProps.title"
                            label="Cím"
                            filled
                            clearable
                            hide-details
                            dense>
                <template v-slot:append-outer>
                  <v-switch v-model="elementProps.showTitle"
                            class="ml-5 mt-0"
                            inset>
                  </v-switch>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <!-- SZÖVEGSZERKESZTŐ START -->
              <editor-menu-bar :editor="textEditor" v-slot:="{ commands, isActive }">
                <v-toolbar dense class="grey lighten-2" flat tile>
                  <v-btn @click="commands.bold"
                         :class="isActive.bold() ? 'grey lighten-1' : ''"
                         x-small
                         text
                         height="36">
                    <v-icon size="18">
                      mdi-format-bold
                    </v-icon>
                  </v-btn>
                  <v-btn @click="commands.italic"
                         :class="isActive.italic() ? 'grey lighten-1' : ''"
                         x-small
                         text
                         height="36">
                    <v-icon size="18">
                      mdi-format-italic
                    </v-icon>
                  </v-btn>
                  <v-btn @click="commands.underline"
                         :class="isActive.underline() ? 'grey lighten-1' : ''"
                         x-small
                         text
                         height="36">
                    <v-icon size="18">
                      mdi-format-underline
                    </v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="commands.bullet_list"
                         :class="isActive.bullet_list() ? 'grey lighten-1' : ''"
                         x-small
                         text
                         height="36">
                    <v-icon size="18">
                      mdi-format-list-bulleted
                    </v-icon>
                  </v-btn>
                  <v-btn @click="commands.ordered_list"
                         :class="isActive.ordered_list() ? 'grey lighten-1' : ''"
                         x-small
                         text
                         height="36">
                    <v-icon size="18">
                      mdi-format-list-numbered
                    </v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="commands.paragraph"
                         :class="isActive.paragraph() ? 'grey lighten-1' : ''"
                         x-small
                         text
                         height="36">
                    <v-icon size="18">
                      mdi-format-pilcrow
                    </v-icon>
                  </v-btn>
                  <v-btn @click="commands.heading({ level: 1 })"
                         :class="isActive.heading({ level: 1 }) ? 'grey lighten-1' : ''"
                         x-small
                         text
                         height="36">
                    <v-icon size="18">
                      mdi-format-header-1
                    </v-icon>
                  </v-btn>
                  <v-btn @click="commands.heading({ level: 2 })"
                         :class="isActive.heading({ level: 2 }) ? 'grey lighten-1' : ''"
                         x-small
                         text
                         height="36">
                    <v-icon size="18">
                      mdi-format-header-2
                    </v-icon>
                  </v-btn>
                  <v-btn @click="commands.heading({ level: 3 })"
                         :class="isActive.heading({ level: 3 }) ? 'grey lighten-1' : ''"
                         x-small
                         text
                         height="36">
                    <v-icon size="18">
                      mdi-format-header-3
                    </v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="commands.undo"
                         x-small
                         text
                         height="36">
                    <v-icon size="18">
                      mdi-undo
                    </v-icon>
                  </v-btn>
                  <v-btn @click="commands.redo"
                         x-small
                         text
                         height="36">
                    <v-icon size="18">
                      mdi-redo
                    </v-icon>
                  </v-btn>
                </v-toolbar>
              </editor-menu-bar>
              <editor-content :editor="textEditor" class="custom-text-editor"></editor-content>
              <!-- SZÖVEGSZERKESZTŐ END -->
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="elementProps.contact.home.text"
                            label="Cím"
                            filled
                            clearable
                            hide-details
                            dense>
                <template v-slot:append-outer>
                  <v-switch v-model="elementProps.contact.home.show"
                            class="ml-5 mt-0"
                            inset>
                  </v-switch>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="elementProps.contact.phone.text"
                            label="Telefonszám"
                            filled
                            clearable
                            hide-details
                            dense>
                <template v-slot:append-outer>
                  <v-switch v-model="elementProps.contact.phone.show"
                            class="ml-5 mt-0"
                            inset>
                  </v-switch>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="elementProps.contact.email.text"
                            label="E-mail cím"
                            filled
                            clearable
                            hide-details
                            dense>
                <template v-slot:append-outer>
                  <v-switch v-model="elementProps.contact.email.show"
                            class="ml-5 mt-0"
                            inset>
                  </v-switch>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="mx-0">
            <v-col cols="12" class="pb-0">
              <v-row justify="space-between" align="center" class="mx-0">
                <span class="text-subtitle-1 text--primary">
                  A befoglaló container 100% szélességű legyen?
                </span>
                <v-switch v-model="elementProps.isContainerFluid"
                          inset>
                </v-switch>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="saveElementProps">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDITOR PANEL END -->
  </div>
</template>

<script>
import LoadingAndResponseDialog from '@/components/shared/LoadingAndResponseDialog.vue';

export default {
  name: 'JobApplicationForm',
  components: {
    LoadingAndResponseDialog,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          isContainerFluid: true,
          title: 'Lorem ipsum!',
          showTitle: true,
          text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sagittis, sem quis maximus sagittis, arcu sem'
            + 'tincidunt sem, non consectetur libero quam sed ipsum.</p>'
            + '<p>Nulla varius vulputate urna in viverra.</p>',
          contact: {
            home: {
              text: '1234 Kiskunfélegyháza, Majoros utca 89/c',
              icon: 'mdi-domain',
              show: true,
            },
            phone: {
              text: '+36-20/123-4567',
              icon: 'mdi-phone',
              show: true,
            },
            email: {
              text: 'info@valami.io',
              icon: 'mdi-email',
              show: true,
            },
          },
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    this.jobSelectIsDisabled = await this.checkJobSelectIsDisabled();
    this.selectFieldOptions.jobs = await this.loadActualJobs();
    await this.setContactFormJob();
  },
  data() {
    return {
      editorPanel: false,
      contactForm: {
        visitor: {
          firstName: '',
          lastName: '',
          middleName: '',
          companyName: '',
          email: '',
          phone: '',
          isGdpr: 1,
        },
        job: null,
        divisionId: 4,
        netSalary: null,
        sourcePage: null,
        sourceOther: null,
        sourceSponsor: null,
      },
      cv: null,
      motivationLetter: null,
      formIsValid: false,
      checkboxGDPR: false,
      checkboxASZF: false,
      phoneMask: {
        prefix: '',
        mask: '##-###-####',
      },
      selectFieldOptions: {
        jobs: [],
      },
      jobSelectIsDisabled: false,
      phoneLocal: '',
      loadingAndResponseDialog: {
        isVisible: false,
        stepper: 1,
        loadingText: 'LOADING_MESSAGES.PROCESS_IN_PROGRESS',
        successText: 'SUCCESS_MESSAGES.CONTACT_FORM_SEND',
        errorText: 'ERROR_MESSAGES.CONTACT_FORM_SEND',
        successBtnText: 'button.ok',
        errorBtnText: 'button.ok',
      },
      textEditorContent: this.elementProps.text,
    };
  },
  methods: {
    editElement() {
      this.textEditorContent = this.elementProps.text;
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    saveElement() {
      this.$emit('save-element');
    },
    saveElementProps() {
      this.elementProps.text = this.textEditorContent;
      this.editorPanel = false;
    },
    async loadActualJobs() {
      const arrayToReturn = [];
      const response = await this.$store.dispatch('showActualJobs');
      if (response.status === 200) {
        response.data.forEach((item) => {
          arrayToReturn.push({ id: item.id, name: item.translation.title });
        });
        return arrayToReturn;
      }
      return [];
    },
    async setContactFormJob() {
      this.contactForm.job = this.$store.state.jobs.frontEndActualJob;
      this.contactForm.divisionId = this.$store.state.jobs.frontEndActualJobDivisionId;
    },
    async checkJobSelectIsDisabled() {
      return !!this.$store.state.jobs.frontEndActualJob;
    },
    async sendContactForm() {
      this.openLARDialog();
      const response = await this.$store.dispatch('sendJobApplicationForm', { form: this.contactForm, cv: this.cv, motivationLetter: this.motivationLetter });
      if (response.status === 200) {
        setTimeout(this.changeLARDialogContentToSuccess, 1100);
      } else {
        setTimeout(this.changeLARDialogContentToError, 1100);
      }
    },
    openLARDialog() {
      this.loadingAndResponseDialog.stepper = 1;
      this.loadingAndResponseDialog.isVisible = true;
    },
    changeLARDialogContentToSuccess() {
      this.loadingAndResponseDialog.stepper = 2;
    },
    changeLARDialogContentToError() {
      this.loadingAndResponseDialog.stepper = 3;
    },
    onSuccussCickInLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
      this.$router.push({ name: 'home' });
      /* this.contactForm = {
        visitor: {
          firstName: '',
          lastName: '',
          middleName: '',
          companyName: '',
          email: '',
          phone: '',
          isGdpr: 1,
        },
        contactType: null,
        message: '',
      };
      this.formIsValid = false;
      this.checkboxGDPR = false;
      this.checkboxASZF = false; */
    },
    onErrorClickInLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
    },
    removeSelectedDocument(docType) {
      if (docType === 1) {
        this.cv = null;
      } else {
        this.motivationLetter = null;
      }
    },
  },
  watch: {
    // eslint-disable-next-line
    'contactForm.sourcePage': function (value) {
      this.contactForm.sourceSponsor = null;
      this.contactForm.sourceOther = null;
    },
    phoneMask() {
      // console.log('phonemask');
      this.contactForm.phone = null;
      this.phoneLocal = null;
    },
    phoneLocal(newVal) {
      this.contactForm.phone = `+36-${this.phoneMask.label}/${newVal}`;
    },
  },
};
</script>

<style scoped>

</style>
