<template>
  <div v-frag>
    <!-- CONTENT START -->
    <v-container :class="editorMode ? 'b-wrapper': ''" fluid class="px-0">
      <!-- EDITOR ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            Basic Spacer
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- EDITOR ACTIONBOX END -->
      <!-- CONTENT START -->
      <v-row :class="`py-${elementProps.height}`"></v-row>
      <!-- CONTENT END -->
    </v-container>
    <!-- CONTENT END -->
    <!-- EDITOR PANEL START -->
    <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
      <v-card>
        <v-toolbar color="primary" dark flat tile>
          <v-btn icon>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            Basic Spacer - Szerkesztés
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorPanel = !editorPanel">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select v-model="elementProps.height"
                        :items="heights"
                        :label="$t('labels.spacerHeight')"
                        :rules="[$store.state.inputRules.requiredField]"
                        :hint="$t('hints.requiredField')"
                        filled
                        persistent-hint/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="saveEditorPanel">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDITOR PANEL END -->
  </div>
</template>

<script>
export default {
  name: 'BasicSpacer',
  components: {
    //
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          height: 10,
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editorPanel: false,
      heights: [
        { text: 32, value: 1 },
        { text: 40, value: 2 },
        { text: 48, value: 3 },
        { text: 56, value: 4 },
        { text: 64, value: 5 },
        { text: 72, value: 6 },
        { text: 80, value: 7 },
        { text: 88, value: 8 },
        { text: 96, value: 9 },
        { text: 104, value: 10 },
      ]
    };
  },
  methods: {
    editElement() {
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    saveElement() {
      this.$emit('save-element');
    },
    saveEditorPanel() {
      this.editorPanel = false;
    },
  },
};
</script>

<style scoped>

</style>
