/* eslint-disable no-shadow */

// initial state
const state = {
  availableLanguages: [
    {
      value: 'hu',
      text: 'hu',
      hun: 'Magyar',
      eng: 'Hungarian',
      deu: 'Ungarisch',
    },
    {
      value: 'en',
      text: 'en',
      hun: 'Angol',
      eng: 'English',
      deu: 'Englisch',
    },
    {
      value: 'de',
      text: 'de',
      hun: 'Német',
      eng: 'German',
      deu: 'Deutsche',
    },
  ],
};

// getters
const getters = {
  //
};

// actions
const actions = {
  //
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
