import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

/**
 * API VISSZA
 * path: ''
 * name: ''
 * layout: ''
 * component: ''
 */

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { layout: 'outer' },
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
  },
  {
    path: '/kezdolap',
    name: 'kezdolap',
    meta: { layout: 'outer' },
    redirect: { name: 'home' },
  },
  {
    path: '/home',
    name: 'kezdolapAlt',
    meta: { layout: 'outer' },
    redirect: { name: 'home' },
  },
  {
    path: '/nyomonkovetes',
    name: 'nyomonkovetes-login',
    meta: { layout: 'outer' },
    component: () => import(/* webpackChunkName: "about" */ '../views/trackingModule/TrackingLoginPage.vue'),
  },
  {
    path: '/nyomonkovetes/reszletek/:vs',
    name: 'nyomonkovetes-reszletek',
    meta: { layout: 'outer' },
    component: () => import('../views/trackingModule/TrackingDetailsPage.vue'),
  },
  {
    path: '/admin/login',
    name: 'admin-login',
    meta: { layout: 'nofooter' },
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginPage.vue'),
  },
  {
    path: '/admin/iranyitopult',
    name: 'admin-iranyitopult',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Dashboard.vue'),
  },
  {
    path: '/admin/projects',
    name: 'admin-projects',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/ProjectsPage.vue'),
  },
  {
    path: '/admin/projects/edit-project/:projectId',
    name: 'admin-projectsDetails',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/ProjectDetailsPage.vue'),
  },
  {
    path: '/admin/page-builder',
    name: 'pageBuilder-home',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/pageBuilder/PageBuilderHome.vue'),
  },
  {
    path: '/admin/page-builder/edit-page/:pageId',
    name: 'pageBuilder-editor',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/pageBuilder/PageBuilderEditor.vue'),
  },
  {
    path: '/admin/page-builder/categories',
    name: 'pageBuilder-categories',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/pageBuilder/PageBuilderCategories.vue'),
  },
  {
    path: '/admin/form-builder',
    name: 'formBuilder-home',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/formBuilder/FormBuilderHome.vue'),
  },
  {
    path: '/admin/form-builder/edit-form/:formId',
    name: 'formBuilder-editor',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/formBuilder/FormBuilderEditor.vue'),
  },
  {
    path: '/admin/footer-builder',
    name: 'footerBuilder-home',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/footerBuilder/FooterBuilderHome.vue'),
  },
  {
    path: '/admin/footer-builder/edit-footer/:footerId',
    name: 'footerBuilder-editor',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/footerBuilder/FooterBuilderEditor.vue'),
  },
  {
    path: '/admin/menu-builder',
    name: 'menuBuilder-home',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/menuBuilder/MenuBuilderHome.vue'),
  },
  {
    path: '/admin/menu-builder/edit-menu/:menuId',
    name: 'menuBuilder-editor',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/menuBuilder/MenuBuilderEditor.vue'),
  },
  {
    path: '/admin/master-data',
    name: 'masterData-home',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/masterData/MasterDataHome.vue'),
  },
  {
    path: '/admin/master-data/api-services',
    name: 'masterData-api',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/masterData/MasterDataApi.vue'),
  },
  {
    path: '/admin/master-data/page-basic-elements',
    name: 'masterData-pageBasic',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/masterData/MasterDataPageBasicElements.vue'),
  },
  {
    path: '/admin/master-data/form-basic-elements',
    name: 'masterData-formBasic',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/masterData/MasterDataFormBasicElements.vue'),
  },
  {
    path: '/admin/access-rights',
    name: 'admin-accessRights',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AccessRightPage.vue'),
  },
  {
    path: '/admin/access-rights/users',
    name: 'admin-accessRightsUsers',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AccessRightUsersPage.vue'),
  },
  {
    path: '/admin/media',
    name: 'admin-media',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/MediaPage.vue'),
  },
  {
    path: '/admin/career/divisions',
    name: 'admin-career-divisions',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/careerModule/CareerDivisionsPage.vue'),
  },
  {
    path: '/admin/career/jobs',
    name: 'admin-career-jobs',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/careerModule/CareerJobsPage.vue'),
  },
  {
    path: '/admin/career/jobs/edit-job/:jobId',
    name: 'admin-career-jobs-edit',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/careerModule/CareerJobBuilderPage.vue'),
  },
  {
    path: '/admin/career/jobs/job-visitors/:jobId',
    name: 'admin-career-jobs-visitors',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/careerModule/CareerJobVisitorsPage.vue'),
  },
  {
    path: '/admin/json',
    name: 'json-raw',
    meta: { layout: 'inner' },
    component: () => import(/* webpackChunkName: "about" */ '../views/JsonRaw.vue'),
  },
  {
    path: '/tablazat',
    name: 'Tablazat',
    meta: { layout: 'outer' },
    component: () => import(/* webpackChunkName: "about" */ '../views/DinamikusTablazat.vue'),
  },
  {
    path: '/teszt',
    name: 'Teszt',
    meta: { layout: 'outer' },
    component: () => import(/* webpackChunkName: "about" */ '../views/TestPage.vue'),
  },
  {
    path: '/404-oldal',
    name: '404-oldal',
    meta: { layout: 'outer' },
    component: () => import(/* webpackChunkName: "about" */ '../views/NoPage.vue'),
  },
  {
    path: '*',
    name: 'SimplePage',
    meta: { layout: 'outer' },
    component: () => import(/* webpackChunkName: "about" */ '../views/SimplePage.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, offset: { x: 0, y: 64 } };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
