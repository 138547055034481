<template>
  <div>
    <v-container :class="editorMode ? 'b-wrapper blueStripesBg': 'blueStripesBg'" fluid>
      <!-- PAGE BUILDER ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            List Style 1
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- PAGE BUILDER ACTIONBOX END -->
      <!-- CONTENT START -->
      <v-container class="py-12">
        <v-row>
          <v-col cols="12" md="4">
            <span class="text-h3 font-weight-bold white--text">
              {{ translatedTitle }}
            </span>
          </v-col>
          <v-col cols="12" md="8">
            <v-row class="mx-0">
              <template v-for="(box, index) in moddedboxes.boxes">
                <v-col cols="12" md="6" :key="index" class="py-0 mb-6">
                  <v-row class="mx-0">
                    <v-col cols="auto" class="pt-1 pb-0">
                      <v-icon size="22" :color="moddedboxes.icon.color">
                        mdi-{{moddedboxes.icon.icon}}
                      </v-icon>
                    </v-col>
                    <v-col class="py-0">
                      <div class="text-h6 white--text">
                        {{ box.translations[box.currentTranslationIndex].title }}
                      </div>
                      <div class="third--text text--lighten-3 font-weight-light text-body-1">
                        {{ box.translations[box.currentTranslationIndex].subtitle }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <!-- CONTENT END -->
    </v-container>
    <!-- EDITOR PANEL START -->
    <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
      <v-card min-height="690">
        <v-toolbar color="primary" height="64" max-height="64" dark flat tile>
          <v-btn icon>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            List Style 1 - Szerkesztés
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorPanel = !editorPanel">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <!-- SECTION TITLE SZERKESZTÉSE START -->
          <v-row class="mb-4 mx-0">
            <v-col col="12">
              <div class="text-subtitle-1 text--primary">
                Főcím szerkesztése
              </div>
            </v-col>
            <v-col cols="6">
              <v-row class="mx-0" justify="end">
                <v-btn color="primary" @click="addNewTranslation('OFF')">
                  {{ $t('button.addNewTranslation') }}
                </v-btn>
              </v-row>
            </v-col>
            <!-- FORDÍTÁS START -->
            <v-col cols="12">
              <v-tabs v-model="translationTab2"
                      background-color="teal darken-1"
                      dark>
                <v-tab v-for="(item, index) in elementProps.translations"
                       :key="`lt-${index}`">
                  {{ item.name }}
                </v-tab>
                <v-tabs-items v-model="translationTab2">
                  <v-tab-item v-for="(item, index) in elementProps.translations"
                              :key="`lc-${index}`">
                    <v-card outlined tile>
                      <v-card-text>
                        <v-col cols="12">
                          <div class="text-h5">
                            {{ item.title }}
                          </div>
                        </v-col>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="pa-4">
                        <v-spacer></v-spacer>
                        <v-btn v-if="elementProps.translations.length > 1"
                               color="error"
                               text
                               class="mr-4"
                               @click="deleteTranslation(index, 'OFF')">
                          {{ $t('button.delete') }}
                        </v-btn>
                        <v-btn color="primary"
                               @click="editTranslation(item, index)">
                          {{ $t('button.edit') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-col>
          </v-row>
          <!-- SECTION TITLE SZERKESZTÉSE END -->
          <v-divider></v-divider>
          <!-- LISTAELEMEK SZERKESZTÉSE START -->
          <v-row class="mx-0 mb-3">
            <v-col col="12" sm="6">
              <span class="text-subtitle-1 text--primary">
                Listaelemek szerkesztése
              </span>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row class="mx-0" justify="end">
                <v-btn color="primary" dark @click="addNewListElement" small>
                  Új hozzáadása
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
          <v-row justify="center" class="mb-4 mx-0">
            <v-expansion-panels accordion>
              <v-expansion-panel v-for="(box, boxindex) in elementProps.boxes" :key="boxindex">
                <v-expansion-panel-header>
                  {{ boxindex + 1 }}. elem
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="my-3 mx-0" >
                    <v-col cols="6" class="text-subtitle-1 text--primary">
                      Szövegek szerkesztése
                    </v-col>
                    <v-col cols="6">
                      <v-row class="mx-0" justify="end">
                        <v-btn color="primary" @click="addNewTranslation(boxindex)">
                          {{ $t('button.addNewTranslation') }}
                        </v-btn>
                      </v-row>
                    </v-col>
                    <!-- FORDÍTÁS START -->
                    <v-col cols="12">
                      <v-tabs v-model="translationTab"
                              background-color="teal darken-1"
                              dark>
                        <v-tab v-for="(item, index) in box.translations"
                               :key="`lt-${index}`">
                          {{ item.name }}
                        </v-tab>
                        <v-tabs-items v-model="translationTab">
                          <v-tab-item v-for="(item, index) in box.translations"
                                      :key="`lc-${index}`">
                            <v-card outlined tile>
                              <v-card-text>
                                <v-col cols="12">
                                  <div class="text-overline">
                                    {{ $t('labels.title') }}:
                                  </div>
                                  <div class="text-h5">
                                    {{ item.title }}
                                  </div>
                                </v-col>
                                <v-divider></v-divider>
                                <v-col cols="12">
                                  <div class="text-overline">
                                    {{ $t('labels.description') }}:
                                  </div>
                                  <div class="text-body1">
                                    {{ item.subtitle }}
                                  </div>
                                </v-col>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions class="pa-4">
                                <v-spacer></v-spacer>
                                <v-btn v-if="box.translations.length > 1"
                                       color="error"
                                       text
                                       class="mr-4"
                                       @click="deleteTranslation(index, boxindex)">
                                  {{ $t('button.delete') }}
                                </v-btn>
                                <v-btn color="primary"
                                       @click="editTranslation(item, index, boxindex)">
                                  {{ $t('button.edit') }}
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-tab-item>
                        </v-tabs-items>
                      </v-tabs>
                    </v-col>
                    <!-- FORDÍTÁS END -->
                    <v-col cols="12">
                      <v-row class="mx-0" justify="end">
                        <v-btn @click="deleteListElement(boxindex)" color="error" dark small>
                          Kártya törlése
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <!-- LISTAELEMEK SZERKESZTÉSE END -->
          <v-divider></v-divider>
          <!-- LISTAIKON SZERKESZTÉSE START -->
          <v-row class="ma-0">
            <v-col col="12">
              <span class="text-subtitle-1 text--primary">
                Listaikon szerkesztése
              </span>
            </v-col>
          </v-row>
          <v-row class="mb-4 mx-0">
            <v-col>
              <v-text-field v-model="elementProps.icon.icon"
                            label="Ikon"
                            filled
                            dense
                            append-icon="mdi-pencil"
                            @click:append="openPopupIcon">
              </v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-row justify="end" class="mx-0">
                <v-avatar height="52" width="52" tile :color="`${elementProps.icon.color}`" @click="openColorPicker"></v-avatar>
              </v-row>
            </v-col>
          </v-row>
          <!-- LISTAIKON SZERKESZTÉSE END -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="saveElementProps">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDITOR PANEL END -->
    <!-- COLOR PICKER POPUP START -->
    <v-dialog v-model="popupColorPicker.isVisible" max-width="340">
      <v-card>
        <v-card-title class="indigo darken-2">
          <span class="white--text">
            Ikon színének kiválasztása
          </span>
        </v-card-title>
        <v-card-text class="pa-5">
          <v-color-picker v-model="popupColorPicker.pickerColor"
                          mode="hexa"
                          show-swatches>
          </v-color-picker>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="popupColorPicker.isVisible = !popupColorPicker.isVisible" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="onSaveColor">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- COLOR PICKER POPUP END -->
    <!-- FORDÍTÁS DIALOG START -->
    <translation-editor-dialog :content="translationDialog"
                               :visible-elements="visibleElements"
                               v-on:@cancel="cancelNewTranslation"
                               v-on:@save="saveNewTranslation" />
    <!-- FORDÍTÁS DIALOG END -->
  </div>
</template>

<script>
import TranslationEditorDialog from '@/components/shared/TranslationEditorDialog.vue';

export default {
  name: 'ListStyle1',
  components: {
    TranslationEditorDialog,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          translations: [
            {
              code: 'hu',
              name: 'Magyar',
              title: 'Miért válassz minket?',
            },
          ],
          icon: {
            icon: 'arrow-right-circle',
            color: '#B3E5FC',
          },
          boxes: [
            {
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  title: 'Gyors javítás',
                  subtitle: 'Rövid átfutási idővel, garantált minőséggel.',
                },
              ],
            },
            {
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  title: 'Gyors javítás',
                  subtitle: 'Rövid átfutási idővel, garantált minőséggel.',
                },
              ],
            },
            {
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  title: 'Gyors javítás',
                  subtitle: 'Rövid átfutási idővel, garantált minőséggel.',
                },
              ],
            },{
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  title: 'Gyors javítás',
                  subtitle: 'Rövid átfutási idővel, garantált minőséggel.',
                },
              ],
            }
          ],
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    this.moddedboxes = await this.cloneElementProps();
    await this.changeTranslations();
  },
  data() {
    return {
      editorPanel: false,
      popupColorPicker: {
        isVisible: false,
        pickerColor: '#000000',
      },
      moddedboxes: [],
      translatedTitle: '',
      translationTab: 0,
      translationTab2: 0,
      expansionPanel: null,
      visibleElements: ['title', 'subtitle'],
      visibleElementsTitle: ['title'],
      useTT2: true,
    };
  },
  methods: {
    editElement() {
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    saveElement() {
      this.$emit('save-element');
    },
    async saveElementProps() {
      this.moddedboxes = await this.cloneElementProps();
      await this.changeTranslations();
      this.translationTab = 0;
      this.translationTab2 = 0;
      this.expansionPanel = null;
      this.editorPanel = false;
    },
    async cloneElementProps() {
      return JSON.parse(JSON.stringify(this.elementProps));
    },
    addNewListElement() {
      this.elementProps.boxes.push(JSON.parse(JSON.stringify(this.elementProps.boxes[0])));
    },
    deleteListElement(index) {
      this.elementProps.boxes.splice(index, 1);
    },
    openColorPicker() {
      this.popupColorPicker.isVisible = true;
    },
    onSaveColor() {
      this.elementProps.icon.color = this.popupColorPicker.pickerColor;
      this.popupColorPicker.isVisible = false;
    },
    openPopupIcon() {
      /* this.popupIcon.isVisible = true;
      this.popupIcon.cardIndex = cardIndex; */
      window.open('http://materialdesignicons.com/', '_blank');
    },
    async changeTranslations() {
      const curIndex = this.elementProps.translations.findIndex((item) => item.code === this.$i18n.locale.substr(0, 2));
      this.translatedTitle = this.elementProps.translations[curIndex].title;
      for (let i = 0; i < this.moddedboxes.boxes.length; i++) {
        const index = this.moddedboxes.boxes[i].translations.findIndex((item) => item.code === this.$i18n.locale.substr(0, 2));
        this.moddedboxes.boxes[i].currentTranslationIndex = index === -1 ? 0 : index;
      }
    },
  },
};
</script>

<style scoped>

</style>
