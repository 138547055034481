import Vue from 'vue';

Vue.prototype.$HTTP_OK = 200;
Vue.prototype.$HTTP_CREATED = 201;
Vue.prototype.$HTTP_ACCEPTED = 202;
Vue.prototype.$HTTP_DELETED = 204;
Vue.prototype.$HTTP_NOT_FOUND = 404;
Vue.prototype.$HTTP_TMX_ERROR = 422;

Vue.prototype.$acceptedFileTypesAndFieldNames = [
  {
    regexp: 'image',
    fieldName: 'pic',
  },
  {
    regexp: 'application',
    fieldName: 'document',
  },
  {
    regexp: 'text',
    fieldName: 'document',
  },
];
Vue.prototype.$navigationLinkNormal = 'NORMAL';
Vue.prototype.$navigationLinkCustom = 'CUSTOM';
Vue.prototype.$ENABLED_LANGUAGES = {
  hun: true,
  eng: true,
  deu: false,
  sl: false,
};
// default $APPBARHEIGHT = 64
Vue.prototype.$APPBARHEIGHT = 60;
Vue.prototype.$DEFAULT_FOOTER = {
  footerWidgetArea: {
    backgroundColor: '#AEAEAE',
    textColor: '#333333',
    isVisible: true,
    fluid: true,
    layoutType: [6,6],
    paddingBottom: 3,
    paddingTop: 3,
  },
  copyRightArea: {
    backgroundColor: '#222222',
    isVisible: true,
    fluid: true,
    paddingBottom: 3,
    paddingTop: 3,
    textAlign: 'center',
    textColor: '#FFFFFF',
    textSize: 'caption',
  },
  boxes: [
    {
      translations: [
        {
          code: 'hu',
          name: 'Magyar',
          text: '<p>Porem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
        },
      ],
    },
    {
      translations: [
        {
          code: 'hu',
          name: 'Magyar',
          text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
        },
      ],
    },
    {
      translations: [
        {
          code: 'hu',
          name: 'Magyar',
          text: '<p>Sorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
        },
      ],
    },
    {
      translations: [
        {
          code: 'hu',
          name: 'Magyar',
          text: '<p>Forem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
        },
      ],
    },
  ],
  translations: [
    {
      code: 'hu',
      name: 'Magyar',
      text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
    },
  ],
}
Vue.prototype.$BOOKING_DISPLAY_DAYS_LENGTH = 5;
Vue.prototype.$WEEKENDS = ['szombat', 'vasárnap'];

Vue.prototype.$CODE = 'ISTUFF';
