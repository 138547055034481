<template>
  <v-container>
    <div :class="`${elementProps.title.fontSize} ${elementProps.title.marginBottom}`"
         :style="`color: ${elementProps.title.color}`">
      {{ elementProps.title.text }}
    </div>
    <ul style="list-style-type: none;"
        :class="`${elementProps.textStyle} pl-${elementProps.paddingLeft}`">
      <template v-for="(item, index) in elementProps.list">
        <li :key="index">
          <a :href="item.link"
             :target="item.target"
             :title="item.title"
             :class="`${elementProps.underline} ${elementProps.underlineOnHover}`"
             :style="`color: ${elementProps.color}`">
            {{ item.text }}
          </a>
        </li>
      </template>
    </ul>
  </v-container>
</template>

<script>
export default {
  name: 'SimpleList1',
  components: {
    //
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          title: {
            text: 'Információ',
            color: '#FFFFFF',
            marginBottom: 'mb-3',
            fontSize: 'text-h6',
          },
          color: '#FFFFFF',
          textStyle: 'body-2',
          underline: 'not-underlined',
          underlineOnHover: 'cmsfooterlink',
          paddingLeft: '2',
          list: [
            {
              text: 'Karbantartás',
              link: '#',
              title: 'Semmi',
              target: '_self',
            },
            {
              text: 'Szerviztér',
              link: 'https://www.google.com',
              title: 'Valami',
              target: '_blank',
            },
          ],
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
