/* eslint-disable no-shadow */
import {
  APIGET,
  APIPOST,
  APIPUT,
  APIFILEDOWNLOAD,
} from '@/utils/apiHelper';

// initial state
const state = {
  frontEndActualJob: null,
  frontEndActualJobDivisionId: null,
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async createJob(context, credentials) {
    const response = await APIPOST('jobs', credentials, true);
    return response.data;
  },
  async showAllJobs(context, credentials) {
    const response = await APIGET('jobs-all');
    return response.data;
  },
  async showActualJobs(context, credentials) {
    const response = await APIGET('jobs-actual');
    return response.data;
  },
  async showJobById(context, credentials) {
    const response = await APIGET(`jobs/${credentials}`);
    return response.data;
  },
  async showJobByUrl(context, credentials) {
    const response = await APIGET(`jobs-url?u=${credentials}`);
    if (response.status === 200) {
      context.commit('setFrontEndActualJob', { id: response.data.data.id, name: response.data.data.translation.title, divisionId: response.data.data.divisionId });
    }
    return response.data;
  },
  async updateJob(context, credentials) {
    const response = await APIPUT(`jobs/${credentials.id}`, credentials, true);
    return response.data;
  },
  async showVisitorsByJobId(context, credentials) {
    const response = await APIGET(`jobs/${credentials}/visitors`, {}, true);
    return response.data;
  },
  async downloadDocument(context, credentials) {
    // console.log('n');
    const response = await APIFILEDOWNLOAD(`jobs-documents/${credentials.hash}`);
    if (response.status === 200) {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([response.data], { type: 'application/pdf' });
      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${credentials.visitor}.pdf`);
      document.body.appendChild(link);
      link.click();
    }
  },
};

// mutations
const mutations = {
  setFrontEndActualJob(s, credentials) {
    state.frontEndActualJob = {
      id: credentials.id,
      name: credentials.name,
    };
    state.frontEndActualJobDivisionId = credentials.divisionId;
  },
  deleteFrontEndActualJob(s, credentials) {
    state.frontEndActualJob = null;
    state.frontEndActualJobDivisionId = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
