/* eslint-disable no-shadow */
import {
  APIGET,
  APIPOST,
  APIPUT,
} from '@/utils/apiHelper';

// initial state
const state = {
  defaultMenu: null,
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async createMenu(context, credentials) {
    const response = await APIPOST('menu', credentials, true);
    return response.data;
  },
  async showAllMenus(context, credentials) {
    const response = await APIGET('menu-all');
    return response.data;
  },
  async showMenuById(context, credentials) {
    const response = await APIGET(`menu/${credentials}`);
    return response.data;
  },
  async showDefaultMenu(context, credentials) {
    const response = await APIGET('menu-default');
    return response.data;
  },
  async updateMenu(context, credentials) {
    const response = await APIPUT(`menu/${credentials.id}`, credentials, true);
    return response.data;
  },
};

// mutations
const mutations = {
  setDefaultMenu(s, payload) {
    state.defaultMenu = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
