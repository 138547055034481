/* eslint-disable no-shadow */

// initial state
const state = {
  hunPhones: [
    { mask: '###-####', label: '20' },
    { mask: '###-###', label: '21' },
    { mask: '###-####', label: '30' },
    { mask: '###-####', label: '31' },
    { mask: '###-####', label: '40' },
    { mask: '###-###', label: '50' },
    { mask: '###-###', label: '51' },
    { mask: '###-###', label: '55' },
    { mask: '###-###', label: '60' },
    { mask: '###-####', label: '70' },
    { mask: '###-###', label: '80' },
    { mask: '###-###', label: '90' },
    { mask: '###-###', label: '91' },
    { mask: '###-####', label: '1' },
    { mask: '###-###', label: '22' },
    { mask: '###-###', label: '23' },
    { mask: '###-###', label: '24' },
    { mask: '###-###', label: '25' },
    { mask: '###-###', label: '26' },
    { mask: '###-###', label: '27' },
    { mask: '###-###', label: '28' },
    { mask: '###-###', label: '29' },
    { mask: '###-###', label: '32' },
    { mask: '###-###', label: '33' },
    { mask: '###-###', label: '34' },
    { mask: '###-###', label: '35' },
    { mask: '###-###', label: '36' },
    { mask: '###-###', label: '37' },
    { mask: '###-###', label: '42' },
    { mask: '###-###', label: '44' },
    { mask: '###-###', label: '45' },
    { mask: '###-###', label: '46' },
    { mask: '###-###', label: '47' },
    { mask: '###-###', label: '48' },
    { mask: '###-###', label: '49' },
    { mask: '###-###', label: '52' },
    { mask: '###-###', label: '53' },
    { mask: '###-###', label: '54' },
    { mask: '###-###', label: '56' },
    { mask: '###-###', label: '57' },
    { mask: '###-###', label: '59' },
    { mask: '###-###', label: '62' },
    { mask: '###-###', label: '63' },
    { mask: '###-###', label: '66' },
    { mask: '###-###', label: '68' },
    { mask: '###-###', label: '69' },
    { mask: '###-###', label: '72' },
    { mask: '###-###', label: '73' },
    { mask: '###-###', label: '74' },
    { mask: '###-###', label: '75' },
    { mask: '###-###', label: '76' },
    { mask: '###-###', label: '77' },
    { mask: '###-###', label: '78' },
    { mask: '###-###', label: '79' },
    { mask: '###-###', label: '82' },
    { mask: '###-###', label: '83' },
    { mask: '###-###', label: '84' },
    { mask: '###-###', label: '85' },
    { mask: '###-###', label: '87' },
    { mask: '###-###', label: '88' },
    { mask: '###-###', label: '89' },
    { mask: '###-###', label: '92' },
    { mask: '###-###', label: '93' },
    { mask: '###-###', label: '94' },
    { mask: '###-###', label: '95' },
    { mask: '###-###', label: '96' },
    { mask: '###-###', label: '99' },
  ],
  inputDate: {
    type1: '####-##-##',
  },
};

// getters
const getters = {
  //
};

// actions
const actions = {
  //
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
