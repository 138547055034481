<template>
  <v-dialog v-model="content.isVisible" fullscreen persistent scrollable>
    <v-card tile>
      <v-card-title class="indigo white--text">
        {{ $t(`${content.title}`) }}
        <v-spacer></v-spacer>
        <v-icon color="white" @click="cancel">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <div class="subtitle-1">
              {{ $t('availableLanguages') }}
            </div>
            <div>
              <v-list>
                <v-list-item-group v-model="content.selectedLanguageIndex"
                                   color="primary">
                  <template v-for="(item, index) in $store.state.languages.availableLanguages">
                    <v-list-item :key="index"
                                 @click="setLanguage(item, index)">
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`languages.${item.text}`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon v-if="!active"
                                  color="grey lighten-1">
                            mdi-checkbox-blank-circle-outline
                          </v-icon>
                          <v-icon v-else
                                  color="primary">
                            mdi-check-circle-outline
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                    <v-divider v-if="index < $store.state.languages.availableLanguages.length - 1" :key="`d-${index}`"></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </div>
          </v-col>
          <v-col cols="8" class="py-0">
            <v-row>
              <v-col cols="12" class="subtitle-1">
                {{ $t('editTexts') }}
              </v-col>
              <v-col cols="12" v-if="visibleElements.includes('title')">
                <v-text-field v-model="content.translation.title"
                              :label="$t('labels.title')"
                              :hint="$t('hints.optionalField')"
                              persistent-hint
                              dense
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="12" v-if="visibleElements.includes('subtitle')">
                <v-text-field v-model="content.translation.subtitle"
                              :label="$t('labels.subtitle')"
                              :hint="$t('hints.optionalField')"
                              persistent-hint
                              dense
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="12" v-if="visibleElements.includes('title2')">
                <v-text-field v-model="content.translation.title2"
                              :label="$t('labels.title')"
                              :hint="$t('hints.optionalField')"
                              persistent-hint
                              dense
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="12" v-if="visibleElements.includes('subtitle2')">
                <v-text-field v-model="content.translation.subtitle2"
                              :label="$t('labels.subtitle')"
                              :hint="$t('hints.optionalField')"
                              persistent-hint
                              dense
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="12" v-if="visibleElements.includes('button')">
                <v-text-field v-model="content.translation.buttonText"
                              :label="$t('labels.buttonText')"
                              :hint="$t('hints.optionalField')"
                              persistent-hint
                              dense
                              filled>
                </v-text-field>
              </v-col>
              <!-- SZÖVEGSZERKESZTŐ 1 START -->
              <v-col cols="12" v-if="visibleElements.includes('text1')">
                <v-container fluid class="pa-0">
                  <v-card elevation="1"
                          tile
                          class="grey lighten-2 pa-2">
                    <v-row>
                      <v-btn @click="editor.chain().focus().toggleBold().run()"
                             :class="editor.isActive('bold') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-bold
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().toggleItalic().run()"
                             :class="editor.isActive('italic') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-italic
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().toggleStrike().run()"
                             :class="editor.isActive('strike') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-strikethrough
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor.chain().focus().setTextAlign('left').run()"
                             :class="editor.isActive({ textAlign: 'left' }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-align-left
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().setTextAlign('center').run()"
                             :class="editor.isActive({ textAlign: 'center' }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-align-center
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().setTextAlign('right').run()"
                             :class="editor.isActive({ textAlign: 'right' }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-align-right
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().setTextAlign('justify').run()"
                             :class="editor.isActive({ textAlign: 'justify' }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-align-justify
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor.chain().focus().paragraph().run()"
                             :class="editor.isActive('paragraph') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-pilcrow
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                             :class="editor.isActive('heading', { level: 1 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-1
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                             :class="editor.isActive('heading', { level: 2 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-2
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
                             :class="editor.isActive('heading', { level: 3 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-3
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
                             :class="editor.isActive('heading', { level: 4 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-4
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
                             :class="editor.isActive('heading', { level: 5 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-5
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
                             :class="editor.isActive('heading', { level: 6 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-6
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="setLink(1)"
                             :class="editor.isActive('link') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-link-variant
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().unsetLink().run()"
                             :disabled="!editor.isActive('link')"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-link-variant-off
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor.chain().focus().toggleCodeBlock().run()"
                             :class="editor.isActive('codeBlock') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-code-tags
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor.chain().focus().toggleBulletList().run()"
                             :class="editor.isActive('bulletList') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-list-bulleted
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().toggleOrderedList().run()"
                             :class="editor.isActive('orderedList') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-list-numbered
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor.chain().focus().undo().run()"
                             :disabled="!editor.can().undo()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-undo
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().redo().run()"
                             :disabled="!editor.can().redo()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-redo
                        </v-icon>
                      </v-btn>
                    </v-row>
                    <v-row>
                      <v-btn @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-plus
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor.chain().focus().addColumnBefore().run()"
                             :disabled="!editor.can().addColumnBefore()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-column-plus-before
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().addColumnAfter().run()"
                             :disabled="!editor.can().addColumnAfter()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-column-plus-after
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().deleteColumn().run()"
                             :disabled="!editor.can().deleteColumn()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-column-remove
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor.chain().focus().addRowBefore().run()"
                             :disabled="!editor.can().addRowBefore()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-row-plus-before
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().addRowAfter().run()"
                             :disabled="!editor.can().addRowAfter()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-row-plus-after
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().deleteRow().run()"
                             :disabled="!editor.can().deleteRow()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-row-remove
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor.chain().focus().mergeCells().run()"
                             :disabled="!editor.can().mergeCells()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-merge-cells
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().splitCell().run()"
                             :disabled="!editor.can().splitCell()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-split-cell
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor.chain().focus().toggleHeaderColumn().run()"
                             :disabled="!editor.can().toggleHeaderColumn()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-column
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().toggleHeaderRow().run()"
                             :disabled="!editor.can().toggleHeaderRow()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-row
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor.chain().focus().deleteTable().run()"
                             :disabled="!editor.can().deleteTable()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-remove
                        </v-icon>
                      </v-btn>
                    </v-row>
                  </v-card>
                  <editor-content :editor="editor" class="custom-text-editor"/>
                </v-container>
              </v-col>
              <!-- SZÖVEGSZERKESZTŐ 1 END -->
              <!-- SZÖVEGSZERKESZTŐ 2 START -->
              <v-col cols="12" v-if="visibleElements.includes('text2')">
                <v-container fluid class="pa-0">
                  <v-card elevation="1"
                          tile
                          class="grey lighten-2 pa-2">
                    <v-row>
                      <v-btn @click="editor2.chain().focus().toggleBold().run()"
                             :class="editor2.isActive('bold') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-bold
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().toggleItalic().run()"
                             :class="editor2.isActive('italic') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-italic
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().toggleStrike().run()"
                             :class="editor2.isActive('strike') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-strikethrough
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor2.chain().focus().setTextAlign('left').run()"
                             :class="editor2.isActive({ textAlign: 'left' }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-align-left
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().setTextAlign('center').run()"
                             :class="editor2.isActive({ textAlign: 'center' }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-align-center
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().setTextAlign('right').run()"
                             :class="editor2.isActive({ textAlign: 'right' }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-align-right
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().setTextAlign('justify').run()"
                             :class="editor2.isActive({ textAlign: 'justify' }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-align-justify
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor2.chain().focus().paragraph().run()"
                             :class="editor2.isActive('paragraph') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-pilcrow
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().toggleHeading({ level: 1 }).run()"
                             :class="editor2.isActive('heading', { level: 1 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-1
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().toggleHeading({ level: 2 }).run()"
                             :class="editor2.isActive('heading', { level: 2 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-2
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().toggleHeading({ level: 3 }).run()"
                             :class="editor2.isActive('heading', { level: 3 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-3
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().toggleHeading({ level: 4 }).run()"
                             :class="editor2.isActive('heading', { level: 4 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-4
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().toggleHeading({ level: 5 }).run()"
                             :class="editor2.isActive('heading', { level: 5 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-5
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().toggleHeading({ level: 6 }).run()"
                             :class="editor2.isActive('heading', { level: 6 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-6
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="setLink(2)"
                             :class="editor.isActive('link') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-link-variant
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().unsetLink().run()"
                             :disabled="!editor.isActive('link')"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-link-variant-off
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor2.chain().focus().toggleBulletList().run()"
                             :class="editor2.isActive('bulletList') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-list-bulleted
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().toggleOrderedList().run()"
                             :class="editor2.isActive('orderedList') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-list-numbered
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor2.chain().focus().undo().run()"
                             :disabled="!editor2.can().undo()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-undo
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().redo().run()"
                             :disabled="!editor2.can().redo()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-redo
                        </v-icon>
                      </v-btn>
                    </v-row>
                    <v-row>
                      <v-btn @click="editor2.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-plus
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor2.chain().focus().addColumnBefore().run()"
                             :disabled="!editor2.can().addColumnBefore()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-column-plus-before
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().addColumnAfter().run()"
                             :disabled="!editor2.can().addColumnAfter()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-column-plus-after
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().deleteColumn().run()"
                             :disabled="!editor2.can().deleteColumn()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-column-remove
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor2.chain().focus().addRowBefore().run()"
                             :disabled="!editor2.can().addRowBefore()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-row-plus-before
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().addRowAfter().run()"
                             :disabled="!editor2.can().addRowAfter()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-row-plus-after
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().deleteRow().run()"
                             :disabled="!editor2.can().deleteRow()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-row-remove
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor2.chain().focus().mergeCells().run()"
                             :disabled="!editor2.can().mergeCells()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-merge-cells
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().splitCell().run()"
                             :disabled="!editor2.can().splitCell()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-split-cell
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor2.chain().focus().toggleHeaderColumn().run()"
                             :disabled="!editor2.can().toggleHeaderColumn()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-column
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor2.chain().focus().toggleHeaderRow().run()"
                             :disabled="!editor2.can().toggleHeaderRow()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-row
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor2.chain().focus().deleteTable().run()"
                             :disabled="!editor2.can().deleteTable()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-remove
                        </v-icon>
                      </v-btn>
                    </v-row>
                  </v-card>
                  <editor-content :editor="editor2" class="custom-text-editor"/>
                </v-container>
              </v-col>
              <!-- SZÖVEGSZERKESZTŐ 2 END -->
              <!-- SZÖVEGSZERKESZTŐ 3 START -->
              <v-col cols="12" v-if="visibleElements.includes('text3')">
                <v-container fluid class="pa-0">
                  <v-card elevation="1"
                          tile
                          class="grey lighten-2 pa-2">
                    <v-row>
                      <v-btn @click="editor3.chain().focus().toggleBold().run()"
                             :class="editor3.isActive('bold') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-bold
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().toggleItalic().run()"
                             :class="editor3.isActive('italic') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-italic
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().toggleStrike().run()"
                             :class="editor3.isActive('strike') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-strikethrough
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor3.chain().focus().setTextAlign('left').run()"
                             :class="editor3.isActive({ textAlign: 'left' }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-align-left
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().setTextAlign('center').run()"
                             :class="editor3.isActive({ textAlign: 'center' }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-align-center
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().setTextAlign('right').run()"
                             :class="editor3.isActive({ textAlign: 'right' }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-align-right
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().setTextAlign('justify').run()"
                             :class="editor3.isActive({ textAlign: 'justify' }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-align-justify
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor3.chain().focus().paragraph().run()"
                             :class="editor3.isActive('paragraph') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-pilcrow
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().toggleHeading({ level: 1 }).run()"
                             :class="editor3.isActive('heading', { level: 1 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-1
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().toggleHeading({ level: 2 }).run()"
                             :class="editor3.isActive('heading', { level: 2 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-2
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().toggleHeading({ level: 3 }).run()"
                             :class="editor3.isActive('heading', { level: 3 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-3
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().toggleHeading({ level: 4 }).run()"
                             :class="editor3.isActive('heading', { level: 4 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-4
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().toggleHeading({ level: 5 }).run()"
                             :class="editor3.isActive('heading', { level: 5 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-5
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().toggleHeading({ level: 6 }).run()"
                             :class="editor3.isActive('heading', { level: 6 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-6
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="setLink(2)"
                             :class="editor.isActive('link') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-link-variant
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().unsetLink().run()"
                             :disabled="!editor.isActive('link')"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-link-variant-off
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor3.chain().focus().toggleBulletList().run()"
                             :class="editor3.isActive('bulletList') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-list-bulleted
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().toggleOrderedList().run()"
                             :class="editor3.isActive('orderedList') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-list-numbered
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor3.chain().focus().undo().run()"
                             :disabled="!editor3.can().undo()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-undo
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().redo().run()"
                             :disabled="!editor3.can().redo()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-redo
                        </v-icon>
                      </v-btn>
                    </v-row>
                    <v-row>
                      <v-btn @click="editor3.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-plus
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor3.chain().focus().addColumnBefore().run()"
                             :disabled="!editor3.can().addColumnBefore()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-column-plus-before
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().addColumnAfter().run()"
                             :disabled="!editor3.can().addColumnAfter()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-column-plus-after
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().deleteColumn().run()"
                             :disabled="!editor3.can().deleteColumn()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-column-remove
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor3.chain().focus().addRowBefore().run()"
                             :disabled="!editor3.can().addRowBefore()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-row-plus-before
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().addRowAfter().run()"
                             :disabled="!editor3.can().addRowAfter()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-row-plus-after
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().deleteRow().run()"
                             :disabled="!editor3.can().deleteRow()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-row-remove
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor3.chain().focus().mergeCells().run()"
                             :disabled="!editor3.can().mergeCells()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-merge-cells
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().splitCell().run()"
                             :disabled="!editor3.can().splitCell()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-split-cell
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor3.chain().focus().toggleHeaderColumn().run()"
                             :disabled="!editor3.can().toggleHeaderColumn()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-column
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor3.chain().focus().toggleHeaderRow().run()"
                             :disabled="!editor3.can().toggleHeaderRow()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-row
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor3.chain().focus().deleteTable().run()"
                             :disabled="!editor3.can().deleteTable()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-remove
                        </v-icon>
                      </v-btn>
                    </v-row>
                  </v-card>
                  <editor-content :editor="editor3" class="custom-text-editor"/>
                </v-container>
              </v-col>
              <!-- SZÖVEGSZERKESZTŐ 3 END -->
              <!-- SZÖVEGSZERKESZTŐ 4 START -->
              <v-col cols="12" v-if="visibleElements.includes('text4')">
                <v-container fluid class="pa-0">
                  <v-card elevation="1"
                          tile
                          class="grey lighten-2 pa-2">
                    <v-row>
                      <v-btn @click="editor4.chain().focus().toggleBold().run()"
                             :class="editor4.isActive('bold') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-bold
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().toggleItalic().run()"
                             :class="editor4.isActive('italic') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-italic
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().toggleStrike().run()"
                             :class="editor4.isActive('strike') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-strikethrough
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor4.chain().focus().setTextAlign('left').run()"
                             :class="editor4.isActive({ textAlign: 'left' }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-align-left
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().setTextAlign('center').run()"
                             :class="editor4.isActive({ textAlign: 'center' }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-align-center
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().setTextAlign('right').run()"
                             :class="editor4.isActive({ textAlign: 'right' }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-align-right
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().setTextAlign('justify').run()"
                             :class="editor4.isActive({ textAlign: 'justify' }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-align-justify
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor4.chain().focus().paragraph().run()"
                             :class="editor4.isActive('paragraph') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-pilcrow
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().toggleHeading({ level: 1 }).run()"
                             :class="editor4.isActive('heading', { level: 1 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-1
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().toggleHeading({ level: 2 }).run()"
                             :class="editor4.isActive('heading', { level: 2 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-2
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().toggleHeading({ level: 3 }).run()"
                             :class="editor4.isActive('heading', { level: 3 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-3
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().toggleHeading({ level: 4 }).run()"
                             :class="editor4.isActive('heading', { level: 4 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-4
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().toggleHeading({ level: 5 }).run()"
                             :class="editor4.isActive('heading', { level: 5 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-5
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().toggleHeading({ level: 6 }).run()"
                             :class="editor4.isActive('heading', { level: 6 }) ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-header-6
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="setLink(2)"
                             :class="editor.isActive('link') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-link-variant
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor.chain().focus().unsetLink().run()"
                             :disabled="!editor.isActive('link')"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-link-variant-off
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor4.chain().focus().toggleBulletList().run()"
                             :class="editor4.isActive('bulletList') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-list-bulleted
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().toggleOrderedList().run()"
                             :class="editor4.isActive('orderedList') ? 'grey lighten-1' : ''"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-format-list-numbered
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor4.chain().focus().undo().run()"
                             :disabled="!editor4.can().undo()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-undo
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().redo().run()"
                             :disabled="!editor4.can().redo()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-redo
                        </v-icon>
                      </v-btn>
                    </v-row>
                    <v-row>
                      <v-btn @click="editor4.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-plus
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor4.chain().focus().addColumnBefore().run()"
                             :disabled="!editor4.can().addColumnBefore()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-column-plus-before
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().addColumnAfter().run()"
                             :disabled="!editor4.can().addColumnAfter()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-column-plus-after
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().deleteColumn().run()"
                             :disabled="!editor4.can().deleteColumn()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-column-remove
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor4.chain().focus().addRowBefore().run()"
                             :disabled="!editor4.can().addRowBefore()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-row-plus-before
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().addRowAfter().run()"
                             :disabled="!editor4.can().addRowAfter()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-row-plus-after
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().deleteRow().run()"
                             :disabled="!editor4.can().deleteRow()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-row-remove
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor4.chain().focus().mergeCells().run()"
                             :disabled="!editor4.can().mergeCells()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-merge-cells
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().splitCell().run()"
                             :disabled="!editor4.can().splitCell()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-split-cell
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor4.chain().focus().toggleHeaderColumn().run()"
                             :disabled="!editor4.can().toggleHeaderColumn()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-column
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editor4.chain().focus().toggleHeaderRow().run()"
                             :disabled="!editor4.can().toggleHeaderRow()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-row
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="editor4.chain().focus().deleteTable().run()"
                             :disabled="!editor4.can().deleteTable()"
                             x-small
                             text
                             height="36">
                        <v-icon size="18">
                          mdi-table-remove
                        </v-icon>
                      </v-btn>
                    </v-row>
                  </v-card>
                  <editor-content :editor="editor4" class="custom-text-editor"/>
                </v-container>
              </v-col>
              <!-- SZÖVEGSZERKESZTŐ 4 END -->
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-6 pb-6">
        <v-spacer></v-spacer>
        <v-btn text
               class="mr-2"
               @click="cancel">
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn color="primary"
               :disabled="!Number.isInteger(content.selectedLanguageIndex)"
               @click="save">
          {{ $t('button.save') }}
        </v-btn>
        <!--v-btn text @click="cancelNewTranslation" class="mr-4">
          Mégsem
        </v-btn>
        <v-btn color="primary"
               :disabled="Number.isNaN(translationDialog.selectedLanguageIndex)"
               @click="saveNewTranslation(translationDialog.addOrEdit)">
          {{ translationDialog.addOrEdit === 'ADD' ? $t('button.save') : $t('button.update') }}
        </v-btn-->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import Link from '@tiptap/extension-link';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';

export default {
  name: 'TranslationEditorDialog',
  components: {
    Editor, EditorContent,
  },
  props: {
    content: {
      type: Object,
      default() {
        return {
          title: 'button.addNewTranslation',
          isVisible: false,
          selectedLanguageIndex: NaN,
          selectedTranslationIndex: NaN,
          addOrEdit: 'ADD',
          translation: {
            code: '',
            name: '',
            title: '',
            title2: '',
            subtitle: '',
            subtitle2: '',
            text: '<p>Írjunk ide valamit...</p>',
            text2: '<p>Írjunk ide is valamit...</p>',
            text3: '<p>Írjunk ide is valamit...</p>',
            text4: '<p>Írjunk ide is valamit...</p>',
            buttonText: '',
          },
        };
      },
    },
    visibleElements: {
      type: Array,
      default() {
        return [
          'title', 'subtitle', 'text1',
        ];
      },
    },
  },
  beforeMount() {
    this.editor = new Editor({
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3, 4, 5, 6],
          },
        }),
        Link.configure({
          autolink: false,
        }),
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
      ],
      content: this.content.translation.text,
      onUpdate: () => {
        // this.$emit('update:text', this.editor.getHTML());
      }
    });
    this.editor2 = new Editor({
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3],
          },
        }),
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
      ],
      content: this.content.translation.text2,
      onUpdate: () => {
        // this.$emit('update:text', this.editor.getHTML());
      }
    });
    this.editor3 = new Editor({
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3],
          },
        }),
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
      ],
      content: this.content.translation.text2,
      onUpdate: () => {
        // this.$emit('update:text', this.editor.getHTML());
      }
    });
    this.editor4 = new Editor({
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3],
          },
        }),
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
      ],
      content: this.content.translation.text2,
      onUpdate: () => {
        // this.$emit('update:text', this.editor.getHTML());
      }
    });
  },
  beforeDestroy() {
    this.editor.destroy();
    this.editor2.destroy();
    this.editor3.destroy();
    this.editor4.destroy();
  },
  data() {
    return {
      editor: null,
      editor2: null,
      editor3: null,
      editor4: null,
    };
  },
  methods: {
    cancel() {
      this.$emit('@cancel');
    },
    save() {
      this.content.translation.text = this.editor.getHTML();
      this.content.translation.text2 = this.editor2.getHTML();
      this.content.translation.text3 = this.editor3.getHTML();
      this.content.translation.text4 = this.editor4.getHTML();
      this.$emit('update:content', this.content);
      this.$emit('@save');
    },
    setLink(editor) {
      let currentEditor = this.editor;
      if (editor === 1) {
        currentEditor = this.editor;
      } else if (editor === 2) {
        currentEditor = this.editor2;
      } else if (editor === 3) {
        currentEditor = this.editor3;
      }  else if (editor === 4) {
        currentEditor = this.editor4;
      }
      const previousLink = currentEditor.getAttributes('link').href;
      const url = window.prompt('Külső hivatkozásnál kötelező a https:// kezdet. Belső hivatkozásnál pedig a / kezdet', previousLink);
      // cancelled
      if (url === null) {
        return;
      }
      // empty
      if (url === '') {
        currentEditor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run();
        return;
      }
      const target = url.charAt(0) === '/' ? '_self' : '_blank';
      console.log(url.charAt(0));
      // update link
      currentEditor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url, target: target })
        .run();
    },
  },
  watch: {
    'content.translation.text': function(value) {
      const isSame = false;
      // const isSame = this.editor.getHTML() === value;
      if (isSame) {
        return;
      }
      if (this.editor) {
        this.editor.commands.setContent(value, false);
      }
    },
    'content.translation.text2': function(value) {
      const isSame = false;
      // const isSame = this.editor.getHTML() === value;
      if (isSame) {
        return;
      }
      if (this.editor2) {
        this.editor2.commands.setContent(value, false);
      }
    },
    'content.translation.text3': function(value) {
      const isSame = false;
      // const isSame = this.editor.getHTML() === value;
      if (isSame) {
        return;
      }
      if (this.editor3) {
        this.editor3.commands.setContent(value, false);
      }
    },
    'content.translation.text4': function(value) {
      const isSame = false;
      // const isSame = this.editor.getHTML() === value;
      if (isSame) {
        return;
      }
      if (this.editor4) {
        this.editor4.commands.setContent(value, false);
      }
    },
  },
  computed: {
    isSame() {
      return false;
      // return this.editor.getHTML() === this.content.translation.text;
    },
  },
};
</script>

<style scoped>

</style>
