/* eslint-disable no-shadow */

// initial state
import { APIGETCRM } from '@/utils/apiHelper';

const state = {
  appSystemInfo: null,
};

// getters
const getters = {
  getAppSystemInfoContent(state) {
    if (state.appSystemInfo) {
      return {
        title: state.appSystemInfo.data.title,
        description: state.appSystemInfo.data.description,
      };
    }
    return {
      title: 'Rendszerüzenet nem található',
      description: '',
    };
  },
};

// actions
const actions = {
  async convertToSlug(context, credentials) {
    return credentials.toLowerCase().trim().split(' ').join('-')
      .replace(/á/g, 'a')
      .replace(/é/g, 'e')
      .replace(/í/g, 'i')
      .replace(/ó/g, 'o')
      .replace(/ö/g, 'o')
      .replace(/ő/g, 'o')
      .replace(/ú/g, 'u')
      .replace(/ü/g, 'u')
      .replace(/ű/g, 'u');
  },
  async convertToUpperCaseCode(context, credentials) {
    return credentials.toUpperCase().trim().split(' ').join('-')
      .replace(/Á/g, 'A')
      .replace(/É/g, 'E')
      .replace(/Í/g, 'I')
      .replace(/Ó/g, 'O')
      .replace(/Ö/g, 'O')
      .replace(/Ő/g, 'O')
      .replace(/Ú/g, 'U')
      .replace(/Ü/g, 'U')
      .replace(/Ű/g, 'U');
  },
  async convertToJson(context, credentials) {
    const contentToConvert = [];
    credentials.forEach((item) => {
      contentToConvert.push(item.contentConverted);
    });
    return JSON.stringify(contentToConvert);
  },
  async GetSystemInfo(context, credentials) {
    const response = await APIGETCRM(`system-info/messages/${credentials.code}`, {}, false);
    if (response.status === 200) {
      context.commit('setAppSystemInfo', response.data);
    }
  },
};

// mutations
const mutations = {
  setAppSystemInfo(state, payload) {
    state.appSystemInfo = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
