/* eslint-disable no-shadow */
import { APIGET, APIPOST, APIPUT } from '@/utils/apiHelper';

// initial state
const state = {
  //
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async createDivision(context, credentials) {
    const response = await APIPOST('divisions', credentials, true);
    return response.data;
  },
  async showDivisions(context, credentials) {
    const response = await APIGET('divisions');
    return response.data;
  },
  async showDivisionById(context, credentials) {
    const response = await APIGET(`divisions/${credentials}`);
    return response.data;
  },
  async updateDivision(context, credentials) {
    const response = await APIPUT(`divisions/${credentials.id}`, credentials, true);
    return response.data;
  },
};

// mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
