<template>
  <div>
    <v-container :class="editorMode ? 'b-wrapper': ''" :fluid="elementProps.isContainerFluid">
      <!-- PAGE BUILDER ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            Split1
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- PAGE BUILDER ACTIONBOX END -->
      <!-- CONTENT START -->
      <v-row justify="center" align="center" align-content="center">
        <v-col cols="12" sm="6" class="text-center">
          <div class="text-h3 mb-8" :style="`color: ${elementProps.titleColor}`">
            {{ translatedTitle }}
          </div>
          <div class="body-1 mb-8">
            {{ translatedSubtitle }}
          </div>
          <div v-show="elementProps.button.isVisible">
            <v-btn :color="elementProps.button.color"
                   @click="onMainButtonClick"
                   :dark="elementProps.button.isDark" large rounded>
              {{ translatedButtonText }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <v-img :src="`${publicPath}public_storage${elementProps.imagePath}`" aspect-ratio="1.3333" max-width="800px"></v-img>
        </v-col>
      </v-row>
      <!-- CONTENT END -->
    </v-container>
    <!-- EDITOR PANEL START -->
    <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
      <v-card min-height="690">
        <v-toolbar color="primary" height="64" max-height="64" dark flat tile>
          <v-btn icon>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            Split1 - Szerkesztés
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorPanel = !editorPanel">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="6" class="text-subtitle-1 text--primary">
              {{ $t('editTexts') }}
            </v-col>
            <v-col cols="6">
              <v-row class="mx-0" justify="end">
                <v-btn color="primary" @click="addNewTranslation('OFF')">
                  {{ $t('button.addNewTranslation') }}
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row class="mx-0">
                <div>
                  {{ $t('titleColor') }}:
                </div>
                <v-spacer></v-spacer>
                <v-avatar height="52" width="52" tile :color="`${elementProps.titleColor}`" @click="openColorPicker(1)"></v-avatar>
              </v-row>
            </v-col>
            <!-- FORDÍTÁS START -->
            <v-col cols="12">
              <v-tabs v-model="translationTab"
                      background-color="teal darken-1"
                      dark>
                <v-tab v-for="(item, index) in elementProps.translations"
                       :key="`lt-${index}`">
                  {{ item.name }}
                </v-tab>
                <v-tabs-items v-model="translationTab">
                  <v-tab-item v-for="(item, index) in elementProps.translations"
                              :key="`lc-${index}`">
                    <v-card outlined tile>
                      <v-card-text>
                        <v-col cols="12">
                          <div class="text-overline">
                            {{ $t('labels.title') }}:
                          </div>
                          <div class="text-h5">
                            {{ item.title }}
                          </div>
                        </v-col>
                        <v-divider></v-divider>
                        <v-col cols="12">
                          <div class="text-overline">
                            {{ $t('labels.description') }}:
                          </div>
                          <div class="text-body-1">
                            {{ item.subtitle }}
                          </div>
                        </v-col>
                        <v-divider></v-divider>
                        <v-col cols="12">
                          <div class="text-overline">
                            {{ $t('labels.buttonText') }}:
                          </div>
                          <div>
                            {{ item.buttonText }}
                          </div>
                        </v-col>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="pa-4">
                        <v-spacer></v-spacer>
                        <v-btn v-if="elementProps.translations.length > 1"
                               color="error"
                               text
                               class="mr-4"
                               @click="deleteTranslation(index, 'OFF')">
                          {{ $t('button.delete') }}
                        </v-btn>
                        <v-btn color="primary"
                               @click="editTranslation(item, index)">
                          {{ $t('button.edit') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-divider></v-divider>
            </v-col>
            <!-- SECTION ACTION BUTTON START -->
            <v-col cols="12">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-row justify="space-between" align="center" class="mx-0">
                  <span class="text-subtitle-1 text--primary">
                    Gomb láthatósága
                  </span>
                    <v-switch v-model="elementProps.button.isVisible"
                              inset>
                    </v-switch>
                  </v-row>
                </v-col>
              </v-row>
              <v-slide-y-transition>
                <v-row v-if="elementProps.button.isVisible" class="mx-0">
                  <v-col cols="12">
                    <v-row class="mx-0">
                      <v-col>
                        Gomb háttérszíne:
                      </v-col>
                      <v-col cols="auto">
                        <v-row justify="end" class="mx-0">
                          <v-avatar height="52" width="52" tile :color="`${elementProps.button.color}`" @click="openColorPicker(2)"></v-avatar>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="8">
                    <v-select v-model="elementProps.button.isExternalLink"
                              label="Gomb hivatkozás típusa"
                              :items="selectFieldOptions.buttonIsExternalLink"
                              filled
                              dense>
                    </v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select v-model="elementProps.button.isDark"
                              label="Gomb felirat színe"
                              :items="selectFieldOptions.buttonTextColor"
                              filled
                              dense>
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="elementProps.button.url"
                                  v-if="elementProps.button.isExternalLink"
                                  label="Gomb hivatkozása"
                                  placeholder="https://www.google.com"
                                  persistent-placeholder
                                  filled
                                  dense>
                    </v-text-field>
                    <v-autocomplete v-model="elementProps.button.url"
                                    v-else
                                    label="Gomb hivatkozása"
                                    :items="$store.state.general.availablePages"
                                    item-text="name"
                                    item-value="url"
                                    filled
                                    dense>
                      <template v-slot:selection="data">
                        {{ data.item.name }} - {{ data.item.group }}
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ data.item.group }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-slide-y-transition>
            </v-col>
            <!-- SECTION ACTION BUTTON END -->
            <v-col cols="12" class="py-0">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="text-subtitle-1 text--primary">
              Kép szerkesztése
            </v-col>
            <v-col cols="12" class="text-subtitle-1 text--primary">
              <v-row>
                <v-col cols="12" sm="6">
                  <div class="mx-auto" style="max-width: 400px;">
                    <v-img :src="`${publicPath}public_storage${elementProps.imagePath}`" aspect-ratio="1.7778"></v-img>
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn color="primary"
                         @click="mediaGallery.isVisible = true">
                    {{ $t('button.changeImage') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="mx-0">
            <v-col cols="12" class="pb-0">
              <v-row justify="space-between" align="center" class="mx-0">
                <span class="text-subtitle-1 text--primary">
                  A befoglaló container 100% szélességű legyen?
                </span>
                <v-switch v-model="elementProps.isContainerFluid"
                          inset>
                </v-switch>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="saveElementProps">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDITOR PANEL END -->
    <!-- MEDIA GALLERY START -->
    <template v-if="editorMode">
      <v-dialog v-model="mediaGallery.isVisible"
                persistent
                fullscreen
                transition="dialog-bottom-transition"
                scrollable>
        <v-card tile>
          <media-page :in-dialog="true"
                      v-on:paste-media="pasteMedia"
                      v-on:close-media-dialog="mediaGallery.isVisible = false"></media-page>
        </v-card>
      </v-dialog>
    </template>
    <!-- MEDIA GALLERY END -->
    <!-- COLOR PICKER POPUP START -->
    <v-dialog v-model="popupColorPicker.isVisible" max-width="340">
      <v-card>
        <v-card-title class="indigo darken-2">
          <span class="white--text">
            {{ popupColorPicker.title }}
          </span>
        </v-card-title>
        <v-card-text class="pa-5">
          <v-color-picker v-model="popupColorPicker.pickerColor"
                          mode="hexa"
                          show-swatches>
          </v-color-picker>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="popupColorPicker.isVisible = !popupColorPicker.isVisible" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="onSaveColor">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- COLOR PICKER POPUP END -->
    <!-- FORDÍTÁS DIALOG START -->
    <translation-editor-dialog :content="translationDialog"
                               :visible-elements="visibleElements"
                               v-on:@cancel="cancelNewTranslation"
                               v-on:@save="saveNewTranslation" />
  </div>
</template>

<script>
import MediaPage from '@/views/admin/MediaPage.vue';
import TranslationEditorDialog from '@/components/shared/TranslationEditorDialog.vue';

export default {
  name: 'Split1',
  components: {
    MediaPage, TranslationEditorDialog,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          isContainerFluid: false,
          titleColor: '#000000DE',
          imagePath: '',
          button: {
            isVisible: true,
            isDark: true,
            isExternalLink: false,
            color: 'blue',
            url: '',
          },
          translations: [
            {
              code: 'hu',
              name: 'Magyar',
              title: 'Lorem ipsum',
              subtitle: 'Lorem ipsum dolor sit amet',
              buttonText: 'Read more',
            },
          ],
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    await this.changeTranslations();
  },
  data() {
    return {
      editorPanel: false,
      mediaGallery: {
        isVisible: false,
      },
      /**
       * type: 1 = a címsor színe
       * type: 2 = a gomb színe
       */
      popupColorPicker: {
        isVisible: false,
        pickerColor: '#000000',
        type: 1,
        title: 'Címsor színének kiválasztása',
      },
      selectFieldOptions: {
        buttonTextColor: [
          { text: 'Fehér', value: true },
          { text: 'Fekete', value: false },
        ],
        buttonIsExternalLink: [
          { text: 'Belső hivatkozás', value: false },
          { text: 'Külső hivatkozás', value: true },
        ],
        buttonUrls: this.$store.state.general.availablePages,
      },
      currentLanguageIndex: 0,
      translationTab: 0,
      translatedTitle: '',
      translatedSubtitle: '',
      translatedButtonText: '',
      visibleElements: ['title', 'subtitle', 'button'],
    };
  },
  methods: {
    editElement() {
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    saveElement() {
      this.$emit('save-element');
    },
    saveElementProps() {
      this.translationTab = 0;
      this.changeTranslations();
      this.editorPanel = false;
    },
    onMainButtonClick() {
      if (this.elementProps.button.isExternalLink) {
        window.open(`${this.elementProps.button.url}`, '_blank');
      } else {
        this.$router.push({ path: this.elementProps.button.url });
        // window.open(`${this.elementProps.button.url}`, '_self');
      }
    },
    pasteMedia() {
      const image = this.$store.getters.getSelectedImageInMediaGallery;
      this.elementProps.imagePath = image.fileFullPath;
      this.mediaGallery.isVisible = false;
      this.$store.commit('clearSelectedImageInMediaGallery');
    },
    openColorPicker(type) {
      if (type === 1) {
        this.popupColorPicker.title = 'Címsor színének kiválasztása';
        this.popupColorPicker.pickerColor = this.elementProps.titleColor;
      } else if (type === 2) {
        this.popupColorPicker.title = 'Gomb színének kiválasztása';
        this.popupColorPicker.pickerColor = this.elementProps.button.color;
      }
      this.popupColorPicker.type = type;
      this.popupColorPicker.isVisible = true;
    },
    onSaveColor() {
      if (this.popupColorPicker.type === 1) {
        this.elementProps.titleColor = this.popupColorPicker.pickerColor;
      } else if (this.popupColorPicker.type === 2) {
        this.elementProps.button.color = this.popupColorPicker.pickerColor;
      }
      this.popupColorPicker.isVisible = false;
    },
    async changeTranslations() {
      const index = this.elementProps.translations.findIndex((item) => item.code === this.$i18n.locale.substr(0, 2));
      const currentLanguageIndex = index === -1 ? 0 : index;
      this.translatedTitle = this.elementProps.translations[currentLanguageIndex].title;
      this.translatedSubtitle = this.elementProps.translations[currentLanguageIndex].subtitle;
      this.translatedButtonText = this.elementProps.translations[currentLanguageIndex].buttonText;
    },
  },
};
</script>

<style scoped>

</style>
