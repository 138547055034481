import Vue from 'vue';

export default Vue.mixin({
  data() {
    return {
      //
    };
  },
  methods: {
    async ProductGroupConverter(arrayToConvert) {
      let input = arrayToConvert;
      for (let i = 0; i < input.length; i++) {
        switch (input[i].id) {
          case 1:
            input[i].displayName = 'iPhone';
            break;
          case 16:
            input[i].displayName = 'Apple TV';
            break;
          case 6:
            input[i].displayName = 'Mac';
            break;
          case 5:
            input[i].displayName = 'Apple Watch';
            break;
          case 4:
            input[i].displayName = 'Apple Accessories';
            break;
          case 2:
            input[i].displayName = 'iPad';
            break;
        }
      }
      return input;
    },
  },
})
