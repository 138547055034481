<template>
  <div>
    <v-container :class="editorMode ? 'b-wrapper': ''" :fluid="elementProps.isContainerFluid">
      <!-- PAGE BUILDER ACTIONBOX START -->
      <div class="pageBuilder-liveEditor-actionBox" v-if="editorMode">
        <v-row align="center" justify="start" class="mx-0">
          <div class="mx-3">
            ImageGallery1
          </div>
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="editElement">
            <v-icon >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon color="teal" class="mx-2" @click="saveElement">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon color="red" @click="deleteElement">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- PAGE BUILDER ACTIONBOX END -->
      <!-- CONTENT START -->
      <v-row justify="center">
        <template v-for="(item, index) in elementProps.images">
          <v-col cols="4" sm="3" md="2" :key="`c${index}`">
            <v-img :src="`${publicPath}public_storage${item.image}`"
                   :title="`${item.title}`"
                   contain
                   aspect-ratio="1"
                   height="100%"></v-img>
          </v-col>
        </template>
      </v-row>
      <!-- CONTENT END -->
    </v-container>
    <!-- EDITOR PANEL START -->
    <v-dialog v-model="editorPanel" persistent scrollable fullscreen>
      <v-card min-height="690">
        <v-toolbar color="primary" height="64" max-height="64" dark flat tile>
          <v-btn icon>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            ImageGallery1 - Szerkesztés
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorPanel = !editorPanel">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn icon @click="editorPanel = !editorPanel" class="pageBuilder-closeSideNav">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="my-3">
            <v-btn color="primary" @click="openMediaGallery('all')">
              {{ $t('button.addNewImage') }}
            </v-btn>
          </v-row>
          <v-row class="mb-6">
            <template v-for="(item, index) in elementProps.images">
              <v-col cols="4" sm="3" md="2" :key="`c${index}`" class="px-2">
                <v-row class="mx-0 elevation-1">
                  <v-col cols="12" class="pa-0">
                    <v-img :src="`${publicPath}public_storage${item.image}`"
                           :title="`${item.title}`"
                           contain
                           aspect-ratio="1"
                           height="100%">
                    </v-img>
                  </v-col>
                  <v-col cols="12" class="pa-2">
                    <v-row class="mx-0" justify="space-around">
                      <v-btn icon color="primary" @click="openMediaGallery(index)">
                        <v-icon>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                      <v-btn icon color="warning" @click="deleteImage(index)">
                        <v-icon>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </template>
          </v-row>
          <v-divider></v-divider>
          <v-row class="mx-0">
            <v-col cols="12" class="pb-0">
              <v-row justify="space-between" align="center" class="mx-0">
                <span class="text-subtitle-1 text--primary">
                  A befoglaló container 100% szélességű legyen?
                </span>
                <v-switch v-model="elementProps.isContainerFluid"
                          inset>
                </v-switch>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="editorPanel = !editorPanel" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="editorPanel = !editorPanel">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDITOR PANEL END -->
    <!-- MEDIA GALLERY START -->
    <template v-if="editorMode">
      <v-dialog v-model="mediaGallery.isVisible"
                persistent
                fullscreen
                transition="dialog-bottom-transition"
                scrollable>
        <v-card tile>
          <media-page :in-dialog="true"
                      v-on:paste-media="pasteMedia"
                      v-on:close-media-dialog="mediaGallery.isVisible = false"></media-page>
        </v-card>
      </v-dialog>
    </template>
    <!-- MEDIA GALLERY END -->
  </div>
</template>

<script>
import MediaPage from '@/views/admin/MediaPage.vue';

export default {
  name: 'ImageGallery2',
  components: {
    MediaPage,
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          isContainerFluid: false,
          images: [
            {
              image: '',
              title: '',
            },
            {
              image: '',
              title: '',
            },
            {
              image: '',
              title: '',
            },
            {
              image: '',
              title: '',
            },
          ],
        };
      },
    },
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editorPanel: false,
      mediaGallery: {
        isVisible: false,
        imageIndex: 1,
      },
    };
  },
  methods: {
    editElement() {
      this.editorPanel = true;
    },
    deleteElement() {
      this.$emit('delete-element');
    },
    saveElement() {
      this.$emit('save-element');
    },
    openMediaGallery(index) {
      this.mediaGallery.imageIndex = index;
      this.mediaGallery.isVisible = true;
    },
    deleteImage(index) {
      this.elementProps.images.splice(index, 1);
    },
    pasteMedia() {
      const image = this.$store.getters.getSelectedImageInMediaGallery;
      if (this.mediaGallery.imageIndex === 'all') {
        this.elementProps.images.push({
          image: image.fileFullPath,
          title: '',
        });
      } else {
        this.elementProps.images[this.mediaGallery.imageIndex].image = image.fileFullPath;
      }
      this.mediaGallery.isVisible = false;
      this.$store.commit('clearSelectedImageInMediaGallery');
    },
  },
};
</script>

<style scoped>

</style>
