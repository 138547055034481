<template>
  <div v-frag>
    <template v-if="elementProps.noDefaultFooter">
      <div style="display: none;"></div>
    </template>
    <template v-else>
      <v-container fluid class="pa-0">
        <!-- FOOTER WIDGET AREA START -->
        <template v-if="elementProps.footerWidgetArea.isVisible">
          <v-container fluid
                       class="pa-0 footerCopyrightArea"
                       :style="`background: ${elementProps.footerWidgetArea.backgroundColor};`">
            <v-container :fluid="elementProps.footerWidgetArea.fluid"
                         :class="`pt-${elementProps.footerWidgetArea.paddingTop} pb-${elementProps.footerWidgetArea.paddingBottom}`">
              <v-row>
                <template v-for="(col, lindex) in elementProps.footerWidgetArea.layoutType">
                  <v-col :key="`l${lindex}`"
                         cols="12"
                         sm="6"
                         md="4"
                         class="text-body-2"
                         :lg="col">
                    <div :style="`color: ${elementProps.footerWidgetArea.textColor};`"
                         v-html="elementProps.boxes[lindex].translations[elementProps.boxes[lindex].translations.findIndex(
                      (item) => item.code === $i18n.locale.substr(0, 2)
                      )].text"></div>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-container>
        </template>
        <!-- FOOTER WIDGET AREA END -->
        <!-- COPYRIGHT AREA START -->
        <template v-if="elementProps.copyRightArea.isVisible">
          <v-container fluid
                       class="pa-0 footerCopyrightArea"
                       :style="`background: ${elementProps.copyRightArea.backgroundColor};`">
            <v-container :fluid="elementProps.copyRightArea.fluid"
                         :class="`pt-${elementProps.copyRightArea.paddingTop} pb-${elementProps.copyRightArea.paddingBottom}`">
              <v-row :justify="elementProps.copyRightArea.textAlign"
                     :class="`text-${elementProps.copyRightArea.textSize}`"
                     :style="`color: ${elementProps.copyRightArea.textColor};`">
                <div v-html="elementProps.translations[elementProps.translations.findIndex((item) => item.code === $i18n.locale.substr(0, 2))].text"></div>
              </v-row>
            </v-container>
          </v-container>
        </template>
        <!-- COPYRIGHT AREA END -->
      </v-container>
    </template>
  </div>
</template>

<script>
export default {
  name: 'GlobalFooter',
  components: {
    //
  },
  props: {
    elementProps: {
      type: Object,
      default() {
        return {
          footerWidgetArea: {
            backgroundColor: '#AEAEAE',
            textColor: '#333333',
            isVisible: true,
            fluid: true,
            layoutType: [6,6],
            paddingBottom: 3,
            paddingTop: 3,
          },
          copyRightArea: {
            backgroundColor: '#222222',
            isVisible: true,
            fluid: true,
            paddingBottom: 3,
            paddingTop: 3,
            textAlign: 'center',
            textColor: 'white',
            textSize: 'caption',
          },
          boxes: [
            {
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  text: '<p>Porem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                },
              ],
            },
            {
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                },
              ],
            },
            {
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  text: '<p>Sorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                },
              ],
            },
            {
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  text: '<p>Forem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                },
              ],
            },
          ],
          translations: [
            {
              code: 'hu',
              name: 'Magyar',
              text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
            },
          ],
        };
      },
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
