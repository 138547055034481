<template>
  <v-container class="py-0">
    <v-toolbar elevation="0" class="transparent" :height="$APPBARHEIGHT">
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <template v-for="(item, index) in mainMenu">
          <template v-if="item.children.length < 1">
            <v-btn color="primary"
                   text
                   @click="onItemClick(item)"
                   :key="index">
              {{ item.label }}
            </v-btn>
          </template>
          <template v-if="item.children.length > 0">
            <v-menu open-on-hover
                    offset-y
                    :key="index">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary"
                       text
                       v-bind="attrs"
                       v-on="on"
                       :key="index">
                  {{ item.label }}
                </v-btn>
              </template>
              <v-list>
                <template v-for="(child, cindex) in item.children">
                  <v-list-item :key="cindex" @click="onItemClick(child)">
                    <v-list-item-title>
                      {{ child.label }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </template>
        </template>
      </v-toolbar-items>
    </v-toolbar>
  </v-container>
</template>

<script>
export default {
  name: 'AppBarVersion1',
  components: {
    //
  },
  props: {
    mainMenu: {
      type: Array,
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    onItemClick(item) {
      this.$emit('on-menu-item-click', item);
    },
  },
};
</script>

<style scoped>

</style>
