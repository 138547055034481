import {
  APIPOST, APIGET, APIPATCH,
} from '@/utils/apiHelper';
import {
  getToken, saveToken, destroyToken, saveWebUser, destroyWebUser, getWebUser,
} from '@/utils/jwtHelper';

// initial state
const state = {
  user: {},
  isAuthenticated: false,
};

// getters
const getters = {
  //
};

// actions
const actions = {
  /**
   *
   * login
   *
   * @param context
   * @param credentials
   * @returns {Promise<{data: [], message: string, status: number}>}
   * @constructor
   */
  async Login(context, credentials) {
    const response = await APIPOST('login', credentials);
    if (response.status === 200) {
      context.commit('setUser', response.data.data);
    }
    return response.data;
  },
  /**
   *
   * logout
   *
   * @return {Promise<void>}
   * @constructor
   */
  async Logout(context) {
    const response = await APIPATCH('logout', {}, true);
    await context.commit('purgeUser');
  },
  async CheckAuthentication(context) {
    const token = await getToken();
    if (token) {
      const response = await APIGET('login/validator', {}, true);
      if (response.status === 200) {
        if (context.state.user === {}) {
          await context.commit('setUser', await getWebUser());
        }
        await context.commit('updateAuth');
      } else {
        await context.commit('purgeUser');
      }
    }
    return 'done';
  },
};

// mutations
const mutations = {
  async setUser(stat, credentials) {
    saveToken(credentials.hash);
    saveWebUser(credentials);
    state.user = credentials;
    state.isAuthenticated = true;
  },
  async updateAuth(stat) {
    state.isAuthenticated = true;
  },
  async purgeUser(stat) {
    state.user = {};
    state.isAuthenticated = false;
    window.sessionStorage.clear();
    destroyToken();
    destroyWebUser();
    window.location.reload();
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
