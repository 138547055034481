<template>
  <v-container fluid class="copyRightColor">
    <v-container>
      <v-row justify="center" align="center">
        <a class="white--text caption cmsfooterlink" @click="$router.push({ name: 'admin-login'})">
          {{ $t('navigation.login') }}
        </a>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'cmsFooter',
  components: {
    //
  },
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
