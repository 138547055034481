import { APIGET, APIPOST } from '@/utils/apiHelper';
// initial state
const state = {
  pageBuilderElements: [
    {
      displayName: 'Article Title 1',
      elementName: 'article-title-1',
      elementProperties: {
        isContainerFluid: false,
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Lorem ipsum dolor sit amet',
            subtitle: 'Lorem ipsum',
          },
        ],
      },
    },
    {
      displayName: 'Goole map',
      elementName: 'my-googlemap',
      elementProperties: {
        isContainerFluid: false,
        xsHeight: '100%',
        smHeight: '540px',
        mdHeight: '540px',
        lgHeight: '540px',
        xlHeight: '440px',
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Lorem ipsum dolor sit amet',
            text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
          },
        ],
      },
    },
    {
      displayName: 'Article Layout 1',
      elementName: 'article-layout-1',
      elementProperties: {
        isContainerFluid: false,
        images: [
          '',
        ],
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Lorem ipsum',
            text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
          },
        ],
      },
    },
    {
      displayName: 'Article Layout 2',
      elementName: 'article-layout-2',
      elementProperties: {
        isContainerFluid: false,
        imgOnLeft: 2,
        imgAlign: 'start',
        textAlign: 'start',
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Lorem ipsum dolor sit amet',
            text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
          },
        ],
        images: [
          '/page/pic/2021/02/26/pic_6038eee244fc7_city.jpg',
          '/page/pic/2021/02/26/pic_6038eee244fc7_city.jpg',
          '/page/pic/2021/02/26/pic_6038eee244fc7_city.jpg',
        ],
      },
    },
    {
      displayName: 'Article Layout 3',
      elementName: 'article-layout-3',
      elementProperties: {
        isContainerFluid: false,
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Lorem ipsum dolor sit amet',
            text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
          },
        ],
        images: [
          '/page/pic/2021/02/26/pic_6038eee244fc7_city.jpg',
          '/page/pic/2021/02/26/pic_6038eee244fc7_city.jpg',
          '/page/pic/2021/02/26/pic_6038eee244fc7_city.jpg',
        ],
      },
    },
    {
      displayName: 'Article Layout 4',
      elementName: 'article-layout-4',
      elementProperties: {
        isContainerFluid: false,
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Lorem ipsum dolor sit amet',
            text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
          },
        ],
      },
    },
    {
      displayName: 'Header Type 1',
      elementName: 'header-1',
      elementProperties: {
        titleFont: 'text-h3',
        subtitleFont: 'text-subtitle-1',
        textColor: '#FFFFFF',
        backgroundColorTop: '#42A5F5',
        backgroundColorBottom: '#1976D2',
        images: [],
        backgroundType: 'color',
        backgroundPosition: 'center',
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Szolgáltatásaink',
            subtitle: 'Lorem ipsum',
          },
        ],
      },
    },
    {
      displayName: 'Card 1',
      elementName: 'card-1',
      elementProperties: {
        isContainerFluid: false,
        boxes: [
          {
            icon: {
              icon: 'emoticon-cool-outline',
              color: 'primary',
            },
            translations: [
              {
                code: 'hu',
                name: 'Magyar',
                title: 'Card title',
                text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                buttonText: 'Tovább',
              },
            ],
            button: {
              isVisible: true,
              isDark: true,
              isExternalLink: false,
              color: 'primary',
              url: '',
            },
          },
        ],
        button: {
          isVisible: true,
          isDark: true,
          isExternalLink: false,
          color: 'red',
          translations: [
            {
              code: 'hu',
              name: 'Magyar',
              buttonText: 'Bővebben',
            },
          ],
          url: '',
        },
      },
    },
    {
      displayName: 'Card 2',
      elementName: 'card-2',
      elementProperties: {
        isContainerFluid: false,
        mainColor: '#1565C0',
        boxes: [
          {
            icon: {
              icon: 'emoticon-cool-outline',
            },
            translations: [
              {
                code: 'hu',
                name: 'Magyar',
                title: 'Card title',
                text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
              },
            ],
          },
          {
            icon: {
              icon: 'emoticon-cool-outline',
            },
            translations: [
              {
                code: 'hu',
                name: 'Magyar',
                title: 'Card title',
                text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
              },
            ],
          },
          {
            icon: {
              icon: 'emoticon-cool-outline',
            },
            translations: [
              {
                code: 'hu',
                name: 'Magyar',
                title: 'Card title',
                text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
              },
            ],
          },
        ],
        button: {
          isVisible: true,
          isDark: true,
          isExternalLink: false,
          color: 'red',
          text: 'Bővebben',
          url: '',
        },
      },
    },
    {
      displayName: 'Card 3',
      elementName: 'card-3',
      elementProperties: {
        isContainerFluid: false,
        boxes: [
          {
            image: [
              //
            ],
            translations: [
              {
                code: 'hu',
                name: 'Magyar',
                title: 'Card title'
              },
            ]
          },
          {
            image: [
              //
            ],
            translations: [
              {
                code: 'hu',
                name: 'Magyar',
                title: 'Card title'
              },
            ]
          },
          {
            image: [
              //
            ],
            translations: [
              {
                code: 'hu',
                name: 'Magyar',
                title: 'Card title'
              },
            ]
          },
        ],
        titleColor: '#000000',
        titleFontSize: '',
      },
    },
    {
      displayName: 'List 1',
      elementName: 'list-1',
      elementProperties: {
        title: 'Miért válassz minket?',
        icon: {
          icon: 'arrow-right-circle',
          color: '#B3E5FC',
        },
        boxes: [
          {
            translations: [
              {
                code: 'hu',
                name: 'Magyar',
                title: 'Gyors javítás',
                subtitle: 'Rövid átfutási idővel, garantált minőséggel.',
              },
            ],
          },
          {
            translations: [
              {
                code: 'hu',
                name: 'Magyar',
                title: 'Gyors javítás',
                subtitle: 'Rövid átfutási idővel, garantált minőséggel.',
              },
            ],
          },
          {
            translations: [
              {
                code: 'hu',
                name: 'Magyar',
                title: 'Gyors javítás',
                subtitle: 'Rövid átfutási idővel, garantált minőséggel.',
              },
            ],
          },{
            translations: [
              {
                code: 'hu',
                name: 'Magyar',
                title: 'Gyors javítás',
                subtitle: 'Rövid átfutási idővel, garantált minőséggel.',
              },
            ],
          }
        ],
      },
    },
    {
      displayName: 'GYIK',
      elementName: 'faq',
      elementProperties: {
        color: '#1565C0',
        list: [
          {
            question: 'Csak regisztráció után vehetem igénybe a szolgáltatásokat?',
            answer: 'Igen, csak akkor tudod igénybe venni és megrendelni a szolgáltatásainkat, ha előbb regisztrálsz'
              + 'weboldalunkon. Ehhez szükséges megadnod néhány személyes adatodat: a teljes nevedet, e-mail címedet és telefonszámodat. ',
          },
          {
            question: 'Csak regisztráció után vehetem igénybe a szolgáltatásokat?',
            answer: 'Igen, csak akkor tudod igénybe venni és megrendelni a szolgáltatásainkat, ha előbb regisztrálsz'
              + 'weboldalunkon. Ehhez szükséges megadnod néhány személyes adatodat: a teljes nevedet, e-mail címedet és telefonszámodat. ',
          },
          {
            question: 'Csak regisztráció után vehetem igénybe a szolgáltatásokat?',
            answer: 'Igen, csak akkor tudod igénybe venni és megrendelni a szolgáltatásainkat, ha előbb regisztrálsz'
              + 'weboldalunkon. Ehhez szükséges megadnod néhány személyes adatodat: a teljes nevedet, e-mail címedet és telefonszámodat. ',
          },
        ],
      },
    },
    {
      displayName: 'Stripe 1',
      elementName: 'stripe-1',
      elementProperties: {
        title: {
          color: '#FFFFFF',
        },
        backgroundColor: '#263238',
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Több mint 350 munkatárs',
            buttonText: 'Tovább',
          },
        ],
        button: {
          isVisible: true,
          isDark: false,
          isExternalLink: false,
          color: '#FFFFFF',
          url: '',
        },
      },
    },
    {
      displayName: 'Basic Slider',
      elementName: 'basic-slider',
      elementProperties: {
        sliderProps: {
          xsHeight: '540',
          smHeight: '540',
          mdHeight: '540',
          lgHeight: '540',
          xlHeight: '540',
          interval: '6000',
          delimiterIcon: 'mdi-circle',
          showDelimiterBackground: true,
          showDelimiter: true,
          showArrows: true,
          showArrowsOnHover: true,
          arrowsIconSet: {
            nextIcon: 'mdi-chevron-right',
            prevIcon: 'mdi-chevron-left',
          },
        },
        slides: [
          {
            backgroundType: 'image',
            backgroundColorBottom: '#00838F',
            backgroundColorTop: '#00ACC1',
            backgroundImage: '',
            buttonAlign: 'left',
            textAlign: 'right',
            slideAlign: 'center',
            showText: true,
            showImage: true,
            imageSrc: '',
            text: {
              translations: [
                {
                  code: 'hu',
                  name: 'Magyar',
                  title: 'Több mint 350 munkatárs',
                  subtitle: 'Lorem ipsum',
                  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tortor ante, imperdiet id gravida nec, aliquet a sapien.',
                  button: 'Bővebben',
                },
              ],
              title: {
                textColor: '#FFFFFF',
                textAlign: 'left',
                textSize: 'text-h2',
              },
              subtitle: {
                textColor: '#FFFFFF',
                textAlign: 'left',
                textSize: 'text-h5',
              },
              text: {
                textColor: '#FFFFFF',
                textAlign: 'left',
                textSize: 'text-subtitle-1',
              },
            },
            button: {
              isVisible: true,
              isDark: true,
              isExternalLink: false,
              color: '#E91E63',
              url: '',
              display: 1,
              isBlock: 1,
              rounded: 1,
              size: 1,
            },
          },
        ],
      },
    },
    {
      displayName: 'Contact Form 1',
      elementName: 'contact-form-1',
      elementProperties: {
        isContainerFluid: true,
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Lorem ipsum dolor sit amet',
            text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
          },
        ],
        contact: {
          home: {
            text: '1234 Kiskunfélegyháza, Majoros utca 89/c',
            icon: 'mdi-domain',
            show: true,
          },
          phone: {
            text: '+36-20/123-4567',
            icon: 'mdi-phone',
            show: true,
          },
          email: {
            text: 'info@valami.io',
            icon: 'mdi-email',
            show: true,
          },
        },
      },
    },
    {
      displayName: 'Aktuális állások lista 1',
      elementName: 'actual-job-list-1',
      elementProperties: {},
    },
    {
      displayName: 'Split 1',
      elementName: 'split-1',
      elementProperties: {
        isContainerFluid: false,
        titleColor: '#000000DE',
        imagePath: '',
        button: {
          isVisible: true,
          isDark: true,
          isExternalLink: false,
          color: 'blue',
          url: '',
        },
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Title',
            subtitle: 'Subtitle',
            buttonText: 'Button text',
          },
        ],
      },
    },
    {
      displayName: 'Image Gallery 1',
      elementName: 'image-gallery-1',
      elementProperties: {
        isContainerFluid: false,
        images: [
          '',
          '',
          '',
          '',
        ],
      },
    },
    {
      displayName: 'Image Gallery 2',
      elementName: 'image-gallery-2',
      elementProperties: {
        isContainerFluid: false,
        images: [
          {
            image: '',
            title: '',
          },
          {
            image: '',
            title: '',
          },
          {
            image: '',
            title: '',
          },
          {
            image: '',
            title: '',
          },
        ],
      },
    },
    {
      displayName: 'Image Gallery 3',
      elementName: 'image-gallery-3',
      elementProperties: {
        isContainerFluid: false,
        images: [
          '',
          '',
          '',
          '',
          '',
        ],
      },
    },
    {
      displayName: 'About 1',
      elementName: 'about-1',
      elementProperties: {
        isContainerFluid: false,
        titleColor: '#000000DE',
        imagePath: '',
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Minta József',
            text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ut risus eros. '
              + 'Nulla facilisi. <i>Nunc blandit metus nisi</i>, in tempor nibh molestie pretium. Nunc varius, '
              + 'nibh ac aliquet cursus, nisl nulla finibus nisi</p><p>Nec sollicitudin lectus odio eget quam. '
              + 'Praesent suscipit blandit lectus tempus rhoncus. Morbi maximus diam nisi, vel malesuada.</p>',
          },
        ],
      },
    },
    {
      displayName: 'Article Layout 5',
      elementName: 'article-layout-5',
      elementProperties: {
        isContainerFluid: false,
        imgOnLeft: 2,
        imgAlign: 'start',
        textAlign: 'start',
        images: [
          '', '',
        ],
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Címsor',
            text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ut risus eros. '
              + 'Nulla facilisi. <i>Nunc blandit metus nisi</i>, in tempor nibh molestie pretium. Nunc varius, '
              + 'nibh ac aliquet cursus, nisl nulla finibus nisi</p><p>Nec sollicitudin lectus odio eget quam. '
              + 'Praesent suscipit blandit lectus tempus rhoncus. Morbi maximus diam nisi, vel malesuada.</p>',
          },
        ],
      },
    },
    {
      displayName: 'Article Layout 6',
      elementName: 'article-layout-6',
      elementProperties: {
        isContainerFluid: false,
        images: [
          '',
        ],
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Jövőképünk',
            text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
            text2: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
          },
        ],
      },
    },
    {
      displayName: 'Article Layout 7',
      elementName: 'article-layout-7',
      elementProperties: {
        isContainerFluid: true,
        images: [
          '',
        ],
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Több mint 350 munkatárs',
            text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tortor ante, imperdiet id gravida nec, aliquet a sapien.</p>',
          },
        ],
      },
    },
    {
      displayName: 'Promobox',
      elementName: 'promobox',
      elementProperties: {
        isContainerFluid: false,
        boxes: [
          {
            justify: {
              title: 'center',
              text: 'center',
              button: 'center',
            },
            button: {
              isVisible: true,
              isDark: true,
              isExternalLink: false,
              color: 'primary',
              url: '',
            },
            translations: [
              {
                code: 'hu',
                name: 'Magyar',
                title: 'Több mint 350 munkatárs',
                text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tortor ante, imperdiet id gravida nec, aliquet a sapien.</p>',
                buttonText: 'Tovább',
              },
            ],
          },
          {
            justify: {
              title: 'center',
              text: 'center',
              button: 'center',
            },
            button: {
              isVisible: true,
              isDark: true,
              isExternalLink: false,
              color: 'primary',
              url: '',
            },
            translations: [
              {
                code: 'hu',
                name: 'Magyar',
                title: 'Több mint 350 munkatárs',
                text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tortor ante, imperdiet id gravida nec, aliquet a sapien.</p>',
                buttonText: 'Tovább',
              },
            ],
          },
        ],
      },
    },
    {
      displayName: 'Futárrendelés űrlap',
      elementName: 'apple-form',
      elementProperties: {
        subtitle: 'Futárrendelés űrlap',
        isContainerFluid: false,
      },
    },
    {
      displayName: 'Horizontális helyköz',
      elementName: 'basic-spacer',
      elementProperties: {
        height: 10,
      },
    },
    {
      displayName: 'Apple slider',
      elementName: 'slider-apple',
      elementProperties: {
        height: 10,
      },
    },
    {
      displayName: 'GYIK 2',
      elementName: 'faq2',
      elementProperties: {
        color: '#000000',
        questions: [
          {
            translations: [
              {
                code: 'hu',
                title: 'Csak regisztráció után vehetem igénybe a szolgáltatásokat?',
                text: 'Nem, regisztráció nélkül is tudsz szolgáltatást igényelni és az igény leadás során megadott adatokkal'
                  + ' lehetőséged nyílik regisztrálni is.',
              },
            ],
            cat: 1,
          },
          {
            translations: [
              {
                code: 'hu',
                name: 'Magyar',
                title: 'Csak regisztráció után vehetem igénybe a szolgáltatásokat?',
                text: 'Nem, regisztráció nélkül is tudsz szolgáltatást igényelni és az igény leadás során megadott adatokkal'
                  + ' lehetőséged nyílik regisztrálni is.',
              },
            ],
            cat: 1,
          },
        ],
        categories: [
          {
            translations: [
              {
                code: 'hu',
                name: 'Magyar',
                title: 'Témakör címe',
              },
            ],
            id: 1,
            icon: 'account-plus',
          },
        ],
      },
    },
    {
      displayName: 'Időpont foglalás',
      elementName: 'appointment-booking',
      elementProperties: {
        address: {
          city: 'Budapest',
          phoneNumber: '20/123-4567, 30/987-6543',
          street: 'Valamilyen utca 67.',
          zipCode: '1112',
        },
        bookingDisplayDaysLength: 3,
        isContainerFluid: false,
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Időpont választása',
            title2: 'Adatok megadása',
            subtitle: 'Foglalja le az Önnek megfelelő szabad időpontot!',
            subtitle2: 'Kérjük, adja meg a foglalás véglegesítéséhez szükséges adatokat.',
          },
        ],
      },
    },
    {
      displayName: 'Árlista',
      elementName: 'price-list-1',
      elementProperties: {
        button: {
          isVisible: true,
          isDark: true,
          isExternalLink: false,
          color: 'blue',
          url: '',
        },
        isContainerFluid: false,
        translations: [
          {
            code: 'hu',
            buttonText: 'Read more',
            name: 'Magyar',
            title: 'Válaszd ki a javítani kívánt készüléked',
            title2: 'A javítás ára',
            text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
            text2: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
            text3: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
            text4: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
          },
        ],
      },
    },
    {
      displayName: 'Simple image 2',
      elementName: 'simple-image-2',
      elementProperties: {
        isContainerFluid: true,
        image: [
          //
        ],
        title: 'Kép címe',
        width: 300,
        height: 300,
        maxWidth: 300,
        maxHeight: 300,
        cornerRadius: 'rounded-0',
      },
    },
    {
      displayName: 'Curved Box',
      elementName: 'curved-box',
      elementProperties: {
        titleFont: 'text-h3',
        textColor: '#FFFFFF',
        backgroundColorTop: '#8a8a8a',
        backgroundColorBottom: '#3d3d3d',
        cornerRadius: 'rounded-xl',
        maxWidth: 100,
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Szolgáltatásaink',
            subtitle: 'Lorem ipsum',
          },
        ],
        isContainerFluid: false,
      },
    },
  ],
  basicElements: [],
};

// getters
const getters = {
  //
};

// actions
const actions = {
  async availablePageBuilderElements(context) {
    return context.state.pageBuilderElements;
  },
  async showBasicElements(context) {
    const response = await APIGET('basic-elements');
    if (response.status === 200) {
      context.commit('setBasicElements', response.data.data);
      return true;
    }
    return false;
  },
  async convertBasicElemetContent(context) {
    // console.log('start convertBasicElemetContent...');
    context.state.basicElements.forEach((item, index) => {
      const temp = {};
      temp.i = index;
      if (index < 1212121) {
        temp.contentJSON = JSON.parse(item.contentJson);
        context.commit('setContentConvertedJSON', temp);
      }
    });
    context.state.basicElements.sort((a, b) => ((a.contentConverted.displayName > b.contentConverted.displayName) ? 1 : -1));
    return context.state.basicElements;
  },
  async saveNewBasicElement(context, credentials) {
    const response = await APIPOST('basic-elements', credentials, true);
    return response.data;
  },
};

// mutations
const mutations = {
  setBasicElements(state, payload) {
    state.basicElements = payload;
  },
  setContentConvertedJSON(state, payload) {
    state.basicElements[payload.i].contentConverted = payload.contentJSON;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
